import API_URL from './apiUrl';

class RequestService {
    addRequest(requestData) {
        return API_URL.post("/request/create/", requestData);
    }

    getAllRequests() {
        return API_URL.get("/request/requests");
    }

    fetchPendingRequests() {
        return API_URL.get(`/request/requests/pending/requests`);
    }

    updateRequest(requestId, updatedRequest) {
        return API_URL.put(`/request/update/${requestId}`, updatedRequest);
    }

    fetchRequest(requestId) {
        return API_URL.get(`/request/${requestId}`);
    }

    deleteRequest(requestId) {
        return API_URL.delete(`/request/delete/${requestId}`);
    }

    fetchUserRequests(userId) {
        return API_URL.get(`/request/users/user/${userId}`);
    }
}

export default new RequestService();

// import API_URL from 'API_URL';
// import API_URL from './apiUrl';

// class RequestService {
//     addRequest(requestData) {
//         return API_URL.post(API_URL + "/request/create/", requestData);
//     }

//     getAllRequests() {
//         return API_URL.get(API_URL + "/request/requests");
//     }

//     fetchPendingRequests() {
//         return API_URL.get(`${API_URL}/request/requests/pending/requests`);
//     }

//     updateRequest(requestId, updatedRequest) {
//         return API_URL.put(`${API_URL}/request/update/${requestId}`, updatedRequest);
//     }

//     fetchRequest(requestId) {
//         return API_URL.get(`${API_URL}/request/${requestId}`);
//     }

//     deleteRequest(requestId) {
//         return API_URL.delete(`${API_URL}/request/delete/${requestId}`);
//     }
// }

// export default new RequestService();