import {
    CREATE_PROJECT_POLYGON_SUCCESS,
    CREATE_PROJECT_POLYGON_FAILURE,
  } from '../actions/types';
const initialState = {
  polygon: null,
  polygons: [],
  error: null,
};

const polygonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECT_POLYGON_SUCCESS:
      return {
        ...state,
        polygon: action.payload,
        error: null,
      };
    case CREATE_PROJECT_POLYGON_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default polygonsReducer;