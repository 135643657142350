import {
    CREATE_TACHE_SUCCESS,
    CREATE_TACHE_FAILURE,
    UPDATE_TACHE_SUCCESS,
    UPDATE_TACHE_FAILURE,
    GET_TACHE_SUCCESS,
    GET_TACHE_FAILURE,
    DELETE_TACHE_SUCCESS,
    DELETE_TACHE_FAILURE,
    GET_ALL_TACHES_SUCCESS,
    GET_ALL_TACHES_FAILURE,
    GET_TACHE_BY_USER_SUCCESS,
    GET_TACHE_BY_USER_FAILURE,
    CONFIRM_TACHE_SUCCESS,
    CONFIRM_TACHE_FAILURE,
    GET_TACHE_BY_PROJECTID_SUCCESS,
    GET_TACHE_BY_PROJECTID_FAILURE,
    RESSIGN_TACHE_SUCCESS,
    RESSIGN_TACHE_FAILURE,
    GET_USER_TACHES_SUCCESS,
    GET_USER_TACHES_FAILURE,
} from '../actions/types';

const initialState = {
    tache: null,
    taches: [],
    allTaches: [],
    userTaches: [],
    error: null,
    err: {
        message: null,
        status: null,
    },
};

const tachesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TACHE_SUCCESS:
            return {
                ...state,
                tache: action.payload,
                err: { message: null, status: null },
            };
        case CREATE_TACHE_FAILURE:
            return {
                ...state,
                err: {
                    message: action.payload.message || "Error assigning task.",
                    status: action.payload.status || 500,
                },
            };
        case RESSIGN_TACHE_SUCCESS:
            return {
                ...state,
                tache: action.payload,
                error: null,
            };
        case RESSIGN_TACHE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_TACHE_SUCCESS:
            return {
                ...state,
                tache: action.payload,
                error: null,
            };
        case UPDATE_TACHE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case CONFIRM_TACHE_SUCCESS:
            return {
                ...state,
                tache: action.payload,
                error: null,
            };
        case CONFIRM_TACHE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_TACHE_SUCCESS:
            return {
                ...state,
                tache: action.payload,
                error: null,
            };
        case GET_TACHE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_TACHE_SUCCESS:
            return {
                ...state,
                tache: null,
                error: null,
            };
        case DELETE_TACHE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_TACHES_SUCCESS:
            return {
                ...state,
                allTaches: action.payload,
                error: null,
            };
        case GET_ALL_TACHES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_TACHE_BY_USER_SUCCESS:
            return {
                ...state,
                taches: action.payload,
                error: null,
            };
        case GET_TACHE_BY_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_USER_TACHES_SUCCESS:
            return {
                ...state,
                userTaches: action.payload,
                error: null,
            };
        case GET_USER_TACHES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_TACHE_BY_PROJECTID_SUCCESS:
            return {
                ...state,
                taches: action.payload,
                error: null,
            };
        case GET_TACHE_BY_PROJECTID_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default tachesReducer;