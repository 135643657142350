import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createTaux } from '../../actions/TauxActions';
import {
    Box,
    Button,
    TextField,
    Typography,
    Grid,
    Snackbar,
    SnackbarContent,
    IconButton,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { Close as CloseIcon } from '@mui/icons-material';


const CreateTaux = () => {
    const dispatch = useDispatch();
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const [tacheData, setTacheData] = useState({
        name: '',
        taux: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTacheData({
            ...tacheData,
            [name]: value,
        });
    };

    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = () => {
        setErrorSnackbarOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(createTaux(tacheData))
            .then(() => {
                setSnackbarType('success');
                setSnackbarMessage('Taux created successfully');
                setSuccessSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarType('error');
                setSnackbarMessage(`Error creating Taux: ${error.message}`);
                setErrorSnackbarOpen(true);
            });
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography textAlign={'center'} variant="h4" gutterBottom>
                Créer un taux
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Task Name"
                            name="name"
                            value={tacheData.name}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="taux"
                            name="taux"
                            value={tacheData.deadline}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button type="submit" variant="contained" color="primary">
                        Créer
                    </Button>
                </Box>
            </form>

            <Snackbar
                open={successSnackbarOpen || errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={
                    snackbarType === 'success'
                        ? handleSuccessSnackbarClose
                        : handleErrorSnackbarClose
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    style={{
                        backgroundColor:
                            snackbarType === 'success' ? 'green' : 'red', 
                    }}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={
                                snackbarType === 'success'
                                    ? handleSuccessSnackbarClose
                                    : handleErrorSnackbarClose
                            }
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        </DashboardLayout>
    );
};

export default CreateTaux;
