import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    DialogTitle,
    Snackbar,
    Grid,
    Card,
    CardContent,
    Box,
    Stack,
    Alert,
} from "@mui/material";
import MDButton from "../../components/MDButton";
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { encodeId } from "../../actions/Crypte";
import FileUpload from "./UploadFiles";
import OperationFiles from "./FilesList";
import StatisticsServices from "../../services/statisticsServices";

const OperationPreview = ({ selectedItem }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isFilesDialogOpen, setIsFilesDialogOpen] = useState(false);
    const handleExportArchive = async (folder) => {
        try {
            const response = await StatisticsServices.downloadArchive(folder);

            const blob = new Blob([response.data], { type: 'application/zip' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'archive.zip';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting archive:', error);
        }
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" color="info" gutterBottom>
                                Type:
                            </Typography>
                            <Typography>{selectedItem?.type}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" color="info" gutterBottom>
                                Compte:
                            </Typography>
                            <Typography>{selectedItem?.compte}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" color="info" gutterBottom>
                                Nom du Compte:
                            </Typography>
                            <Typography>{selectedItem?.nameCompte}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <PaidIcon fontSize="large" sx={{ mr: 1 }} color="info" />
                                <Typography variant="h6" color="info" gutterBottom>
                                    PRIX
                                </Typography>
                            </Stack>
                            <Typography component="div" variant="h5" gutterBottom>
                                {selectedItem?.prix}&nbsp;
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="success"
                                    sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}
                                >
                                    MAD
                                </Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <CalendarMonthIcon fontSize="large" sx={{ mr: 1 }} color="info" />
                                <Typography variant="h6" color="info" gutterBottom>
                                    DATE
                                </Typography>
                            </Stack>
                            <Typography component="div" variant="h5" gutterBottom>
                                {selectedItem?.date}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <MDButton
                        onClick={() => setIsDialogOpen(true)}
                        size='small'
                        color='info'
                    >
                        Upload Files
                    </MDButton>
                    <MDButton
                        onClick={() => setIsFilesDialogOpen(true)}
                        size='small'
                        color='warning'
                        className='ms-2'
                    >
                        Liste des fichiers
                    </MDButton>
                    <MDButton
                        onClick={() => handleExportArchive(`operations-bancaires.${selectedItem?.id}`)}
                        size='small'
                        color='success'
                        className='ms-2'
                    >
                        Exporter L'archive
                    </MDButton>
                </Grid>
                <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    {selectedItem && (
                        <>
                            <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                                Détails de l'opération
                            </DialogTitle>
                            <DialogContent>
                                <FileUpload operationId={selectedItem?.id} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setIsDialogOpen(false)} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
                <Dialog
                    open={isFilesDialogOpen}
                    onClose={() => setIsFilesDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    {selectedItem && (
                        <>
                            <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                                Détails de l'opération
                            </DialogTitle>
                            <DialogContent>
                                <OperationFiles operationId={selectedItem?.id} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setIsFilesDialogOpen(false)} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            </Grid>
        </>
    )
}

export default OperationPreview;