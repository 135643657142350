import API_URL from './apiUrl';

class ClientServices {
  createClient(clientData) {
    return API_URL.post("/clients/create", clientData);
  }

  getAllClient() {
    return API_URL.get('/clients');
  }

  updateClient(clientId, updatedClient) {
    return API_URL.put(`/clients/update/${clientId}`, updatedClient);
  }

  fetchClient(clientId) {
    return API_URL.get(`/clients/${clientId}`);
  }

  deleteClient(clientId) {
    return API_URL.delete(`/clients/delete/${clientId}`);
  }

  getAll() {
    return API_URL.get('/clients');
  }

  uploadFilesToClient = (clientId, formData) => {
    return API_URL.post(`/clients/client/${clientId}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  getAllClientFiles = (clientId) => {
    return API_URL.get(`/clients/client/${clientId}/files`);
  }

  getFileContent = (clientId, fileName) => {
    return API_URL.get(`/clients/client/${clientId}/files/${fileName}`);
  }

  downloadFile = (clientId, fileName) => {
    return API_URL.get(`/clients/client/${clientId}/download/${fileName}`, {
      responseType: 'blob',
    });
  };

  deleteFile = (clientId, fileName) => {
    return API_URL.delete(`/clients/client/${clientId}/delete/${fileName}`);
  }
}

export default new ClientServices();