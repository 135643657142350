import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTaux } from "../../actions/TauxActions";
import { createCotisation } from "../../actions/CotisationActions";
import { getAllUsers } from "../../actions/UserActions";
import {
  MenuItem,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import AccessDenied from "../Errors/AccessDenied";

const CreateCotisation = ({ currentUser, darkMode }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const taux = useSelector((state) => state.taux.taux);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [cotisationData, setCotisationData] = useState({
    mois: '',
    salaire_net: '',
    salaire_base: '',
    salaire_brut: '',
    salaire_brutimpo: '',
    primes: '',
    nb_heures25: '',
    nb_heures50: '',
    nb_heures100: '',
    userId: '',
  });
  const [cotisationCalcul, setCotisationCalcul] = useState({
    primes_ancienneté: '',
    primes_heurssup: '',
    primes_nonImp: '',
    primes_Impo: '',
    rest_primeimpo: '',
    mpcnss: '',
    mpamo: '',
    mscnss: '',
    msamo: '',
    alocation_fa: '',
    tfp: '',
    caise_social: '',

  });
  const [percentage25, setPercentage25] = useState('');
  const [percentage50, setPercentage50] = useState('');
  const [percentage100, setPercentage100] = useState('');

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllTaux());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCotisationData({
      ...cotisationData,
      [name]: value,
    });
  };

  const calculateCotisation = () => {

    const user = users.find((user) => user.id === selectedUserId);
    const salaireBase = user ? user.salaire * 191 : 0;
    if (!user || !user.date_recrutement) {
      return 0;
    }
    const recrutementDate = new Date(user.date_recrutement);
    const currentDate = new Date();
    const diffInYears = (currentDate - recrutementDate) / (1000 * 60 * 60 * 24 * 365);
    let primeAnciennete = 0;
    if (diffInYears >= 25) {
      primeAnciennete = (salaireBase * 25) / 100;
    } else if (diffInYears >= 20) {
      primeAnciennete = (salaireBase * 20) / 100;
    } else if (diffInYears >= 12) {
      primeAnciennete = (salaireBase * 15) / 100;
    } else if (diffInYears >= 5) {
      primeAnciennete = (salaireBase * 10) / 100;
    } else if (diffInYears >= 2) {
      primeAnciennete = (salaireBase * 5) / 100;
    } else {
      primeAnciennete = 0;
    }
    const formattedMonth = cotisationData.mois ? `${cotisationData.mois.split('-')[1]}-${cotisationData.mois.split('-')[0]}` : '';

    const userPrimesNonImp = user?.primes?.filter((prime) => {
      const formattedPrimeMonth = `${('0' + (new Date(prime.mois).getMonth() + 1)).slice(-2)}-${new Date(prime.mois).getFullYear()}`;
      return prime.type === "non imposable" && formattedPrimeMonth === formattedMonth;
    });

    const totalNonImpMontant = userPrimesNonImp.reduce((total, prime) => total + prime.montant, 0);
    const userPrimesImp = user?.primes?.filter((prime) => {
      const formattedPrimeMonth = `${('0' + (new Date(prime.mois).getMonth() + 1)).slice(-2)}-${new Date(prime.mois).getFullYear()}`;
      return prime.type === "imposable" && formattedPrimeMonth === formattedMonth;
    });

    const ImpMontant = userPrimesImp.reduce((total, prime) => total + prime.montant, 0);
    const tauxPcnss = taux.find((t) => t.name === "Cotisations patronales de la CNSS");
    const tauxPamo = taux.find((t) => t.name === "Cotisations patronales de l’AMO");
    const tauxScnss = taux.find((t) => t.name === "Cotisations salariales de la CNSS");
    const tauxSamo = taux.find((t) => t.name === "Cotisations salariales de l’AMO");
    const tauxAllocationFa = taux.find((t) => t.name === "Prestations Familiales");
    const tauxTfp = taux.find((t) => t.name === "Taxe de formation professionnelle");
    const selectedPercentage25 = parseFloat(percentage25);
    const selectedPercentage50 = parseFloat(percentage50);
    const selectedPercentage100 = parseFloat(percentage100);
    const Supplementaires25 = (user?.salaire * 1.25) * (selectedPercentage25 || 0);
    const Supplementaires50 = (user?.salaire * 1.50) * (selectedPercentage50 || 0);
    const Supplementaires100 = (user?.salaire * 2.00) * (selectedPercentage100 || 0);
    const heuresSupplementairesPrime = Supplementaires25 + Supplementaires50 + Supplementaires100;

    const totalImpMontant = ImpMontant + (primeAnciennete || 0) + (heuresSupplementairesPrime || 0);
    const salaireBrut = salaireBase + (primeAnciennete || 0) + (totalNonImpMontant || 0) + (heuresSupplementairesPrime || 0) + (ImpMontant || 0);
    const salaireBrutImpo = salaireBrut - (totalNonImpMontant || 0);

    const userPrimesTotal = (primeAnciennete || 0) + (totalNonImpMontant || 0) + (heuresSupplementairesPrime || 0) + (totalImpMontant || 0)
    const mpcnss = (salaireBrutImpo * tauxPcnss?.taux) / 100 || 0;
    const mpamo = (salaireBrutImpo * tauxPamo?.taux) / 100 || 0;
    const mscnss = (salaireBrutImpo * tauxScnss?.taux) / 100 || 0;
    const msamo = (salaireBrutImpo * tauxSamo?.taux) / 100 || 0;
    const alocationFa = (salaireBrutImpo * tauxAllocationFa?.taux) / 100 || 0;
    const tfp = (salaireBrutImpo * tauxTfp?.taux) / 100 || 0;
    const caiseSocial = mscnss + msamo + mpcnss + mpamo + tfp + alocationFa + mscnss + msamo;
    const salaireNet = salaireBrutImpo - (msamo + mscnss);


    setCotisationData({
      mois: cotisationData.mois,
      salaire_base: salaireBase,
      primes: userPrimesTotal || 0,
      salaire_brut: salaireBrut,
      salaire_net: salaireNet,
      salaire_brutimpo: salaireBrutImpo,
      nb_heures25: percentage25 || 0,
      nb_heures50: percentage50 || 0,
      nb_heures100: percentage100 || 0,
      userId: selectedUserId,
    });
    setCotisationCalcul({
      primes_ancienneté: primeAnciennete,
      primes_heurssup: heuresSupplementairesPrime,
      primes_nonImp: totalNonImpMontant,
      primes_Impo: totalImpMontant,
      rest_primeimpo: ImpMontant,
      mpcnss: mpcnss,
      mpamo: mpamo,
      mscnss: mscnss,
      msamo: msamo,
      alocation_fa: alocationFa,
      tfp: tfp,
      caise_social: caiseSocial,
    });
  };

  const handleUserChange = (e) => {
    setSelectedUserId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createCotisation(cotisationData));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des salaires")) ? (
        <>
          <Typography textAlign={'center'} variant="h4" gutterBottom>
            Paiement des Salaires
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Mois"
                    type='month'
                    name="mois"
                    value={cotisationData.mois}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    label="Employé(e)"
                    name="selectedUserId"
                    value={selectedUserId}
                    onChange={handleUserChange}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.nom} {user.prenom}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Heures Supplémentaires à 25%"
                    name="percentage25"
                    value={percentage25}
                    onChange={(e) => setPercentage25(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Heures Supplémentaires à 50%"
                    name="percentage50"
                    value={percentage50}
                    onChange={(e) => setPercentage50(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Heures Supplémentaires à 100%"
                    name="percentage100"
                    value={percentage100}
                    onChange={(e) => setPercentage100(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDButton
                    variant="contained"
                    color="primary"
                    onClick={calculateCotisation}
                  >
                    Calculer le BULLETIN DE PAIE
                  </MDButton>
                </Grid>
              </Grid>
            </Box>
            {selectedUserId && (
              <div className="mt-3">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Salaire de Base"
                      name="salaire_base"
                      value={cotisationData.salaire_base}
                      fullWidth
                      sx={{
                        color: "#000"
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Total des Primes"
                      name="primes"
                      value={cotisationData.primes}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Salaire Brut"
                      name="salaire_brut"
                      value={cotisationData.salaire_brut}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Cotisation Patronale CNSS"
                      name="mpcnss"
                      value={cotisationCalcul.mpcnss}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Cotisation Patronale AMO"
                      name="mpamo"
                      value={cotisationCalcul.mpamo}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Prime d'ancienneté"
                      name="primes_ancienneté"
                      value={cotisationCalcul.primes_ancienneté}
                      fullWidth
                      sx={{
                        color: '#000 !important'
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Prime des Heures Supp"
                      name="primes_heurssup"
                      value={cotisationCalcul.primes_heurssup}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Total des Primes non imposables"
                      name="primes_nonImp"
                      value={cotisationCalcul.primes_nonImp}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Total des Primes imposables"
                      name="primes_Impo"
                      value={cotisationCalcul.primes_Impo}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Cotisation Salariale CNSS"
                      name="mscnss"
                      value={cotisationCalcul.mscnss}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Primes imposables hors Ancienneté et Heures Supp"
                      name="rest_primeimpo"
                      value={cotisationCalcul.rest_primeimpo}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Cotisation Salariale AMO"
                      name="msamo"
                      value={cotisationCalcul.msamo}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Allocation Familiale"
                      name="alocation_fa"
                      value={cotisationCalcul.alocation_fa}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Taxe de Formation Professionnelle"
                      name="tfp"
                      value={cotisationCalcul.tfp}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Total des Cotisations"
                      name="caise_social"
                      value={cotisationCalcul.caise_social}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Salaire Net"
                      name="salaire_net"
                      value={cotisationData.salaire_net}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Salaire Brut Imposable"
                      name="salaire_brutimpo"
                      value={cotisationData.salaire_brutimpo}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDButton type="submit" variant="contained" color="primary">
                      Sauvegader 
                    </MDButton>
                  </Grid>
                </Grid>
              </div>
            )}
          </form>
        </>
      ) : (
        <div className="container">
          <AccessDenied />
        </div>
      )}
    </DashboardLayout>
  );
};

export default CreateCotisation;
