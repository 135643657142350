import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTache, updateTache } from '../../actions/TacheActions';
import { getAllUsers } from '../../actions/UserActions';
import {
    Box,
    Button,
    TextField,
    Typography,
    Grid,
    Select,
    MenuItem,
    Snackbar,
    SnackbarContent,
    IconButton,
} from '@mui/material';
import { Close as CloseIcon, MoveToInbox } from '@mui/icons-material';

const UpdateTache = ({ tacheId }) => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users) || [];
    const currentTache = useSelector((state) => state.tache.tache) || {};

    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getTache(tacheId));
    }, [dispatch, tacheId]);

    useEffect(() => {
        setTacheData((prevData) => ({
            ...prevData,
            deadline: currentTache.deadline || prevData.deadline,
            note: currentTache.note || prevData.note,
            userId: currentTache.userId || prevData.userId,
        }));
    }, [currentTache]);

    const [tacheData, setTacheData] = useState({
        deadline: '',
        note: '',
        userId: '',
    });

    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = () => {
        setErrorSnackbarOpen(false);
    };

    const handleInputChange = (e) => {
        setTacheData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleUpdate = () => {
        const updatedTache = {
            deadline: tacheData.deadline,
            note: tacheData.note,
            userId: tacheData.userId,
        };

        dispatch(updateTache(tacheId, updatedTache))
            .then(() => {
                setSuccessSnackbarOpen(true);
                setSnackbarMessage('Task updated successfully.');
                setSnackbarType('success');
            })
            .catch((error) => {
                setErrorSnackbarOpen(true);
                setSnackbarMessage('Error updating task. Please try again.');
                setSnackbarType('error');
            });
    };

    return (
        <>
            <Grid container mt={2} spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        label="Date d'échéance"
                        fullWidth
                        type="date"
                        name="deadline"
                        value={tacheData.deadline}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Note"
                        fullWidth
                        multiline
                        rows={4}
                        name="note"
                        value={tacheData.note}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Utilisateur"
                        fullWidth
                        name="userId"
                        value={tacheData.userId}
                        onChange={handleInputChange}
                    >
                        {users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                {`${user.nom} ${user.prenom}`}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" sx={{ color: "#fff !important" }} onClick={handleUpdate}>
                        Modifier
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={successSnackbarOpen || errorSnackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={successSnackbarOpen ? handleSuccessSnackbarClose : handleErrorSnackbarClose}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    style={{ backgroundColor: snackbarType === 'success' ? 'green' : 'red' }}
                    action={
                        <IconButton size="small" color="inherit" onClick={handleSuccessSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        </>
    );
};

export default UpdateTache;
