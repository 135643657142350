import React, { useEffect, useState } from 'react';
import ChargeService from '../../services/chargesServices';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const ChargesByDesignation = () => {
    const [chargesByDesignation, setChargesByDesignation] = useState([]);

    useEffect(() => {
        fetchChargesByDesignation();
    }, []);

    const fetchChargesByDesignation = async () => {
        try {
            const response = await ChargeService.getTotalChargesByDesignation();
            setChargesByDesignation(response.data);
        } catch (error) {
            console.error('Error fetching charges by designation:', error);
        }
    };

    const chartData = chargesByDesignation.map((charge) => ({
        designation: charge.designation,
        totalCharges: charge.totalCharges,
    }));

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <Typography variant="h3" textAlign={'center'} gutterBottom>
                    Total Charges by Designation
                </Typography>
                <Box alignItems={'center'} style={{ height: 300 }}>
                    <ResponsiveBar
                        data={chartData}
                        keys={['totalCharges']}
                        indexBy="designation"
                        margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                        padding={0.3}
                        colors={{ scheme: 'category10' }}
                        axisBottom={{
                            tickRotation: -45,
                        }}
                        axisLeft={{
                            tickValues: 5,
                            tickPadding: 5,
                            tickSize: 5,
                            tickRotation: 0,
                            legend: 'Total Charges',
                        }}
                        enableGridX
                        enableGridY
                        labelTextColor="inherit:darker(1.2)"
                        tooltip={({ id, value, color }) => (
                            <strong style={{ color }}>
                                {id}: {value}
                            </strong>
                        )}
                    />
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Designation</TableCell>
                                <TableCell align="right">Total Charges</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chargesByDesignation.map((charge) => (
                                <TableRow key={charge.designation}>
                                    <TableCell component="th" scope="row">
                                        {charge.designation}
                                    </TableCell>
                                    <TableCell align="right">{charge.totalCharges}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DashboardLayout>
        </>
    );
};

export default ChargesByDesignation;
