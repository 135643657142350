import {
    CREATE_NATURE_SUCCESS,
    CREATE_NATURE_FAILURE,
    UPDATE_NATURE_SUCCESS,
    UPDATE_NATURE_FAILURE,
    GET_NATURE_SUCCESS,
    GET_NATURE_FAILURE,
    DELETE_NATURE_SUCCESS,
    DELETE_NATURE_FAILURE,
    GET_ALL_NATURES_SUCCESS,
    GET_ALL_NATURES_FAILURE,
  } from '../actions/types';
const initialState = {
  nature: null,
  natures: [],
  error: null,
};

const natureReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NATURE_SUCCESS:
      return {
        ...state,
        nature: action.payload,
        error: null,
      };
    case CREATE_NATURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_NATURE_SUCCESS:
      return {
        ...state,
        nature: action.payload,
        error: null,
      };
    case UPDATE_NATURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_NATURE_SUCCESS:
      return {
        ...state,
        nature: action.payload,
        error: null,
      };
    case GET_NATURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_NATURE_SUCCESS:
      return {
        ...state,
        nature: null,
        error: null,
      };
    case DELETE_NATURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_NATURES_SUCCESS:
      return {
        ...state,
        natures: action.payload,
        error: null,
      };
    case GET_ALL_NATURES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default natureReducer;