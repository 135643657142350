import API_URL from './apiUrl';

class RepportsServices {

  getRepportsFiles = () => {
    return API_URL.get(`/repports/all`);
  }

  getFileContent = (fileName) => {
    return API_URL.get(`/repports/all/files/${fileName}`);
  }

  // generateDailyRepport = () =>{
  //   return API_URL.get(`/repports/generate-report`);
  // }

  generateDailyRepport = (startDate, endDate, note) =>{
    console.log(note);
    return API_URL.post(`/repports/generate-report?startDate=${startDate}&endDate=${endDate}`, note);
  }

  sendDailyRepport = (fileName) => {
    return API_URL.get(`/repports/send-daily-report/${fileName}`);
  }

}

export default new RepportsServices();