import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polyline as LeafletPolyline, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';
import PolylineServices from '../../services/polylineServices';
import {
    Paper,
    Grid,
    TextField,
    Typography,
    Box,
} from "@mui/material";
import MDButton from '../../components/MDButton';

const PolylinesMap = () => {
    const [convertedCoordinates, setConvertedCoordinates] = useState([]);
    const [initialZoom, setInitialZoom] = useState(10);
    const [polylines, setPolylines] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPolyline, setSelectedPolyline] = useState(null);

    const mapRef = useRef(null);

    useEffect(() => {
        fetchPolylines();
    }, []);

    const fetchPolylines = async () => {
        try {
            const response = await PolylineServices.getPolylines();
            setPolylines(response.data.polylines);
        } catch (error) {
            console.error('Error fetching polylines:', error);
        }
    };

    const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

    useEffect(() => {
        if (polylines?.length === 0) return;

        const converted = polylines?.map((polyline) => {
            const zone = polyline.zone;
            const sourceCRS = getSourceCRS(zone);

            proj4.defs('EPSG:3857', targetCRS);
            proj4.defs(`EPSG:${zone}`, sourceCRS);

            return polyline.geometry.coordinates.map((coord) => {
                const [x, y] = proj4(sourceCRS, targetCRS, coord);
                return [y, x];
            });
        });

        setConvertedCoordinates(converted);
    }, [polylines]);

    const handleSearch = () => {
        setInitialZoom(19);
        const filteredPolyline = polylines.find(
            (polyline) =>
                polyline.projet.numero === searchQuery || polyline.projet.reference_fonciere === searchQuery
        );

        if (filteredPolyline) {
            const zone = filteredPolyline.zone;
            const sourceCRS = getSourceCRS(zone);

            const converted = filteredPolyline.geometry.coordinates.map((coord) => {
                const [x, y] = proj4(sourceCRS, targetCRS, coord);
                return [y, x];
            });

            setConvertedCoordinates([converted]);
            setSelectedPolyline(filteredPolyline);

            mapRef.current.flyTo(converted[0], 18);
        } else {
            setConvertedCoordinates([]);
            setSelectedPolyline(null);
        }
    };

    const getSourceCRS = (zone) => {
        switch (zone) {
            case '26191':
                return '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
            case '26192':
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
            case '26194':
                return '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.999616304 +x_0=1200000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
            case '26195':
                return '+proj=lcc +lat_1=22.5 +lat_0=22.5 +lon_0=-5.4 +k_0=0.999616437 +x_0=1500000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
            default:
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
    };
    
    const Map = () => {
        const map = useMap();
        mapRef.current = map;

        return null;
    };
    return (
        <>
            <Box>
                <Grid container spacing={2} mb={2} mt={2}>
                    <Grid item xs={12} className='d-flex'>
                        <TextField
                            label="Recherche par numéro de projet ou référence"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="standard"
                            fullWidth
                            sx={{
                                marginRight: '1rem'
                            }}
                        // margin="normal"
                        />
                        <MDButton
                            variant="gradient"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Afficher
                        </MDButton>
                    </Grid>
                </Grid>
            </Box>
            {convertedCoordinates?.length > 0 && (
                <MapContainer
                    center={[30.39341198110206, -9.565342191279383]}
                    zoom={initialZoom}
                    style={{ height: '80vh', width: '100%' }}
                >
                    <Map />
                    <TileLayer
                        url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                        maxZoom={21}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                    />
                    <LeafletPolyline positions={convertedCoordinates} pathOptions={{ color: 'red' }} />
                    {selectedPolyline && (
                        <Popup position={convertedCoordinates[0][0]} autoPan={true}>
                            <Box>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        background: '#1A73E8',
                                        color: '#fff',
                                        p: 2,
                                        borderRadius: '8px',
                                        width: '300px',
                                    }}
                                >
                                    <Typography sx={{color: '#fff',}} variant="h5" fontWeight="bold">
                                        Informations de Projet
                                    </Typography>
                                </Paper>
                                <Box mt={2} p={1}>
                                    <Typography>
                                        <strong>Numero:</strong> {selectedPolyline.projet.numero}
                                    </Typography>
                                    <Typography>
                                        <strong>Reference Fonciere:</strong>{' '}
                                        {selectedPolyline.projet.reference_fonciere}
                                    </Typography>
                                    <Typography>
                                        <strong>Nature:</strong>{' '}
                                        {selectedPolyline.projet?.nature?.map((item, index) => (
                                            <span key={index}>
                                                {item?.name}
                                                {index < selectedPolyline.projet.nature.length - 1 && ', '}
                                            </span>
                                        )) || ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Popup>
                    )}
                </MapContainer>
            )}
        </>
    );
};

export default PolylinesMap;
