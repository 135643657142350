import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    useMediaQuery,
    Alert,
    Button,
    IconButton,
    Tooltip,
    Box, Grid, Paper, Divider
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import { useMaterialUIController } from "../../context";
import MDButton from "../../components/MDButton";
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchRejcted } from "../../actions/ChargesActions";
import { deleteRequest } from "../../actions/RequestActions";
import { deleteHoliday } from "../../actions/HolidaysActions";
import { deleteCharge, updateCharge } from "../../actions/ChargesActions";
import { deleteAvance, updateAvance } from "../../actions/EncaissementActions";
import UpdateAvance from "./UpdateAvance";


const RejectedList = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCharge, setSelectedCharge] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [isReqDeleteOpen, setReqDeleteOpen] = useState(false);
    const [isHoliDeleteOpen, setIsHoliDeleteOpen] = useState(false);
    const [isAvanceDeleteOpen, setIsAvanceDeleteOpen] = useState(false);
    const [isAvanceUpdateOpen, setIsAvanceUpdateOpen] = useState(false);
    const [isDisplayOpen, setIsDisplayOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity] = useState("success");
    const [pageSize, setPageSize] = useState(25);
    const [paiement, setPaiement] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');
    const rejected = useSelector((state) => state.charges.rejected);
    const chargesRejcted = rejected?.rejectedCharges;
    const holidaysRejcted = rejected?.rejectedHolidays;
    const requestsRejcted = rejected?.rejectedRequests;
    const rejectedAvances = rejected?.rejectedAvances;

    // ------------- avances --------------- // 
    const openAvanaceDelete = (item) => {
        setSelectedItem(item);
        setIsAvanceDeleteOpen(true);
    };

    const closeAvanceDelete = () => {
        setSelectedItem(null);
        setIsAvanceDeleteOpen(false);
    };

    const handleAvanceDelete = async () => {
        if (selectedItem) {
            try {
                await dispatch(deleteAvance(selectedItem.id))
                    .then(() => {
                        dispatch(fetchRejcted(currentUser?.id));
                        setSnackbarMessage("Avance supprimés avec succès.");
                    });
            } catch (error) {
                setSnackbarMessage("Erreur lors de la suppression.");
                console.error("Erreur lors de la suppression :", error);
            }
            setSnackbarOpen(true);
            setSelectedItem(null);
        }
        closeAvanceDelete();
    };

    const openAvanaceUpdate = (item) => {
        setSelectedItem(item);
        setIsAvanceUpdateOpen(true);
    };

    const closeAvanceUpdate = () => {
        setSelectedItem(null);
        setIsAvanceUpdateOpen(false);
    };


    // ------------- charges delete --------------- // 

    const openDeleteModal = (charge) => {
        setSelectedCharge(charge);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedCharge(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = async () => {
        if (selectedCharge) {
            try {
                await dispatch(deleteCharge(selectedCharge.id));
                setSnackbarMessage("Charge supprimés avec succès.");
            } catch (error) {
                setSnackbarMessage("Erreur lors de la suppression.");
                console.error("Erreur lors de la suppression :", error);
            }
            setSnackbarOpen(true);
            setSelectedCharge(null);
        }
        closeDeleteModal();
    };

    // ---------------- charges display ---------------- // 

    const openDisplayModal = (item) => {
        setSelectedItem(item);
        setIsDisplayOpen(true);
    };

    const closeDisplayModal = () => {
        setSelectedItem(null);
        setIsDisplayOpen(false);
    };

    // ----------- req delete model ------------- //

    const openReqDeleteModal = (item) => {
        setSelectedItem(item);
        setReqDeleteOpen(true);
    };

    const closeReqDeleteModal = () => {
        setSelectedItem(null);
        setReqDeleteOpen(false);
    };

    const handleReqDelete = () => {
        if (selectedItem) {
            dispatch(deleteRequest(selectedItem.id))
                .then(() => {
                    dispatch(fetchRejcted(currentUser.id));
                    setSnackbarMessage("Demande supprimée avec succès.");
                })
                .catch((error) => {
                    console.error('Error delete Request:', error);
                    setSnackbarMessage("Erreur lors de la suppression.");
                });
            setSnackbarOpen(true);
            setSelectedItem(null);
        }
        closeReqDeleteModal();
    };

    // --------------- holiday delete model --------------- //

    const openHolidayModal = (item) => {
        setSelectedItem(item);
        setIsHoliDeleteOpen(true);
    };

    const closeHolidayModal = () => {
        setSelectedItem(null);
        setIsHoliDeleteOpen(false);
    };

    const handleHoliDelete = () => {
        if (selectedItem) {
            dispatch(deleteHoliday(selectedItem.id))
                .then(() => {
                    dispatch(fetchRejcted(currentUser.id));
                    setSnackbarMessage("Supprimée avec succès.");
                })
                .catch((error) => {
                    console.error('Error Confiramtion indemnite:', error);
                    setSnackbarMessage("Erreur lors de la suppression.");
                });
            setSnackbarOpen(true);
            setSelectedItem(null);
        }
        closeHolidayModal();
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const formatDateForMonthDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        return `${year}-${month}`;
    };

    const filteredCharges = chargesRejcted?.rows?.filter(
        (charge) =>
            charge.designation.toLowerCase().includes(searchInput.toLowerCase()) ||
            formatDateForMonthDisplay(charge.mois).includes(searchInput.toLowerCase()) ||
            charge.cout.toString().includes(searchInput.toLowerCase())
    );

    const formatDateForDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = (dateObj.getDay()).toString().padStart(2, "0");
        return `${day}-${month}-${year}`;
    };

    const chargesColumns = [
        {
            field: 'designation', headerName: 'Designation',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'mois', headerName: 'Mois',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueFormatter: (params) => formatDateForMonthDisplay(params.value)
        },
        {
            field: 'createdAt', headerName: 'Date',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueFormatter: (params) => formatDateForDisplay(params.value)
        },
        {
            field: 'cout', headerName: 'Coût',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <Tooltip title="Voir les détails" arrow>
                        <IconButton color="warning" onClick={() => openDisplayModal(params.row)}>
                            <VisibilityIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Modifier" arrow>
                        <IconButton color="info" component={Link} to={`/charges/update/${params.id}`} >
                            <BorderColorIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer" arrow>
                        <IconButton color="error" onClick={() => openDeleteModal(params.row)}>
                            <DeleteIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            flex: 1.5,
            cellClassName: 'actions-cell',
        },
    ];

    const holidaysColumns = [
        {
            field: 'requestDays', headerName: 'Nombre de jours',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'requestDate', headerName: 'Date de la demande',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'startDate', headerName: 'Date de début',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'endDate', headerName: 'Date de fin',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'confirmTask',
            headerName: 'Actions',
            ...(isMobile ? { width: 120 } : { flex: 2 }),
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Modifier" arrow>
                            <IconButton color="info"
                                component={Link}
                                to={`/ressources-humaines/holidays/update/${params.row.id}`}
                            >
                                <BorderColorIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer" arrow>
                            <IconButton color="error" onClick={() => openHolidayModal(params.row)}>
                                <DeleteIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
            cellClassName: 'actions-cell',
        },
    ];

    const requestsColumns = [
        {
            field: 'mois', headerName: 'Mois',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueFormatter: (params) => formatDateForMonthDisplay(params.value)
        },
        { field: 'createdAt', headerName: "Date d'envoi", flex: 1 },
        { field: 'prix', headerName: 'Cout', flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <Tooltip title="Modifier" arrow>
                        <IconButton color="info" component={Link}
                            to={`/requests/update/${params.id}`}
                        >
                            <BorderColorIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer" arrow>
                        <IconButton color="error" onClick={() => openReqDeleteModal(params.row)}>
                            <DeleteIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            flex: 2,
            cellClassName: 'actions-cell',
        },
    ];

    const avancesColumns = [
        {
            field: 'prix', headerName: 'Prix',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'type', headerName: 'Type de paiement',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'date', headerName: 'Date',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        // {
        //     field: 'projet.client.full_name',
        //     headerName: 'Client',
        //     ...(isMobile ? { width: 190 } : { flex: 1 }),
        //     valueGetter: (params) => params.row.projet?.client?.full_name || '',
        // },
        // {
        //     field: 'projet.numero',
        //     headerName: 'Projet',
        //     ...(isMobile ? { width: 120 } : { flex: 1 }),
        //     valueGetter: (params) => params.row.projet?.numero || '',
        // },
        {
            field: 'actions',
            headerName: 'Actions',
            ...(isMobile ? { width: 180 } : { flex: 1 }),
            renderCell: (params) => (
                <>
                    <Tooltip title='Modifier' arrow>
                        <IconButton
                            // component={Link}
                            // to={`/projects/avances/update/${params.row.id}`}
                            onClick={() => openAvanaceUpdate(params.row)}
                            variant="contained"
                            color="info"
                            size="small"
                            className='ms-2'
                        >
                            <BorderColorIcon fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Supprimer' arrow>
                        <IconButton
                            variant="contained"
                            color={"error"}
                            size="small"
                            onClick={() => openAvanaceDelete(params.row)}
                            className='ms-2 btn btn-success'
                        >
                            <DeleteIcon fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <>
                {rejectedAvances?.length > 0 && (
                    <>
                        <Typography variant="h4" textAlign={"center"} gutterBottom>
                            Les encaissements rejetées
                        </Typography>
                        <Divider sx={{
                            margin: 'auto',
                            width: '50%',
                            marginBottom: '1rem',
                            fontSize: '28px',
                            fontWeight: 'bold',
                            color: 'rgb(31, 119, 180) !important',
                            height: '5px',
                        }} />
                        <DataGrid
                            rows={rejectedAvances || []}
                            columns={avancesColumns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            className={darkMode ? "text-light" : "text-dark"}
                            components={{
                                Toolbar: GridToolbar
                            }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight
                        />
                    </>
                )}
                {chargesRejcted?.count > 0 && (
                    <>
                        <Typography variant="h4" textAlign={"center"} gutterBottom>
                            Les charges rejetées
                        </Typography>
                        <Divider sx={{
                            margin: 'auto',
                            width: '50%',
                            marginBottom: '1rem',
                            fontSize: '28px',
                            fontWeight: 'bold',
                            color: 'rgb(31, 119, 180) !important',
                            height: '5px',
                        }} />
                        <DataGrid
                            rows={filteredCharges || []}
                            columns={chargesColumns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            className={darkMode ? "text-light" : "text-dark"}
                            components={{
                                Toolbar: GridToolbar
                            }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight
                        />
                    </>
                )}
                {holidaysRejcted?.count > 0 && (
                    <>
                        <Typography variant="h4" mt={2} textAlign={"center"} gutterBottom>
                            Les congés rejetés
                        </Typography>
                        <Divider sx={{
                            margin: 'auto',
                            marginBottom: '1rem',
                            width: '50%',
                            fontSize: '28px',
                            fontWeight: 'bold',
                            color: 'rgb(31, 119, 180) !important',
                            height: '5px',
                        }} />
                        <DataGrid
                            rows={holidaysRejcted?.rows || []}
                            columns={holidaysColumns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            className={darkMode ? "text-light" : "text-dark"}
                            components={{
                                Toolbar: GridToolbar
                            }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight
                        />
                    </>
                )}
                {requestsRejcted?.count > 0 && (
                    <>
                        <Typography variant="h4" mt={2} textAlign={"center"} gutterBottom>
                            Demandes d'avance sur salaire rejetées
                        </Typography>
                        <Divider sx={{
                            margin: 'auto',
                            marginBottom: '1rem',
                            width: '50%',
                            fontSize: '28px',
                            fontWeight: 'bold',
                            color: 'rgb(31, 119, 180) !important',
                            height: '5px',
                        }} />
                        <DataGrid
                            rows={requestsRejcted?.rows || []}
                            columns={requestsColumns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            className={darkMode ? "text-light" : "text-dark"}
                            components={{
                                Toolbar: GridToolbar
                            }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight
                        />
                    </>
                )}
            </>
            <Dialog
                open={isDeleteModalOpen}
                onClose={closeDeleteModal}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "red",
                        color: '#fff'
                    }}
                >
                    Confirmation de la suppression
                </DialogTitle>
                <DialogContent>
                    <Typography mt={2} variant="h6">
                        Êtes-vous sûr de vouloir supprimer ce charge ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal}>Annuler</Button>
                    <Button onClick={handleDelete} variant="contained"
                        sx={{
                            marginRight: '1rem',
                            backgroundColor: "red",
                            color: '#fff'
                        }}
                    >
                        supprimer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isReqDeleteOpen}
                onClose={closeReqDeleteModal}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "red",
                        color: '#fff'
                    }}
                >
                    Confirmation de la suppression
                </DialogTitle>
                <DialogContent>
                    <Typography mt={2} variant="h6">
                        Êtes-vous sûr de vouloir supprimer cette demande ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeReqDeleteModal}>Annuler</Button>
                    <MDButton onClick={handleReqDelete} variant="contained"
                        sx={{
                            marginRight: '1rem',
                            backgroundColor: "red",
                            color: '#fff'
                        }}>
                        supprimer
                    </MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isHoliDeleteOpen}
                onClose={closeHolidayModal}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "red",
                        color: '#fff'
                    }}
                >
                    Confirmation de la suppression
                </DialogTitle>
                <DialogContent>
                    <Typography mt={2} variant="h6">
                        Êtes-vous sûr de vouloir supprimer ce congé ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHolidayModal}>Annuler</Button>
                    <MDButton onClick={handleHoliDelete} variant="contained"
                        sx={{
                            marginRight: '1rem',
                            backgroundColor: "red",
                            color: '#fff'
                        }}>
                        supprimer
                    </MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDisplayOpen}
                onClose={closeDisplayModal}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle textAlign={'center'} className={darkMode ? "text-light" : "text-dark"}>
                    Détails de la charge
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Paper sx={{ padding: 2 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <PersonIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Personnel
                                    </Typography>
                                    <Typography>{currentUser?.nom}&nbsp;{currentUser?.prenom}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item md={6}>
                                <Paper sx={{ padding: 2 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <AssignmentIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Designation
                                    </Typography>
                                    <Typography>{selectedItem?.designation}</Typography>
                                </Paper>
                            </Grid>

                            <Grid item md={12}>
                                <Paper sx={{ padding: 2 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <CommentIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Observation
                                    </Typography>
                                    <Typography>{selectedItem?.note || 'Aucune remarque !'}</Typography>
                                </Paper>
                            </Grid>

                            <Grid item md={6}>
                                <Paper sx={{ padding: 2, textAlign: 'center' }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Cout
                                    </Typography>
                                    <Typography component="div" variant="h5" gutterBottom>
                                        {selectedItem?.cout}&nbsp;
                                        <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                            MAD
                                        </Typography>
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item md={6}>
                                <Paper sx={{ padding: 2, textAlign: 'center' }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <CalendarMonthIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Mois
                                    </Typography>
                                    <Typography component="div" variant="h5" gutterBottom>
                                        {formatDateForMonthDisplay(selectedItem?.mois)}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography>Oups, il y a peut-être une erreur</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeDisplayModal}>
                        Annuler
                    </MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isAvanceUpdateOpen}
                onClose={closeAvanceUpdate}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle textAlign={'center'} className={darkMode ? "text-light" : "text-dark"}>
                    Modifier l'avance
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <>
                            <UpdateAvance avance={selectedItem} currentUser={currentUser} />
                        </>
                    ) : (
                        <Typography>Oups, il y a peut-être une erreur</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeAvanceUpdate}>
                        Annuler
                    </MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isAvanceDeleteOpen}
                onClose={closeAvanceDelete}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "red",
                        color: '#fff'
                    }}
                >
                    Confirmation de la suppression
                </DialogTitle>
                <DialogContent>
                    <Typography mt={2} variant="h6">
                        Êtes-vous sûr de vouloir supprimer cette avance ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAvanceDelete}>Annuler</Button>
                    <MDButton onClick={handleAvanceDelete} variant="contained"
                        sx={{
                            marginRight: '1rem',
                            backgroundColor: "red",
                            color: '#fff'
                        }}>
                        supprimer
                    </MDButton>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default RejectedList;
