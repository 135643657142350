import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDevis, getDevis } from '../../actions/DevisActions';
import { useParams } from 'react-router-dom';
import { Snackbar, Alert } from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';


const UpdateDevis = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const devis = useSelector((state) => state.devis.devi);
  const [date, setDate] = useState('');
  const [objet, setObjet] = useState('');
  const [statut, setStatut] = useState('non');
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState('');
  const [itemUnite, setItemUnite] = useState('');
  const [itemPriceUnit, setItemPriceUnit] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const devisId = id;
    dispatch(getDevis(devisId));
  }, [dispatch, id]);

  useEffect(() => {
    if (devis) {
      setDate(devis.date);
      setObjet(devis.objet);
      setStatut(devis.valider || 'non');
      setItems([...devis.items]);
    }
  }, [devis]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedDevis = {
      date,
      objet,
      valider: statut,
      items,
    };

    dispatch(updateDevis(devis.id, updatedDevis))
      .then(() => {
        setSnackbarMessage('Devis mis à jour avec succès!');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleItemChange = (index, fieldName, fieldValue) => {
    const updatedItems = [...items];
    updatedItems[index][fieldName] = fieldValue;
    
    if (fieldName === 'quantity' || fieldName === 'prix_unitaire') {
      const item = updatedItems[index];
      item.price = parseFloat(item.prix_unitaire) * parseInt(item.quantity);
    }
    setItems(updatedItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    if (!itemName || !itemUnite || !itemPriceUnit || !itemQuantity) return;

    const newItem = {
      name: itemName,
      unite: itemUnite,
      prix_unitaire: parseFloat(itemPriceUnit),
      price: parseFloat(itemPriceUnit) * parseInt(itemQuantity),
      quantity: parseInt(itemQuantity),
    };

    setItems((prevItems) => [...prevItems, newItem]);

    setItemName('');
    setItemUnite('');
    setItemPriceUnit('');
    setItemQuantity('');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const calculateTotalPrice = () => {
    let total = 0;
    items.forEach((item) => {
      total += item.prix_unitaire * item.quantity;
    });
    return total;
  };

  const calculTotalePrix = (item) => {
    return item.prix_unitaire * item.quantity;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les devis")) ? (
        <>
          {devis ? (
            <>
              <Typography variant="h2" align="center" mb={3}>
                Mettre à jour le devis
              </Typography>
              <Typography variant="body1" align="center" mb={1}>
                {devis.client?.full_name}
              </Typography>
              <Typography variant="body1" align="center" mb={3}>
                {devis.numero}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Objet"
                      value={objet}
                      onChange={(e) => setObjet(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Valider</InputLabel>
                      <Select
                        value={statut}
                        onChange={(e) => setStatut(e.target.value)}
                      >
                        <MenuItem value="non">Non</MenuItem>
                        <MenuItem value="oui">Oui</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item mt={2} xs={12}>
                  <Typography variant="h4" mb={2}>Désignations :</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Designation</TableCell>
                          <TableCell>Unite</TableCell>
                          <TableCell>Prix Unitaire</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Total</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <TextField
                                fullWidth
                                type="text"
                                name='name'
                                value={item.name}
                                onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                type="text"
                                name='unite'
                                value={item.unite}
                                onChange={(e) => handleItemChange(index, 'unite', e.target.value)}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                type="number"
                                value={item.prix_unitaire}
                                onChange={(e) => handleItemChange(index, 'prix_unitaire', parseFloat(e.target.value))}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                type="number"
                                value={item.quantity}
                                onChange={(e) => handleItemChange(index, 'quantity', parseFloat(e.target.value))}
                              />
                            </TableCell>
                            <TableCell>
                              {calculTotalePrix(item)}
                            </TableCell>
                            <TableCell>
                              <MDButton
                                variant="outlined"
                                color="error"
                                onClick={() => handleRemoveItem(index)}
                              >
                                Remove
                              </MDButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className='float-end mb-2 mt-3'>
                    <Typography variant="body1">
                      Totale HT: {calculateTotalPrice()}
                    </Typography>
                    <Typography variant="body1">
                      Totale TTC: {calculateTotalPrice() * 1.2}
                    </Typography>
                  </div>
                  <Grid container mt={3} mb={2} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        type="text"
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                        placeholder="Item name"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        type="text"
                        value={itemUnite}
                        onChange={(e) => setItemUnite(e.target.value)}
                        placeholder="Item unite"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        type="number"
                        value={itemPriceUnit}
                        onChange={(e) => setItemPriceUnit(e.target.value)}
                        placeholder="Item price unit"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        type="number"
                        value={itemQuantity}
                        onChange={(e) => setItemQuantity(e.target.value)}
                        placeholder="Item quantity"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        variant="outlined"
                        color="primary"
                        onClick={handleAddItem}
                      >
                        Ajouter un item
                      </MDButton>
                    </Grid>
                  </Grid>

                </Grid>

                <MDButton type="submit" variant="contained" color="info" mt={3}>
                  Mettre à jour le devis
                </MDButton>
              </form>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity="success"
                  variant='filled'
                  sx={{ width: '100%' }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </>
      ) : (
        <div>
          <AccessDenied />
        </div>
      )}
    </DashboardLayout>
  );
};

export default UpdateDevis;
