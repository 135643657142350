import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStagiaire, getStagiaire } from '../../actions/StagiaireActions';
import { TextField, Grid, Container, Typography, Snackbar, Alert } from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import { useParams } from 'react-router-dom';
import { decodeId } from '../../actions/Crypte';
import AccessDenied from '../Errors/AccessDenied';

const UpdateStagiaire = ({ currentUser }) => {
    const { id } = useParams();
    const stagiaireId = decodeId(id);
    const dispatch = useDispatch();
    const stagiaire = useSelector((state) => state.stagiaires.stagiaire);

    const [stagiaireData, setStagiaireData] = useState({
        cin: '',
        full_name: '',
        email: '',
        telephone: '',
        adresse: '',
        date_debut: '',
        note: '',
        date_fin: '',
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getStagiaire(stagiaireId));
    }, [dispatch, stagiaireId]);

    useEffect(() => {
        setStagiaireData({
            cin: stagiaire?.cin || '',
            full_name: stagiaire?.full_name || '',
            email: stagiaire?.email || '',
            telephone: stagiaire?.telephone || '',
            adresse: stagiaire?.adresse || '',
            date_debut: stagiaire?.date_debut || '',
            note: stagiaire?.note || '',
            date_fin: stagiaire?.date_fin || '',
        });
    }, [stagiaire]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStagiaireData({
            ...stagiaireData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateStagiaire(stagiaireId, stagiaireData))
            .then(() => {
                setSnackbarMessage('Stagiaire mis à jour avec succès.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarMessage('Erreur lors de la mise à jour du stagiaire. Veuillez réessayer.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('Erreur lors de la mise à jour du stagiaire:', error);
            });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les stagiaires")) ? (
                <Container maxWidth="sm">
                    <Typography variant="h2" textAlign={'center'} mb={1} gutterBottom>
                        Modifier le stagiaire
                    </Typography>
                    <Typography variant="h4" textAlign="center" mb={2}>
                        {stagiaireData?.full_name}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="CIN"
                                    name="cin"
                                    type="text"
                                    value={stagiaireData.cin}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nom complet"
                                    name="full_name"
                                    type="text"
                                    value={stagiaireData.full_name}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={stagiaireData.email}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Telephone"
                                    name="telephone"
                                    type="text"
                                    value={stagiaireData.telephone}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Adresse"
                                    name="adresse"
                                    type="text"
                                    value={stagiaireData.adresse}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Date de début"
                                    name="date_debut"
                                    type="date"
                                    value={stagiaireData.date_debut}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Date de fin"
                                    name="date_fin"
                                    type="date"
                                    value={stagiaireData.date_fin}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Note (Optionnel)"
                                    name="note"
                                    type="text"
                                    value={stagiaireData.note}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MDButton type="submit" variant="contained" color="info">
                                    Mettre à jour
                                </MDButton>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity={snackbarSeverity} onClose={handleCloseSnackbar}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}

        </DashboardLayout>
    );
};

export default UpdateStagiaire;
