import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPendingTaches, deleteTache } from '../../actions/TacheActions';
import {
    Tooltip,
    Typography,
    Snackbar,
    Alert,
    Card,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { useMaterialUIController } from "../../context";
import { Delete, OpenInFull } from '@mui/icons-material';
import ProjectsReview from '../projet/ProjectsReview';

const UserPendingTaches = ({ userId }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tache.taches) ?? [];
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tacheToDelete, setTacheToDelete] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [dialogPreviewOpen, setDialogPreviewOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [pageSize, setPageSize] = useState(25);

    useEffect(() => {
        dispatch(getPendingTaches(userId))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching pending tasks:', error);
                setLoading(false);
            });
    }, [dispatch, userId]);

    const handleDeleteClick = (tacheId) => {
        setTacheToDelete(tacheId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteTache(tacheToDelete))
            .then(() => {
                setSuccessMessage('Task deleted successfully.');
                setOpenSnackbar(true);
                dispatch(getPendingTaches(userId))
            })
            .catch((error) => {
                console.error('Error deleting task:', error);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDialog = (project) => {
        setSelectedProject(project);
        setDialogPreviewOpen(true);
    };

    const closeDialog = () => {
        setSelectedProject(null);
        setDialogPreviewOpen(false);
    };

    const columns = [
        { field: 'name', headerName: 'Tâche', flex: 1 },
        {
            field: 'projet?.numero', headerName: 'Projet', flex: 1,
            valueGetter: (params) => params.row.projet?.numero || '',
        },
        {
            field: 'projet?.reference_fonciere',
            headerName: 'Référence foncière',
            flex: 1,
            valueGetter: (params) => params.row.projet?.reference_fonciere || '',
        },
        {
            field: 'nature',
            headerName: 'Nature',
            flex: 1,
            valueGetter: (params) => params.row.projet?.nature?.map((natures) => natures.name).join(", "),
        },
        { field: 'deadline', headerName: "Date d'échéance", flex: 1 },
        {
            field: 'projet?.date_creation',
            headerName: 'Attribué à',
            flex: 1,
            valueGetter: (params) => params.row.projet?.date_creation || '',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les tâches")) && (
                            <IconButton
                                variant="contained"
                                size='small'
                                color="error"
                                onClick={() => handleDeleteClick(params.row.id)}
                            >
                                <Delete />
                            </IconButton>
                        )}
                        <Tooltip title="Détails" arrow>
                            <IconButton
                                color="warning"
                                aria-label="Preview"
                                onClick={() => openDialog(params.row.projet)}
                            >
                                <OpenInFull />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
            cellClassName: 'actions-cell',
        },
    ];

    return (
        <>
            <Card>
                <DataGrid
                    className={darkMode ? "text-light" : "text-dark"}
                    rows={tasks?.tasks ?? []}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    loading={loading}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    autoHeight
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Card>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Supprimer la tâche</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir supprimer cette tâche ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                        onClick={handleDeleteConfirm} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogPreviewOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="100%"
            >
                <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                    Détails du projet: {selectedProject?.numero}
                </DialogTitle>
                <DialogContent>
                    <ProjectsReview project={selectedProject} currentUser={currentUser} darkMode={darkMode} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserPendingTaches;
