import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllHolidays, deleteHoliday } from "../../actions/HolidaysActions";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Box,
    Dialog,
    IconButton,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { useMaterialUIController } from "../../context";
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import { Delete, BorderColorOutlined, ControlPoint } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)(() => ({
    '& .super-app-theme--confirmed': {
        backgroundColor: 'rgb(192, 216, 193)',
        '&:hover': {
            backgroundColor: 'rgb(150, 190, 152)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(150, 190, 152)',
            '&:hover': {
                backgroundColor: 'rgb(150, 190, 152)',
            },
        },
    },
    '& .super-app-theme--rejected': {
        backgroundColor: 'rgb(241, 192, 192)',
        '&:hover': {
            backgroundColor: 'rgb(233, 151, 151)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(233, 151, 151)',
            '&:hover': {
                backgroundColor: 'rgb(233, 151, 151)',
            },
        },
    },
    '& .super-app-theme--pending': {
        backgroundColor: 'rgb(249, 210, 179)',
        '&:hover': {
            backgroundColor: 'rgb(246, 181, 128)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(246, 181, 128)',
            '&:hover': {
                backgroundColor: 'rgb(246, 181, 128)',
            },
        },
    },
}));

const HolidaysList = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const holidays = useSelector((state) => state.holiday.holidays);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageSize, setPageSize] = useState(25);


    useEffect(() => {
        dispatch(getAllHolidays());
    }, [dispatch]);

    const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedItem(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedItem) {
            dispatch(deleteHoliday(selectedItem.id))
                .then(() => {
                    dispatch(getAllHolidays());
                })
                .catch((error) => {
                    console.error('Error Confiramtion indemnite:', error);
                });
            setSelectedItem(null);
        }
        closeDeleteModal();
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        {
            field: 'user?.nom', headerName: 'Personnel', flex: 1,
            valueGetter: (params) => params.row.user?.nom || '',
        },
        { field: 'requestDays', headerName: 'Days', flex: 1 },
        { field: 'requestDate', headerName: 'Date', flex: 1 },
        { field: 'startDate', headerName: 'Start Date', flex: 1 },
        { field: 'endDate', headerName: 'Date fin', flex: 1 },
        { field: 'status', headerName: 'Statut', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => (
                <div style={{ overflow: 'visible !important' }}>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les congés")) && (
                        <MDButton
                            size='small'
                            color='error'
                            className="btn btn-sm"
                            onClick={() => openDeleteModal(params.row)}
                            style={{ marginLeft: "8px" }}
                        >
                            <Delete />
                        </MDButton>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les congés")) && (
                        <MDButton
                            to={`/ressources-humaines/holidays/update/${params.row.id}`}
                            component={Link}
                            size='small'
                            color='info'
                            className="btn btn-sm"
                            style={{ marginLeft: "8px" }}
                        >
                            <BorderColorOutlined />
                        </MDButton>
                    )}
                </div>

            ),
            cellClassName: 'actions-cell',
        },
    ];

    const CustomButton = () => {
        return (
            <>
                <IconButton
                    color="info"
                    component={Link}
                    to={`/ressources-humaines/holidays/send/request`}
                    sx={{ fontSize: '0.75rem !important', fontWeight: 'bold' }}
                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-knwngq-MuiButtonBase-root-MuiButton-root"
                >
                    <ControlPoint sx={{ mr: '7px' }} />
                    AJOUTER
                </IconButton>
            </>
        );
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <CustomButton style={{ marginRight: '2rem' }} />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les congés")) ? (
                <>
                    {/* <Container maxWidth="md"> */}
                        <Typography variant="h2" textAlign={"center"} mb={5} gutterBottom>
                            Historiques des congés
                        </Typography>
                        {holidays?.length === 0 ? (
                            <>
                                <Typography variant="body1">Pas de données disponibles.</Typography>
                                <Link to={`/ressources-humaines/holidays/send/request`} className="text-decoration-none float-end">
                                    <MDButton color='info' className="btn btn-sm">
                                        <ControlPointIcon sx={{ mr: "10px" }} />
                                        Ajouter
                                    </MDButton>
                                </Link>
                            </>
                        ) : (
                            <Box
                                m="0 0 0 0"
                                height="90vh"
                            >
                                <StyledDataGrid
                                    rows={holidays}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    className={`${darkMode ? "text-light" : "text-dark"}`}
                                    getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </Box>
                        )}
                    {/* </Container> */}
                    <Dialog
                        open={isDeleteModalOpen}
                        onClose={closeDeleteModal}
                    >
                        <DialogTitle
                            textAlign={'center'}
                            sx={{
                                backgroundColor: "red",
                                color: '#fff'
                            }}
                        >Confirmation de la suppression</DialogTitle>
                        <DialogContent>
                            <DialogContentText mt={2} style={{ color: darkMode ? "#fff" : "#000" }}>
                                Êtes-vous sûr de vouloir supprimer ce congé?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton className="btn btn-sm" onClick={closeDeleteModal}>
                                Annuler
                            </MDButton>
                            <MDButton color='error' className="btn btn-sm" onClick={handleDelete}>
                                Supprimer
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout>
    );
};

export default HolidaysList;
