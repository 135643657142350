import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChargeFiles, fetchFileContent, downloadFile, deleteFile } from '../../actions/ChargesActions';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';

const FilePreview = ({ fileContent, fileName, onClose, chargeId, currentUser }) => {
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1].toLowerCase();
        }
        return '';
    };
    const fileExtension = getFileExtension(fileName);

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isPdf = fileExtension === 'pdf';

    const handleDownload = () => {
        dispatch(downloadFile(chargeId, fileName))
            .then(() => {
                dispatch(fetchChargeFiles(chargeId));
            });
    };

    const handleDelete = () => {
        setSnackbarOpen(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteFile(chargeId, fileName));
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog open={true} fullWidth maxWidth="md">
                <DialogTitle>Aperçu du fichier</DialogTitle>
                <DialogContent>
                    {isImage && (
                        <>
                            <img
                                src={`data:image/${fileExtension};base64,${fileContent}`}
                                alt="Preview"
                            />
                        </>
                    )}
                    {isPdf && (
                        <>
                            <embed
                                src={`data:application/pdf;base64,${fileContent}`}
                                type="application/pdf"
                                width="100%"
                                height="500px"
                            />
                        </>
                    )}

                    {!isImage && (!isPdf) && (
                        <>
                            <p>Aperçu non disponible pour ce type de fichier.</p>
                        </>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                        || currentUser.permissions.includes("Exporter les fichiers")) && (
                            <>
                                <MDButton className='btn-sm me-1 mt-2' size='small' color={'info'} onClick={handleDownload}>
                                    Télécharger
                                </MDButton>
                            </>
                        )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                        || currentUser.permissions.includes("Supprimer les fichiers")) && (
                            <>
                                <MDButton className='btn-sm ms-1 mt-2' size='small' color={'error'} onClick={handleDelete}>
                                    Supprimer
                                </MDButton>
                            </>
                        )}
                    <MDButton className='btn-sm ms-2 mt-2' size='small' color={'secondary'} onClick={onClose}>
                        Fermer
                    </MDButton>
                </DialogContent>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity="warning"
                    >
                        Êtes-vous sûr de vouloir supprimer ce fichier?
                        <MDButton color="inherit" size="small" onClick={handleConfirmDelete}>
                            Confirmer
                        </MDButton>
                    </Alert>
                </Snackbar>
            </Dialog>
        </>
    );
};

const ChargeFiles = ({ chargeId }) => {
    const dispatch = useDispatch();
    const projectFiles = useSelector((state) => state.files.files);
    const [selectedFile, setSelectedFile] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(fetchChargeFiles(chargeId));
    }, [dispatch, chargeId]);


    const handleViewFile = async (fileName) => {
        try {
            const response = await dispatch(fetchFileContent(chargeId, fileName));
            setSelectedFile({
                fileName,
                fileContent: response.data,
            });
        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    };

    const handleCloseFilePreview = () => {
        setSelectedFile(null);
    };


    return (
        <div>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Consulter les fichiers")) && (
                    <>
                        {projectFiles.length > 0 ? (
                            <List>
                                {projectFiles.map((file, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={file} />
                                        <MDButton
                                            variant="contained"
                                            className='btn-sm mt-2'
                                            size='small'
                                            color="primary"
                                            onClick={() => handleViewFile(file)}
                                        >
                                            Aperçu
                                        </MDButton>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <p>Aucun fichier trouvé.</p>
                        )}
                    </>)}
            {selectedFile && (
                <FilePreview
                    fileContent={selectedFile.fileContent}
                    fileName={selectedFile.fileName}
                    chargeId={chargeId}
                    onClose={handleCloseFilePreview}
                    currentUser={currentUser}
                />
            )}
        </div>
    );
};

export default ChargeFiles;
