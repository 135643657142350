import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    useMediaQuery,
    TableHead,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    Snackbar,
    Alert,
    TableRow,
    Modal,
    Typography,
    Select,
    MenuItem,
    TextField,
    Box,
    Tooltip,
    IconButton,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import { getProjectTasks, ressignTask, deleteTache } from '../../actions/TacheActions';
import { getAllUsers } from '../../actions/UserActions';
import UpdateTache from './UpdateTask';
import { Link } from 'react-router-dom';
import { Delete, BorderColor } from '@mui/icons-material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(() => ({
    '& .super-app-theme--pending': {
        backgroundColor: 'orange',
        '&:hover': {
            backgroundColor: 'orange',
        },
        '&.Mui-selected': {
            backgroundColor: 'orange',
            '&:hover': {
                backgroundColor: 'orange',
            },
        },
    },
    '& .super-app-theme--rejected': {
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        },
        '&.Mui-selected': {
            backgroundColor: 'red',
            '&:hover': {
                backgroundColor: 'red',
            },
        },
    },
    '& .super-app-theme--confirmed': {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
        '&.Mui-selected': {
            backgroundColor: 'green',
            '&:hover': {
                backgroundColor: 'green',
            },
        },
    },

    '& .super-app-theme--': {
        backgroundColor: '#DBE4F0',
        '&:hover': {
            backgroundColor: '#B0C4DE',
        },
        '&.Mui-selected': {
            backgroundColor: '#B0C4DE',
            '&:hover': {
                backgroundColor: '#B0C4DE',
            },
        },
    },
}));


const ProjectTasks = ({ projectId, darkMode, currentUser }) => {
    const dispatch = useDispatch();
    const taskData = useSelector((state) => state.tache.taches) || { tasks: [] };
    const { tasks } = taskData;

    const users = useSelector((state) => state.users.users) || [];
    const [pageSize, setPageSize] = useState(25);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tacheToDelete, setTacheToDelete] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    // useEffect(() => {
    //     dispatch(getAllUsers());
    // }, [dispatch]);

    useEffect(() => {
        dispatch(getProjectTasks(projectId));
    }, [dispatch, projectId]);

    useEffect(() => {
        if (Array.isArray(tasks)) {
            const filteredItem = tasks?.filter((task) => {
                const name = task?.numero || '';
                const nom = task.user?.nom || '';
                const prenom = task.user?.prenom || '';
                return (
                    
                    name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    prenom.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setFilteredItems(filteredItem);
        }
    }, [tasks, searchTerm]);



    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const handleDeleteClick = (tacheId) => {
        setTacheToDelete(tacheId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteTache(tacheToDelete))
            .then(() => {
                dispatch(getProjectTasks(projectId));
                setSuccessMessage('Task deleted successfully.');
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.error('Error deleting task:', error);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };



    const columns = [
        {
            field: 'projet?.numero', headerName: 'Projet',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.projet?.numero || '',
        },
        {
            field: 'user?.nom', headerName: 'Personnel',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.user?.nom || '',
        },
        {
            field: 'projet?.reference_fonciere',
            headerName: 'Référence',
            flex: 1,
            valueGetter: (params) => params.row.projet?.reference_fonciere || '',
        },
        { field: 'name', headerName: 'Tâche', ...(isMobile ? { width: 120 } : { flex: 1 }), },
        {
            field: 'status',
            headerName: 'Status',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.status || '-',
            renderCell: (params) => {
                let statusLabel, statusColor;

                switch (params.row.status) {
                    case 'pending':
                        statusLabel = 'En cours';
                        statusColor = 'orange';
                        break;
                    case 'confirmed':
                        statusLabel = 'Validée';
                        statusColor = 'green';
                        break;
                    case 'rejected':
                        statusLabel = 'Rejetée';
                        statusColor = 'red';
                        break;
                    default:
                        statusLabel = "Dans la liste d'attente";
                        statusColor = 'black';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statusLabel}</span>
                );
            },
        },
        {
            field: 'deadline', headerName: "Date d'échéance",
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'actions',
            headerName: 'Supprimer',
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les tâches")) && (
                            <Tooltip title='Supprimer' arrow>
                                <IconButton
                                    variant="contained"
                                    size='small'
                                    color="error"
                                    onClick={() => handleDeleteClick(params.row.id)}
                                >
                                    <Delete fontSize='medium' />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            cellClassName: 'actions-cell',
        },

    ];

    const getStatusColor = (isRejected, isConfirmed, isPending) => {
        if (isConfirmed) {
            return '#4CAF50';
        } else if (isPending) {
            return '#FFA500';
        } else if (isRejected) {
            return 'red';
        } else {
            return '';
        }
    };
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    return (
        <>
            <Box>
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Rechercher par nom de la tâche ou par presonnel"
                    onChange={handleSearch}
                    value={searchTerm}
                    name="Search Project"
                    sx={{
                        gridColumn: "span 2",
                        marginBottom: '1rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        "& .MuiInputLabel-root": { paddingLeft: '1rem', paddingRight: '1rem' },
                    }}
                />
                <StyledDataGrid
                    rows={filteredItems}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    components={{ Toolbar: GridToolbar }}
                    getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                    autoHeight
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        background: 'red !important',
                        color: '#fff',
                        marginBottom: '1rem'
                    }}
                >Supprimer la tâche</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir supprimer cette tâche ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Annuler</Button>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                        onClick={handleDeleteConfirm} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                >
                    {successMessage}
                </Alert>
            </Snackbar>


            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Modifier la tâche
                </DialogTitle>
                <DialogContent>
                    <UpdateTache tacheId={selectedItem?.id} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default ProjectTasks;

