import React, { useEffect, useState } from 'react';
import ProjectService from '../../services/projetServices';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Paper, Typography, TextField } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ResponsiveBar } from '@nivo/bar'; 
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const AmountByPaiementAndMonth = () => {
    const [projectsAmountByPaiementAndMonth, setProjectsAmountByPaiementAndMonth] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        fetchProjectsAmountByPaiementAndMonth();
    }, []);

    const fetchProjectsAmountByPaiementAndMonth = async () => {
        try {
            const response = await ProjectService.getTotalProjectsAmountByPaiementAndMonth();
            setProjectsAmountByPaiementAndMonth(response.data);
        } catch (error) {
            console.log(error);
        }
    };


    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setPage(0);
    };

    const filteredProjects = projectsAmountByPaiementAndMonth.filter((item) =>
        item.month?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginationCount = Math.ceil(filteredProjects.length / rowsPerPage);
    const paginatedProjects = filteredProjects.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const sortedProjects = React.useMemo(() => {
        if (!sortColumn) return paginatedProjects;

        return paginatedProjects.slice().sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];

            // Handle null values (consider them as empty strings)
            const aValueStr = aValue ? aValue : '';
            const bValueStr = bValue ? bValue : '';

            if (sortDirection === 'asc') {
                return aValueStr.localeCompare(bValueStr);
            } else {
                return bValueStr.localeCompare(aValueStr);
            }
        });
    }, [sortColumn, sortDirection, paginatedProjects]);

    const renderSortIndicator = (column) => {
        if (sortColumn === column) {
            return sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
        }
        return null;
    };

    const yAxisTickFormatter = (tickValue) => {
        if (tickValue >= 1000) {
            return `${tickValue / 1000}k`;
        }
        return tickValue;
    };

    const CustomYAxisTick = ({ x, y, payload }) => (
        <g transform={`translate(${x},${y})`}>
            <text
                className='mt-2'
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {yAxisTickFormatter(payload.value)}
            </text>
        </g>
    );

    const chartData = sortedProjects.map((item) => {
        return {
            month: item.month,
            [item.paiement]: parseFloat(item.totalAmount),
        };
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h3" textAlign={'center'} gutterBottom>
                Total Projects Amount By Paiement and Month
            </Typography>
            <div style={{ height: 300 }}> {/* Set the height for the chart */}
                <ResponsiveBar
                    data={chartData}
                    indexBy="month"
                    keys={['oui', 'non']}
                    margin={{ top: 20, right: 30, bottom: 50, left: 60 }} // Adjust margins as needed
                    padding={0.3}
                    layout="horizontal"
                    colors={{ scheme: 'nivo' }}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -35, // Adjust the rotation of the X-axis labels
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    enableLabel={false}
                    tooltip={function ({ id, value, color }) {
                        return (
                            <strong style={{ color }}>{id}: {value}</strong>
                        );
                    }}
                />
            </div>
            <TextField
                label="Search by month"
                variant="filled"
                size="small"
                fullWidth
                value={searchTerm}
                onChange={handleSearch}
                style={{ marginBottom: 10 }}
                sx={{ gridColumn: "span 2" }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => handleSort('paiement')}>
                                Paiement {renderSortIndicator('paiement')}
                            </TableCell>
                            <TableCell onClick={() => handleSort('month')}>
                                Month {renderSortIndicator('month')}
                            </TableCell>
                            <TableCell onClick={() => handleSort('totalAmount')}>
                                Total Amount {renderSortIndicator('totalAmount')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedProjects.map((item) => (
                            <TableRow key={item.paiement + item.month}>
                                <TableCell>{item.paiement}</TableCell>
                                <TableCell>{item.month}</TableCell>
                                <TableCell>{item.totalAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredProjects.length}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </DashboardLayout>
    );
};

export default AmountByPaiementAndMonth;
