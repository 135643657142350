import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserHolidays, deleteHoliday } from "../../actions/HolidaysActions";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { useMaterialUIController } from "../../context";
import MDButton from "../../components/MDButton";


const UserHolidaysList = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const holidays = useSelector((state) => state.holiday.holidays);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageSize, setPageSize] = useState(25);


    useEffect(() => {
        dispatch(getUserHolidays(currentUser.id));
    }, [dispatch]);

    const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedItem(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedItem) {
            dispatch(deleteHoliday(selectedItem.id))
                .then(() => {
                    dispatch(getUserHolidays(currentUser.id));
                })
                .catch((error) => {
                    console.error('Error Confiramtion indemnite:', error);
                });
            setSelectedItem(null);
        }
        closeDeleteModal();
    };
    const columns = [
        // { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'requestDays', headerName: 'Days', flex: 1 },
        { field: 'requestDate', headerName: 'Date', flex: 1 },
        { field: 'startDate', headerName: 'Start Date', flex: 1 },
        { field: 'endDate', headerName: 'Date fin', flex: 1 },
        { field: 'status', headerName: 'Statut', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => (
                <div style={{ overflow: 'visible !important' }}>
                    {params.row.status === 'pending' && (
                        <>
                            <MDButton
                                size='small'
                                color='error'
                                className="btn btn-sm"
                                onClick={() => openDeleteModal(params.row)}
                                style={{ marginLeft: "8px" }}
                            >
                                Supprimer
                            </MDButton>

                            <MDButton
                                to={`/holidays/update/${params.row.id}`}
                                component={Link}
                                size='small'
                                color='success'
                                className="btn btn-sm"
                                style={{ marginLeft: "8px" }}
                            >
                                Modifier
                            </MDButton>
                        </>
                    )}
                </div>

            ),
            cellClassName: 'actions-cell',
        },
    ];

    const createButton = (
        <>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Envoyer des demandes de congés")) && (
                <MDButton
                    component={Link}
                    to={`/ressources-humaines/holidays/send/request`}
                    variant="contained"
                    color='dark'
                    className='mt-2 ms-2'
                    size='small'
                >
                    <ControlPointIcon sx={{ mr: "12px" }} />
                    Ajouter
                </MDButton>
            )}
        </>
    );
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="md">
                <Typography variant="h3" textAlign={"center"} mb={5} gutterBottom>
                    Liste des congés
                </Typography>
                {holidays?.length === 0 ? (
                    <>
                        <Typography variant="body1">No indemnisation available.</Typography>
                        <Link to={`/ressources-humaines/holidays/send/request`} className="text-decoration-none float-end">
                            <MDButton color='info' className="btn btn-sm">
                                <ControlPointIcon sx={{ mr: "10px" }} />
                                Create
                            </MDButton>
                        </Link>
                    </>
                ) : (
                    <Box
                        m="0 0 0 0"
                        height="90vh"
                    >
                        <DataGrid
                            rows={holidays?.holidays || []}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            className={`${darkMode ? "text-light" : "text-dark"}`}
                            components={{
                                Toolbar: (props) => (
                                    <div className='d-flex'>
                                        {createButton}
                                        <GridToolbar {...props} />
                                    </div>
                                ),
                            }}
                            disableSelectionOnClick
                        />
                    </Box>
                )}
            </Container>
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle className={darkMode ? "text-light" : "text-dark"}>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: darkMode ? "#fff" : "#000" }}>
                        Are you sure you want to delete this holiday?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeDeleteModal}>
                        Cancel
                    </MDButton>
                    <MDButton color='error' className="btn btn-sm" onClick={handleDelete}>
                        Delete
                    </MDButton>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default UserHolidaysList;
