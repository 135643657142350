import ProductService from '../services/productsServices';
import {
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_FAILURE,
    GET_PRODUCT_STOCK_SUCCESS,
    GET_PRODUCT_STOCK_FAILURE,
} from './types';

export const createProduct = (productData) => {
    return (dispatch) => {
        return ProductService.createProduct(productData)
            .then((response) => {
                dispatch({
                    type: CREATE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const addQuantity = (productId, productData) => {
    return (dispatch) => {
        return ProductService.addQuantity(productId, productData)
            .then((response) => {
                dispatch({
                    type: CREATE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const minusQuantity = (productId, productData) => {
    return (dispatch) => {
        return ProductService.minusQuantity(productId, productData)
            .then((response) => {
                dispatch({
                    type: CREATE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const fetchProductStock = (productId) => {
    return (dispatch) => {
        return ProductService.getAllProductStock(productId)
            .then((response) => {
                dispatch({
                    type: GET_PRODUCT_STOCK_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_PRODUCT_STOCK_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchAllProducts = () => {
    return (dispatch) => {
        return ProductService.getAllProducts()
            .then((response) => {
                dispatch({
                    type: GET_ALL_PRODUCTS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_PRODUCTS_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const updateProduct = (productId, updatedProduct) => {
    return (dispatch) => {
        return ProductService.updateProduct(productId, updatedProduct)
            .then((response) => {
                dispatch({
                    type: UPDATE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const getProduct = (productId) => {
    return (dispatch) => {
        return ProductService.fetchProduct(productId)
            .then((response) => {
                dispatch({
                    type: GET_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: GET_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const deleteProduct = (productId) => {
    return (dispatch) => {
        return ProductService.deleteProduct(productId)
            .then((response) => {
                dispatch({
                    type: DELETE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};
