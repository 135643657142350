import React, { useEffect, useState } from 'react';
import { Typography, Grid, Paper, Link, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StatisticsServices from '../../services/statisticsServices';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const AnimatedCard = styled(Paper)({
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#45a049',
  },
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  '@media (max-width:600px)': {
    width: '100%',
  },
});

const MainContainer = styled('div')({
  padding: '20px',
});

const CardContent = styled('div')({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const styles = {
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: '10px',
    color: '#fff',
  },
  description: {
    textAlign: 'center',
    fontSize: '1rem',
    color: '#eee',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
    marginTop: '10px',
    backgroundColor: '#4CAF50',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  // icon: {
  //   fontSize: '3rem',
  //   marginBottom: '10px',
  //   color: '#fff',
  // },

  icoCard: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '12rem',
    opacity: '0.2',
    color: 'rgba(255,255,255,1)',
  },
};

const CreativeCard = ({ title, secondDesc, firstDesc, link, icon, firsCount, secondCount }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Link component={RouterLink} to={link} sx={{ textDecoration: 'none', color: 'inherit' }}>
        <AnimatedCard>
          <CardContent>
            <Typography variant="h1" sx={styles.title}>
              {title.toUpperCase()}
            </Typography>
            <Typography component="p" sx={styles.description}>
              {firstDesc} : {firsCount}
            </Typography>
            <Typography component="p" sx={styles.description}>
              {secondDesc} : {secondCount}
            </Typography>
          </CardContent>
          <div className="ico-card" sx={styles.icoCard}>
            <IconButton sx={styles.icon}>
              {icon}
            </IconButton>
          </div>
        </AnimatedCard>
      </Link>
    </Grid>
  );
};

const Statistics = () => {
  const [projectsCount, setProjectsCount] = useState(null);
  const [chargesAmount, setChargesAmount] = useState(null);
  const [encaissementsAmount, setEncaissementsAmount] = useState(null);
  const [projectAmount, setProjectAmount] = useState(null);
  const [tasksCount, setTasksCount] = useState(null);

  useEffect(() => {
    fetchProjectsLastCurrentMonth();
    fetchChargesAmountLastCurrentMonth();
    fetchEncaissementsAmountLastCurrentMonth();
    projectAmountClotureNonPaiement();
    // completedTasksLastCurrentMonth();
  }, []);

  const fetchProjectsLastCurrentMonth = async () => {
    try {
      const response = await StatisticsServices.projectsCreatedInLastAndCurrentMonth();
      setProjectsCount(response.data);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  const fetchChargesAmountLastCurrentMonth = async () => {
    try {
      const response = await StatisticsServices.chargesAmountInLastAndCurrentMonth();
      setChargesAmount(response.data);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  const fetchEncaissementsAmountLastCurrentMonth = async () => {
    try {
      const response = await StatisticsServices.encaissementsAmountCurrentAndPrevMonth();
      setEncaissementsAmount(response.data);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  const projectAmountClotureNonPaiement = async () => {
    try {
      const response = await StatisticsServices.totalAmountClotureNonPaiement();
      setProjectAmount(response.data);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  // const completedTasksLastCurrentMonth = async () => {
  //   try {
  //     const response = await StatisticsServices.completedTasksCountMonth();
  //     setTasksCount(response.data);
  //   } catch (error) {
  //     console.error('Error fetching:', error);
  //   }
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MainContainer>
        <Typography
          sx={{
            fontSize: '36px',
            textAlign: 'center',
            marginTop: '12px',
            fontWeight: 'bold',
            color: '#3e4396',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
          variant="h2"
          gutterBottom
        >
          ANALYSES ET STATISIQUES
        </Typography>
        <Grid container spacing={3}>
          <CreativeCard
            title="PROJET"
            firstDesc="nombre de projets dans le mois en cours"
            secondDesc="nombre de projets dans le mois précédent."
            link="/statistics/projects"
            icon={<PermMediaIcon />}
            firsCount={projectsCount?.projectsCurrentMonth || 0}
            secondCount={projectsCount?.projectsPreviousMonth || 0}
          />
          <CreativeCard
            title="CHARGES"
            firstDesc="totale des charges dans le mois en cours"
            secondDesc="totale des charges dans le mois précédent."
            link="/statistics/charges"
            icon={<RequestQuoteIcon />}
            firsCount={chargesAmount?.chargesCurrentMonth || 0}
            secondCount={chargesAmount?.chargesPreviousMonth || 0}
          />
          <CreativeCard
            title="RECETTES"
            firstDesc="totale des recettes dans le mois en cours"
            secondDesc="totale des recettes dans le mois précédent."
            link="/statistics/recettes"
            icon={<PointOfSaleIcon />}
            firsCount={encaissementsAmount?.encaissementsCurrentMonth || 0}
            secondCount={encaissementsAmount?.encaissementsPreviousMonth || 0}
          />
          <CreativeCard
            title="RECOUVREMENT"
            firstDesc="montant total projet clôturé impayé mois en cours"
            secondDesc="montant total projet clôturé impayé précédent."
            link="/statistics/recouvrement"
            icon={<CurrencyExchangeIcon />}
            firsCount={projectAmount?.totalAmountCurrentMonth || 0}
            secondCount={projectAmount?.totalAmountPreviousMonth || 0}
          />
          {/* <CreativeCard
            title="PERFOMANCE"
            firstDesc="nombre des tâches exécutées dans le moi en cours"
            secondDesc="nombre des tâches exécutées dans le moi précédent."
            link="/statistics/performance"
            icon={<PeopleAltIcon />}
            firsCount={tasksCount?.tasksCurrentMonth || 0}
            secondCount={tasksCount?.tasksPreviousMonth || 0}
          /> */}
        </Grid>
      </MainContainer>
    </DashboardLayout>
  );
};

export default Statistics;
