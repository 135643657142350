import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvanceFiles, fetchFileContent, downloadFile, deleteFile } from '../../actions/EncaissementActions';
import {
    Snackbar,
    Typography,
    Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import FileUpload from './UploadFiles';
import StatisticsServices from "../../services/statisticsServices";

const FilePreview = ({ fileContent, fileName, onClose, projectId, currentUser }) => {
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1].toLowerCase();
        }
        return '';
    };
    const fileExtension = getFileExtension(fileName);

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isPdf = fileExtension === 'pdf';

    const handleDownload = () => {
        dispatch(downloadFile(projectId, fileName));
    };

    const handleDelete = () => {
        setSnackbarOpen(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteFile(projectId, fileName))
            .then(() => {
                dispatch(fetchAvanceFiles(projectId));
            });
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    return (
        <>
            {isImage && (
                <div >
                    <img
                        src={`data:image/${fileExtension};base64,${fileContent}`}
                        alt="Preview"
                        style={{ height: 'auto', width: '90%' }}
                    />
                </div>
            )}
            {isPdf && (
                <>
                    <embed
                        src={`data:application/pdf;base64,${fileContent}`}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                    />
                </>
            )}

            {!isImage && (!isPdf) && (
                <>
                    <p>Aperçu non disponible pour ce type de fichier.</p>
                </>
            )}
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Exporter les fichiers")) && (
                    <>
                        <MDButton className='btn-sm me-1 mt-2' size='small' color={'info'} onClick={handleDownload}>
                            Télécharger
                        </MDButton>
                    </>
                )}
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Supprimer les fichiers")) && (
                    <>
                        <MDButton className='btn-sm ms-1 mt-2' size='small' color={'error'} onClick={handleDelete}>
                            Supprimer
                        </MDButton>
                    </>
                )}
            <MDButton className='btn-sm ms-2 mt-2' size='small' color={'info'} onClick={onClose}>
                Fermer
            </MDButton>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="warning"
                >
                    Êtes-vous sûr de vouloir supprimer ce fichier ?
                    <MDButton color="inherit" size="small" onClick={handleConfirmDelete}>
                        Supprimer
                    </MDButton>
                </Alert>
            </Snackbar>
        </>
    );
};

const AvanceFiles = ({ projectId }) => {
    const dispatch = useDispatch();
    const files = useSelector((state) => state.files.files);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setError(null);
                await dispatch(fetchAvanceFiles(projectId));
            } catch (error) {
                console.error('Error fetching files:', error);
                setError('Une erreur s\'est produite lors du chargement des fichiers.');
            }
        };

        fetchData();
    }, [dispatch, projectId]);

    const handleViewFile = async (fileName) => {
        try {
            const response = await dispatch(fetchFileContent(projectId, fileName));
            setSelectedFile({
                fileName,
                fileContent: response.data,
            });
        } catch (error) {
            console.error('Error fetching file content:', error);
            setError('Une erreur s\'est produite lors du chargement du fichier.');
        }
    };

    const handleCloseFilePreview = () => {
        setSelectedFile(null);
    };
    const handleExportArchive = async (folder) => {
        try {
            const response = await StatisticsServices.downloadArchive(folder);

            const blob = new Blob([response.data], { type: 'application/zip' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'archive.zip';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting archive:', error);
        }
    };

    return (
        <div className='p-2'>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Consulter les fichiers")) && (
                    <>
                        {error ? (
                            <p>{error}</p>
                        ) : (
                            files.length > 0 ? (
                                <>
                                    <List>
                                        {files.map((file, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={file} />
                                                <MDButton
                                                    variant="contained"
                                                    className='btn-sm mt-2'
                                                    size='small'
                                                    color="primary"
                                                    onClick={() => handleViewFile(file)}
                                                >
                                                    Aperçu
                                                </MDButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                                        || currentUser.permissions.includes("Exporter l'archive")) &&
                                        <MDButton
                                            onClick={() => handleExportArchive(`encaissements.${projectId}`)}
                                            size='small'
                                            color='success'
                                            className='mt-2'
                                        >
                                            Exporter L'archive
                                        </MDButton>
                                    }
                                </>
                            ) : (
                                <p>Aucun fichier trouvé.</p>
                            )
                        )}
                        {selectedFile && (
                            <FilePreview
                                fileContent={selectedFile.fileContent}
                                fileName={selectedFile.fileName}
                                projectId={projectId}
                                onClose={handleCloseFilePreview}
                                currentUser={currentUser}
                            />
                        )}
                    </>)}
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Importer les fichiers")) && (
                    <div className='mt-1 p-4'>
                        <Typography variant='h4' mb={2} textAlign={'center'}>
                            Importer des fichiers
                        </Typography>
                        <FileUpload projectId={projectId} />
                    </div>
                )}
        </div>
    );
};


export default AvanceFiles;
