import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../actions/UserActions";
import { getAllVehicules } from "../../actions/VehiculeActions";
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  Container,
  TextField,
  Autocomplete,
  Button,
  Grid,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import API_URL from "../../services/apiUrl";
import { fetchAllProjects } from '../../actions/ProjetActions';

const MissionDorder = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const vehicules = useSelector((state) => state.vehicule.vehicules);
  const projects = useSelector((state) => state.project.projects);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [renderMiss, setRenderMiss] = useState("");
  const [nDeMission, setNDeMission] = useState("");
  const [objet, setObjet] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedDateDebut, setSelectedDateDebut] = useState(null);
  const [selectedHeureDebut, setSelectedHeureDebut] = useState(null);
  const [selectedDateRet, setSelectedDateRet] = useState(null);
  const [selectedHeureRet, setSelectedHeureRet] = useState(null);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllVehicules());
    dispatch(fetchAllProjects());
  }, [dispatch]);

  const handleExportToWord = async () => {
    try {
      const response = await axios.get('/order_template.docx', {
        responseType: 'arraybuffer',
      });

      const projectTemplate = response.data;
      const zip = new PizZip(projectTemplate);
      const doc = new Docxtemplater(zip);

      const data = {
        date: selectedDate || "-",
        numero: selectedProject?.numero || "-",
        users: selectedUsers?.map(user => ({ nom: user.nom, prenom: user.prenom })) || [],
        render: renderMiss || "-",
        objet: objet || "-",
        voiture: selectedMaterial?.type || "-",
        mat: selectedMaterial?.matricule || "-",
        ddepart: selectedDateDebut || "-",
        hdepart: selectedHeureDebut || "-",
        dretour: selectedDateRet || "-",
        hretour: selectedHeureRet || "-",
      };

      doc.setData(data);

      try {
        doc.render();
      } catch (error) {
        console.error('Error rendering the template:', error);
        return;
      }

      try {
        const blob = doc.getZip().generate({ type: 'blob' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `mission_${nDeMission}.docx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (error) {
        console.error('Error generating the Word document:', error);
      }
    } catch (error) {
      console.error('Error fetching the template:', error);
    }
  };

  return (
    <>
      <Container sx={{marginTop: '1rem'}}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={users}
                getOptionLabel={(user) => user.nom}
                onChange={(event, newValue) => setSelectedUsers([...selectedUsers, newValue])}
                renderInput={(params) => <TextField {...params} label="Users" />}
              />
              <List>
                {selectedUsers?.map((user, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={user?.nom} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="date"
                label="Date"
                onChange={(e) => setSelectedDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={projects}
                getOptionLabel={(project) => project.numero}
                onChange={(event, newValue) => setSelectedProject(newValue)}
                renderInput={(params) => <TextField {...params} label="Project" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="De se rendre à"
                variant="outlined"
                fullWidth
                value={renderMiss}
                onChange={(e) => setRenderMiss(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Objet"
                variant="outlined"
                fullWidth
                value={objet}
                onChange={(e) => setObjet(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={vehicules}
                getOptionLabel={(vehicule) => vehicule.matricule}
                onChange={(event, newValue) => setSelectedMaterial(newValue)}
                renderInput={(params) => <TextField {...params} label="Vehicules" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="date"
                label="Date Debut"
                onChange={(e) => setSelectedDateDebut(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="time"
                label="Heure Debut"
                onChange={(e) => setSelectedHeureDebut(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="date"
                label="Date Fin"
                onChange={(e) => setSelectedDateRet(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="time"
                label="Heure Fin"
                onChange={(e) => setSelectedHeureRet(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
              variant="contained"
              sx={{
                color: '#fff !important'
              }}
              color="primary"
              onClick={handleExportToWord}>
              Exporter 
            </Button>
          </Box>
      </Container>
    </>
  );
};

export default MissionDorder;
