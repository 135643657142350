import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getVehicule, updateVehicule } from '../../actions/VehiculeActions';
import { useParams } from 'react-router-dom';
import { getAllUsers } from '../../actions/UserActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDButton from '../../components/MDButton';
import AccessDenied from "../Errors/AccessDenied";

const UpdateVehicule = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const vehicule = useSelector((state) => state.vehicule.vehicule);
  const [type, setType] = useState('');
  const [model, setModel] = useState('');
  const [matricule, setMatricule] = useState("");
  const [dateAcquisition, setDateAcquisition] = useState('');
  const [prixAcquisition, setPrixAcquisition] = useState(0);
  const [kilometrage, setKilometrage] = useState(0);
  const [userId, setUserId] = useState('');
  const users = useSelector((state) => state.users.users);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    dispatch(getVehicule(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (vehicule) {
      setType(vehicule?.type);
      setModel(vehicule?.model);
      setDateAcquisition(vehicule?.date_acquisition);
      setPrixAcquisition(vehicule?.prix_acquisition);
      setKilometrage(vehicule?.kilometrage);
      setMatricule(vehicule?.matricule);
      setUserId(vehicule?.userId);
    }
  }, [vehicule]);

  const handleUpdate = () => {
    const updatedVehicule = {
      type,
      model,
      date_acquisition: dateAcquisition,
      prix_acquisition: prixAcquisition,
      kilometrage,
      matricule,
      userId,
    };

    dispatch(updateVehicule(vehicule.id, updatedVehicule))
      .then(() => {
        setSnackbarMessage('Vehicule updated successfully');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les véhicules")) ? (
        <Container>
          <Typography variant="h2" align="center" gutterBottom>
            Mettre à jour le Véhicule
          </Typography>
          {vehicule ? (
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Type"
                    id="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Model"
                    id="model"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Matricule"
                    id="matricule"
                    value={matricule}
                    onChange={(e) => setMatricule(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Date Acquisition"
                    id="dateAcquisition"
                    type="date"
                    value={dateAcquisition}
                    onChange={(e) => setDateAcquisition(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Prix Acquisition"
                    id="prixAcquisition"
                    type="number"
                    value={prixAcquisition}
                    onChange={(e) => setPrixAcquisition(Number(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Kilométrage"
                    id="kilometrage"
                    type="number"
                    value={kilometrage}
                    onChange={(e) => setKilometrage(Number(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="userIdLabel">User</InputLabel>
                    <Select
                      labelId="userIdLabel"
                      id="userId"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    >
                      {users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <MDButton
                    type="button"
                    variant="contained"
                    color="info"
                    onClick={handleUpdate}
                    fullWidth
                  >
                    Update
                  </MDButton>
                </Grid>
              </Grid>
            </form>
          ) : (
            <p>Loading vehicule details...</p>
          )}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={closeSnackbar}
          >
            <Alert onClose={closeSnackbar} severity="success">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <div className="container">
          <AccessDenied />
        </div>
      )}
    </DashboardLayout>
  );
};

export default UpdateVehicule;
