import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectFiles, fetchFileContent, downloadFile, deleteFile } from '../../actions/ProjetActions';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Grid,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const FilePreview = ({ fileContent, fileName, onClose, projectId, currentUser }) => {
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1].toLowerCase();
        }
        return '';
    };
    const fileExtension = getFileExtension(fileName);

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isPdf = fileExtension === 'pdf';

    const handleDownload = () => {
        dispatch(downloadFile(projectId, fileName));
    };

    const handleDelete = () => {
        setSnackbarOpen(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteFile(projectId, fileName))
        .then(() => {
            dispatch(fetchProjectFiles(projectId));
        });
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog open={true} fullWidth maxWidth="sm">
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    File Preview
                </DialogTitle>
                <DialogContent>
                    {isImage && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <img
                                        src={`data:image/${fileExtension};base64,${fileContent}`}
                                        alt="Preview"
                                        width={'540px'}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {isPdf && (
                        <>
                            <embed
                                src={`data:application/pdf;base64,${fileContent}`}
                                type="application/pdf"
                                width="100%"
                                height="500px"
                            />
                        </>
                    )}

                    {!isImage && (!isPdf) && (
                        <>
                            <p>Aperçu non disponible pour ce type de fichier.</p>
                        </>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                        || currentUser.permissions.includes("Exporter les fichiers")) && (
                            <>
                                <MDButton className='btn-sm me-1 mt-2' size='small' color={'info'} onClick={handleDownload}>
                                    Télécharger
                                </MDButton>
                            </>
                        )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                        || currentUser.permissions.includes("Supprimer les fichiers")) && (
                            <>
                                <MDButton className='btn-sm ms-1 mt-2' size='small' color={'error'} onClick={handleDelete}>
                                    Supprimer
                                </MDButton>
                            </>
                        )}
                    <DialogActions>
                        <Button className='btn-sm ms-2 mt-2' size='small' color={'secondary'} onClick={onClose}>
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity="warning"
                    >
                        Êtes-vous sûr de vouloir supprimer ce fichier ?
                        <MDButton color="inherit" size="small" onClick={handleConfirmDelete}>
                            Supprimer
                        </MDButton>
                    </Alert>
                </Snackbar>
            </Dialog>
        </>
    );
};

const ProjectFilesList = ({ projectId }) => {
    const dispatch = useDispatch();
    const projectFiles = useSelector((state) => state.project.files);
    const [selectedFile, setSelectedFile] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(fetchProjectFiles(projectId));
    }, [dispatch, projectId]);


    const handleViewFile = async (fileName) => {
        try {
            const response = await dispatch(fetchFileContent(projectId, fileName));
            setSelectedFile({
                fileName,
                fileContent: response.data,
            });
        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    };

    const handleCloseFilePreview = () => {
        setSelectedFile(null);
    };

    return (
        <div>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Importer les fichiers")) && (
                    <>
                        {projectFiles.length > 0 ? (
                            <List>
                                {projectFiles.map((file, index) => (
                                    <ListItem key={index}
                                        sx={{
                                            padding: "8px",
                                            backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
                                            "&:hover": {
                                                backgroundColor: index % 2 === 0 ? "#e0e0e0" : "#f8f8f8",
                                                padding: "10px"
                                            },

                                        }}
                                    >
                                        <ListItemText primary={file} />
                                        <MDButton
                                            variant="contained"
                                            className='btn-sm mt-2'
                                            size='small'
                                            color="info"
                                            onClick={() => handleViewFile(file)}
                                        >
                                            <RemoveRedEyeIcon />
                                            Voir
                                        </MDButton>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <p>Aucun fichier trouvé.</p>
                        )}
                    </>
                )}
            {selectedFile && (
                <FilePreview
                    fileContent={selectedFile.fileContent}
                    fileName={selectedFile.fileName}
                    projectId={projectId}
                    onClose={handleCloseFilePreview}
                    currentUser={currentUser}
                />
            )}
        </div>
    );
};

export default ProjectFilesList;
