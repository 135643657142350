import {
    CREATE_CLIENT_SUCCESS,
    RETRIEVE_CLIENT_BY_TYPE,
    UPDATE_CLIENT,
    RETRIEVE_CLIENT,
    DELETE_CLIENT,
    RETRIEVE_CLIENTS,
    GET_CLIENT
  } from "../actions/types";
  
  const initialState = [];
  
  function clientReducer(clients = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_CLIENT_SUCCESS:
        return [...clients, payload];
  
      case RETRIEVE_CLIENT:
        return payload;
  
      case RETRIEVE_CLIENTS:
        return payload;
  
      case UPDATE_CLIENT:
        // return [...clients, payload];
        return clients.map((client) => {
          if (client.id === payload.id) {
            return {
              ...client,
              ...payload,
            };
          } else {
            return client;
          }
        });
      case GET_CLIENT:
        return clients.map((client) => {
          if (client.id === payload.id) {
            return {
              ...client,
              ...payload,
            };
          } else {
            return client;
          }
        });
  
      case DELETE_CLIENT:
        return clients.filter(({ id }) => id !== payload.id);
  
      case RETRIEVE_CLIENT_BY_TYPE:
        return payload;
  
      default:
        return clients;
    }
  };
  
  export default clientReducer;
  