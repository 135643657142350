import {
    GET_ALL_HOLIDAYS_SUCCESS,
    GET_ALL_HOLIDAYS_FAILURE,
    UPDATE_HOLIDAY_SUCCESS,
    UPDATE_HOLIDAY_FAILURE,
    GET_ALL_PENDING_HOLIDAYS_SUCCESS,
    GET_ALL_PENDING_HOLIDAYS_FAILURE,
    DELETE_HOLIDAY_SUCCESS,
    DELETE_HOLIDAY_FAILURE,
    SEND_HOLIDAY_REQUEST_SUCCESS,
    SEND_HOLIDAY_REQUEST_FAILURE,
    FETCH_HOLIDAY_SUCCESS,
    FETCH_HOLIDAY_FAILURE,
    GET_ALL_USERHOLIDAYS_SUCCESS,
    GET_ALL_USERHOLIDAYS_FAILURE,
} from '../actions/types';

const initialState = {
    holiday: null,
    holidays: [],
    error: null,
};

const holidayReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_HOLIDAY_REQUEST_SUCCESS:
            return {
                ...state,
                holiday: action.payload,
                error: null,
            };
        case SEND_HOLIDAY_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_PENDING_HOLIDAYS_SUCCESS:
            return {
                ...state,
                holidays: action.payload,
                error: null,
            };
        case GET_ALL_PENDING_HOLIDAYS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_HOLIDAYS_SUCCESS:
            return {
                ...state,
                holidays: action.payload,
                error: null,
            };
        case GET_ALL_HOLIDAYS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_USERHOLIDAYS_SUCCESS:
            return {
                ...state,
                holidays: action.payload,
                error: null,
            };
        case GET_ALL_USERHOLIDAYS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case FETCH_HOLIDAY_SUCCESS:
            return {
                ...state,
                holiday: action.payload,
                error: null,
            };
        case FETCH_HOLIDAY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_HOLIDAY_SUCCESS:
            return {
                ...state,
                holiday: action.payload,
                error: null,
            };
        case UPDATE_HOLIDAY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_HOLIDAY_SUCCESS:
            return {
                ...state,
                holiday: null,
                error: null,
            };
        case DELETE_HOLIDAY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default holidayReducer;
