import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPendingTaches } from '../../actions/TacheActions';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { format, startOfWeek, endOfWeek, eachDayOfInterval } from 'date-fns';
import { fr } from 'date-fns/locale';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { utcToZonedTime } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import { encodeId } from '../../actions/Crypte';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const UsersSchedule = ({ userId }) => {
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tache.taches) ?? [];
    const [loading, setLoading] = useState(true);
    const [selectedDay, setSelectedDay] = useState(null);

    useEffect(() => {
        dispatch(getPendingTaches(userId))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching pending tasks:', error);
                setLoading(false);
            });
    }, [dispatch, userId]);

    const currentDate = new Date();
    const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
    const endOfCurrentWeek = endOfWeek(currentDate, { weekStartsOn: 1 });

    const weekDays = eachDayOfInterval({ start: startOfCurrentWeek, end: endOfCurrentWeek });
    const tasksForCurrentWeek = tasks?.tasks?.filter((task) => {
        const taskDate = new Date(task.deadline);
        return taskDate >= startOfCurrentWeek && taskDate <= endOfCurrentWeek;
    });

    const formatFrenchDate = (date) => {
        const zonedDate = utcToZonedTime(date, 'Europe/Paris');
        return format(zonedDate, 'EEEE d MMM yyyy', { locale: fr });
    };

    const formatFrenchDatehalf = (date) => {
        const zonedDate = utcToZonedTime(date, 'Europe/Paris');
        return format(zonedDate, 'EEEE d MMM', { locale: fr });
    };

    const handleDayClick = (day) => {
        setSelectedDay(day);
    };

    const handleCloseDialog = () => {
        setSelectedDay(null);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{
                            background: 'rgba(255, 255, 255, 0.3)',
                            borderRadius: '4px',
                            boxShadow: ' 0 5px 20px rgba(0, 0, 0, .3)',
                            height: '501px',
                            color: '#fff !important',
                            width: '100%',
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="schedule-header">&nbsp;</TableCell>
                                    <TableCell className="schedule-header">Tâches</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {weekDays.map((day) => (
                                    <TableRow key={day.getTime()}
                                        sx={{
                                            cursor: 'pointer', '&:hover': { backgroundColor: '#2196f3', color: '#fff !important' }
                                        }} onClick={() => handleDayClick(day)}>
                                        <TableCell
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: '#2196f3',
                                                    color: '#fff !important'
                                                }
                                            }}
                                            className="schedule-cell">
                                            {formatFrenchDatehalf(day).toUpperCase()}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: '#2196f3',
                                                    color: '#fff !important'
                                                }
                                            }}
                                            className="schedule-cell">
                                            {tasksForCurrentWeek
                                                ?.filter((task) => {
                                                    const taskDate = new Date(task.deadline);
                                                    return taskDate.getDate() === day.getDate();
                                                })
                                                .length
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <Dialog
                    open={selectedDay !== null}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth='md'
                >
                    <DialogTitle textAlign={'center'}>{selectedDay && formatFrenchDate(selectedDay).toUpperCase()}</DialogTitle>
                    <DialogContent >
                        {tasksForCurrentWeek
                            .filter((task) => {
                                const taskDate = new Date(task.deadline);
                                const isSameDay = taskDate.getDate() === selectedDay?.getDate();
                                return isSameDay;
                            })
                            .length > 0 ? (
                            <>
                                {tasksForCurrentWeek
                                    .filter((task) => {
                                        const taskDate = new Date(task.deadline);
                                        const isSameDay = taskDate.getDate() === selectedDay?.getDate();
                                        return isSameDay;
                                    })
                                    .map((task) => (
                                        <div className="d-style bgc-white btn btn-brc-tp btn-outline-green btn-h-outline-green btn-a-outline-green w-100 my-2 py-3 shadow-sm border-2">
                                            <div className="d-flex align-items-center">
                                                <div className="me-2 pe-3">
                                                    <h4 className="pt-3 letter-spacing">
                                                        {task?.projet?.numero}
                                                    </h4>
                                                </div>
                                                <div className="w-100 me-2 pe-3">
                                                    <p className="pt-3">
                                                        {task?.projet?.nature && task?.projet?.nature.length > 0 ? (
                                                            <>
                                                                {task?.projet?.nature?.map((natures) => (
                                                                    <div key={natures.id}>{natures.name}</div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                        {task?.projet?.nature.map}
                                                    </p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="pt-3">
                                                        {task.name.toUpperCase()}
                                                    </p>
                                                </div>
                                                <div className="col-12 col-md-4 text-center">
                                                    <Button
                                                        component={Link}
                                                        to={`/projects/project/${encodeId(task?.projet?.id)}`}
                                                        sx={{
                                                            color: '#fff !important',
                                                            backgroundColor: 'green !important',
                                                        }}
                                                        className="btn btn-success btn-raised px-4 py-25 w-75 text-600">
                                                        <VisibilityIcon fontSize='large :important' />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        ) : (
                            <>Aucune tâche disponible</>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </DashboardLayout>
    );
};

export default UsersSchedule;
