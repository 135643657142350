import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Typography,
    TextField,
    Grid,
    Snackbar,
    Autocomplete,
    Alert,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';
import { fetchAllProjects } from '../../actions/ProjetActions';
import MDButton from '../../components/MDButton';
import PolygonService from '../../services/polygonServices';

const AddPolygon = ({ currentUser, projectId }) => {
    const dispatch = useDispatch();

    const projects = useSelector((state) => state.project.projects);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjection, setSelectedProjection] = useState('MoroccoZone1');
    const [dxfFile, setDxfFile] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(fetchAllProjects());
    }, [dispatch]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const projectOptions = projects.map((projet) => ({
        value: projet.id,
        label: projet.numero,
    }));

    const handleFileChange = (event) => {
        console.log(event.target.files);
        setDxfFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!dxfFile) {
            setSnackbarMessage('Veuillez sélectionner un fichier DXF.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
        // console.log(selectedProject);
        // console.log(dxfFile);
        const formData = new FormData();
        formData.append('selectedProjection', selectedProjection);
        formData.append('dxfFile', dxfFile, dxfFile.name);

        try {
            console.log(formData);
            const response = await PolygonService.addPolygonToProject(
                // selectedProject.value,
                projectId,
                formData
            );

            setSnackbarMessage(response.data.message);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage(
                error.response?.data?.error || 'Une erreur s\'est produite.'
            );
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            {/* <DashboardNavbar /> */}
            {/* {currentUser && currentUser.roles.includes('ROLE_ADMIN') ? ( */}
                <Container maxWidth="sm">
                    <Typography variant="h4" textAlign={'center'} gutterBottom>
                        Ajouter un polygon
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <Autocomplete
                                    options={projectOptions}
                                    value={selectedProject}
                                    onChange={(event, newValue) => setSelectedProject(newValue)}
                                    getOptionLabel={(option) => option.label}
                                    isClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Projet"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                {/* <InputLabel id="projection-label">Zone de projection</InputLabel> */}
                                <TextField
                                    select
                                    label="Zone de projection"
                                    // id="projection"
                                    value={selectedProjection}
                                    onChange={(event) => setSelectedProjection(event.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    <MenuItem value="26191">Zone 1</MenuItem>
                                    <MenuItem value="26192">Zone 2</MenuItem>
                                    <MenuItem value="26194">Zone 3</MenuItem>
                                    <MenuItem value="26195">Zone 4</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor="dxfFile">Fichier DXF</label>
                                <input
                                    type="file"
                                    id="dxfFile"
                                    name="dxfFile"
                                    accept=".dxf"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={dxfFile ? dxfFile.name : ''}
                                    disabled
                                />
                                <label htmlFor="dxfFile">
                                    <MDButton
                                        component="span"
                                        variant="contained"
                                        color="info"
                                        fullWidth
                                    >
                                        Télécharger le fichier DXF
                                    </MDButton>
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                <MDButton
                                    type="submit"
                                    variant="contained"
                                    color="info"
                                    fullWidth
                                >
                                    Ajouter
                                </MDButton>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant='filled' severity={snackbarSeverity} onClose={handleCloseSnackbar}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            {/* ) : (
                <>
                    <AccessDenied />
                </>
            )} */}
        </>
    );
};

export default AddPolygon;
