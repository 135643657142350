import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProjects } from '../../actions/ProjetActions';
import { createProjectPolygon } from '../../actions/PolygonActions';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import 'leaflet-draw';
import { MapContainer, TileLayer, useMapEvents, Polygon } from 'react-leaflet';
import { Autocomplete, TextField, Snackbar, Button } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const ProjectsMap = () => {
  const projects = useSelector((state) => state.project.projects);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllProjects());
  }, [dispatch]);

  const [selectedProject, setSelectedProject] = useState(null);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSelectProject = (event, newValue) => {
    setSelectedProject(newValue);
  };

  const handleMapClick = () => { };

  const handlePolygonSubmit = () => {
    console.log('selectedProject', selectedProject);
    console.log('polygonCoordinates', polygonCoordinates);
    if (selectedProject && polygonCoordinates.length > 2) {
      const polygonData = {
        projectId: selectedProject.value,
        polygonName: 'Polygon Name',
        area: calculatePolygonArea(),
        geometry: {
          type: 'Polygon',
          coordinates: [polygonCoordinates],
        },
        note: 'Polygon Note',
      };
  
      dispatch(createProjectPolygon(selectedProject.value, polygonData))
        .then(() => {
          setSnackbarSeverity('success');
          setSnackbarMessage('Polygon submitted successfully');
          setSnackbarOpen(true);
        })
        .catch((error) => {
          setSnackbarSeverity('error');
          setSnackbarMessage(`Error: ${error.message}`);
          setSnackbarOpen(true);
        })
        .finally(() => {
          setSelectedProject(null);
          setPolygonCoordinates([]);
        });
    } else {
      console.log('Error: Invalid selectedProject or polygonCoordinates');
    }
  };
  

  const calculatePolygonArea = () => {
    return polygonCoordinates.length;
  };

  const DrawPolygon = () => {
    const map = useMapEvents({
      click: handleMapClick,
    });

    useEffect(() => {
      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw({
        draw: {
          polygon: true,
          polyline: false,
          circle: false,
          marker: false,
          circlemarker: false,
        },
        edit: {
          featureGroup: drawnItems,
          edit: false,
          remove: false,
        },
      });

      map.addControl(drawControl);

      map.on(L.Draw.Event.CREATED, (event) => {
        const { layer } = event;
        drawnItems.addLayer(layer);
        setPolygonCoordinates(layer.getLatLngs()[0].map(({ lat, lng }) => [lat, lng]));
      });

      return () => {
        map.removeControl(drawControl);
        map.off(L.Draw.Event.CREATED);
      };
    }, [map]);

    return null;
  };

  const mapLayers = {
    google: {
      url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      maxZoom: 21,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '<a href="https://www.google.com/maps">Google Maps</a>',
    },
  };

  const projectOptions = projects.map((projet) => ({
    value: projet.id,
    label: projet.numero,
  }));

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Autocomplete
          options={projectOptions}
          onChange={handleSelectProject}
          value={selectedProject} 
          getOptionLabel={(option) => option.label}
          isClearable
          renderInput={(params) => (
            <TextField {...params} label="Projet" variant="outlined" fullWidth margin="normal" />
          )}
        />

        <MapContainer center={[0, 0]} zoom={2} style={{ height: '500px' }} className="leaflet-container-draw">
          <TileLayer
            url={mapLayers.google.url}
            maxZoom={mapLayers.google.maxZoom}
            attribution={mapLayers.google.attribution}
            subdomains={mapLayers.google.subdomains}
          />
          <DrawPolygon />
        </MapContainer>
        <Button onClick={handlePolygonSubmit}>Submit Polygon</Button>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </DashboardLayout>
  );
};

export default ProjectsMap;
