import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Card,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import {
    Add,
    ListAlt,
} from '@mui/icons-material';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import MDButton from '../../components/MDButton';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import PvdeMssion from '../taches/PvMission';
import MissionDorder from '../taches/MissionDorder';
import RepportsList from '../repports/RepportsFiles';
import ProductsList from '../products/ProductsList';

const Bureau = ({ darkMode, currentUser }) => {
    const [pvDialogOpen, setPvDialogOpen] = useState(false);
    const [ordermissionDialogOpen, setOrderMissionDialogOpen] = useState(false);
    const [contactsDialogOpen, setContactsDialogOpen] = useState(false);
    const [addDialogOpen, setaddDialogOpen] = useState(false);
    const [tachesDialogOpen, setTachesDialogOpen] = useState(false);

    const handleOpenDialog = (dialogName) => {
        switch (dialogName) {
            case 'pvmission':
                setPvDialogOpen(true);
                break;
            case 'ordermission':
                setOrderMissionDialogOpen(true);
                break;
            case 'contacts':
                setContactsDialogOpen(true);
                break;
            case 'add':
                setaddDialogOpen(true);
                break;
            case 'repports':
                setTachesDialogOpen(true);
                break;
            default:
                break;
        }
    };

    const handleCloseDialog = (dialogName) => {
        switch (dialogName) {
            case 'pvmission':
                setPvDialogOpen(false);
                break;
            case 'ordermission':
                setOrderMissionDialogOpen(false);
                break;
            case 'contacts':
                setContactsDialogOpen(false);
                break;
            case 'add':
                setaddDialogOpen(false);
                break;
            case 'repports':
                setTachesDialogOpen(false);
                break;
            default:
                break;
        }
    };

    const renderDialog = (dialogName) => {
        switch (dialogName) {
            case 'pvmission':
                return (
                    <Dialog open={pvDialogOpen}
                        maxWidth="md"
                        fullWidth
                        onClose={() => handleCloseDialog('pvmission')}>
                        <DialogTitle textAlign={'center'}>PV de Mission</DialogTitle>
                        <DialogContent>
                            <PvdeMssion />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog('pvmission')} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            case 'ordermission':
                return (
                    <Dialog
                        maxWidth="md"
                        fullWidth
                        open={ordermissionDialogOpen}
                        onClose={() => handleCloseDialog('ordermission')}>
                        <DialogTitle textAlign={'center'}>Order de Mission</DialogTitle>
                        <DialogContent>
                            <MissionDorder />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog('ordermission')} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            case 'contacts':
                return (
                    <Dialog open={contactsDialogOpen} onClose={() => handleCloseDialog('contacts')}>
                        <DialogTitle>Contacts</DialogTitle>
                        <DialogContent>
                            <List>
                                <ListItem button component={Link} to="/contacts">
                                    <ListItemIcon>
                                        <ListAlt />
                                    </ListItemIcon>
                                    <ListItemText primary="Liste des Contacts" />
                                </ListItem>
                                <ListItem button component={Link} to="/contacts/create">
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    <ListItemText primary="Créer un Contact" />
                                </ListItem>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog('contacts')} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                );

            case 'add':
                return (
                    <Dialog
                        open={addDialogOpen}
                        onClose={() => handleCloseDialog('add')}
                        maxWidth="100%"
                        fullWidth
                    >
                        <DialogTitle
                            sx={{
                                fontSize: '36px',
                                textAlign: 'center',
                                marginTop: '12px',
                                fontWeight: 'bold',
                                color: '#3e4396',
                                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                            }}
                        >Gestion de stock</DialogTitle>
                        <DialogContent>
                            <ProductsList currentUser={currentUser} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog('add')} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            case 'repports':
                return (
                    <Dialog
                        open={tachesDialogOpen}
                        onClose={() => handleCloseDialog('repports')}
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogTitle textAlign={'center'}>Rapports</DialogTitle>
                        <DialogContent>
                            <RepportsList />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog('repports')} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            default:
                return null;
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDTypography mt={2} className='text-center' variant="h2">Bureau d'ordre</MDTypography>
            <MDBox mt={3} mb={3}>
                <Grid item xs={12} lg={8}>
                    <Card>
                        <MDBox p={2} lineHeight={0}>
                        </MDBox>
                        <MDBox p={2}>
                            <Grid container spacing={3}>
                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                                    || (currentUser.permissions.includes("Exporter PV de mission"))) && (
                                        <Grid item xs={12} sm={6} >
                                            <MDButton
                                                variant="gradient"
                                                color="success"
                                                fullWidth
                                                onClick={() => handleOpenDialog('pvmission')}
                                            >
                                                PV de Mission
                                            </MDButton>
                                        </Grid>
                                    )}
                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                                    || (currentUser.permissions.includes("Exporter ordre de mission"))) && (
                                        <Grid item xs={12} sm={6} >
                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={() => handleOpenDialog('ordermission')}
                                            >
                                                Ordre de Mission
                                            </MDButton>
                                        </Grid>
                                    )}
                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                                    || (currentUser.permissions.includes("Générer et envoyer le rapport"))) && (
                                        <Grid item xs={12} sm={6} >
                                            <MDButton
                                                variant="gradient"
                                                color="secondary"
                                                fullWidth
                                                onClick={() => handleOpenDialog('repports')}
                                            >
                                                Rapport d'activité
                                            </MDButton>
                                        </Grid>
                                    )}
                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                                    || (currentUser.permissions.includes("Gérer le stock"))) && (
                                        <Grid item xs={12} sm={6} >
                                            <MDButton
                                                variant="gradient"
                                                color="warning"
                                                fullWidth
                                                onClick={() => handleOpenDialog('add')}
                                            >
                                                gestion de stock
                                            </MDButton>
                                        </Grid>
                                    )}
                            </Grid>
                        </MDBox>
                    </Card>
                </Grid>
            </MDBox>
            {renderDialog('pvmission')}
            {renderDialog('ordermission')}
            {/* {renderDialog('contacts')} */}
            {renderDialog('add')}
            {renderDialog('repports')}
        </DashboardLayout>
    );
};

export default Bureau;
