import FactureService from '../services/factureServices';
import {
  CREATE_FACTURE_SUCCESS,
  CREATE_FACTURE_FAILURE,
  UPDATE_FACTURE_SUCCESS,
  UPDATE_FACTURE_FAILURE,
  GET_FACTURE_SUCCESS,
  GET_FACTURE_FAILURE,
  DELETE_FACTURE_SUCCESS,
  DELETE_FACTURE_FAILURE,
  GET_ALL_FACTURES_SUCCESS,
  GET_ALL_FACTURES_FAILURE,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
  GET_FILES_SUCCESS,
  GET_FILES_FAILURE,
  GET_CLIENT_FACTURES_SUCCESS,
  GET_CLIENT_FACTURES_FAILURE,
} from './types';

export const uploadFactureFiles = (factureId, formData) => {
  return async (dispatch) => {
    try {
      const response = await FactureService.uploadFilesToFacture(factureId, formData);
      dispatch({
        type: UPLOAD_FILES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: UPLOAD_FILES_FAILURE,
        payload: error.response ? error.response.data : 'An error occurred',
      });
      throw error;
    }
  };
};

export const fetchFactureFiles = (factureId) => {
  return (dispatch) => {
    return FactureService.getAllFactureFiles(factureId)
      .then((response) => {
        dispatch({
          type: GET_FILES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FILES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchFileContent = (factureId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await FactureService.getFileContent(factureId, fileName);
      return response;
    } catch (error) {
      console.error('Error fetching file content:', error);
      throw error;
    }
  };
};

export const downloadFile = (factureId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await FactureService.downloadFile(factureId, fileName);

      if (response.data instanceof Blob) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } else {
        console.error('Invalid file content in the response.');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
};

export const deleteFile = (factureId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await FactureService.deleteFile(factureId, fileName);
      return response;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  };
};

export const createFacture = (factureData) => {
  return (dispatch) => {
    return FactureService.createFacture(factureData)
      .then((response) => {
        dispatch({
          type: CREATE_FACTURE_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: CREATE_FACTURE_FAILURE,
          payload: error.message,
        });
        return Promise.reject(error);
      });
  };
};

export const getAllFactures = () => {
  return (dispatch) => {
    return FactureService.getAllFactures()
      .then((response) => {
        dispatch({
          type: GET_ALL_FACTURES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_FACTURES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateFacture = (factureId, updatedFacture) => {
  return (dispatch) => {
    return FactureService.updateFacture(factureId, updatedFacture)
      .then((response) => {
        dispatch({
          type: UPDATE_FACTURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_FACTURE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getFacture = (factureId) => {
  return (dispatch) => {
    return FactureService.fetchFacture(factureId)
      .then((response) => {
        dispatch({
          type: GET_FACTURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FACTURE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteFacture = (factureId) => {
  return (dispatch) => {
    return FactureService.deleteFacture(factureId)
      .then((response) => {
        dispatch({
          type: DELETE_FACTURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_FACTURE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchClientFactures = (clientId) => {
  return (dispatch) => {
    return FactureService.fetchClientFactures(clientId)
      .then((response) => {
        dispatch({
          type: GET_CLIENT_FACTURES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CLIENT_FACTURES_FAILURE,
          payload: error.message,
        });
      });
  };
};