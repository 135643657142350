import ConstantService from '../services/constantServices';
import {
  CREATE_CONSTANT_SUCCESS,
  CREATE_CONSTANT_FAILURE,
  UPDATE_CONSTANT_SUCCESS,
  UPDATE_CONSTANT_FAILURE,
  GET_CONSTANT_SUCCESS,
  GET_CONSTANT_FAILURE,
  DELETE_CONSTANT_SUCCESS,
  DELETE_CONSTANT_FAILURE,
  GET_ALL_CONSTANTS_SUCCESS,
  GET_ALL_CONSTANTS_FAILURE,
  GET_USER_CONSTANT_SUCCESS,
  GET_USER_CONSTANT_FAILURE,
} from './types';

export const createConstant = (constantData) => {
  return (dispatch) => {
    return ConstantService.createConstant(constantData)
      .then((response) => {
        dispatch({
          type: CREATE_CONSTANT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_CONSTANT_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllConstants = () => {
  return (dispatch) => {
    return ConstantService.getAllConstants()
      .then((response) => {
        dispatch({
          type: GET_ALL_CONSTANTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_CONSTANTS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getUserConstant = (userId) => {
  return (dispatch) => {
    return ConstantService.fetchUserConstant(userId)
      .then((response) => {
        dispatch({
          type: GET_USER_CONSTANT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_CONSTANT_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateConstant = (constantId, updatedConstant) => {
  return (dispatch) => {
    return ConstantService.updateConstant(constantId, updatedConstant)
      .then((response) => {
        dispatch({
          type: UPDATE_CONSTANT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CONSTANT_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getConstant = (constantId) => {
  return (dispatch) => {
    return ConstantService.fetchConstant(constantId)
      .then((response) => {
        dispatch({
          type: GET_CONSTANT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CONSTANT_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteConstant = (constantId) => {
  return (dispatch) => {
    return ConstantService.deleteConstant(constantId)
      .then((response) => {
        dispatch({
          type: DELETE_CONSTANT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CONSTANT_FAILURE,
          payload: error.message,
        });
      });
  };
};
