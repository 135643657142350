import {
    GET_ALL_USERS,
    UPDATE_USER,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    ONE_USER,
    GET_ALL_ROLES_SUCCESS,
    GET_ALL_ROLES_FAILURE,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAILURE,
    UPDATE_USER_PERMISSION_SUCCESS,
    UPDATE_USER_PERMISSION_FAILURE,
    UPLOAD_USER_FILES_SUCCESS,
    UPLOAD_USER_FILES_FAILURE,
    GET_USER_FILES_SUCCESS,
    GET_USER_FILES_FAILURE,
} from "../actions/types";

const initialState = {
    users: [],
    user: null,
    roles: [],
    files: [],
    message: null,
    loading: false,
    success: false,
    error: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_USERS:
            return {
                ...state,
                users: payload,
            };
        case UPDATE_USER:
            // Find the index of the user being updated
            const index = state.users.findIndex((user) => user.id === payload.id);

            if (index !== -1) {
                // Update the user in the users array
                const updatedUsers = [...state.users];
                updatedUsers[index] = payload.updatedUser;

                return {
                    ...state,
                    users: updatedUsers,
                };
            }
            return state;
        case ONE_USER:
            return {
                ...state,
                user: payload,
            };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null,
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case GET_ALL_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                error: null,
            };
        case GET_ALL_ROLES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                success: true,
                error: null
            };
        case UPDATE_USER_PASSWORD_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload
            };
        case UPDATE_USER_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case UPDATE_USER_PERMISSION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_USER_FILES_SUCCESS:
            return {
                ...state,
                files: action.payload,
                error: null,
            };
        case GET_USER_FILES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPLOAD_USER_FILES_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null
            };
        case UPLOAD_USER_FILES_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload
            };
        default:
            return state;
    }
}
