import {
    CREATE_REQUEST_SUCCESS,
    CREATE_REQUEST_FAILURE,
    UPDATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_FAILURE,
    GET_REQUEST_SUCCESS,
    GET_REQUEST_FAILURE,
    GET_PENDING_REQUESTS_SUCCESS,
    GET_PENDING_REQUESTS_FAILURE,
    DELETE_REQUEST_SUCCESS,
    DELETE_REQUEST_FAILURE,
    GET_ALL_REQUESTS_SUCCESS,
    GET_ALL_REQUESTS_FAILURE,
    GET_ALL_USER_REQUESTS_SUCCESS,
    GET_ALL_USER_REQUESTS_FAILURE,
} from '../actions/types';

const initialState = {
    request: null,
    requests: [],
    error: null,
};

const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_REQUEST_SUCCESS:
            return {
                ...state,
                request: action.payload,
                error: null,
            };
        case CREATE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                request: action.payload,
                error: null,
            };
        case UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_REQUEST_SUCCESS:
            return {
                ...state,
                request: action.payload,
                error: null,
            };
        case GET_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                request: null,
                error: null,
            };
        case DELETE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_REQUESTS_SUCCESS:
            return {
                ...state,
                requests: action.payload,
                error: null,
            };
        case GET_ALL_REQUESTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PENDING_REQUESTS_SUCCESS:
            return {
                ...state,
                requests: action.payload,
                error: null,
            };
        case GET_PENDING_REQUESTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_USER_REQUESTS_SUCCESS:
            return {
                ...state,
                requests: action.payload,
                error: null,
            };
        case GET_ALL_USER_REQUESTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default requestReducer;