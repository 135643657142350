import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, Polyline as LeafletPolyline, Polygon as LeafletPolygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';

const ProjectEmp = ({ polylines, polygons }) => {
    const mapRef = useRef(null);
    const [initialCenter, setInitialCenter] = useState([30.39341198110206, -9.565342191279383]);
    const [initialZoom, setInitialZoom] = useState(10);
    const [convertedPolylines, setConvertedPolylines] = useState([]);
    const [convertedPolygonCoordinates, setConvertedPolygonCoordinates] = useState([]);
    const [center, setCenter] = useState([30.39341198110206, -9.565342191279383]);

    const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';
    useEffect(() => {
        if (polylines?.length === 0) return;

        const converted = polylines?.map((polyline) => {
            const zone = polyline.zone;
            const sourceCRS = getSourceCRS(zone);

            proj4.defs('EPSG:3857', targetCRS);
            proj4.defs(`EPSG:${zone}`, sourceCRS);
            return polyline?.geometry?.coordinates?.map((coord) => {
                const [x, y] = proj4(sourceCRS, targetCRS, coord);
                return [y, x];
            });
        });

        setConvertedPolylines(converted);
        setInitialCenter(converted[0]);
        setInitialZoom(18);

    }, [polylines]);

    useEffect(() => {
        if (polygons?.length === 0) return;

        const convert = polygons?.map((polygon) => {
            const zone = polygon.zone;
            const sourceCRS = getSourceCRS(zone);
            proj4.defs('EPSG:3857', targetCRS);
            proj4.defs(`EPSG:${zone}`, sourceCRS);

            const flattenedCoords = polygon?.geometry?.coordinates?.flat();

            return flattenedCoords?.map((coord) => {
                const [x, y] = proj4(sourceCRS, targetCRS, coord);
                return [y, x];
            });
        });

        setConvertedPolygonCoordinates(convert);
        setInitialCenter(convert[0]);
        setInitialZoom(18);

    }, [polygons]);

    const getSourceCRS = (zone) => {
        switch (zone) {
            case '26191':
                return '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
            case '26192':
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
            case '26194':
                return '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.999616304 +x_0=1200000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
            case '26195':
                return '+proj=lcc +lat_1=22.5 +lat_0=22.5 +lon_0=-5.4 +k_0=0.999616437 +x_0=1500000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
            default:
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
    };

    useEffect(() => {
        if (convertedPolylines?.length > 0) {
            setCenter(convertedPolylines[0][0]);
        } else if (convertedPolygonCoordinates?.length > 0) {
            setCenter(convertedPolygonCoordinates[0][0]);
        }
    }, [convertedPolylines, convertedPolygonCoordinates]);

    useEffect(() => {
        if (center && mapRef.current) {
            mapRef.current.setView(center, 19);
        }
    }, [center]);
    
    console.log('center',center);

    const Map = () => {
        const map = useMap();
        mapRef.current = map;

        return null;
    };
    return (
        <>
            {((convertedPolylines?.length || 0) + (convertedPolygonCoordinates?.length || 0)) > 0 && (
                <MapContainer
                    center={center}
                    zoom={19}
                    style={{ height: '80vh', width: '100%' }}
                    whenCreated={(mapInstance) => {
                        mapRef.current = mapInstance;
                    }}
                >
                    <Map />
                    <TileLayer
                        url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                        maxZoom={21}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                    />
                    <LeafletPolyline
                        positions={convertedPolylines}
                        pathOptions={{ color: 'red' }}
                    />
                    <LeafletPolygon
                        positions={convertedPolygonCoordinates}
                        pathOptions={{ color: 'red' }}
                    />
                </MapContainer>
            )}
        </>
    );
    
};

export default ProjectEmp;