import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactProjects } from '../../actions/ProjetActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    TextField,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import PageLayout from '../../examples/LayoutContainers/PageLayout';
import PageNavbar from '../../examples/Navbars/DashboardNavbar/PageNavbar';
import MDButton from '../../components/MDButton';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ProjectPreview from './ProjectPreview';
import ProjectTasks from './ProjectTasks';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(() => ({
    '& .super-app-theme--preparation': {
        backgroundColor: 'rgb(249, 210, 179)',
        '&:hover': {
            backgroundColor: 'rgb(246, 181, 128)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(246, 181, 128)',
            '&:hover': {
                backgroundColor: 'rgb(246, 181, 128)',
            },
        },
    },
    '& .super-app-theme--terrain': {
        backgroundColor: 'rgb(249, 210, 179)',
        '&:hover': {
            backgroundColor: 'rgb(246, 181, 128)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(246, 181, 128)',
            '&:hover': {
                backgroundColor: 'rgb(246, 181, 128)',
            },
        },
    },
    '& .super-app-theme--bureau': {
        backgroundColor: 'rgb(249, 210, 179)',
        '&:hover': {
            backgroundColor: 'rgb(246, 181, 128)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(246, 181, 128)',
            '&:hover': {
                backgroundColor: 'rgb(246, 181, 128)',
            },
        },
    },
    '& .super-app-theme--controle': {
        backgroundColor: 'rgb(249, 210, 179)',
        '&:hover': {
            backgroundColor: 'rgb(246, 181, 128)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(246, 181, 128)',
            '&:hover': {
                backgroundColor: 'rgb(246, 181, 128)',
            },
        },
    },
    '& .super-app-theme--livraison': {
        backgroundColor: 'rgb(249, 210, 179)',
        '&:hover': {
            backgroundColor: 'rgb(246, 181, 128)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(246, 181, 128)',
            '&:hover': {
                backgroundColor: 'rgb(246, 181, 128)',
            },
        },
    },
    '& .super-app-theme--cloture': {
        backgroundColor: '#DBE4F0',
        '&:hover': {
            backgroundColor: '#B0C4DE',
        },
        '&.Mui-selected': {
            backgroundColor: '#B0C4DE',
            '&:hover': {
                backgroundColor: '#B0C4DE',
            },
        },
    },
}));

const ContactProjects = ({ currentContact, darkMode }) => {
    const [pageSize, setPageSize] = useState(25);
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.project.contactProjects);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isTasksDialogOpen, setIsTasksDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchContactProjects(currentContact?.id));
    }, [dispatch, currentContact?.id]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredProjects = projects
            .filter((project) => {
                const reference = project.reference_fonciere || '';
                const numero = project.numero || '';
                const client = project.client?.full_name || '';
    
                return (
                    reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    client.toLowerCase().includes(searchTerm.toLowerCase())
                );
            })
            .sort((a, b) => new Date(b.date_creation) - new Date(a.date_creation)); 
    
        setFilteredProjects(filteredProjects);
    }, [projects, searchTerm]);
    
    // useEffect(() => {
    //     const filteredProjects = projects.filter((project) => {
    //         const reference = project.reference_fonciere || '';
    //         const numero = project.numero || '';
    //         const client = project.client?.full_name || '';

    //         return (
    //             reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             client.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //     });

    //     setFilteredProjects(filteredProjects);
    // }, [projects, searchTerm]);

    const openDialog = (project) => {
        setSelectedProject(project);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedProject(null);
        setIsDialogOpen(false);
    };

    const openTasksDialog = (project) => {
        setSelectedProject(project);
        setIsTasksDialogOpen(true);
    };

    const closeTasksDialog = () => {
        setSelectedProject(null);
        setIsTasksDialogOpen(false);
    };

    const columns = [
        {
            field: 'client?.full_name', headerName: 'Client',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.client?.full_name || '',
        },
        {
            field: 'reference_fonciere', headerName: 'Reference',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'paiement', headerName: 'Paiement',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        // {
        //     field: 'statut', headerName: 'Statut',
        //     ...(isMobile ? { width: 120 } : { flex: 1 }),
        // },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <MDButton
                        className='me-1 ms-1'
                        size='small'
                        color='warning'
                        onClick={() => openDialog(params.row)}
                    >
                        <OpenInFullIcon />
                    </MDButton>
                    <MDButton
                        component={Link}
                        className='me-1 ms-1'
                        size='small'
                        color='success'
                        onClick={() => openTasksDialog(params.row)}
                    >
                        <VisibilityIcon />
                    </MDButton>

                </>
            ),
            ...(isMobile ? { width: 180 } : { flex: 1 }),
            cellClassName: 'actions-cell',
        },
    ];
    return (
        <PageLayout>
            <PageNavbar />
            <Typography
                sx={{
                    fontSize: '36px',
                    textAlign: 'center',
                    marginTop: '12px',
                    fontWeight: 'bold',
                    color: '#3e4396',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                }}
                variant="h2"
                gutterBottom
            >
                Projets
            </Typography>
            <Box
                sx={{
                    padding: '1rem',
                }}
            >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Search Project"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <Box
                    m="0 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                    }}
                >
                    <StyledDataGrid
                        rows={filteredProjects}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        style={{ color: darkMode ? "#fff" : "#000" }}
                        pageSize={pageSize}
                        getRowClassName={(params) => `super-app-theme--${params.row.statut}`}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                    />
                </Box>
            </Box>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle textAlign={'center'}>Détails de projet</DialogTitle>
                <DialogContent>
                    {selectedProject && (
                        <ProjectPreview project={selectedProject} darkMode={darkMode} />

                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isTasksDialogOpen}
                onClose={closeTasksDialog}
                fullWidth
                maxWidth="100%"

            >
                <>
                    <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                        Table de processus
                    </DialogTitle>
                    <DialogContent>
                        <ProjectTasks projectId={selectedProject?.id} darkMode={darkMode} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeTasksDialog} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
        </PageLayout >
    );
};

export default ContactProjects;

