import  API_URL from './apiUrl';

class PermissionService {
  createPermission(permissionData) {
    return API_URL.post("/permission/create/", permissionData);
  }

  getAllPermissions() {
    return API_URL.get("/permission");
  }

  updatePermission(permissionId, updatedPermission) {
    return API_URL.put(`/permission/update/${permissionId}`, updatedPermission);
  }

  fetchPermission(permissionId) {
    return API_URL.get(`/permission/${permissionId}`);
  }

  deletePermission(permissionId) {
    return API_URL.delete(`/permission/delete/${permissionId}`);
  }
  
}

export default new PermissionService();
