import React, { useEffect, useState } from "react";
import PolylineServices from '../../services/polylineServices';
import PolygonServices from "../../services/polygonServices";
import {
    Typography,
    Card,
    Grid,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from "@mui/material";
import MDButton from "../../components/MDButton";
import ShowPolylines from "./ShowPolylines";
import AddPolyline from "./AjouterPolyline";
import AddPolygon from "./AddPolygon";
import ProjectEmp from "./ProjectEmp";

const PolylineIndex = ({ projectId, currentUser }) => {
    const [polylines, setPolylines] = useState([]);
    const [polygons, setPolygons] = useState([]);
    const [isPolylineProjectOpen, setIsPolylineProjectOpen] = useState(false);
    const [isPolygonProjectOpen, setIsPolygonProjectOpen] = useState(false);

    useEffect(() => {
        fetchPolylines();
        fetchPolygons();
    }, [projectId]);


    const fetchPolylines = async () => {
        try {
            const response = await PolylineServices.getPolylinesByProjectId(projectId);
            setPolylines(response.data.polylines);
        } catch (error) {
            console.error('Error fetching polylines:', error);
        }
    };
    const fetchPolygons = async () => {
        try {
            const response = await PolygonServices.getPolygonsByProjectId(projectId);
            setPolygons(response.data.polygons);
        } catch (error) {
            console.error('Error fetching polygons:', error);
        }
    };
    

    return (
        <>
            {(polylines?.length + polygons?.length) > 0 ? (
                <>
                    {/* <ShowPolylines polylines={polylines} /> */}
                    <ProjectEmp polygons={polygons} polylines={polylines} />
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Card>
                            <Box p={2} lineHeight={0}>
                                <Typography variant="body1">
                                    Aucun emplacement trouvé, veuillez importer votre emplacement du projet
                                </Typography>
                            </Box>
                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Importer les fichiers")) &&
                                <Grid container spacing={2} mb={2} mt={2} p={2}>
                                    <Grid item xs={12} sm={6}>
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            fullWidth
                                            onClick={() => setIsPolylineProjectOpen(true)}
                                        >
                                            Importer Polyline
                                        </MDButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MDButton
                                            variant="gradient"
                                            color="warning"
                                            fullWidth
                                            onClick={() => setIsPolygonProjectOpen(true)}
                                        >
                                            Importer Polygon
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            }
                        </Card>
                    </Grid >
                </>
            )}
            <Dialog
                open={isPolylineProjectOpen}
                onClose={() => setIsPolylineProjectOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Importer DXF
                </DialogTitle>
                <DialogContent>
                    <AddPolyline projectId={projectId} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPolylineProjectOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPolygonProjectOpen}
                onClose={() => setIsPolygonProjectOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Importer DXF
                </DialogTitle>
                <DialogContent>
                    <AddPolygon projectId={projectId} currentUser={currentUser} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPolygonProjectOpen(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PolylineIndex;