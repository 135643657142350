import  API_URL  from './apiUrl';

class SalaireService {
  createSalaire(salaireData) {
    return API_URL.post("/salaires/create/", salaireData);
  }

  getAllSalaires() {
    return API_URL.get("/salaires");
  }

  updateSalaire(salaireId, updatedSalaire) {
    return API_URL.put(`/salaires/update/${salaireId}`, updatedSalaire);
  }

  fetchSalaire(salaireId) {
    return API_URL.get(`/salaires/${salaireId}`);
  }

  fetchUserSalaires(userId) {
    return API_URL.get(`/salaires/salaires/user/${userId}`);
  }
  
  deleteSalaire(salaireId) {
    return API_URL.delete(`/salaires/delete/${salaireId}`);
  }
}

export default new SalaireService();
