import {
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE,
    GET_FILES_SUCCESS,
    GET_FILES_FAILURE,
} from '../actions/types';

const initialState = {
    file: null,
    files: [],
    error: null,
};

const fileReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                file: action.payload,
                error: null,
            };
        case UPLOAD_FILES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_FILES_SUCCESS:
            return {
                ...state,
                files: action.payload,
                error: null,
            };
        case GET_FILES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default fileReducer;
