import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createCommercial } from '../../actions/ContactActions';
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';
import MDButton from '../../components/MDButton';

const CreateContact = ({ currentUser, createCommercial, success }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = (e) => {
    e.preventDefault();

    const commercialData = {
      name,
      phone,
      email,
    };

    createCommercial(commercialData)
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('Contact créé avec succès !');
        setSnackbarOpen(true);
        setName('');
        setPhone('');
        setEmail('');
      })
      .catch((error) => {
        setSnackbarSeverity('error');
        setSnackbarMessage('Erreur lors de la création du contact. Veuillez réessayer.');
        setSnackbarOpen(true);
        console.error(error);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des contacts")) ? (
        <Container maxWidth="md">
          <Typography variant="h2" align="center" gutterBottom>
            Créer un contact
          </Typography>
          <form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
            <TextField
              label="Nom"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Téléphone"
              variant="outlined"
              fullWidth
              margin="normal"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <MDButton type="submit" variant="contained" color="warning" sx={{ mt: 2 }}>
              Sauvegarder
            </MDButton>
          </form>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps, { createCommercial })(CreateContact);
