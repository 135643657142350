import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { minusQuantity, fetchAllProducts } from '../../actions/ProductsActions';
import {
    TextField,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';

const MinusQuantity = ({ productId }) => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(0);
    const currentDate = new Date();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSubmit = (e) => {
        e.preventDefault();

        const Data = {
            quantity: parseFloat(quantity),
            lastMin: currentDate,
            productId,
        };

        dispatch(minusQuantity(productId, Data))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Produit inséré avec succès !');
                setSnackbarOpen(true);
                dispatch(fetchAllProducts());
                setQuantity(0);
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la création. Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <form style={{ marginTop: '1rem' }}>
                <TextField
                    label="Quantité"
                    variant="outlined"
                    type='number'
                    fullWidth
                    margin="normal"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        color: '#fff !important'
                    }}
                    onClick={handleSubmit}
                >
                    Sauvegarder
                </Button>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};


export default MinusQuantity;
