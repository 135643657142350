import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserTaches, deleteTache, updateTache } from '../../actions/TacheActions';
import {
    Box,
    Typography,
    Snackbar,
    Alert,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Tooltip,
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import { VisibilityOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { encodeId } from '../../actions/Crypte';
import { Unarchive, Archive } from '@mui/icons-material';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import UnarchiveIcon from '@mui/icons-material/Unarchive';

const UserTasks = ({ currentUser, darkMode }) => {
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tache.userTaches) || [];
    const [loading, setLoading] = useState(true);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const [pageSize, setPageSize] = useState(25);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [backDialogOpen, setBackDialogOpen] = useState(false);
    // const [tacheToBack, setTacheToBack] = useState(null);

    useEffect(() => {
        dispatch(getUserTaches(currentUser?.id))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching pending tasks:', error);
                setLoading(false);
            });
    }, [dispatch, currentUser?.id]);

    useEffect(() => {
        if (Array.isArray(tasks)) {
            const filteredItems = tasks?.filter((task) => {
                const numero = task?.projet?.numero || '';

                return (
                    numero.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            setFilteredItems(filteredItems);
        }
    }, [tasks, searchTerm]);

    const columns = [
        {
            field: 'projet?.numero', headerName: 'Project', flex: 1,
            valueGetter: (params) => params.row.projet?.numero || '',
        },
        {
            field: 'projet?.reference_fonciere',
            headerName: 'Reference',
            flex: 1,
            valueGetter: (params) => params.row.projet?.reference_fonciere || '',
        },
        {
            field: 'projet?.nature',
            headerName: 'Nature',
            flex: 1,
            valueGetter: (params) => params.row.projet?.nature?.map((item) => (item?.name)) || '',
        },
        { field: 'name', headerName: 'Task Name', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            valueGetter: (params) => params.row.status || '-',
            renderCell: (params) => {
                let statusLabel, statusColor;

                switch (params.row.status) {
                    case 'pending':
                        statusLabel = 'En cours';
                        statusColor = 'orange';
                        break;
                    case 'confirmed':
                        statusLabel = 'Validée';
                        statusColor = 'green';
                        break;
                    case 'rejected':
                        statusLabel = 'Rejetée';
                        statusColor = 'red';
                        break;
                    default:
                        statusLabel = "Dans la liste d'attente";
                        statusColor = 'black';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statusLabel}</span>
                );
            },
        },
        {
            field: 'deadline', headerName: 'Deadline', flex: 1,
            valueGetter: (params) => params.row.deadline || '____-__-__',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => {
                return (
                    <>
                        <IconButton
                            variant="contained"
                            // size='small'
                            color="info"
                            component={Link}
                            to={`/projects/project/${encodeId(params.row.projetId)}`}
                        >
                            <VisibilityOutlined />
                        </IconButton>
                        {params.row.status === null &&
                            <Tooltip title="Récupérez-le de la liste d'attente" arrow>
                                <IconButton
                                    variant="contained"
                                    size='large'
                                    color="success"
                                    onClick={() => openBackDialog(params.row.id)}
                                >
                                    <Unarchive />
                                </IconButton>
                            </Tooltip>
                        }
                        {params.row.status === 'pending' &&
                            <Tooltip title="Mettez-le sur la liste d'attente" arrow>
                                <IconButton
                                    variant="contained"
                                    size='large'
                                    color="error"
                                    onClick={() => openDialog(params.row.id)}
                                >
                                    <Archive />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                );
            },
            cellClassName: 'actions-cell',
        },

    ];

    const openDialog = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    }

    const closeDialog = () => {
        setSelectedItem(null);
        setDialogOpen(false);
    }

    const openBackDialog = (item) => {
        setSelectedItem(item);
        setBackDialogOpen(true);
    }

    const closeBackDialog = () => {
        setSelectedItem(null);
        setBackDialogOpen(false);
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleArchive = () => {
        const updatedTache = {
            deadline: null,
            status: null,
            // userId: tacheData.userId,
        };

        dispatch(updateTache(selectedItem, updatedTache))
            .then(() => {
                setOpenSnackbar(true);
                setSuccessMessage(' successfully.');
                setSnackbarType('success');
                closeDialog();
                dispatch(getUserTaches(currentUser?.id));
            })
            .catch((error) => {
                setOpenSnackbar(true);
                setSuccessMessage('Error updating task. Please try again.');
                setSnackbarType('error');
            });
    };

    const handleUnarchive = () => {
        const updatedTache = {
            deadline: new Date(),
            status: 'pending',
        };

        dispatch(updateTache(selectedItem, updatedTache))
            .then(() => {
                setOpenSnackbar(true);
                setSuccessMessage(' successfully.');
                setSnackbarType('success');
                closeBackDialog();
                dispatch(getUserTaches(currentUser?.id));
            })
            .catch((error) => {
                setOpenSnackbar(true);
                setSuccessMessage('Error updating task. Please try again.');
                setSnackbarType('error');
            });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box>
                <Typography variant="h2" mt={2} textAlign={'center'} gutterBottom>
                    Historique des tâches
                </Typography>
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Rechercher par numéro du projet ou par presonnel"
                    onChange={handleSearch}
                    value={searchTerm}
                    name="Search Project"
                    sx={{
                        gridColumn: "span 2",
                        marginBottom: '1rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        "& .MuiInputLabel-root": { paddingLeft: '1rem', paddingRight: '1rem' },
                    }}
                />
                <DataGrid
                    className={darkMode ? "text-light" : "text-dark"}
                    rows={filteredItems ?? []}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    components={{ Toolbar: GridToolbar }}
                    loading={loading}
                    autoHeight
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    variant='filled'
                    onClose={() => setOpenSnackbar(false)}
                    severity={snackbarType}
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        background: '#1A73E8',
                        color: '#fff',
                        fontSize: '1.7rem',
                        marginBottom: '1rem'
                    }}
                >Mettez-le sur la liste d'attente</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir mettre cette tâche sur la liste d'attente ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                        onClick={closeDialog}>Non</Button>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'green !important',
                        }}
                        onClick={handleArchive} color="error">
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={backDialogOpen}
                onClose={closeBackDialog}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        background: '#1A73E8',
                        color: '#fff',
                        fontSize: '1.7rem',
                        marginBottom: '1rem'
                    }}
                >Récupérez-le de la liste d'attente</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir récupérez cette tâche dans la liste d'attente ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                        onClick={closeBackDialog}>Non</Button>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'green !important',
                        }}
                        onClick={handleUnarchive} color="error">
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default UserTasks;
