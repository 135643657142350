import React from 'react';
import { useDispatch } from 'react-redux';
import MDButton from '../../components/MDButton';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Button, Typography } from '@mui/material';
const UserAttestation = ({ user }) => {

    const handleExportToWord = async (user) => {
        try {
            const response = await axios.get('/attestation_travail.docx', {
                responseType: 'arraybuffer',
            });

            const projectTemplate = response.data;
            const zip = new PizZip(projectTemplate);
            const doc = new Docxtemplater(zip);
            let day = new Date();

            const data = {
                nom: user.nom,
                prenom: user.prenom || "-",
                cin: user.cin || "-",
                recrutment: user.date_recrutement || "-",
                export: day.toLocaleDateString() || "-",
            };

            doc.setData(data);

            try {
                doc.render();
            } catch (error) {
                console.error('Error rendering the template:', error);
                return;
            }

            try {
                const blob = doc.getZip().generate({ type: 'blob' });
                saveAs(blob, `user_${user?.nom}.docx`);
            } catch (error) {
                console.error('Error generating the Word document:', error);
            }
        } catch (error) {
            console.error('Error fetching the template:', error);
        }
    };

    return (
        <>
            <Typography
                variant="contained"
                className="me-1 mb-4"
                // color="primary"
                component={Button}
                onClick={() => handleExportToWord(user)}
            >
                Exporter attestation de travail
            </Typography>

        </>
    );
};

export default UserAttestation;
