import React, { useEffect, useState } from 'react';
import { Typography, Container, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import ChargeService from '../../services/chargesServices';
import { ResponsiveBar } from '@nivo/bar';
import { CSVLink } from 'react-csv';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const formatDateForMonthDisplay = (dateString) => {
    if (!dateString) return '';
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}`;
};

const TotalChargesByMonthAndYear = () => {
    const [totalCharges, setTotalCharges] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [year, setYear] = useState('');

    useEffect(() => {
        fetchTotalCharges();
    }, []);

    const fetchTotalCharges = async () => {
        try {
            const response = await ChargeService.fetchTotaleCharges();
            setTotalCharges(response.data);
        } catch (error) {
            console.error('Error fetching total charges:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIdx = page * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    const currentData = totalCharges.slice(startIdx, endIdx);

    const chartData = currentData.map((charge) => ({
        month: formatDateForMonthDisplay(charge.mois),
        totalCost: parseFloat(charge.totalCost),
    }));

    const handleExportChargesByYear = () => {
        if (!year) {
            return null;
        }

        const filteredCharges = totalCharges.filter(charge =>
            formatDateForMonthDisplay(charge.mois).startsWith(year)
        );

        const csvData = filteredCharges.map(charge => ({
            Month: formatDateForMonthDisplay(charge.mois),
            TotalCost: charge.totalCost,
        }));

        const csvFields = [
            'Month',
            'TotalCost',
        ];

        const csvOptions = { delimiter: ';' };

        return (
            <CSVLink
                className='btn btn-info ms-3'
                data={csvData}
                headers={csvFields}
                separator={csvOptions.delimiter}
            >
                Export
            </CSVLink>
        );
    };

    const currentYear = new Date().getFullYear();
    const startYear = 2019;
    const yearOptions = [];
    for (let year = currentYear; year >= startYear; year--) {
        yearOptions.push(
            <MenuItem key={year} value={year}>
                {year}
            </MenuItem>
        );
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <Container maxWidth="md">
                    <Typography variant="h1" align="center" mb={5} gutterBottom>
                        Total Charges
                    </Typography>
                    {totalCharges.length === 0 ? (
                        <Typography variant="body1">No data available.</Typography>
                    ) : (
                        <>
                            <Box alignItems={'center'} style={{ height: 300 }}>
                                <ResponsiveBar
                                    data={chartData}
                                    keys={['totalCost']}
                                    indexBy="month"
                                    margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                                    padding={0.3}
                                    colors={{ scheme: 'category10' }}
                                    axisBottom={{
                                        tickRotation: -45,
                                    }}
                                    axisLeft={{
                                        tickValues: 5,
                                        tickPadding: 5,
                                        tickSize: 5,
                                        tickRotation: 0,
                                        legend: 'Total Cost',
                                    }}
                                    enableGridX
                                    enableGridY
                                    labelTextColor="inherit:darker(1.2)"
                                    tooltip={({ id, value, color }) => (
                                        <strong style={{ color }}>
                                            {id}: {value}
                                        </strong>
                                    )}
                                />
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Month</TableCell>
                                        <TableCell>Total Cost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentData.map((charge) => (
                                        <TableRow key={charge.mois}>
                                            <TableCell>{formatDateForMonthDisplay(charge.mois)}</TableCell>
                                            <TableCell>{charge.totalCost}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={totalCharges.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    <Typography variant="h5" mt={'4px'} mb={5} gutterBottom>
                        Export Charges
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                        <FormControl variant="outlined" sx={{ width: '100%', marginRight: 2 }}>
                            <InputLabel id="year-select-label">Select Year</InputLabel>
                            <Select
                                labelId="year-select-label"
                                id="year-select"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                label="Select Year"
                            >
                                <MenuItem value="">
                                    <em>Select Year</em>
                                </MenuItem>
                                {yearOptions}
                            </Select>
                        </FormControl>
                        {handleExportChargesByYear()}
                    </Box>
                </Container>
            </DashboardLayout>
        </>
    );
};

export default TotalChargesByMonthAndYear;
