import API_URL from './apiUrl';

class PiecesService {
  createPieces(piecesData) {
    return API_URL.post("/pieces/create", piecesData);
  }

  getAllPieces() {
    return API_URL.get("/pieces");
  }

  updatePieces(piecesId, updatedPieces) {
    return API_URL.put(`/pieces/update/${piecesId}`, updatedPieces);
  }

  fetchPieces(piecesId) {
    return API_URL.get(`/pieces/${piecesId}`);
  }
  deletePieces(piecesId) {
    return API_URL.delete(`/pieces/delete/${piecesId}`);
  }
}

export default new PiecesService();