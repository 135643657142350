import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../actions/UserActions";
import { getAllClients } from '../../actions/ClientActions';
import { fetchAllProjects } from '../../actions/ProjetActions';
import { getAllMaterials } from '../../actions/MaterialsActions';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
    Container,
    Typography,
    TextField,
    Autocomplete,
    Button,
    Grid,
    Box,
    Paper,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { getProjectsByClientId } from '../../actions/ProjetActions';

const PvdeMssion = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users);
    const materials = useSelector((state) => state.material.materials);
    const clients = useSelector((state) => state.clients);
    // const projects = useSelector((state) => state.project.projects);
    const clientProjects = useSelector((state) => state.project.projectsClient);
    const projects = clientProjects?.projects || [];
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedBourneur, setSelectedBourneur] = useState(null);
    const [selectedAssistant, setSelectedAssistant] = useState(null);
    const [lieuDeMission, setLieuDeMission] = useState("");
    const [selectedMaterials, setSelectedMaterials] = useState([]);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getAllMaterials());
        dispatch(getAllClients());
        // dispatch(fetchAllProjects());
    }, [dispatch]);

    useEffect(() => {
        if (selectedClient) {
            dispatch(getProjectsByClientId(selectedClient.id));
        }
    }, [dispatch, selectedClient]);

    const handleExportToWord = async () => {
        try {
            const response = await axios.get('/pv_template.docx', {
                responseType: 'arraybuffer',
            });

            const projectTemplate = response.data;
            const zip = new PizZip(projectTemplate);
            const doc = new Docxtemplater(zip);

            const data = {
                projectNumber: selectedProject?.numero || "-",
                date: selectedDate || "-",
                natures: selectedProject?.nature.map(nature => ({ name: nature.name })) || [],
                lieudesmission: lieuDeMission || "-",
                voiture: selectedBourneur?.vehicules.map(vehicule => ({ type: vehicule.type, matricule: vehicule.matricule })) || [],
                nomB: selectedBourneur?.nom || "-",
                CinB: selectedBourneur?.cin || "-",
                TelB: selectedBourneur.telephone || "-",
                nomA: selectedAssistant?.nom || "-",
                CinA: selectedAssistant?.cin || "-",
                TelA: selectedAssistant?.telephone || "-",
                appareils: selectedMaterials?.map(material => ({ name: material.type })),
                client: selectedClient?.full_name || "-",
                cinClient: selectedClient?.cin || "-",
            };

            doc.setData(data);

            try {
                doc.render();
            } catch (error) {
                console.error('Error rendering the template:', error);
                return;
            }

            try {
                const blob = doc.getZip().generate({ type: 'blob' });
                saveAs(blob, `pv_mission_${selectedProject?.numero}.docx`);
            } catch (error) {
                console.error('Error generating the Word document:', error);
            }
        } catch (error) {
            console.error('Error fetching the template:', error);
        }
    };

    return (
        <>
            <Container sx={{marginTop: '1rem'}}>
                {/* <Paper elevation={3} style={{ padding: '30px', marginTop: '10px' }}> */}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={users}
                            getOptionLabel={(user) => user.nom}
                            onChange={(event, newValue) => setSelectedBourneur(newValue)}
                            renderInput={(params) => <TextField {...params} label="Agent Bourneur" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={users}
                            getOptionLabel={(user) => user.nom}
                            onChange={(event, newValue) => setSelectedAssistant(newValue)}
                            renderInput={(params) => <TextField {...params} label="Agent Assistant" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={clients}
                            getOptionLabel={(client) => client.full_name}
                            onChange={(event, newValue) => setSelectedClient(newValue)}
                            renderInput={(params) => <TextField {...params} label="Client" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={projects}
                            getOptionLabel={(project) => project.numero}
                            onChange={(event, newValue) => setSelectedProject(newValue)}
                            renderInput={(params) => <TextField {...params} label="Project" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="date"
                            label="Date"
                            onChange={(e) => setSelectedDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Lieu de Mission"
                            variant="outlined"
                            fullWidth
                            value={lieuDeMission}
                            onChange={(e) => setLieuDeMission(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            options={materials}
                            getOptionLabel={(material) => material.type}
                            onChange={(event, newValue) => setSelectedMaterials([...selectedMaterials, newValue])}
                            renderInput={(params) => <TextField {...params} label="Materials" />}
                        />
                        {/* <List> */}
                        <ul className="mt-2">
                            {selectedMaterials?.map((material, index) => (
                                <li key={index}>{material?.type}</li>
                                // <ListItem key={index}>
                                //     <ListItemText primary={material?.type} />
                                // </ListItem>
                            ))}
                        </ul>
                        {/* </List> */}
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button variant="contained"
                        sx={{
                            color: '#fff !important'
                        }}
                        color="primary"
                        onClick={handleExportToWord}>
                        Export to Word
                    </Button>
                </Box>
                {/* </Paper> */}
            </Container>
        </>
    );
};

export default PvdeMssion;
