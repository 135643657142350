import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStagiaires, deleteStagiaire } from '../../actions/StagiaireActions';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from "react-router-dom";
import {
    Box,
    TextField,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
    Modal,
    Backdrop,
    Fade,
    Typography,
    Paper,
    Alert,
    Snackbar
} from "@mui/material";
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { encodeId } from '../../actions/Crypte';

const StagairesList = ({ currentUser, darkMode }) => {
    const [pageSize, setPageSize] = useState(25);
    const dispatch = useDispatch();
    const stagiaires = useSelector((state) => state.stagiaires.stagiaires);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        dispatch(getAllStagiaires());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        // setCurrentPage(1);
    };

    useEffect(() => {
        const filteredProjects = stagiaires.filter((project) => {
            const stagiaire = project?.full_name || '';
            return (
                stagiaire.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredProjects(filteredProjects);
        // setCurrentPage(1)
    }, [stagiaires, searchTerm]);

    const handleOpenModal = (nature) => {
        setSelectedItem(nature);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setOpenModal(false);
    };

    const handleDelete = () => {
        if (selectedItem) {
            dispatch(deleteStagiaire(selectedItem.id))
                .then(() => {
                    setSnackbarMessage('Stagiaires supprimés avec succès');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    setSnackbarMessage('Échec de la suppression de stagiaire');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                });
            handleCloseModal();
            dispatch(getAllStagiaires());
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const handleExportToWord = async (stagiaire) => {
        try {
            const response = await axios.get('/attestation_stage.docx', {
                responseType: 'arraybuffer',
            });

            const projectTemplate = response.data;
            const zip = new PizZip(projectTemplate);
            const doc = new Docxtemplater(zip);
            let day = new Date();
            const data = {
                nom: stagiaire.full_name,
                debut: stagiaire.date_debut ? formatDate(stagiaire.date_debut) : "-",
                cin: stagiaire.cin || "-",
                fin: stagiaire.date_fin ? formatDate(stagiaire.date_fin) : "-",
                export: day.toLocaleDateString() || "-",
            };

            doc.setData(data);

            try {
                doc.render();
            } catch (error) {
                console.error('Error rendering the template:', error);
                return;
            }

            try {
                const blob = doc.getZip().generate({ type: 'blob' });
                saveAs(blob, `stagiaire_${stagiaire?.full_name}.docx`);
            } catch (error) {
                console.error('Error generating the Word document:', error);
            }
        } catch (error) {
            console.error('Error fetching the template:', error);
        }
    };
    const columns = [
        { field: 'full_name', headerName: 'Nom Complet', flex: 1 },
        { field: 'date_debut', headerName: 'Date Début', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'telephone', headerName: 'Telephone', flex: 1 },

        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les stagiaires")) && (
                        <MDButton
                            className='me-1 ms-1'
                            size='small'
                            color='error'
                            onClick={() => handleOpenModal(params.row)}
                        >
                            Supprimer
                        </MDButton>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les stagiaires")) && (

                        <MDButton
                            component={Link}
                            className='ms-1 me-1'
                            size='small'
                            color='info'
                            to={`/ressources-humaines/satagiaires/update/${encodeId(params.id)}`}
                        >
                            <BorderColorIcon />
                        </MDButton>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")) && (
                        <MDButton
                            variant="contained"
                            className="me-1"
                            size='small'
                            color="primary"
                            onClick={() => handleExportToWord(params.row)}
                        >
                            Exporter
                        </MDButton>
                    )}
                </>
            ),
            flex: 3,
            cellClassName: 'actions-cell',
        },
    ];

    const createButton = (
        <MDButton
            component={Link}
            to={`/ressources-humaines/satagiaires/ajouter`}
            variant="contained"
            className='mt-1'
            color="info"
            size='small'
        >
            <ControlPointIcon sx={{ mr: "12px" }} />
            ajouter
        </MDButton>
    );
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography
                variant="h2"
                textAlign="center"
                mb={1}
                sx={{
                    fontSize: '36px',
                    fontWeight: 'bold',
                    color: '#3e4396',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                }}
            >
                Liste des stagiaires
            </Typography>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les stagiaires")) ? (
                <Box>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Recherche...."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <Box
                        m="0 0 0 0"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: "leaderboard",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "secondary",
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: "primary",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: "secondary",
                            },
                            "& .MuiCheckbox-root": {
                                color: `leaderboard !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `primary !important`,
                            },
                        }}
                    >
                        <DataGrid
                            rows={filteredProjects}
                            columns={columns}
                            components={{
                                Toolbar: (props) => (
                                    <div className='d-flex'>
                                        {createButton}
                                        <GridToolbar {...props} />
                                    </div>
                                ),
                            }}
                            style={{ color: darkMode ? "#fff" : "#000" }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openModal}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="100vh"
                            >
                                <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Confirmation de la suppression
                                    </Typography>
                                    <Typography paragraph>
                                        Êtes-vous sûr de vouloir supprimer ce stagiaire ?
                                    </Typography>
                                    <MDButton onClick={handleDelete} variant="contained" color="error" style={{ marginRight: '10px' }}>
                                        Supprimer
                                    </MDButton>
                                    <MDButton onClick={handleCloseModal} variant="contained" color="info">
                                        Annuler
                                    </MDButton>
                                </Paper>
                            </Box>
                        </Fade>
                    </Modal>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}

        </DashboardLayout>
    );
};

export default StagairesList;

