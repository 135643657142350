import React from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import ProjectCountByMonthAndNature from "./ProjectCountByMonthAndNature";
import ProjectCountByYear from "./ProjectsByYear";
import PorjectCountByYearAndNature from "./PorjectCountByYearAndNature";
import ProjectCountByPaiement from "./ProjectsByPaiement";
import PorjectsAmount from "./ProjectsAmount";
import PorjectAmountByNature from "./ProjectsAmountByNature";
import {
    Typography,
    Grid,
    Paper,
    Divider,
} from '@mui/material';
const ProjectsStatistics = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h2" mb={2} textAlign={"center"}>
                PROJETS
            </Typography>
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Nombre des projets par le mois et nature
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <ProjectCountByMonthAndNature />
            <Typography variant="h6" mt={4} mb={2} textAlign={"center"}>
                Nombre de projets dans une année spécifique
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <ProjectCountByYear />
            <Typography variant="h6" mt={4} mb={2} textAlign={"center"}>
                Nombre de projets par nature dans une année spécifique
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <PorjectCountByYearAndNature />
            <Typography variant="h6" mt={4} mb={2} textAlign={"center"}>
            Nombre de projets par statut de paiement au cours d'un mois spécifique
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <ProjectCountByPaiement />
            {/* <PorjectsAmount />
            <PorjectAmountByNature /> */}
        </DashboardLayout>
    )
}

export default ProjectsStatistics;