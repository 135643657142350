import React from "react";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ForumIcon from "@mui/icons-material/Forum";
import { Link } from "react-router-dom";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import {
    Avatar,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: '#f4f4f4'
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));


const NotificationsList = ({ charges, avances, holidays, requests, totalRejected, currentUser, tasks }) => {
    const chipSX = {
        height: 24,
        padding: '0 6px'
    };

    return (
        <>
            <>
                {(charges?.count || 0) + (avances?.count || 0) + (holidays?.holidayCount || 0) + (requests?.count || 0) + (tasks?.taskCount || 0) + (totalRejected || 0) ? (
                    <>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 330,
                                py: 0,
                                borderRadius: '10px',
                                '& .MuiListItemSecondaryAction-root': {
                                    top: 22
                                },
                                '& .MuiDivider-root': {
                                    my: 0
                                },
                                '& .list-container': {
                                    pl: 7
                                }
                            }}
                        >
                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les charges")) &&
                                <>
                                    {charges?.count > 0 && (
                                        <ListItemWrapper>
                                            <ListItem alignItems="center"  >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            color: '#cc0066',
                                                            backgroundColor: '#ffcce0',
                                                            border: 'none',
                                                            borderColor: '#cc0b6b'
                                                        }}
                                                    >
                                                        <RequestQuoteIcon stroke={1.5} size="1.3rem" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Typography variant="subtitle1">Nouvelles Charges</Typography>} />
                                                <ListItemSecondaryAction>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Grid container direction="column" className="list-container">
                                                <Grid item xs={12} sx={{ pb: 2 }}>
                                                    <Typography variant="subtitle2">
                                                        Vous avez de nouveaux Charges en attente de validation,<br />
                                                        <strong> {charges?.count}&nbsp;Charges </strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Chip label="Voir Plus"
                                                                sx={{
                                                                    color: '#cc0066',
                                                                    marginRight: '5px',
                                                                    height: 24,
                                                                    padding: '0 6px'
                                                                }}
                                                                component={Link}
                                                                to={'/charges/pendinglist'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItemWrapper>
                                    )}
                                    <Divider />
                                </>
                            }
                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider des encaissements")) &&
                                <>
                                    {avances?.count > 0 && (
                                        <ListItemWrapper>
                                            <ListItem alignItems="center">
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            color: '#26a826',
                                                            backgroundColor: '#ccebcc',
                                                            border: 'none',
                                                            borderColor: '#26a826'
                                                        }}
                                                    >
                                                        <PriceCheckIcon stroke={1.5} size="1.3rem" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Typography variant="subtitle1">Nouveaux Encaissements</Typography>} />
                                                <ListItemSecondaryAction>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Grid container direction="column" className="list-container">
                                                <Grid item xs={12} sx={{ pb: 2 }}>
                                                    <Typography variant="subtitle2">
                                                        Vous avez Nouveaux Encaissements,<br />
                                                        <strong> {avances?.count}&nbsp;Encaissements </strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Chip label="Voir Plus"
                                                                sx={{
                                                                    color: '#26a826',
                                                                    marginRight: '5px',
                                                                    height: 24,
                                                                    padding: '0 6px'
                                                                }}
                                                                component={Link}
                                                                to={'/finances/projects/avances/pendinglist'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItemWrapper>
                                    )}
                                    <Divider />
                                </>
                            }
                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les congés")) &&
                                <>
                                    {holidays?.holidayCount > 0 && (
                                        <ListItemWrapper>
                                            <ListItem alignItems="center">
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            color: '#3d6eff',
                                                            backgroundColor: '#d6e0ff',
                                                            border: 'none',
                                                            borderColor: '#3d6eff'
                                                        }}
                                                    >
                                                        <CalendarTodayIcon stroke={1.5} size="1.3rem" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Typography variant="subtitle1">Demandes de congés</Typography>} />
                                                <ListItemSecondaryAction>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Grid container direction="column" className="list-container">
                                                <Grid item xs={12} sx={{ pb: 2 }}>
                                                    <Typography variant="subtitle2">
                                                        Vous avez des demandes de congés,<br />
                                                        <strong> {holidays.holidayCount} demandes </strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Chip label="Voir Plus"
                                                                sx={{
                                                                    color: '#3d6eff',
                                                                    marginRight: '5px',
                                                                    height: 24,
                                                                    padding: '0 6px'
                                                                }}
                                                                component={Link}
                                                                to={'/ressources-humaines/holidays/pending/requests'} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItemWrapper>
                                    )}
                                    <Divider />
                                </>
                            }
                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les avances de salaires")) &&
                                <>
                                    {requests?.count > 0 && (
                                        <ListItemWrapper>
                                            <ListItem alignItems="center">
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            color: '#ffcc00',
                                                            backgroundColor: '#fff7d9',
                                                            border: 'none',
                                                            borderColor: '#ffcc00'
                                                        }}
                                                    >
                                                        <ForumIcon stroke={1.5} size="1.3rem" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Typography variant="subtitle1">Demandes des avances sur salaire</Typography>} />
                                                <ListItemSecondaryAction>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                                {/* {currentDate} */}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Grid container direction="column" className="list-container">
                                                <Grid item xs={12} sx={{ pb: 2 }}>
                                                    <Typography variant="subtitle2">
                                                        Vous avez des nouvelles demandes d'avances sur salaire,<br />
                                                        <strong> {requests?.count} demandes </strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Chip label="Voir Plus"
                                                                sx={{
                                                                    color: '#ffcc00',
                                                                    marginRight: '5px',
                                                                    height: 24,
                                                                    padding: '0 6px'
                                                                }}
                                                                component={Link}
                                                                to={"/requests/pendingList"}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItemWrapper>
                                    )}
                                    <Divider />
                                </>
                            }
                            {tasks?.taskCount > 0 && (
                                <>
                                    <ListItemWrapper>
                                        <ListItem alignItems="center">
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        color: '#ff6e0d',
                                                        backgroundColor: '#fff0e6',
                                                        border: 'none',
                                                        borderColor: '#ff6e0d'
                                                    }}
                                                >
                                                    <AssignmentIcon stroke={1.5} size="1.3rem" />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={<Typography variant="subtitle1">Nouvelles Tâches</Typography>} />
                                            <ListItemSecondaryAction>
                                                <Grid container justifyContent="flex-end">
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" display="block" gutterBottom>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Grid container direction="column" className="list-container">
                                            <Grid item xs={12} sx={{ pb: 2 }}>
                                                <Typography variant="subtitle2">
                                                    Vous avez des Nouvelles tâches<br />
                                                    <strong> {tasks?.taskCount} tâches </strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item>
                                                        <Chip label="Voir Plus"
                                                            sx={{
                                                                color: '#ff6e0d',
                                                                marginRight: '5px',
                                                                height: 24,
                                                                padding: '0 6px'
                                                            }}
                                                            component={Link}
                                                            to={'/projects/taches'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ListItemWrapper>
                                    <Divider />
                                </>
                            )}
                            {totalRejected > 0 && (
                                <>
                                    <ListItemWrapper>
                                        <ListItem alignItems="center">
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        color: '#ff0000',
                                                        backgroundColor: '#ffcccc',
                                                        border: 'none',
                                                        borderColor: '#ff0000'
                                                    }}
                                                >
                                                    <HighlightOffIcon stroke={1.5} size="1.3rem" />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={<Typography variant="subtitle1">Nouveaux rejets</Typography>} />
                                            <ListItemSecondaryAction>
                                                <Grid container justifyContent="flex-end">
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" display="block" gutterBottom>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Grid container direction="column" className="list-container">
                                            <Grid item xs={12} sx={{ pb: 2 }}>
                                                <Typography variant="subtitle2">
                                                    Vous avez des Nouveaux rejets<br />
                                                    <strong> {totalRejected} rejets </strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item>
                                                        <Chip label="Voir Plus"
                                                            sx={{
                                                                color: '#ff0000',
                                                                marginRight: '5px',
                                                                height: 24,
                                                                padding: '0 6px'
                                                            }}
                                                            component={Link}
                                                            to={'/rejected/list'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ListItemWrapper>
                                    <Divider />
                                </>
                            )}
                        </List>
                    </>
                ) : (
                    <>
                        <Grid p={2} container spacing={2}>
                            <Typography ml={2} mt={2} variant="body1" gutterBottom>
                                Aucune notification disponible :)!
                            </Typography>
                        </Grid>
                    </>
                )}
            </>
        </>
    );
}

export default NotificationsList;