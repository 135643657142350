import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsByClientId, deleteProject } from '../../actions/ProjetActions';
import { Link, useParams } from 'react-router-dom';
import {
    TextField,
    Typography,
    Modal,
    Box,
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccessDenied from '../Errors/AccessDenied';
import { encodeId } from '../../actions/Crypte';

const ClientProjects = ({ currentUser }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(25);
    const projects = useSelector((state) => state.project.projectsClient);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentProjectId, setCurrentProjectId] = useState(null);
    useEffect(() => {
        dispatch(getProjectsByClientId(id));
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredProjects = projects?.projects?.filter((project) => {
            const reference = project.reference_fonciere || '';
            const numero = project.numero || '';

            return (
                reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
                numero.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredProjects(filteredProjects);
    }, [projects, searchTerm]);

    const openDeleteModal = (projectId) => {
        setDeleteModalOpen(true);
        setCurrentProjectId(projectId);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleDelete = (projectId) => {
        dispatch(deleteProject(projectId));
    };

    const handleConfirmDelete = () => {
        if (currentProjectId) {
            handleDelete(currentProjectId);
            closeDeleteModal();
        }
    };
    console.log(filteredProjects);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const columns = [
        { field: 'numero', headerName: 'Project', flex: 1, sortable: true },
        { field: 'reference_fonciere', headerName: 'Reference', flex: 1, sortable: true },
        {
            field: 'nature',
            headerName: 'Nature',
            flex: 1,
            sortable: true,
            valueGetter: (params) => params.row.nature.map((natures) => natures.name).join(', '),
        },
        { field: 'date_creation', headerName: 'Created', flex: 1, sortable: true },
        { field: 'statut', headerName: 'Status', flex: 1, sortable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <Box>
                    <MDButton component={Link}
                        size="small" color='success'
                        to={`/projects/project/${encodeId(params.id)}`}
                    >
                        <VisibilityIcon />
                    </MDButton>
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("Supprimer les projets")) &&
                        <MDButton
                            className='ms-2'
                            color="error"
                            size="small"
                            aria-label="Delete"
                            title="Delete"
                            onClick={() => openDeleteModal(params.row.id)}
                        >
                            <DeleteIcon />
                        </MDButton>
                    }
                </Box>
            ),
            flex: 2,
        },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) ? (
                <div className="container">
                    <Typography
                        textAlign={'center'}
                        variant="h3"
                        gutterBottom
                        mt={3}
                        mb={2}
                        sx={{
                            fontWeight: 'bold',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        Projets de : {filteredProjects && filteredProjects.length > 0 ? filteredProjects[0]?.client?.full_name || '-' : '-'}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Rechercher un projet par référence ou numéro"
                        onChange={handleSearch}
                        value={searchTerm}
                    />
                    <DataGrid
                        columns={columns}
                        rows={filteredProjects || []}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        autoHeight
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />

                    <Modal
                        open={isDeleteModalOpen}
                        onClose={closeDeleteModal}
                        aria-labelledby="delete-modal-title"
                        aria-describedby="delete-modal-description"
                    >
                        <Box sx={{ ...modalStyle }}>
                            <MDTypography id="delete-modal-title" variant="h5" color="dark" component="h2" gutterBottom>
                                Confirmation de suppression
                            </MDTypography>
                            <Typography id="delete-modal-description" sx={{ marginBottom: '1rem' }}>
                                Êtes-vous sûr de vouloir supprimer ce projet ?
                            </Typography>
                            <MDButton onClick={handleConfirmDelete} variant="contained" color="error" sx={{ marginRight: '1rem' }}>
                                Supprimer
                            </MDButton>
                            <MDButton onClick={closeDeleteModal} variant="contained">
                                Annuler
                            </MDButton>
                        </Box>
                    </Modal>
                </div>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </DashboardLayout>
    );
};

export default ClientProjects;
