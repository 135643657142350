import React, { useState, useEffect } from 'react';
import StatisticsService from '../../../services/statisticsServices';
import {
    Typography,
    Grid,
    Paper,
    Divider,
    TextField,
    Button,
    Switch,
} from '@mui/material';

const CaisseStatistics = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [encaissementsStatistics, setEncaissementsStatistics] = useState(null);
    const [chargesStatistics, setChargesStatistics] = useState(null);
    const [requestsStatistics, setRequestsStatistics] = useState(null);
    const [operationsStatistics, setOperationsStatistics] = useState(null);
    const [caisseSelected, setCaisseSelected] = useState(true);
    const [selectedRecettes, setSelectedRecettes] = useState({
        Espèces: true,
        Chèques: true,
        'Virement bancaire': true,
    });
    const [selectedCharges, setSelectedCharges] = useState({
        oui: true,
        non: true,
        'en cours': true,
    });
    const [selectedRequests, setSelectedRequests] = useState({
        oui: true,
        non: true,
        'en cours': true,
    });
    const [selectedOperations, setSelectedOperations] = useState({
        'Versement Recettes': true,
        'Virement Recettes': true,
        'Chèque Recettes': true,
        'Virement Charges': true,
        'Virement Salaires': true,
        'Prélèvement CNSS': true,
        'Prélèvement TVA': true,
        'Prélèvement IS': true,
        'Caution': true,
        'Versement de Caisse': true,
        'Retrait pour Caisse': true,
    });

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleCaisseToggle = () => {
        setCaisseSelected(!caisseSelected);
    };

    const handleRecettesToggle = (event) => {
        setSelectedRecettes({ ...selectedRecettes, [event.target.name]: event.target.checked });
    };

    const handleChargesToggle = (event) => {
        setSelectedCharges({ ...selectedCharges, [event.target.name]: event.target.checked });
    };

    const handleRequestsToggle = (event) => {
        setSelectedRequests({ ...selectedRequests, [event.target.name]: event.target.checked });
    };

    const handleOperationsToggle = (event) => {
        setSelectedOperations({ ...selectedOperations, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const encaissementsResponse = await StatisticsService.totalEncaissementByType(startDate, endDate);
                setEncaissementsStatistics(encaissementsResponse.data);

                const chargesResponse = await StatisticsService.totalChargesByStatus(startDate, endDate);
                setChargesStatistics(chargesResponse.data);

                const requestsResponse = await StatisticsService.totalRequests(startDate, endDate);
                setRequestsStatistics(requestsResponse.data);

                const operationsResponse = await StatisticsService.totalOperationsByType(startDate, endDate);
                setOperationsStatistics(operationsResponse.data);
            } catch (error) {
                console.error('Error fetching statistics:', error);
            }
        };

        fetchData();
    }, [startDate, endDate]);

    const decaisseCharges = Object.keys(selectedCharges).reduce((acc, key) => {
        return selectedCharges[key] ? acc + (chargesStatistics ? chargesStatistics[key.toLowerCase()] : 0) : acc;
    }, 0);

    const decaisseRequests = Object.keys(selectedRequests).reduce((acc, key) => {
        return selectedRequests[key] ? acc + (requestsStatistics ? requestsStatistics[key] : 0) : acc;
    }, 0);

    const caisseTotal = caisseSelected
        ? Object.keys(selectedRecettes).reduce((acc, key) => {
            return selectedRecettes[key] ? acc + (encaissementsStatistics ? encaissementsStatistics[key] : 0) : acc;
        }, 0)
        : Object.keys(selectedCharges).reduce((acc, key) => {
            return selectedCharges[key] ? acc + (chargesStatistics ? chargesStatistics[key.toLowerCase()] : 0) : acc;
        }, 0);

    const decaisseTotal = decaisseCharges + decaisseRequests;
    // const decaisseTotal = Object.keys(selectedRequests).reduce((acc, key) => {
    //     return selectedRequests[key] ? acc + (requestsStatistics ? requestsStatistics[key] : 0) : acc;
    // }, 0);

    const finCaisse = caisseTotal - decaisseTotal;

    return (
        <>
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="start-date"
                        label="Depuis"
                        type="date"
                        fullWidth
                        value={startDate}
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="end-date"
                        label="à"
                        type="date"
                        value={endDate}
                        fullWidth
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper sx={{
                        padding: '1rem'
                    }}>
                        <Typography variant="h6">Type de statistiques:</Typography>
                        <div className='d-flex mb-3'>
                            <Switch
                                checked={caisseSelected}
                                onChange={handleCaisseToggle}
                                name="caisseSelected"
                                inputProps={{ 'aria-label': 'toggle caisse' }}
                            />
                            <Typography className='mt-1' variant="body2">{caisseSelected ? 'Caisse' : 'Decaisse'}</Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper sx={{
                        padding: '1rem'
                    }}>
                        <Typography variant="h6">Total En Caisse: {caisseTotal}</Typography>
                        <Typography variant="h6">Total Decaisse: {decaisseTotal}</Typography>
                        <Typography variant="h6">Caisse: {finCaisse}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{
                        padding: '1rem'
                    }}>
                        <Typography variant="h6">Recettes / Charges:</Typography>
                        {caisseSelected ? (
                            <>
                                {Object.keys(selectedRecettes).map((key) => (
                                    <div key={key} className='d-flex'>
                                        <Switch
                                            checked={selectedRecettes[key]}
                                            onChange={handleRecettesToggle}
                                            name={key}
                                            inputProps={{ 'aria-label': key }}
                                        />
                                        <Typography variant="body2">{key} : {encaissementsStatistics && encaissementsStatistics[key]}</Typography>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                {Object.keys(selectedCharges).map((key) => (
                                    <div key={key} className='d-flex'>
                                        <Switch
                                            checked={selectedCharges[key]}
                                            onChange={handleChargesToggle}
                                            name={key}
                                            inputProps={{ 'aria-label': key }}
                                        />
                                        <Typography variant="body2">{key} : {chargesStatistics && chargesStatistics[key.toLowerCase()]}</Typography>
                                    </div>
                                ))}
                            </>
                        )}
                    </Paper>
                </Grid>
                {!caisseSelected && (
                    <Grid item xs={12}>
                        <Paper sx={{
                            padding: '1rem'
                        }}>
                            <Typography variant="h6">Les avances sur salaires:</Typography>
                            {Object.keys(selectedRequests).map((key) => (
                                <div key={key} className='d-flex'>
                                    <Switch
                                        checked={selectedRequests[key]}
                                        onChange={handleRequestsToggle}
                                        name={key}
                                        inputProps={{ 'aria-label': key }}
                                    />
                                    <Typography variant="body2">{key} : {requestsStatistics && requestsStatistics[key]}</Typography>
                                </div>
                            ))}
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Paper sx={{
                        padding: '1rem'
                    }}>
                        <Typography variant="h6">Operations:</Typography>
                        {Object.keys(selectedOperations).map((key) => (
                            <div key={key} className='d-flex'>
                                <Switch
                                    checked={selectedOperations[key]}
                                    onChange={handleOperationsToggle}
                                    name={key}
                                    inputProps={{ 'aria-label': key }}
                                />
                                <Typography variant="body2">{key} : {operationsStatistics && operationsStatistics[key]}</Typography>
                            </div>
                        ))}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default CaisseStatistics;


// import React, { useState, useEffect } from 'react';
// import StatisticsService from '../../../services/statisticsServices';
// import {
//     Typography,
//     Grid,
//     Paper,
//     Divider,
//     TextField,
//     Button,
//     Switch,
// } from '@mui/material';

// const CaisseStatistics = () => {
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//     const [encaissementsStatistics, setEncaissementsStatistics] = useState(null);
//     const [chargesStatistics, setChargesStatistics] = useState(null);
//     const [operationsStatistics, setOperationsStatistics] = useState(null);
//     const [caisseSelected, setCaisseSelected] = useState(true);
//     const [selectedRecettes, setSelectedRecettes] = useState({
//         Espèces: true,
//         Chèques: true,
//         'Virement bancaire': true,
//     });
//     const [selectedCharges, setSelectedCharges] = useState({
//         oui: true,
//         non: true,
//         'en cours': true,
//     });
//     const [selectedRequests, setSelectedRequests] = useState({
//         oui: true,
//         non: true,
//         'en cours': true,
//     });
//     const [selectedOperations, setSelectedOperations] = useState({
//         'Versement Recettes': true,
//         'Virement Recettes': true,
//         'Chèque Recettes': true,
//         'Virement Charges': true,
//         'Virement Salaires': true,
//         'Prélèvement CNSS': true,
//         'Prélèvement TVA': true,
//         'Prélèvement IS': true,
//         'Caution': true,
//         'Versement de Caisse': true,
//         'Retrait pour Caisse': true,
//     });

//     const handleStartDateChange = (event) => {
//         setStartDate(event.target.value);
//     };

//     const handleEndDateChange = (event) => {
//         setEndDate(event.target.value);
//     };

//     const handleCaisseToggle = () => {
//         setCaisseSelected(!caisseSelected);
//     };

//     const handleRecettesToggle = (event) => {
//         setSelectedRecettes({ ...selectedRecettes, [event.target.name]: event.target.checked });
//     };

//     const handleChargesToggle = (event) => {
//         setSelectedCharges({ ...selectedCharges, [event.target.name]: event.target.checked });
//     };

//     const handleRequestsToggle = (event) => {
//         setSelectedCharges({ ...selectedRequests, [event.target.name]: event.target.checked });
//     };

//     const handleOperationsToggle = (event) => {
//         setSelectedOperations({ ...selectedOperations, [event.target.name]: event.target.checked });
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const encaissementsResponse = await StatisticsService.totalEncaissementByType(startDate, endDate);
//                 setEncaissementsStatistics(encaissementsResponse.data);

//                 const chargesResponse = await StatisticsService.totalChargesByStatus(startDate, endDate);
//                 setChargesStatistics(chargesResponse.data);

//                 const operationsResponse = await StatisticsService.totalOperationsByType(startDate, endDate);
//                 setOperationsStatistics(operationsResponse.data);

//                 const requestsResponse = await StatisticsService.totalRequests(startDate, endDate);
//                 setSelectedRequests(requestsResponse.data);
//             } catch (error) {
//                 console.error('Error fetching encaissements statistics:', error);
//             }
//         };

//         fetchData();
//     }, [startDate, endDate]);

//     const caisseTotal = caisseSelected
//         ? Object.keys(selectedRecettes).reduce((acc, key) => {
//             return selectedRecettes[key] ? acc + (encaissementsStatistics ? encaissementsStatistics[key] : 0) : acc;
//         }, 0)
//         : Object.keys(selectedCharges).reduce((acc, key) => {
//             return selectedCharges[key] ? acc + (chargesStatistics ? chargesStatistics[key.toLowerCase()] : 0) : acc;
//         }, 0);

//     const decaisseTotal = caisseSelected
//         ? Object.keys(selectedCharges).reduce((acc, key) => {
//             return selectedCharges[key] ? acc + (chargesStatistics ? chargesStatistics[key.toLowerCase()] : 0) : acc;
//         }, 0)
//         : Object.keys(selectedRecettes).reduce((acc, key) => {
//             return selectedRecettes[key] ? acc + (encaissementsStatistics ? encaissementsStatistics[key] : 0) : acc;
//         }, 0);

//     const finCaisse = caisseTotal - decaisseTotal;

//     return (
//         <>
//             <Grid container spacing={3} mt={1}>
//                 <Grid item xs={12} sm={6}>
//                     <TextField
//                         id="start-date"
//                         label="Depuis"
//                         type="date"
//                         fullWidth
//                         value={startDate}
//                         onChange={handleStartDateChange}
//                         InputLabelProps={{
//                             shrink: true,
//                         }}
//                     />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                     <TextField
//                         id="end-date"
//                         label="à"
//                         type="date"
//                         value={endDate}
//                         fullWidth
//                         onChange={handleEndDateChange}
//                         InputLabelProps={{
//                             shrink: true,
//                         }}
//                     />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                     <Paper sx={{
//                         padding: '1rem'
//                     }}>
//                         <Typography variant="h6">Type de statistiques:</Typography>
//                         <div className='d-flex mb-3'>
//                             <Switch
//                                 checked={caisseSelected}
//                                 onChange={handleCaisseToggle}
//                                 name="caisseSelected"
//                                 inputProps={{ 'aria-label': 'toggle caisse' }}
//                             />
//                             <Typography className='mt-1' variant="body2">{caisseSelected ? 'Caisse' : 'Decaisse'}</Typography>
//                         </div>
//                     </Paper>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                     <Paper sx={{
//                         padding: '1rem'
//                     }}>
//                         <Typography variant="h6">Total En Caisse: {caisseTotal}</Typography>
//                         <Typography variant="h6">Total Decaisse: {decaisseTotal}</Typography>
//                         <Typography variant="h6">Caisse: {finCaisse}</Typography>
//                     </Paper>
//                 </Grid>
//                 <Grid item xs={12}>
//                     <Paper sx={{
//                         padding: '1rem'
//                     }}>
//                         <Typography variant="h6">Recettes / Charges:</Typography>
//                         {caisseSelected ? (
//                             <>
//                                 {Object.keys(selectedRecettes).map((key) => (
//                                     <div key={key} className='d-flex'>
//                                         <Switch
//                                             checked={selectedRecettes[key]}
//                                             onChange={handleRecettesToggle}
//                                             name={key}
//                                             inputProps={{ 'aria-label': key }}
//                                         />
//                                         <Typography variant="body2">{key} : {encaissementsStatistics && encaissementsStatistics[key]}</Typography>
//                                     </div>
//                                 ))}
//                             </>
//                         ) : (
//                             <>
//                                 {Object.keys(selectedCharges).map((key) => (
//                                     <div key={key} className='d-flex'>
//                                         <Switch
//                                             checked={selectedCharges[key]}
//                                             onChange={handleChargesToggle}
//                                             name={key}
//                                             inputProps={{ 'aria-label': key }}
//                                         />
//                                         <Typography variant="body2">{key} : {chargesStatistics && chargesStatistics[key.toLowerCase()]}</Typography>
//                                     </div>
//                                 ))}
//                             </>
//                         )}
//                     </Paper>
//                 </Grid>
//                 <Grid item xs={12}>
//                     <Paper sx={{
//                         padding: '1rem'
//                     }}>
//                         <Typography variant="h6">Operations:</Typography>
//                         {Object.keys(selectedOperations).map((key) => (
//                             <div key={key} className='d-flex'>
//                                 <Switch
//                                     checked={selectedOperations[key]}
//                                     onChange={handleOperationsToggle}
//                                     name={key}
//                                     inputProps={{ 'aria-label': key }}
//                                 />
//                                 <Typography variant="body2">{key} : {operationsStatistics && operationsStatistics[key]}</Typography>
//                             </div>
//                         ))}
//                     </Paper>
//                 </Grid>
//             </Grid>
//         </>
//     );
// };

// export default CaisseStatistics;
