import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import StatisticsServices from '../../../services/statisticsServices';

const ProjectCountByPaiement = () => {
  const theme = useTheme();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [projectCounts, setProjectCounts] = useState(null);

  useEffect(() => {
    if (selectedMonth === '' && selectedYear === '') {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      setSelectedMonth(String(currentMonth));
      setSelectedYear(String(currentYear));
    }
  }, []);

  const fetchProjectsLastCurrentMonth = async (month, year) => {
    try {
      const response = await StatisticsServices.countProjectsByMonthAndPaiement(month, year);
      setProjectCounts(response.data || []);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      fetchProjectsLastCurrentMonth(selectedMonth, selectedYear);
    }
  }, [selectedMonth, selectedYear]);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const generateMonthOptions = () => {
    const months = [
      { value: '1', label: 'January' },
      { value: '2', label: 'February' },
      { value: '3', label: 'March' },
      { value: '4', label: 'April' },
      { value: '5', label: 'May' },
      { value: '6', label: 'June' },
      { value: '7', label: 'July' },
      { value: '8', label: 'August' },
      { value: '9', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];

    return months.map((month) => (
      <MenuItem key={month.value} value={month.value}>
        {month.label}
      </MenuItem>
    ));
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2017;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years.map((year) => (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    ));
  };

  console.log(projectCounts);

  return (
    <>
      <Box
        mt="25px"
        p="0 30px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              select
              value={selectedMonth}
              onChange={handleMonthChange}
              label="Sélectionner un mois"
              fullWidth
            >
              <MenuItem value="">-- Select Month --</MenuItem>
              {generateMonthOptions()}
            </TextField>
          </Grid>
          <Grid item md={6}>
            <TextField
              select
              value={selectedYear}
              onChange={handleYearChange}
              label="Sélectionner l'année"
              fullWidth
            >
              <MenuItem value="">-- Select Year --</MenuItem>
              {generateYearOptions()}
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <Box height="400px" m="-20px 0 0 0">
        {projectCounts && projectCounts.length > 0 ? (
          <ResponsivePie
            data={projectCounts.map(project => ({ id: project.paiement, label: project.paiement, value: project.count }))}
            margin={{ top: 50, right: 80, bottom: 20, left: 30 }}
            colors={{ scheme: 'category10' }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            theme={theme.nivo}
          />
        ) : (
          <Typography variant="body2" mt={5} p={5}>Oops! Aucune donnée disponible à la date spécifiée</Typography>
        )}
      </Box>
    </>
  );
};

export default ProjectCountByPaiement;

