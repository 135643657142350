import {
    CREATE_MATERIAL_SUCCESS, 
    CREATE_MATERIAL_FAILURE, 
    UPDATE_MATERIAL_SUCCESS, 
    UPDATE_MATERIAL_FAILURE, 
    GET_MATERIAL_SUCCESS, 
    GET_MATERIAL_FAILURE, 
    DELETE_MATERIAL_SUCCESS, 
    DELETE_MATERIAL_FAILURE, 
    GET_ALL_MATERIALS_SUCCESS, 
    GET_ALL_MATERIALS_FAILURE 
  } from '../actions/types';
const initialState = {
  material: null,
  materials: [],
  error: null,
};

const materialReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MATERIAL_SUCCESS:
      return {
        ...state,
        material: action.payload,
        error: null,
      };
    case CREATE_MATERIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_MATERIAL_SUCCESS:
      return {
        ...state,
        material: action.payload,
        error: null,
      };
    case UPDATE_MATERIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_MATERIAL_SUCCESS:
      return {
        ...state,
        material: action.payload,
        error: null,
      };
    case GET_MATERIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_MATERIAL_SUCCESS:
      return {
        ...state,
        material: null,
        error: null,
      };
    case DELETE_MATERIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: action.payload,
        error: null,
      };
    case GET_ALL_MATERIALS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default materialReducer;
