import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createConstant } from '../../actions/ConstantActions';
import { getAllUsers } from "../../actions/UserActions";
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
    Button,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDButton from '../../components/MDButton';

const CreateConstant = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users);
    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);
    const [selectedUser, setSelectedUser] = useState('');
    const [constantData, setConstantData] = useState({
        pcnss: '',
        pamo: '',
        scnss: '',
        samo: '',
        nb_heures: '',
        taf: '',
        tfp: '',
        userId: selectedUser,
    });

    console.log(users);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setConstantData({
            ...constantData,
            [name]: value,
        });
    };

    const handleCreateConstant = () => {
        dispatch(createConstant(constantData))
            .then(() => {
                setSuccessMessage('Constant created successfully');
                // Clear form data after success
                setConstantData({
                    pcnss: '',
                    pamo: '',
                    scnss: '',
                    samo: '',
                    nb_heures: '',
                    taf: '',
                    tfp: '',
                    userId: selectedUser,
                });
            })
            .catch((error) => {
                setErrorMessage(`Error creating constant: ${error.message}`);
            });
    };

    const handleDefaultCreateConstant = () => {
        dispatch(createConstant({
            pcnss: 8.98,
            pamo: 4.11,
            scnss: 4.48,
            samo: 2.26,
            nb_heures: 191,
            taf: 6.4,
            tfp: 1.6,
            userId: selectedUser,
        }))
            .then(() => {
                setSuccessMessage('Constant created successfully');
                // Clear form data after success
                setConstantData({
                    pcnss: '',
                    pamo: '',
                    scnss: '',
                    samo: '',
                    nb_heures: '',
                    taf: '',
                    tfp: '',
                    userId: '',
                });
            })
            .catch((error) => {
                setErrorMessage(`Error creating constant: ${error.message}`);
            });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom>
                    Create a New Constant
                </Typography>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Select
                                name="userId"
                                value={selectedUser}
                                onChange={(e) => setSelectedUser(e.target.value)}
                                required
                                fullWidth
                            >
                                <MenuItem value="" disabled>
                                    Select User
                                </MenuItem>
                                {users?.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user?.nom}&nbsp;{user?.prenom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Taux Patronale de CNSS (pcnss)"
                                name="pcnss"
                                value={constantData.pcnss}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Taux Patronal de AMO (pamo)"
                                name="pamo"
                                value={constantData.pamo}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Taux Salarial de CNSS (scnss)"
                                name="scnss"
                                value={constantData.scnss}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Taux Salariale de AMO (samo)"
                                name="samo"
                                value={constantData.samo}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Nombre des heures travaillées (nb_heures)"
                                name="nb_heures"
                                value={constantData.nb_heures}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Taux d'Allocation Familiale (taf)"
                                name="taf"
                                value={constantData.taf}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Taux de Formation Professionnelle (tfp)"
                                name="tfp"
                                value={constantData.tfp}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDButton
                            className='me-1'
                                variant="contained"
                                color="info"
                                onClick={handleCreateConstant}
                            >
                                Create Constant
                            </MDButton>
                            <MDButton
                            className='ms-1'
                                variant="contained"
                                color="primary"
                                onClick={handleDefaultCreateConstant}
                            >
                                Default Constant
                            </MDButton>
                        </Grid>
                    </Grid>
                </form>
                {/* Success Snackbar */}
                {successMessage && (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert severity="success" onClose={() => setSuccessMessage(null)}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                )}
                {/* Error Snackbar */}
                {errorMessage && (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert severity="error" onClose={() => setErrorMessage(null)}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                )}
            </Container>
        </DashboardLayout>
    );
};

export default CreateConstant;
