import {
  CREATE_FACTURE_SUCCESS,
  CREATE_FACTURE_FAILURE,
  UPDATE_FACTURE_SUCCESS,
  UPDATE_FACTURE_FAILURE,
  GET_FACTURE_SUCCESS,
  GET_FACTURE_FAILURE,
  DELETE_FACTURE_SUCCESS,
  DELETE_FACTURE_FAILURE,
  GET_ALL_FACTURES_SUCCESS,
  GET_ALL_FACTURES_FAILURE,
  GET_CLIENT_FACTURES_SUCCESS,
  GET_CLIENT_FACTURES_FAILURE,
} from '../actions/types';

const initialState = {
  facture: null,
  factures: [],
  error: null,
};

const factureReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_FACTURE_SUCCESS:
      return {
        ...state,
        facture: action.payload,
        error: null,
      };
    case CREATE_FACTURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_FACTURE_SUCCESS:
      return {
        ...state,
        facture: action.payload,
        error: null,
      };
    case UPDATE_FACTURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_FACTURE_SUCCESS:
      return {
        ...state,
        facture: action.payload,
        error: null,
      };
    case GET_FACTURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_FACTURE_SUCCESS:
      return {
        ...state,
        facture: null,
        error: null,
      };
    case DELETE_FACTURE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_FACTURES_SUCCESS:
      return {
        ...state,
        factures: action.payload,
        error: null,
      };
    case GET_ALL_FACTURES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CLIENT_FACTURES_SUCCESS:
      return {
        ...state,
        factures: action.payload,
        error: null,
      };
    case GET_CLIENT_FACTURES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default factureReducer;
