import API_URL from './apiUrl';

class ContactService {
  createCommercial(commercialData) {
    return API_URL.post("/contacts/create/", commercialData);
  }

  getAllCommercials() {
    return API_URL.get("/contacts");
  }

  updateCommercial(commercialId, updatedCommercial) {
    return API_URL.put(`/contacts/update/${commercialId}`, updatedCommercial);
  }

  fetchCommercial(commercialId) {
    return API_URL.get(`/contacts/${commercialId}`);
  }

  deleteCommercial(commercialId) {
    return API_URL.delete(`/contacts/delete/${commercialId}`);
  }

  createAccount(contactId, data) {
    return API_URL.put(`/contacts/create/account/${contactId}`, data);
  }

  contactLogin(contactData) {
    return API_URL.post("/contacts/contact/auth/signin", contactData)
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("contact", JSON.stringify(response.data));
        }
        return response.data;
      });
  };

  logout = () => {
    localStorage.removeItem("contact");
  };

}

export default new ContactService();
