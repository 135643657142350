import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllCharges, deleteCharge, updateCharge } from "../../actions/ChargesActions";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from "react-router-dom";
import {
    Paper,
    Typography,
    useMediaQuery,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Snackbar,
    Alert,
    Button,
    Box
} from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import { useMaterialUIController } from "../../context";
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import FileUpload from "./UploadFiles";
import ChargeFiles from "./ChargesFiles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ControlPoint } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import ChragesPreview from "./ChargesPreview";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const formatDateForMonthDisplay = (dateString) => {
    if (!dateString) return "";
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
};

const Charges = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    let charges = useSelector((state) => state.charges.allCharges) || [];
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCharge, setSelectedCharge] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [isModalValiderOpen, setModalValiderOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity] = useState("success");
    const [pageSize, setPageSize] = useState(25);
    const [dialogOpen, setDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        dispatch(getAllCharges());
    }, [dispatch]);

    const openDeleteModal = (charge) => {
        setSelectedCharge(charge);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedCharge(null);
        setDeleteModalOpen(false);
    };

    const openValideModal = (item) => {
        setSelectedItem(item);
        setModalValiderOpen(true);
    };

    const closeValideModal = () => {
        setSelectedItem(null);
        setModalValiderOpen(false);
    };

    const openDialog = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setDialogOpen(false);
    };

    const handleValider = () => {
        if (selectedItem) {
            dispatch(updateCharge(selectedItem.id, { status: 'oui' }))
                .then(() => {
                    dispatch(getAllCharges());
                })
                .catch((error) => {
                    console.error('Error Confiramtion charge:', error);
                });
            setSelectedItem(null);
        }
        closeValideModal();
    };

    const handleRejeter = () => {
        if (selectedItem) {
            dispatch(updateCharge(selectedItem.id, { status: 'non' }))
                .then(() => {
                    dispatch(getAllCharges());
                })
                .catch((error) => {
                    console.error('Error rejecting charge:', error);
                });
            setSelectedItem(null);
        }
        closeValideModal();
    };

    const handleDelete = async () => {
        if (selectedCharge) {
            try {
                await dispatch(deleteCharge(selectedCharge.id))
                    .then(() => {
                        dispatch(getAllCharges());
                    });
                setSnackbarMessage("Charge deleted successfully.");
            } catch (error) {
                setSnackbarMessage("Error deleting charge.");
                console.error("Error deleting charge:", error);
            }
            setSnackbarOpen(true);
            setSelectedCharge(null);
        }
        closeDeleteModal();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const CustomButton = () => {

        return (
            <>
                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Gérer les charges")) && (
                    <IconButton
                        color="info"
                        component={Link}
                        to={`/charges/ajouter`}
                        sx={{ fontSize: '0.75rem !important', fontWeight: 'bold' }}
                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-knwngq-MuiButtonBase-root-MuiButton-root"
                    >
                        <ControlPoint sx={{ mr: '7px' }} />
                        AJOUTER
                    </IconButton>
                )}
            </>
        );
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <CustomButton style={{ marginRight: '2rem' }} />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    if (!Array.isArray(charges)) {
        charges = [];
    }

    const filteredCharges = charges?.filter(
        (charge) =>
            charge.designation.toLowerCase().includes(searchInput.toLowerCase()) ||
            formatDateForMonthDisplay(charge.mois).includes(searchInput.toLowerCase()) ||
            charge.cout.toString().includes(searchInput.toLowerCase())
    )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const columns = [
        {
            field: 'designation', headerName: 'Designation',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'mois', headerName: 'Mois',
            ...(isMobile ? { width: 100 } : { flex: 1 }),
            valueFormatter: (params) => formatDateForMonthDisplay(params.value)
        },
        {
            field: 'cout', headerName: 'Coût',
            ...(isMobile ? { width: 100 } : { flex: 1 })
        },
        {
            field: 'user?.nom', headerName: 'Personnel',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.user?.nom || '',
        },
        {
            field: 'status',
            headerName: 'Statut',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.status || '-',
            renderCell: (params) => {
                let statusLabel, statusColor;

                switch (params.row.status) {
                    case 'en cours':
                        statusLabel = 'En cours';
                        statusColor = 'orange';
                        break;
                    case 'oui':
                        statusLabel = 'Validée';
                        statusColor = 'green';
                        break;
                    case 'non':
                        statusLabel = 'Rejetée';
                        statusColor = 'red';
                        break;
                    default:
                        statusLabel = '-';
                        statusColor = 'black';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statusLabel}</span>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les charges")) && (
                        <IconButton component={Link} to={`/charges/modifier/${params.id}`} variant="contained" color="info" size="small">
                            <BorderColorIcon fontSize="medium" />
                        </IconButton>
                    )}
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les charges")) && (
                        <IconButton
                            variant="contained"
                            color="error"
                            onClick={() => openDeleteModal(params.row)}
                            style={{ marginLeft: "8px" }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les charges")) && (
                        <>
                            {params.row.status === 'en cours' && (
                                <IconButton
                                    variant="contained"
                                    color="success"
                                    onClick={() => openValideModal(params.row)}
                                    style={{ marginLeft: "8px" }}
                                >
                                    <DoneOutlineIcon />
                                </IconButton>
                            )}
                        </>
                    )}
                    <IconButton
                        variant="contained"
                        color="warning"
                        onClick={() => openDialog(params.row)}
                        style={{ marginLeft: "8px" }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </>
            ),
            flex: 3,
            cellClassName: 'actions-cell',
        },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Consulter les charges")
            ) ? (
                <>
                    <TextField
                        label="Recherche par désignation, mois ou coût"
                        value={searchInput}
                        fullWidth
                        onChange={(e) => setSearchInput(e.target.value)}
                        variant="standard"
                        margin="normal"
                        sx={{
                            marginBottom: '0.5rem',
                        }}
                    />
                    {charges.length === 0 ? (
                        <>
                            <Typography variant="body1">Aucune charges disponible.</Typography>
                            <Link to={`/charges/ajouter`} className="text-decoration-none float-end">
                                <Button variant="contained" color="success" >
                                    <ControlPointIcon sx={{ mr: "10px" }} />
                                    Ajouter
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <Box
                            m="0 0 0 0"
                            height="90vh"
                        >
                            <DataGrid
                                rows={filteredCharges}
                                columns={columns}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                className={darkMode ? "text-light" : "text-dark"}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                autoHeight
                            />
                        </Box>
                    )}

                </>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
                {selectedItem && (
                    <>
                        <DialogTitle
                            sx={{
                                textAlign: 'center',

                            }}
                        >
                            Aperçu du charge
                        </DialogTitle>
                        <DialogContent>
                            <ChragesPreview charge={selectedItem} />
                            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers")) && (
                                <Paper sx={{ padding: 2 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>Fichiers:</Typography>
                                    <ChargeFiles chargeId={selectedItem?.id} />
                                </Paper>
                            )}
                            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Importer les fichiers")) && (
                                <Paper sx={{ padding: 2, marginTop: '1rem' }}>
                                    <Typography variant="h6" color="primary" gutterBottom>Importer:</Typography>
                                    <FileUpload chargeId={selectedItem.id} />
                                </Paper>
                            )}
                            <div className='mt-4 d-flex float-end'>
                                <Button
                                    className="ms-2 btn-sm"
                                    onClick={closeDialog}
                                >
                                    Fermer
                                </Button>
                            </div>
                        </DialogContent>
                    </>
                )}
            </Dialog>
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle>Confirmation de la suppression</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Etes-vous sûr de vouloir supprimer ce Charge?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal}>Annuler</Button>
                    <Button onClick={handleDelete} sx={{ color: 'red !important' }} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isModalValiderOpen} onClose={closeValideModal}>
                <DialogTitle className={darkMode ? "text-light" : "text-dark"}>
                    Confirmation de la Charge
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: darkMode ? "#fff" : "#000" }}>
                        Êtes-vous sûr de vouloir confirmer ou rejeter ce Charge ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeValideModal}>
                        Annuler
                    </MDButton>
                    <MDButton color='success' className="btn btn-sm" onClick={handleValider}>
                        Valider
                    </MDButton>
                    <MDButton color='error' className="btn btn-sm" onClick={handleRejeter}>
                        Rejeter
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default Charges;
