import React, { useEffect, useState } from 'react';
import ProjectService from '../../services/projetServices';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const ProjectsAmountByPaiement = () => {
  const [projectsAmountByPaiement, setProjectsAmountByPaiement] = useState([]);

  useEffect(() => {
    fetchProjectsAmountByPaiement();
  }, []);

  const fetchProjectsAmountByPaiement = async () => {
    try {
      const response = await ProjectService.getTotalProjectsAmountByPaiement();
      setProjectsAmountByPaiement(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const chartData = projectsAmountByPaiement.map((item) => ({
    id: item.paiement || 'null',
    value: parseFloat(item.totalAmount),
  }));

  // Define custom colors for the pie chart
  const colors = ['#8884d8', '#82ca9d', '#ffc658'];

  return (
    <DashboardLayout>
        <DashboardNavbar />
      <Typography variant="h3" textAlign={'center'} gutterBottom>
        Total Projects Amount By Paiement
      </Typography>
      <div style={{ height: 300 }}>
      <ResponsivePie
        data={chartData}
        id="id"
        value="value"
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={colors}
        enableRadialLabels={false}
        enableSliceLabels={true}
        sliceLabel={(e) => `${e.id}: ${e.value}`}
      />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ display: 'table-header-group' }}>
            <TableRow>
              <TableCell>Paiement</TableCell>
              <TableCell>Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectsAmountByPaiement.map((item) => (
              <TableRow key={item.paiement}>
                <TableCell>{item.paiement}</TableCell>
                <TableCell>{item.totalAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
};

export default ProjectsAmountByPaiement;
