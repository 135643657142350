export default {
    nonImposable: {
        names: [
            "Prime d’outillage",
            "Prime de salissure",
            "Prime d’usure de vêtements",
            "Prime de panier",
            "Repas servis en ramadan",
            "Bons ou chèques de restauration ou alimentation",
            "Indemnité de lait",
            "Postes téléphoniques",
            "Indemnité d’utilisation de véhicule personnel",
            "Indemnité de déménagement",
            "Indemnité de caisse",
            "Indemnité de licenciement",
            "Dispense ou réduction intérêts",
            "L’indemnité ou frais de déplacement sur justificatifs",
            "L’indemnité ou frais de déplacement forfaitaire",
            "L’indemnité de transport",
            "Indemnité kilométrique pour utilisation voiture personnelle",
            "La prime de tournée",
            "La prime de voyage en congé au pays d’origine (étrangers)",
            "L’aide médicale",
            "Gratifications à titre social",
            "Allocation versée à un enfant du travailleur",
            "L’indemnité de stage formation insertion",
            "L’allocation d’apprentissage",
            "L’indemnité de représentation",
            "L’allocation de rentrée école",
            "Prime voyage à la Mecque",
            "Primes jouets Achoura",
            "Primes achat mouton",
            "Frais portuaires (bons bleus)",
            "Frais portuaires (manœuvres du port)",
            "Jetons de présence : administrateurs",
            "Dons",
            "Œuvres sociales",
        ],
        notes: [ 
            "100,00 dh par mois",
            "200,00 dh par mois",
            "100,00 dh par mois",
            "20 dh par jour",
            "20 dh par jour",
            "10 dh par jour et 20% du salaire brut",
            "150 dh par mois",
            "50% du montant",
            "150 dh par mois",
            "10 dh x nombre km",
            "150 dh par mois",
            "de 0 à 5 ans inclus: 96 h, de 6 à 10 :144 h, de 11 à 15 : 192 h et de 16 et plus : 240 h de salaires",
            "Pas de limite",
            "Déplacement occasionnel",
            "Déplac. dirigeant ou commercial",
            "500 dh urbain et 750 dh hors ville",
            "Dépend de la qualité du bénéficiaire avec ordre de mission",
            "1.500 dh par mois",
            "billet aller-retour",
            "1.000 dh par an",
            "5.000 dh une fois tous les 4 ans",
            "SMIG et maximum 2 mois par an",
            "min : 1.600 dh max : 4.500 dh",
            "Non définie",
            "Maximum 10% salaire de base",
            "400 dh par enfant et maximum 1.600 par salarié",
            "Maxi Billet avion aller - retour",
            "150 dh par enfant max 750 dh",
            "2.000 dh par salarié",
            "bons bleus",
            "manœuvres du port",
            "administrateurs",
            "max 100,00 par personne",
            "1% salaires",
        ],
    },
};
