import  API_URL  from './apiUrl';

class NatureService {
  createNature(natureData) {
    return API_URL.post("/nature/create/", natureData);
  }

  getAllNatures() {
    return API_URL.get("/nature");
  }

  updateNature(natureId, updatedNature) {
    return API_URL.put(`/nature/update/${natureId}`, updatedNature);
  }

  fetchNature(natureId) {
    return API_URL.get(`/nature/${natureId}`);
  }
  deleteNature(natureId) {
    return API_URL.delete(`/nature/delete/${natureId}`);
  }
}

export default new NatureService();
