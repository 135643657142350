import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createPieces } from '../../actions/PieceActions';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Grid,
} from '@mui/material';

const AddPieces = ({ currentUser, createPieces, success }) => {
  const [piece, setPiece] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const natureData = {
      piece,
    };

    createPieces(natureData);

    setPiece('');
    setShowAlert(true);
  };

  const handleChange = (e) => {
    setPiece(e.target.value);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des pièces")) ? (
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Typography variant="h2" align="center">Ajouter un Pièce</Typography>
          {showAlert && (
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
              <Alert onClose={handleCloseAlert} severity="success" sx={{ mb: 2 }}>
                Pièce créée avec succès !
              </Alert>
            </Snackbar>
          )}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center" mt={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Pièce"
                  name="piece"
                  value={piece}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" sx={{color: '#fff !important'}} type="submit">
                  Sauvegarder
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <Typography variant="body1" align="center">Erreur</Typography>
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    success: state.nature.success,
  };
};

export default connect(mapStateToProps, { createPieces })(AddPieces);
