import OperationService from '../services/operationsServices';
import {
    CREATE_OPERATION_SUCCESS,
    CREATE_OPERATION_FAILURE,
    UPDATE_OPERATION_SUCCESS,
    UPDATE_OPERATION_FAILURE,
    GET_OPERATION_SUCCESS,
    GET_OPERATION_FAILURE,
    DELETE_OPERATION_SUCCESS,
    DELETE_OPERATION_FAILURE,
    GET_ALL_OPERATIONS_SUCCESS,
    GET_ALL_OPERATIONS_FAILURE,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE,
    GET_FILES_SUCCESS,
    GET_FILES_FAILURE,
} from './types';

export const createOperation = (oprationData) => {
    return (dispatch) => {
        return OperationService.createOperation(oprationData)
            .then((response) => {
                dispatch({
                    type: CREATE_OPERATION_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_OPERATION_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const getAllOperations = () => {
    return (dispatch) => {
        return OperationService.getAllOperations()
            .then((response) => {
                dispatch({
                    type: GET_ALL_OPERATIONS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_OPERATIONS_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const updateOperation = (oprationId, updatedOperation) => {
    return (dispatch) => {
        return OperationService.updateOperation(oprationId, updatedOperation)
            .then((response) => {
                dispatch({
                    type: UPDATE_OPERATION_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_OPERATION_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getOperation = (oprationId) => {
    return (dispatch) => {
        return OperationService.fetchOperation(oprationId)
            .then((response) => {
                dispatch({
                    type: GET_OPERATION_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_OPERATION_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const deleteOperation = (oprationId) => {
    return (dispatch) => {
        return OperationService.deleteOperation(oprationId)
            .then((response) => {
                dispatch({
                    type: DELETE_OPERATION_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_OPERATION_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const uploadFiles = (operationId, formData) => {
    return async (dispatch) => {
        try {
            const response = await OperationService.uploadFilesToOperation(operationId, formData);
            dispatch({
                type: UPLOAD_FILES_SUCCESS,
                payload: response.data,
            });
            return response;
        } catch (error) {
            dispatch({
                type: UPLOAD_FILES_FAILURE,
                payload: error.response ? error.response.data : 'An error occurred',
            });
            throw error;
        }
    };
};

export const fetchOperationFiles = (operationId) => {
    return (dispatch) => {
        return OperationService.getAllOperationFiles(operationId)
            .then((response) => {
                dispatch({
                    type: GET_FILES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_FILES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchFileContent = (operationId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await OperationService.getFileContent(operationId, fileName);
            return response;
        } catch (error) {
            console.error('Error fetching file content:', error);
            throw error;
        }
    };
};

export const downloadFile = (operationId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await OperationService.downloadFile(operationId, fileName);

            if (response.data instanceof Blob) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            } else {
                console.error('Invalid file content in the response.');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
};

export const deleteFile = (operationId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await OperationService.deleteFile(operationId, fileName);
            return response;
        } catch (error) {
            console.error('Error deleting file:', error);
            throw error;
        }
    };
};

