import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClients } from '../../actions/ClientActions';
import { Snackbar, Alert, TextField, Button, Grid, Typography, FormControl, InputLabel, MenuItem, Select as MuiSelect, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';
import { createDevis } from "../../actions/DevisActions";

const CreateDevis = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [date, setDate] = useState('');
    const [objet, setObjet] = useState('');
    const [selectedClient, setSelectedClient] = useState(null);
    const [items, setItems] = useState([]);
    const [itemName, setItemName] = useState('');
    const [itemUnite, setItemUnite] = useState('');
    const [itemPriceUnit, setItemPriceUnit] = useState('');
    const [itemQuantity, setItemQuantity] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const clients = useSelector((state) => state.clients);

    useEffect(() => {
        dispatch(getAllClients());
    }, [dispatch]);


    const handleAddItem = () => {
        if (!itemName || !itemQuantity || !itemUnite || !itemPriceUnit) return;

        const newItem = {
            name: itemName,
            unite: itemUnite,
            prix_unitaire: parseFloat(itemPriceUnit),
            price: parseFloat(itemPriceUnit) * parseFloat(itemQuantity),
            quantity: parseFloat(itemQuantity),
        };

        setItems((prevItems) => [...prevItems, newItem]);
        setItemName('');
        setItemUnite('');
        setItemPriceUnit('');
        setItemQuantity('');
    };

    const clientOptions = clients.map((client) => ({
        id: client.id,
        value: client.id,
        label: client.full_name,
    }));

    // const clientOptions = clients.map((client) => ({
    //     value: client.id,
    //     label: client.full_name,
    // }));

    const handleRemoveItem = (index) => {
        setItems((prevItems) => prevItems.filter((item, i) => i !== index));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const Data = {
            date,
            objet,
            clientId: selectedClient.value,
            items,
        };

        dispatch(createDevis(Data))
            .then(() => {
                openSnackbar('Devis créée avec succès', 'success');
                setDate('');
                setObjet('');
                setSelectedClient(null);
                setItems([]);
                setItemName('');
                setItemUnite('');
                setItemPriceUnit('');
                setItemQuantity('');
            })
            .catch((error) => {
                openSnackbar(error?.response?.data?.message);
                console.log('Error creating facture:', error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'itemName':
                setItemName(value);
                break;
            case 'itemPriceUnit':
                setItemPriceUnit(value);
                break;
            case 'itemUnite':
                setItemUnite(value);
                break;
            case 'itemQuantity':
                setItemQuantity(value);
                break;
            default:
                break;
        }
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity || 'success');
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const calculateTotalPrice = () => {
        let total = 0;
        items.forEach((item) => {
            total += item.prix_unitaire * item.quantity;
        });

        return total.toFixed(2);
    };

    const calculateTotalTTC = () => {
        const totalHT = calculateTotalPrice();
        const totalTTC = totalHT * 1.2;

        return totalTTC.toFixed(2);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.permissions.includes('Ajouter des factures')) ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: '36px',
                                textAlign: 'center',
                                marginTop: '12px',
                                fontWeight: 'bold',
                                color: '#3e4396',
                                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                            }}
                            variant="h4"
                            align="center"
                        >Créer un devis</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField
                                label="Date"
                                type="date"
                                name="date"
                                variant="standard"
                                margin="normal"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={clientOptions}
                                value={selectedClient}
                                onChange={(event, newValue) => setSelectedClient(newValue)}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.id === value.id} // Compare IDs
                                filterOptions={(options, { inputValue }) =>
                                    options.filter((option) =>
                                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                                    )
                                }
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Client"
                                        fullWidth
                                        variant="standard"
                                        margin="normal"
                                    />
                                )}
                            />
                            {/* <Autocomplete
                                options={clientOptions}
                                value={selectedClient}
                                onChange={(event, newValue) => setSelectedClient(newValue)}
                                getOptionLabel={(option) => option.label}
                                isSearchable
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Client"
                                        fullWidth
                                        variant="standard"
                                        margin="normal"
                                    />
                                )}
                            /> */}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                label="Objet"
                                type="text"
                                name="objet"
                                margin="normal"
                                variant="standard"
                                value={objet}
                                onChange={(e) => setObjet(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                margin: '12px',
                            }}
                            variant="h5"
                        >
                            Ajouter des désignations
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead style={{ display: 'table-header-group' }}>
                                    <TableRow>
                                        <TableCell>N°</TableCell>
                                        <TableCell>Designation</TableCell>
                                        <TableCell>Unite</TableCell>
                                        <TableCell>Prix Unitaire</TableCell>
                                        <TableCell>Quantité</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map(({ name, unite, prix_unitaire, quantity, price }, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{name}</TableCell>
                                            <TableCell>{unite}</TableCell>
                                            <TableCell>{prix_unitaire}</TableCell>
                                            <TableCell>{quantity}</TableCell>
                                            <TableCell>{prix_unitaire * quantity}</TableCell>
                                            <TableCell>
                                                <MDButton
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => handleRemoveItem(index)}
                                                >
                                                    Supprimer
                                                </MDButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box sx={{ margin: '1rem' }}>
                                <Typography variant="h6" align="right">Total HT: {calculateTotalPrice()}</Typography>
                                <Typography variant="h6" align="right">Total TTC: {calculateTotalTTC()}</Typography>
                            </Box>
                        </TableContainer>
                        <Grid container spacing={2} marginTop="8px" alignItems="center">
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="itemName"
                                    value={itemName}
                                    onChange={handleChange}
                                    label="Désignation"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="itemUnite"
                                    value={itemUnite}
                                    onChange={handleChange}
                                    label="Unité"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    name="itemQuantity"
                                    value={itemQuantity}
                                    onChange={handleChange}
                                    label="Quantité"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    name="itemPriceUnit"
                                    value={itemPriceUnit}
                                    onChange={handleChange}
                                    label="Prix Unitaire"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MDButton
                                    variant="contained"
                                    color="success"
                                    onClick={handleAddItem}
                                    fullWidth
                                >
                                    Ajouter
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <MDButton
                            variant="contained"
                            color="info"
                            onClick={handleSubmit}
                            fullWidth
                        >
                            créer le devis
                        </MDButton>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert variant='filled' onClose={closeSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default CreateDevis;

// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Autocomplete from '@mui/material/Autocomplete';
// import {
//     Container,
//     Typography,
//     TextField,
//     Button,
//     MenuItem,
//     Box,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Snackbar,
//     Alert,
//     Paper,
// } from "@mui/material";
// import { createDevis } from "../../actions/DevisActions";
// import { getAllClients } from "../../actions/ClientActions";
// import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
// import MDButton from "../../components/MDButton";
// import { useMaterialUIController } from "../../context";

// const CreateDevis = ({ currentUser }) => {
//     const [controller] = useMaterialUIController();
//     const { darkMode } = controller;
//     const dispatch = useDispatch();
//     const [date, setDate] = useState("");
//     const [objet, setObjet] = useState("");
//     const [selectedClient, setSelectedClient] = useState(null);
//     const [items, setItems] = useState([]);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//     const clients = useSelector((state) => state.clients);

//     useEffect(() => {
//         dispatch(getAllClients());
//     }, [dispatch]);

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const devisData = {
//             date,
//             objet,
//             clientId: selectedClient.value,
//             items,
//         };

//         dispatch(createDevis(devisData))
//             .then(() => {
//                 openSnackbar('Devis créé avec succès', 'success');
//                 setDate("");
//                 setObjet("");
//                 setSelectedClient(null);
//                 setItems([]);
//             })
//             .catch((error) => {
//                 openSnackbar('Échec de la création de Devis', 'error');
//                 console.log('Error creating devis:', error);
//             });
//     };

//     const handleAddItem = () => {
//         const newItem = {
//             name: "",
//             unite: "",
//             quantity: 0,
//             prix_unitaire: 0,
//             price: 0,
//         };
//         setItems([...items, newItem]);
//     };

//     const handleItemChange = (index, field, value) => {
//         const updatedItems = items.map((item, i) =>
//             i === index ? { ...item, [field]: value } : item
//         );

//         const updatedItem = updatedItems[index];
//         const calculatedPrice = parseFloat(updatedItem.quantity) * parseFloat(updatedItem.prix_unitaire);
//         updatedItem.price = isNaN(calculatedPrice) ? 0 : calculatedPrice;

//         setItems(updatedItems);
//     };

//     const handleRemoveItem = (index) => {
//         const updatedItems = items.filter((_, i) => i !== index);
//         setItems(updatedItems);
//     };

//     const clientOptions = clients.map((client) => ({
//         value: client.id,
//         label: client.full_name,
//     }));

//     const openSnackbar = (message, severity) => {
//         setSnackbarMessage(message);
//         setSnackbarSeverity(severity || 'success');
//         setSnackbarOpen(true);
//     };

//     const closeSnackbar = () => {
//         setSnackbarOpen(false);
//     };

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des devis")) ? (
//                 <>
//                     <Box p={3}>
//                         <Typography variant="h2" align="center" gutterBottom>
//                             Créer des devis
//                         </Typography>
//                         <form onSubmit={handleSubmit}>
//                             <TextField
//                                 type="date"
//                                 value={date}
//                                 onChange={(e) => setDate(e.target.value)}
//                                 variant="outlined"
//                                 fullWidth
//                                 margin="normal"
//                             />

//                             <TextField
//                                 label="Objet"
//                                 value={objet}
//                                 onChange={(e) => setObjet(e.target.value)}
//                                 variant="outlined"
//                                 fullWidth
//                                 margin="normal"
//                             />

//                             <Autocomplete
//                                 options={clientOptions}
//                                 value={selectedClient}
//                                 onChange={(event, newValue) => setSelectedClient(newValue)}
//                                 getOptionLabel={(option) => option.label}
//                                 isClearable
//                                 renderInput={(params) => (
//                                     <TextField
//                                         {...params}
//                                         label="Client"
//                                         variant="outlined"
//                                         fullWidth
//                                         margin="normal"
//                                         InputProps={{ ...params.InputProps, style: { width: '100%' } }}
//                                     />
//                                 )}
//                             />

//                             {items.length > 0 && (
//                                 <TableContainer component={Paper}>
//                                     <Table>
//                                         <TableHead>
//                                             <TableRow>
//                                                 <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Designation</TableCell>
//                                                 <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Unite</TableCell>
//                                                 <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Prix Unitaire</TableCell>
//                                                 <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Quantity</TableCell>
//                                                 <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Total</TableCell>
//                                                 <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Action</TableCell>
//                                             </TableRow>
//                                         </TableHead>
//                                         <TableBody>
//                                             {items.map(({ name, unite, prix_unitaire, quantity, price }, index) => (
//                                                 <TableRow key={index} >
//                                                     <TableCell component="th" scope="row">
//                                                         <TextField
//                                                             sx={{ width: '100%' }}
//                                                             value={name}
//                                                             onChange={(e) => handleItemChange(index, "name", e.target.value)}
//                                                         />
//                                                     </TableCell>
//                                                     <TableCell>
//                                                         <TextField
//                                                             sx={{ width: '100%' }}
//                                                             value={unite}
//                                                             onChange={(e) => handleItemChange(index, "unite", e.target.value)}
//                                                         />
//                                                     </TableCell>
//                                                     <TableCell>
//                                                         <TextField
//                                                             sx={{ width: '100%' }}
//                                                             type="number"
//                                                             value={prix_unitaire}
//                                                             onChange={(e) => handleItemChange(index, "prix_unitaire", e.target.value)}
//                                                         />
//                                                     </TableCell>
//                                                     <TableCell>
//                                                         <TextField
//                                                             sx={{ width: '100%' }}
//                                                             type="number"
//                                                             value={quantity}
//                                                             onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
//                                                         />
//                                                     </TableCell>
//                                                     <TableCell>{price}</TableCell>
//                                                     <TableCell>
//                                                         <MDButton
//                                                             variant="contained"
//                                                             color="error"
//                                                             onClick={() => handleRemoveItem(index)}
//                                                         >
//                                                             Remove
//                                                         </MDButton>
//                                                     </TableCell>
//                                                 </TableRow>
//                                             ))}
//                                         </TableBody>
//                                     </Table>
//                                 </TableContainer>
//                             )}

//                             <MDButton
//                                 variant="contained"
//                                 color="info"
//                                 className='mt-2'
//                                 onClick={handleAddItem}
//                             >
//                                 Ajouter un item
//                             </MDButton>
//                             <div className="float-end">
//                                 <MDButton
//                                     type="submit"
//                                     variant="contained"
//                                     color="secondary"
//                                     sx={{ mt: 3 }}
//                                 >
//                                     Create Devis
//                                 </MDButton>
//                             </div>
//                         </form>
//                     </Box>
//                 </>
//             ) : (
//                 <div className="container">
//                     <h4 className="text-center">You don't have access to this page!</h4>
//                 </div>
//             )}
//             <Snackbar
//                 anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//                 open={snackbarOpen}
//                 autoHideDuration={6000}
//                 onClose={closeSnackbar}
//             >
//                 <Alert variant="filled" onClose={closeSnackbar} severity={snackbarSeverity}>
//                     {snackbarMessage}
//                 </Alert>
//             </Snackbar>
//         </DashboardLayout>
//     );
// };

// export default CreateDevis;

