import API_URL from './apiUrl';

class PrimesService {
  createPrimes(primesData) {
    return API_URL.post("/prime/create", primesData);
  }

  AddPrimesToUser(primesData) {
    return API_URL.post("/prime/primes/user/add", primesData);
  }

  getAllPrimes() {
    return API_URL.get("/prime");
  }

  fetchUserPrimes(userId) {
    return API_URL.get(`/prime/primes/user/${userId}`);
  }

  fetchUserPrimesByMonth(userId, mois) {
    return API_URL.get(`/prime/users/user/${userId}/primes?month=${mois}`);
  }

  updatePrimes(primeId, updatedPrimes) {
    return API_URL.put(`prime/update/${primeId}`, updatedPrimes);
  }

  updateUsersPrimes(userId, updatedPrimes) {
    return API_URL.put(`/prime/users/${userId}/primes`, updatedPrimes);
  }

  fetchPrimes(primeId) {
    return API_URL.get(`/prime/${primeId}`);
  }

  deletePrimes(primeId) {
    return API_URL.delete(`/prime/delete/${primeId}`);
  }
}

export default new PrimesService();