import React from "react";
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
    Button,
    IconButton,
    Tooltip,
    Box, Grid, Paper
} from "@mui/material";
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessDenied from "../Errors/AccessDenied";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';

const SalairePreview = ({ selectedItem, user }) => {

    const formatDateForMonthDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        return `${year}-${month}`;
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="info" gutterBottom>
                            <PersonIcon fontSize="medium" sx={{ mr: 1 }} />
                            Personnel
                        </Typography>
                        <Typography>{user?.nom}&nbsp;{user?.prenom}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="info" gutterBottom>
                            <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                            Salaire NET
                        </Typography>
                        <Typography component="div" variant="h5" gutterBottom>
                            {selectedItem?.salaire_net}&nbsp;
                            <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="info" gutterBottom>
                            <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                            Salaire Brut
                        </Typography>
                        <Typography component="div" variant="h5" gutterBottom>
                            {selectedItem?.salaire_brut}&nbsp;
                            <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="info" gutterBottom>
                            <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                            Salaire Brut Immposble
                        </Typography>
                        <Typography component="div" variant="h5" gutterBottom>
                            {selectedItem?.salaire_brutimpo}&nbsp;
                            <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="info" gutterBottom>
                            <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                            Primes
                        </Typography>
                        <Typography component="div" variant="h5" gutterBottom>
                            {selectedItem?.primes}&nbsp;
                            <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="info" gutterBottom>
                            <CalendarMonthIcon fontSize="medium" sx={{ mr: 1 }} />
                            Mois
                        </Typography>
                        <Typography component="div" variant="h5" gutterBottom>
                            {formatDateForMonthDisplay(selectedItem?.mois)}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
export default SalairePreview;