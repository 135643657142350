import React, { useEffect, useState } from 'react';
import ChargeService from '../../services/chargesServices';
import { Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const TotalChargesForVehicles = () => {
  const [totalChargesForVehicles, setTotalChargesForVehicles] = useState([]);

  useEffect(() => {
    fetchTotalChargesForVehicles();
  }, []);

  const fetchTotalChargesForVehicles = async () => {
    try {
      const response = await ChargeService.getTotalChargesForVehicules();
      setTotalChargesForVehicles(response.data);
    } catch (error) {
      console.error('Error fetching total charges for vehicles:', error);
    }
  };

  const chartData = totalChargesForVehicles.map((charge) => ({
    designation: charge.designation,
    totalCharges: parseFloat(charge.totalCharges),
  }));

  return (
    <>
    <DashboardLayout>
        <DashboardNavbar />
      <Typography variant="h3" textAlign={'center'} gutterBottom>
        Total Charges for Vehicles
      </Typography>
      {totalChargesForVehicles.length === 0 ? (
        <Typography variant="body1">No data available.</Typography>
      ) : (
        <>
        <Box alignItems={'center'} style={{ height: 300 }}>
          <ResponsiveBar
            data={chartData}
            keys={['totalCharges']}
            indexBy="designation"
            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
            padding={0.3}
            colors={{ scheme: 'category10' }}
            axisBottom={{
              tickRotation: -45,
            }}
            axisLeft={{
              tickValues: 5,
              tickPadding: 5,
              tickSize: 5,
              tickRotation: 0,
              legend: 'Total Charges',
            }}
            enableGridX
            enableGridY
            labelTextColor="inherit:darker(1.2)"
            tooltip={({ id, value, color }) => (
              <strong style={{ color }}>
                {id}: {value}
              </strong>
            )}
          />
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Designation</TableCell>
                  <TableCell>Total Charges</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {totalChargesForVehicles.map((charge) => (
                  <TableRow key={charge.designation}>
                    <TableCell>{charge.designation}</TableCell>
                    <TableCell>{charge.totalCharges}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> 
        </>
      )}
      </DashboardLayout>
    </>
  );
};

export default TotalChargesForVehicles;
