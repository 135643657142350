import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRepports, fetchFileContent, sendDaliyRepport } from '../../actions/RepprotActions';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Pagination,
    Button,
    Box,
    TextField,
    Snackbar,
    Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import RepportsServices from '../../services/repportServices';

const FilePreview = ({ fileContent, fileName, onClose }) => {
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1].toLowerCase();
        }
        return '';
    };
    const handleSend = async (fileName) => {
        try {
            const response = await dispatch(sendDaliyRepport(fileName));
            const message = response.data.message;
            setSnackbarMessage(message);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error fetching file content:', error);
            setSnackbarMessage('Error sending report.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };



    const fileExtension = getFileExtension(fileName);

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isPdf = fileExtension === 'pdf';

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog
                open={true}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    File Preview
                </DialogTitle>
                <DialogContent>
                    {isImage && (
                        <>
                            <img
                                src={`data:image/${fileExtension};base64,${fileContent}`}
                                alt="Preview"
                            />
                        </>
                    )}
                    {isPdf && (
                        <>
                            <embed
                                src={`data:application/pdf;base64,${fileContent}`}
                                type="application/pdf"
                                width="100%"
                                height="500px"
                            />
                            <MDButton
                                className='btn-sm me-1 mt-2'
                                size='small'
                                color={'success'}
                                fullWidth
                                onClick={() => handleSend(fileName)}
                            >
                                Envoyer Le rapport
                            </MDButton>
                        </>
                    )}

                    {!isImage && (!isPdf) && (
                        <>
                            <p>Preview not available for this file type.</p>
                        </>
                    )}
                    <DialogActions>
                        <Button className='btn-sm ms-2 mt-2' size='small' color={'secondary'} onClick={onClose}>
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </Dialog>
        </>
    );
};

const RepportsList = () => {
    const dispatch = useDispatch();
    const files = useSelector((state) => state.files.files);
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [note, setNote] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedStart, setSelectedStart] = useState('');
    const [selctedEnd, setSelectedEnd] = useState('');
    useEffect(() => {
        dispatch(fetchRepports());
    }, [dispatch]);

    const handleViewFile = async (fileName) => {
        try {
            const response = await dispatch(fetchFileContent(fileName));
            setSelectedFile({
                fileName,
                fileContent: response.data,
            });
        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    };

    const handleCloseFilePreview = () => {
        setSelectedFile(null);
    };

    const filteredFiles = files.filter((file) =>
        file.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredFiles.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleGenerateDailyReport = async () => {
        try {
            if (selectedStart && selctedEnd) {
                console.log(note);
                const response = await RepportsServices.generateDailyRepport(selectedStart, selctedEnd, { note });
                const message = response.data.message;
                dispatch(fetchRepports());
                setSnackbarMessage(message);
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error generating daily report:', error);
            setSnackbarMessage('Error generating daily report.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    return (
        <div>
            <Box>
                <TextField
                    fullWidth
                    type="date"
                    label="depuis"
                    margin='normal'
                    onChange={(e) => setSelectedStart(e.target.value)}
                    value={selectedStart}
                    sx={{ gridColumn: 'span 2', marginBottom: 'mb-2' }}
                />
                <TextField
                    fullWidth
                    type="date"
                    label="à"
                    margin='normal'
                    onChange={(e) => setSelectedEnd(e.target.value)}
                    value={selctedEnd}
                    sx={{ gridColumn: 'span 2', marginBottom: 'mb-2' }}
                />
                <TextField
                    multiline
                    fullWidth
                    rows={4}
                    type="text"
                    label="Remarques"
                    margin='normal'
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    sx={{ gridColumn: 'span 2', marginBottom: 'mb-2' }}
                />
                <Button color={'info'}
                    sx={{
                        backgroundColor: "#4caf50",
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        margin: "16px",
                    }}
                    onClick={handleGenerateDailyReport}>
                    Générer le rapport
                </Button>
            </Box>
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Rechercher un rapport"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                sx={{ gridColumn: 'span 2', marginBottom: 'mb-2' }}
            />
            {currentItems.length > 0 ? (
                <List>
                    {currentItems.map((file, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                    cursor: 'pointer',
                                },
                                padding: 2,
                                borderRadius: 2,
                            }}
                        >
                            <ListItemText primary={file} />
                            <MDButton
                                variant="contained"
                                className="btn-sm mt-2"
                                size="small"
                                color="primary"
                                onClick={() => handleViewFile(file)}
                            >
                                View
                            </MDButton>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <p>No files found.</p>
            )}
            {filteredFiles.length > itemsPerPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 2 }}>
                    <Pagination
                        count={Math.ceil(filteredFiles.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            )}
            {selectedFile && (
                <FilePreview
                    fileContent={selectedFile.fileContent}
                    fileName={selectedFile.fileName}
                    onClose={handleCloseFilePreview}
                />
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </div>
    );
};

export default RepportsList;
