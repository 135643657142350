import Dashboard from "./layouts/dashboard";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapOfProjects from "./pages/projet/MapOfProjects";
import MaterialsTables from "./pages/tables/Materials";
import FinanceTables from "./pages/tables/Finance";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import Statistics from "./pages/tables/Statistics";
import ProjectList from "./pages/projet/Projects";
import Clients from "./pages/clients/Clients";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HumainsTables from "./pages/tables/RessourcesHumaines";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Charges from "./pages/charges/Charges";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import OperationsList from "./pages/operations/Operations";
import AssignmentIcon from '@mui/icons-material/Assignment';
import TasksList from "./pages/taches/Taches";
const routes = (userRoles, userPermissions) => [
  {
    type: "collapse",
    name: "Tableau de bord",
    key: "dashboard",
    icon: <DashboardIcon fontSize="small"></DashboardIcon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  ...((userRoles.includes("ROLE_ADMIN") ||
    userPermissions.includes("Consulter des clients")
  ) ? [
    {
      type: "collapse",
      name: "Clients",
      key: "clients",
      icon: <SupervisorAccountIcon fontSize="small"></SupervisorAccountIcon>,
      route: "/clients",
      component: <Clients />,
    },
  ] : []),
  ...((userRoles.includes("ROLE_ADMIN") ||
    userPermissions.includes("Consulter les projets")
  ) ? [
    {
      type: "collapse",
      name: "Projets",
      key: "projects",
      icon: <FolderCopyIcon fontSize="small"></FolderCopyIcon>,
      route: "/projects",
      component: <ProjectList />,
    },
  ] : []),
  {
    type: "collapse",
    name: "Historique des tâches",
    key: "projects/history/tasks",
    icon: <AssignmentIcon fontSize="small"></AssignmentIcon>,
    route: "/projects/history/tasks",
    component: <TasksList />,
  },
  ...((userRoles.includes("ROLE_ADMIN")
    || userPermissions.includes("Consulter les charges")
  ) ? [
    {
      type: "collapse",
      name: "Charges",
      key: "charges",
      icon: <RequestQuoteIcon fontSize="small"></RequestQuoteIcon>,
      route: "/charges",
      component: <Charges />,
    },
  ] : []),
  ...((userRoles.includes("ROLE_ADMIN") ||
    userPermissions.includes("Consulter les salaires") ||
    userPermissions.includes("Consulter les devis") ||
    userPermissions.includes("Consulter les factures") ||
    userPermissions.includes("Consulter les encaissements"))
    || userPermissions.includes("Consulter les charges")
    ? [
      {
        type: "collapse",
        name: "Finances",
        key: "finances",
        icon: <AttachMoneyIcon fontSize="small"></AttachMoneyIcon>,
        route: "/finances",
        component: <FinanceTables />,
      },
    ] : []),
  ...((userRoles.includes("ROLE_ADMIN") || userPermissions.includes("Consulter les opérations")) ? [
    {
      type: "collapse",
      name: "Opération Bancaire",
      key: "operations-bancaire",
      icon: <CurrencyExchangeIcon fontSize="small"></CurrencyExchangeIcon>,
      route: "/operations-bancaire",
      component: <OperationsList />,
    },
  ] : []),
  ...((userRoles.includes("ROLE_ADMIN") ||
    userPermissions.includes("Consulter les personnel") ||
    userPermissions.includes("Consulter les congés") ||
    userPermissions.includes("Consulter les stagiaires")) ? [
    {
      type: "collapse",
      name: "Ressources Humaines",
      key: "ressources-humaines",
      icon: <PeopleAltIcon fontSize="small"></PeopleAltIcon>,
      route: "/ressources-humaines",
      component: <HumainsTables />,
    },
  ] : []),
  ...((userRoles.includes("ROLE_ADMIN") ||
    userPermissions.includes("Consulter les matériels") ||
    userPermissions.includes("Consulter les véhicules")
  ) ? [
    {
      type: "collapse",
      name: "Moyens Materiels",
      key: "moyens-materiels",
      icon: <HomeRepairServiceIcon fontSize="small"></HomeRepairServiceIcon>,
      route: "/moyens-materiels",
      component: <MaterialsTables />,
    },
  ] : []),
  ...((userRoles.includes("ROLE_ADMIN") ||
    userPermissions.includes("Consulter les analyses et les statistiques")
  ) ? [
    {
      type: "collapse",
      name: "Analyses & statistiques",
      key: "statistics",
      icon: <DonutSmallIcon fontSize="small"></DonutSmallIcon>,
      route: "/statistics",
      component: <Statistics />,
    },
  ] : []),
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <NotificationsActiveIcon fontSize="small" />,
    route: "/notifications",
    component: <MapOfProjects />,
  },
  {
    type: "collapse",
    name: "Mapping",
    key: "projects/locations",
    icon: <LocationOnIcon fontSize="small" />,
    route: "/projects/locations",
    component: <MapOfProjects />,
  },

];

export default routes;
