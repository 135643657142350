import TauxService from '../services/tauxServices';
import {
    CREATE_TAUX_SUCCESS,
    CREATE_TAUX_FAILURE,
    UPDATE_TAUX_SUCCESS,
    UPDATE_TAUX_FAILURE,
    GET_TAUX_SUCCESS,
    GET_TAUX_FAILURE,
    DELETE_TAUX_SUCCESS,
    DELETE_TAUX_FAILURE,
    GET_ALL_TAUXS_SUCCESS,
    GET_ALL_TAUXS_FAILURE,
} from './types';

export const createTaux = (tauxData) => {
    return (dispatch) => {
        return TauxService.createTaux(tauxData)
            .then((response) => {
                dispatch({
                    type: CREATE_TAUX_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_TAUX_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getAllTaux = () => {
    return (dispatch) => {
        return TauxService.getAllTaux()
            .then((response) => {
                dispatch({
                    type: GET_ALL_TAUXS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_TAUXS_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const updateTaux = (tauxId, updatedTaux) => {
    return (dispatch) => {
        return TauxService.updateTaux(tauxId, updatedTaux)
            .then((response) => {
                dispatch({
                    type: UPDATE_TAUX_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_TAUX_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getTaux = (tauxId) => {
    return (dispatch) => {
        return TauxService.fetchTaux(tauxId)
            .then((response) => {
                dispatch({
                    type: GET_TAUX_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_TAUX_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const deleteTaux = (tauxId) => {
    return (dispatch) => {
        return TauxService.deleteTaux(tauxId)
            .then((response) => {
                dispatch({
                    type: DELETE_TAUX_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_TAUX_FAILURE,
                    payload: error.message,
                });
            });
    };
};

