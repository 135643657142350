import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateRequest } from "../../actions/RequestActions";
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Box,
    Snackbar,
    Autocomplete,
} from "@mui/material";
import { Alert } from "@mui/material";

const CreateUserRequest = ({ currentUser, userId }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState("");
    const [mois, setMois] = useState("");
    const [note, setNote] = useState("");
    const [cout, setCout] = useState("");
    const [modePaiement, setModePaiement] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    let status = '';

    if (currentUser && currentUser.roles.includes("ROLE_ADMIN")) {
        status = 'oui';
    } else {
        status = 'en cours';
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const Data = {
            mois,
            prix: cout,
            note,
            userId: userId,
            status: status,
            modePaiement: modePaiement,
        };

        try {
            const response = await dispatch(CreateRequest(Data));
            setSnackbarMessage("Advance ajouté avec succès.");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setType("");
            setMois("");
            setNote("");
            setCout("");
        } catch (error) {
            console.error("Error creating Request:", error.response.data.message);
            setSnackbarMessage(error.response.data.message);
            setSnackbarSeverity("error");
            setOpenSnackbar(true);

            console.error("Error creating Request:", error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Box p={3}>
                <form onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            label="Mois"
                            type="month"
                            value={mois}
                            onChange={(e) => setMois(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                        />
                        <TextField
                            label="Cout"
                            type="number"
                            value={cout}
                            onChange={(e) => setCout(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            select
                            fullWidth
                            label='Mode Paiement'
                            value={modePaiement}
                            onChange={(e) => setModePaiement(e.target.value)}
                        >
                            <MenuItem value="" disabled>Select ...</MenuItem>
                            <MenuItem value="Espèces">Espèces</MenuItem>
                            <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                            <MenuItem value="Chèques">Chèques</MenuItem>
                        </TextField>
                        <TextField
                            fullWidth
                            label="Note"
                            multiline
                            rows={5}
                            variant="outlined"
                            margin="normal"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                mt: 3,
                                color: '#fff !important',
                            }}
                        >
                            Ajouter
                        </Button>
                    </div>

                </form>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{ mt: 2 }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
};

export default CreateUserRequest;
