import RoleService from '../services/rolesServices';
import {
    GET_ALL_ROLES_SUCCESS,
    GET_ALL_ROLES_FAILURE,
} from './types';

export const getAllRoles = () => {
    return (dispatch) => {
      return RoleService.getAllRoles()
        .then((response) => {
          dispatch({
            type: GET_ALL_ROLES_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_ALL_ROLES_FAILURE,
            payload: error.message,
          });
        });
    };
  };