import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAvance, getAvance } from '../../actions/EncaissementActions';
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Button,
    Snackbar,
    Alert
} from '@mui/material';
import { useParams } from "react-router-dom";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import { decodeId } from '../../actions/Crypte';

const UpdateAvance = ({ currentUser }) => {
    const { id } = useParams();
    const avanceId = decodeId(id);
    const dispatch = useDispatch();
    const avance = useSelector((state) => state.encaissement.encaissement);

    const [updatedAvance, setUpdatedAvance] = useState({
        prix: '',
        type: '',
        date: '',
        status: '',
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getAvance(avanceId));
    }, [dispatch, avanceId]);

    useEffect(() => {
        if (avance) {
            setUpdatedAvance({
                prix: avance.prix,
                type: avance.type || '',
                date: avance.date || '',
                status: avance.status || '',
            });
        }
    }, [avance]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedAvance({
            ...updatedAvance,
            [name]: value,
        });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateAvance(avanceId, updatedAvance))
            .then(() => {
                setSnackbarMessage('Avance a été mis à jour avec succès.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarMessage(error.response.data.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('Error updating avance:', error);
            });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Modifier les encaissements")) ? (
                <Container maxWidth="sm">
                    <Typography variant="h3" textAlign={'center'} gutterBottom>
                        Modifier l'avance de :&nbsp;
                        {avance?.projet?.numero}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Prix"
                            name="prix"
                            type="text"
                            value={updatedAvance.prix}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            margin="normal"
                        />
                        <TextField
                            select
                            label='Mode de paiement'
                            name="type"
                            value={updatedAvance.type}
                            onChange={handleChange}
                            required
                            fullWidth
                        >
                            <MenuItem value="" disabled>
                                Sélectionnez le mode
                            </MenuItem>
                            <MenuItem value="Espèces">Espèces</MenuItem>
                            <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                            <MenuItem value="Chèques">Chèques</MenuItem>
                        </TextField>
                        <TextField
                            fullWidth
                            label="Date"
                            name="date"
                            type="date"
                            value={updatedAvance.date}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                            || currentUser.permissions.includes("Valider les encaissements")) &&
                            <TextField
                                select
                                label='Validation'
                                name="status"
                                value={updatedAvance.status}
                                onChange={handleChange}
                                required
                                fullWidth
                            >
                                <MenuItem value="" disabled>
                                    Sélectionnez statut
                                </MenuItem>
                                <MenuItem value="oui">Valider</MenuItem>
                                <MenuItem value="non">Rejeter</MenuItem>
                                <MenuItem value="en cours">En cours</MenuItem>
                            </TextField>
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                color: '#fff !important',
                                marginTop: '12px',
                            }}
                        // fullWidth
                        >
                            Modifier
                        </Button>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant='filled' severity={snackbarSeverity} onClose={handleCloseSnackbar}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </DashboardLayout>
    );
};

export default UpdateAvance;
