import {
    GET_FILES_SUCCESS,
    GET_FILES_FAILURE,
} from "./types";


import RepportsServices from "../services/repportServices";

export const fetchRepports = () => {
    return (dispatch) => {
        return RepportsServices.getRepportsFiles()
            .then((response) => {
                dispatch({
                    type: GET_FILES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_FILES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchFileContent = (fileName) => {
    return async (dispatch) => {
        try {
            const response = await RepportsServices.getFileContent(fileName);
            return response;
        } catch (error) {
            console.error('Error fetching file content:', error);
            throw error;
        }
    };
};

export const sendDaliyRepport = (fileName) => {
    console.log('fileName',fileName);
    return async (dispatch) => {
        try {
            const response = await RepportsServices.sendDailyRepport(fileName);
            return response;
        } catch (error) {
            console.error('Error fetching file content:', error);
            throw error;
        }
    };
};