import React from 'react';
import { Typography, Grid, Paper, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import HandymanIcon from '@mui/icons-material/Handyman';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import CameraRearIcon from '@mui/icons-material/CameraRear';

const AnimatedCard = styled(Paper)({
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#45a049',
  },
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  '@media (max-width:600px)': {
    width: '100%',
  },
});

const MainContainer = styled('div')({
  padding: '20px',
});

const CardContent = styled('div')({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const styles = {
  title: {
    fontSize: '1.5rem',
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: '10px',
    color: '#fff',
  },
  description: {
    textAlign: 'center',
    fontSize: '1rem',
    color: '#eee',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
    marginTop: '10px',
    backgroundColor: '#4CAF50',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '10px',
    color: '#fff',
  },
};

const CreativeCard = ({ title, description, link, icon }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Link component={RouterLink} to={link} sx={{ textDecoration: 'none', color: 'inherit' }}>
        <AnimatedCard>
          <CardContent>
            {icon && React.cloneElement(icon, { sx: styles.icon })}
            <Typography variant="h1" sx={styles.title}>
              {title}
            </Typography>
            <Typography component="p" sx={styles.description}>
              {description}
            </Typography>
          </CardContent>
        </AnimatedCard>
      </Link>
    </Grid>
  );
};

const MaterialsTables = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MainContainer>
        <Typography
          sx={{
            fontSize: '36px',
            textAlign: 'center',
            marginTop: '12px',
            fontWeight: 'bold',
            color: '#3e4396',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
          variant="h2"
          gutterBottom
        >
          Moyens Matériels
        </Typography>
        <Grid container spacing={3}>
          <CreativeCard
            title="Matériel topographique"
            description="Découvrez la liste du matériel topographique."
            link="/moyens-materiels/materiels/materiel-topographique"
            icon={<CameraRearIcon />}
          />
          <CreativeCard
            title="Véhicules"
            description="Découvrez la liste des véhicules."
            link="/moyens-materiels/vehicules"
            icon={<TimeToLeaveIcon />}
          />
          <CreativeCard
            title="Matériel informatique"
            description="Découvrez la liste du matériel informatique."
            link="/moyens-materiels/materiels/materiel-informatique"
            icon={<ImportantDevicesIcon />}
          />
          <CreativeCard
            title="Autre"
            description="Découvrez la liste du autre matériel."
            link="/moyens-materiels/materiels/autre"
            icon={<HandymanIcon />}
          />
        </Grid>
      </MainContainer>
    </DashboardLayout>
  );
};

export default MaterialsTables;


