import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateRequest } from "../../actions/RequestActions";
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Select,
    Box,
    Snackbar,
    FormControl,
    InputLabel,
} from "@mui/material";
import { Alert } from "@mui/material";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';


const SendRequest = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState("");
    const [mois, setMois] = useState("");
    const [note, setNote] = useState("");
    const [cout, setCout] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const chargeData = {
            type,
            mois,
            prix: cout,
            note,
            userId: currentUser.id,
        };

        try {
            await dispatch(CreateRequest(chargeData));
            setSnackbarMessage("Demande envoyée avec succès.");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setType("");
            setMois("");
            setNote("");
            setCout("");
        } catch (error) {
            setSnackbarMessage(error.response.data.message);
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            console.error("Error creating Request:", error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="sm">
                <Box p={3}>
                    <Typography variant="h2" align="center" gutterBottom>
                        Envoyer une demande d'avance sur salaire
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <>
                                <TextField
                                    label="Mois"
                                    type="month"
                                    value={mois}
                                    onChange={(e) => setMois(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                                <TextField
                                    label="Cout"
                                    type="number"
                                    value={cout}
                                    onChange={(e) => setCout(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </>

                            <TextField
                                fullWidth
                                label="Note"
                                multiline
                                rows={5}
                                variant="outlined"
                                margin="normal"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    mt: 3,
                                    color: "#fff !important"
                                }}
                            >
                                Envoyer
                            </Button>
                        </div>

                    </form>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            variant="filled"
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{ mt: 2, color: "#fff" }}
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </DashboardLayout>
    );
};

export default SendRequest;
