import RequestService from '../services/requestsServices';
import {
  CREATE_REQUEST_SUCCESS,
  CREATE_REQUEST_FAILURE,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_FAILURE,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILURE,
  GET_PENDING_REQUESTS_SUCCESS,
  GET_PENDING_REQUESTS_FAILURE,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST_FAILURE,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_REQUESTS_FAILURE,
  GET_ALL_USER_REQUESTS_SUCCESS,
  GET_ALL_USER_REQUESTS_FAILURE,
} from './types';

export const CreateRequest = (requestData) => {
  return (dispatch) => {
    return RequestService.addRequest(requestData)
      .then((response) => {
        dispatch({
          type: CREATE_REQUEST_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_REQUEST_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getAllRequests = () => {
  return (dispatch) => {
    return RequestService.getAllRequests()
      .then((response) => {
        dispatch({
          type: GET_ALL_REQUESTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_REQUESTS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllPendingRequests = () => {
  return (dispatch) => {
    return RequestService.fetchPendingRequests()
      .then((response) => {
        dispatch({
          type: GET_PENDING_REQUESTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PENDING_REQUESTS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateRequest = (requestId, updatedRequest) => {
  return (dispatch) => {
    return RequestService.updateRequest(requestId, updatedRequest)
      .then((response) => {
        dispatch({
          type: UPDATE_REQUEST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_REQUEST_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getRequest = (requestId) => {
  return (dispatch) => {
    return RequestService.fetchRequest(requestId)
      .then((response) => {
        dispatch({
          type: GET_REQUEST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_REQUEST_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteRequest = (requestId) => {
  return (dispatch) => {
    return RequestService.deleteRequest(requestId)
      .then((response) => {
        dispatch({
          type: DELETE_REQUEST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_REQUEST_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getUserRequests = (userId) => {
  return (dispatch) => {
    return RequestService.fetchUserRequests(userId)
      .then((response) => {
        dispatch({
          type: GET_ALL_USER_REQUESTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_USER_REQUESTS_FAILURE,
          payload: error.message,
        });
      });
  };
};
