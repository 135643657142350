import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPendingTaches, confirmTache, updateTache } from '../../actions/TacheActions';
import {
    Box,
    Typography,
    Snackbar,
    Alert,
    TextField,
    IconButton,
    Dialog,
    Tooltip,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    Button
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { useMaterialUIController } from "../../context";
import MDButton from '../../components/MDButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TachePreview from './TachePreview';
import { encodeId } from '../../actions/Crypte';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Link } from 'react-router-dom';

const PendingTasksList = ({ userId }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tache.taches) ?? [];
    const [loading, setLoading] = useState(true);
    const [note, setNote] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        dispatch(getPendingTaches(userId))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching pending tasks:', error);
                setLoading(false);
            });
    }, [dispatch, userId]);

    useEffect(() => {
        const filteredItems = tasks?.tasks?.filter((task) => {
            const numero = task?.projet?.numero || '';
            const refernce = task.projet?.reference_fonciere || '';
            return (
                numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
                refernce.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredItems(filteredItems);
    }, [tasks, searchTerm]);

    const columns = [
        {
            field: 'projet?.numero', headerName: 'Project',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.projet?.numero || '',
        },
        {
            field: 'projet?.reference_fonciere', headerName: 'Référence',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.projet?.reference_fonciere || '',
        },
        {
            field: 'name', headerName: 'Tâche',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'deadline', headerName: "Date d'échéance",
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'nature',
            headerName: 'Nature',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.projet?.nature.map((natures) => natures.name).join(", "),
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            ...(isMobile ? { width: 260 } : { flex: 2}),
            filterable: false,
            renderCell: (params) => {
                const handleConfirmTask = () => {
                    dispatch(confirmTache(params.row.id))
                        .then(() => {
                            dispatch(getPendingTaches(userId));
                        });
                    setSuccessMessage(`Task "${params.row.name}" confirmed.`);
                    setOpenSnackbar(true);
                };

                return (
                    <>
                        <Tooltip title="Détails" arrow>
                            <IconButton
                                sx={{
                                    marginRight: "1rem",
                                }}
                                color='warning'
                                onClick={() => openPreviewDialog(params.row)}
                            >
                                <OpenInFullIcon fontSize='medium' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Détails" arrow>
                            <IconButton
                                sx={{
                                    marginRight: "1rem",
                                }}
                                color='dark'
                                component={Link}
                                to={`/projects/project/${encodeId(params.row.projet?.id)}`}
                            >
                                <VisibilityIcon fontSize='medium' />
                            </IconButton>
                        </Tooltip>
                        <MDButton
                            variant="contained"
                            color="success"
                            className='me-1'
                            size='small'
                            onClick={handleConfirmTask}
                        >
                            Valider
                        </MDButton>
                        <MDButton
                            variant="contained"
                            color="error"
                            className='ms-1'
                            size='small'
                            onClick={() => openDialog(params.row)}
                        >
                            Rejeter
                        </MDButton>
                    </>
                );
            },
        },
    ];

    const openDialog = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setDialogOpen(false);
    };

    const openPreviewDialog = (item) => {
        setSelectedItem(item);
        setPreviewDialogOpen(true);
    };

    const closePreviewDialog = () => {
        setSelectedItem(null);
        setPreviewDialogOpen(false);
    };

    const handleRejectTask = () => {
        dispatch(updateTache(selectedItem?.id, { status: 'rejected', note: note }));
        setSuccessMessage(`Task rejected !`);
        setOpenSnackbar(true);
        setDialogOpen(false);
        dispatch(getPendingTaches(userId));
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box m="20px">
                <Typography textAlign={'center'} variant="h2" gutterBottom>
                    les tâches à réaliser
                </Typography>
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Rechercher par numéro ou référence fonciere du projet"
                    onChange={handleSearch}
                    value={searchTerm}
                    name="Search Project"
                    sx={{
                        gridColumn: "span 2",
                        marginBottom: '1rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        "& .MuiInputLabel-root": { paddingLeft: '1rem', paddingRight: '1rem' },
                    }}
                />
                <DataGrid
                    className={darkMode ? "text-light" : "text-dark"}
                    rows={filteredItems ?? []}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    loading={loading}
                    autoHeight
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "red",
                        color: '#fff'
                    }}
                >
                    Rejeter la tâche
                </DialogTitle>
                <DialogContent>
                    <Typography mt={2} variant="h6">
                        Êtes-vous sûr de vouloir rejeter cette tâche?
                    </Typography>
                    <TextField
                        fullWidth
                        variant="standard"
                        type="text"
                        label="la cause du rejet"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                        name="note"
                        required
                        sx={{
                            gridColumn: "span 2",
                            marginBottom: '1rem',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            "& .MuiInputLabel-root": { paddingLeft: '1rem', paddingRight: '1rem' },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <MDButton onClick={handleRejectTask} variant="contained" color="error" sx={{ marginRight: '1rem' }}>
                        Rejeter
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={previewDialogOpen}
                onClose={closePreviewDialog}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle
                    textAlign={'center'}
                >
                    Détails de la tâche
                </DialogTitle>
                <DialogContent>
                    <TachePreview selectedItem={selectedItem} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePreviewDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    variant="filled"
                >
                    {successMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default PendingTasksList;
