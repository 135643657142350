import API_URL from './apiUrl';

class MaterialService {
  createMaterial(materialData) {
    return API_URL.post("/material/create", materialData);
  }

  getAllMaterials() {
    return API_URL.get("/material");
  }

  updateMaterial(materialId, updatedMaterial) {
    return API_URL.put(`/material/update/${materialId}`, updatedMaterial);
  }

  fetchMaterialsByType(type) {
    return API_URL.get(`/material/materials/${type}`);
  }

  fetchMaterial(materialId) {
    return API_URL.get(`/material/${materialId}`);
  }

  deleteMaterial(materialId) {
    return API_URL.delete(`/material/delete/${materialId}`);
  }
}

export default new MaterialService();
