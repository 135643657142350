import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Typography, Grid } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import typography from "../../assets/theme/base/typography";

function ProfileInfoCard({ title, info, shadow }) {
  const labels = [];
  const values = [];
  const { size } = typography;

  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  Object.values(info).forEach((el) => values.push(el));

  const renderItems = labels.map((label, key) => (
    <Grid item xs={12} sm={6}>
      <MDBox key={label} display="flex" py={1} pr={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {label}: &nbsp;
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text">
          &nbsp;{values[key]}
        </MDTypography>
      </MDBox>
    </Grid>

  ));

  return (
    <>
      <Card sx={{ height: "100%", marginLeft: "2rem", boxShadow: !shadow && "none" }}>
        <Typography variant="h4" component="div" align="center" mt={2}>
          {title}
        </Typography>
        <MDBox p={2}>
          <MDBox>
            <Grid container spacing={2}>
              {renderItems}
              <MDBox display="flex" py={1} pr={2}>
              </MDBox>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

ProfileInfoCard.defaultProps = {
  shadow: true,
};

ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
