import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMaterial, updateMaterial } from "../../actions/MaterialsActions";
import { useParams } from "react-router-dom";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { useMaterialUIController } from "../../context";
import AccessDenied from "../Errors/AccessDenied";
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Button,
    Snackbar,
    Alert,
    Box,
} from "@mui/material";

const UpdateMaterial = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const { id } = useParams();
    const material = useSelector((state) => state.material.material);
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [dateAcquisition, setDateAcquisition] = useState("");
    const [prixAcquisition, setPrixAcquisition] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    useEffect(() => {
        dispatch(getMaterial(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (material) {
            setType(material?.type);
            setName(material?.name);
            setDateAcquisition(material?.date_acquisition);
            setPrixAcquisition(material?.prix_acquisition);
        }
    }, [material]);

    const handleUpdate = () => {
        const updatedMaterial = {
            type,
            name,
            date_acquisition: dateAcquisition,
            prix_acquisition: prixAcquisition,
        };

        dispatch(updateMaterial(material.id, updatedMaterial))
            .then(() => {
                setSnackbarSeverity("success");
                setSnackbarMessage("Material updated successfully");
                setOpenSnackbar(true);
            })
            .catch((error) => {
                setSnackbarSeverity("error");
                setSnackbarMessage(`Error updating material: ${error.message}`);
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les matériels")) ? (
                <Container maxWidth="sm" style={{ marginTop: "20px" }}>
                    <Typography variant="h2" align="center" gutterBottom>
                        Mettre à jour le matériel
                    </Typography>
                    {material ? (
                        <form>
                            <TextField
                                select
                                label="Mode de paiement"
                                name="type"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                required
                                fullWidth
                            >
                                <MenuItem value="" disabled>
                                    Sélectionnez le type
                                </MenuItem>
                                <MenuItem value="materiel-topographique">Matériel topographique</MenuItem>
                                <MenuItem value="materiel-informatique">Matériel informatique</MenuItem>
                                <MenuItem value="autre">Autre</MenuItem>
                            </TextField>
                            <TextField
                                label="Nom"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Date Acquisition"
                                type="date"
                                value={dateAcquisition}
                                onChange={(e) => setDateAcquisition(e.target.value)}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                margin="normal"
                            />
                            <TextField
                                label="Prix Acquisition"
                                type="number"
                                value={prixAcquisition}
                                onChange={(e) => setPrixAcquisition(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpdate}
                                fullWidth
                                sx={{
                                    marginTop: 2,
                                    color: "#fff !important",
                                }}
                            >
                                Mettre à jour
                            </Button>
                        </form>
                    ) : (
                        <Typography>Loading material details...</Typography>
                    )}
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </DashboardLayout>
    );
};

export default UpdateMaterial;

