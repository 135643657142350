import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createOperation } from "../../actions/OperationsActions";
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Snackbar,
    Alert,
    Box,
} from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import FileUpload from "./UploadFiles";

const AddOperation = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState("");
    const [nameCompte, setNameCompte] = useState("");
    const [compte, setCompte] = useState("");
    const [date, setDate] = useState("");
    const [data, setData] = useState(null);
    const [prix, setPrix] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const materialData = {
            type,
            nameCompte,
            compte,
            date,
            prix,
        };

        try {
            const response = await dispatch(createOperation(materialData));
            console.log(response);
            setData(response.data);
            setSnackbarSeverity("success");
            setSnackbarMessage("Opération ajouté avec succès!");
        } catch (error) {
            console.log(error);
            setSnackbarSeverity("error");
            setSnackbarMessage("Erreur lors de l'ajout du opération.");
        }

        setSnackbarOpen(true);
        setType("");
        setNameCompte("");
        setCompte("");
        setDate("");
        setPrix("");
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des opérations bancaires")) ? (
                <>
                    {snackbarOpen ? (
                        <>
                            <Container maxWidth="md">
                                <Typography variant="h2" align="center" gutterBottom>
                                    Ajouter des opérations
                                </Typography>
                                <div className={`alert alert-${snackbarSeverity} mt-2`}>
                                    {snackbarMessage}
                                </div>
                                <Typography variant="h5" align="center" gutterBottom>
                                    Ajouter des Justifications
                                </Typography>
                                <FileUpload operationId={data?.id} />
                            </Container>
                        </>
                    ) : (
                        <>
                            <Container maxWidth="md">
                                <Box p={3}>
                                    <Typography variant="h2" align="center" gutterBottom>
                                        Ajouter des opérations
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            select
                                            label="Type d'opération"
                                            variant="standard"
                                            name="type"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            required
                                            fullWidth
                                        >
                                            <MenuItem value="" disabled>
                                                Sélectionnez le type
                                            </MenuItem>
                                            <MenuItem value="Versement Recettes">Versement Recettes</MenuItem>
                                            <MenuItem value="Virement Recettes">Virement Recettes</MenuItem>
                                            <MenuItem value="Chèque Recettes">Chèque Recettes</MenuItem>
                                            <MenuItem value="Versement de Caisse">Versement de Caisse</MenuItem>
                                            <MenuItem value="Retrait pour Caisse">Retrait pour Caisse</MenuItem>
                                            <MenuItem value="Virement Charges">Virement Charges</MenuItem>
                                            <MenuItem value="Virement Salaires">Virement Salaires</MenuItem>
                                            <MenuItem value="Caution">Caution</MenuItem>
                                            <MenuItem value="Prélèvement CNSS">Prélèvement CNSS</MenuItem>
                                            <MenuItem value="Prélèvement TVA">Prélèvement TVA</MenuItem>
                                            <MenuItem value="Prélèvement IS">Prélèvement IS</MenuItem>
                                        </TextField>
                                        <TextField
                                            type="date"
                                            label="date d'opération"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            variant="standard"
                                            fullWidth
                                            margin="normal"
                                            required
                                        />
                                        <TextField
                                            label="Prix d'opération"
                                            type="number"
                                            value={prix}
                                            onChange={(e) => setPrix(e.target.value)}
                                            variant="standard"
                                            fullWidth
                                            margin="normal"
                                            required
                                        />
                                        <TextField
                                            select
                                            label="Type du compte"
                                            name="compte"
                                            variant="standard"
                                            value={compte}
                                            onChange={(e) => setCompte(e.target.value)}
                                            required
                                            fullWidth
                                        >
                                            <MenuItem value="" disabled>
                                                Sélectionnez le Compte
                                            </MenuItem>
                                            <MenuItem value="Compte professionnel">Compte professionnel</MenuItem>
                                            <MenuItem value="Compte personnel">Compte personnel</MenuItem>
                                        </TextField>
                                        <TextField
                                            label="Nom de Compte (facultatif)"
                                            value={nameCompte}
                                            onChange={(e) => setNameCompte(e.target.value)}
                                            variant="standard"
                                            fullWidth
                                            margin="normal"
                                        />

                                        <MDButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            // fullWidth
                                            sx={{ mt: 3 }}
                                        >
                                            Ajouter
                                        </MDButton>
                                    </form>
                                    <Snackbar
                                        open={snackbarOpen}
                                        autoHideDuration={6000}
                                        onClose={handleSnackbarClose}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    >
                                        <Alert
                                            onClose={handleSnackbarClose}
                                            severity={snackbarSeverity}
                                            sx={{ width: "100%" }}
                                        >
                                            {snackbarMessage}
                                        </Alert>
                                    </Snackbar>
                                </Box>
                            </Container>
                        </>
                    )}

                </>

            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout>
    );
};

export default AddOperation;
