import MaterialService from '../services/materialsServices';
import {
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_FAILURE,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_FAILURE,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_FAILURE,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAILURE,
  GET_ALL_MATERIALS_SUCCESS,
  GET_ALL_MATERIALS_FAILURE,
} from './types';

export const createMaterial = (materialData) => {
  return (dispatch) => {
    return MaterialService.createMaterial(materialData)
      .then((response) => {
        dispatch({
          type: CREATE_MATERIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_MATERIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllMaterials = () => {
  return (dispatch) => {
    return MaterialService.getAllMaterials()
      .then((response) => {
        dispatch({
          type: GET_ALL_MATERIALS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_MATERIALS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getMaterialsByType = (type) => {
  return (dispatch) => {
    return MaterialService.fetchMaterialsByType(type)
      .then((response) => {
        dispatch({
          type: GET_ALL_MATERIALS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_MATERIALS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateMaterial = (materialId, updatedMaterial) => {
  return (dispatch) => {
    return MaterialService.updateMaterial(materialId, updatedMaterial)
      .then((response) => {
        dispatch({
          type: UPDATE_MATERIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_MATERIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getMaterial = (materialId) => {
  return (dispatch) => {
    return MaterialService.fetchMaterial(materialId)
      .then((response) => {
        dispatch({
          type: GET_MATERIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_MATERIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteMaterial = (materialId) => {
  return (dispatch) => {
    return MaterialService.deleteMaterial(materialId)
      .then((response) => {
        dispatch({
          type: DELETE_MATERIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_MATERIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};
