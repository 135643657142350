import {
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_FAILURE,
    FETCH_ALL_PROJECTS_REQUEST,
    FETCH_ALL_PROJECTS_SUCCESS,
    FETCH_ALL_PROJECTS_FAILURE,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAILURE,
    FETCH_PROJECT_COUNT_REQUEST,
    FETCH_PROJECT_COUNT_SUCCESS,
    FETCH_PROJECT_COUNT_FAILURE,
    GET_PROJECTS_BY_STATUS_REQUEST,
    GET_PROJECTS_BY_STATUS_SUCCESS,
    GET_PROJECTS_BY_STATUS_FAILURE,
    GET_PROJECTS_BY_CLIENT_ID_REQUEST,
    GET_PROJECTS_BY_CLIENT_ID_SUCCESS,
    GET_PROJECTS_BY_CLIENT_ID_FAILURE,
    COUNT_PROJECTS_LAST_MONTH,
    COUNT_PROJECTS_LAST_MONTH_SUCCESS,
    COUNT_PROJECTS_LAST_MONTH_FAILURE,
    FETCH_PROJECT_COUNT_BY_MONTH_REQUEST,
    FETCH_PROJECT_COUNT_BY_MONTH_SUCCESS,
    FETCH_PROJECT_COUNT_BY_MONTH_FAILURE,
    UPLOAD_PROJECT_FILES_FAILURE,
    UPLOAD_PROJECT_FILES_SUCCESS,
    GET_PROJECT_FILES_SUCCESS,
    GET_PROJECT_FILES_FAILURE,
    GET_PROJECTS_BY_CONTACT_ID_SUCCESS,
    GET_PROJECTS_BY_CONTACT_ID_FAILURE,
} from '../actions/types';

const initialState = {
    project: null,
    projects: [],
    projectsClient: [],
    loading: false,
    error: null,
    count: 0,
    projectsByStatus: [],
    files: [],
    projectCount: [],
    contactProjects: [],
};


const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                project: action.payload,
                error: null
            };
        case CREATE_PROJECT_FAILURE:
            return {
                ...state,
                project: null,
                error: action.payload
            };
        case UPLOAD_PROJECT_FILES_SUCCESS:
            return {
                ...state,
                project: action.payload,
                error: null
            };
        case UPLOAD_PROJECT_FILES_FAILURE:
            return {
                ...state,
                project: null,
                error: action.payload
            };
        case UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                project: action.payload,
                error: null,
            };
        case UPDATE_PROJECT_FAILURE:
            return {
                ...state,
                project: null,
                error: action.payload,
            };
        case FETCH_PROJECT_SUCCESS:
            return {
                ...state,
                project: action.payload,
                error: null,
            };
        case FETCH_PROJECT_FAILURE:
            return {
                ...state,
                project: null,
                error: action.payload,
            };
        case FETCH_ALL_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ALL_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload,
                loading: false,
                error: null
            };
        case FETCH_ALL_PROJECTS_FAILURE:
            return {
                ...state,
                projects: [],
                loading: false,
                error: action.payload
            };
        case DELETE_PROJECT_SUCCESS:
            return {
                ...state,
                projects: state.projects.filter((project) => project.id !== action.payload),
                error: null,
            };
        case DELETE_PROJECT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case FETCH_PROJECT_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PROJECT_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                projectCount: action.payload,
                error: "",
            };
        case FETCH_PROJECT_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                projectCount: {},
                error: action.payload,
            };
        case GET_PROJECTS_BY_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_PROJECTS_BY_STATUS_SUCCESS:
            return {
                ...state,
                projectsByStatus: action.payload,
                loading: false,
                error: null
            };
        case GET_PROJECTS_BY_STATUS_FAILURE:
            return {
                ...state,
                projectsByStatus: [],
                loading: false,
                error: action.payload
            };
        case GET_PROJECTS_BY_CLIENT_ID_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_PROJECTS_BY_CLIENT_ID_SUCCESS:
            return {
                ...state,
                projectsClient: action.payload,
                loading: false,
                error: null
            };
        case GET_PROJECTS_BY_CLIENT_ID_FAILURE:
            return {
                ...state,
                projectsClient: [],
                loading: false,
                error: action.payload
            };
        case COUNT_PROJECTS_LAST_MONTH:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case COUNT_PROJECTS_LAST_MONTH_SUCCESS:
            return {
                ...state,
                count: action.payload,
                loading: false,
                error: null,
            };
        case COUNT_PROJECTS_LAST_MONTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case FETCH_PROJECT_COUNT_BY_MONTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_PROJECT_COUNT_BY_MONTH_SUCCESS:
            return {
                ...state,
                projects: action.payload,
                loading: false,
                error: null
            };
        case FETCH_PROJECT_COUNT_BY_MONTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case GET_PROJECT_FILES_SUCCESS:
            return {
                ...state,
                files: action.payload,
                error: null,
            };
        case GET_PROJECT_FILES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PROJECTS_BY_CONTACT_ID_SUCCESS:
            return {
                ...state,
                contactProjects: action.payload,
                error: null,
            };
        case GET_PROJECTS_BY_CONTACT_ID_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default projectReducer;