import React from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import RecettesByYear from "./RecettesByMonth";
import RecettesFactureByYear from "./RecettesFactureByYear";
import RecettesFactureByYearAndMonth from "./RecettesFactureByYearAndMonth";
import PorjectsAmount from "../projects/ProjectsAmount";
import {
    Typography,
    Grid,
    Paper,
    Divider,
} from '@mui/material';
import PorjectAmountByNature from "../projects/ProjectsAmountByNature";
const RecettesStatistics = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h2" mb={2} textAlign={"center"}>
                RECETTES
            </Typography>
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Total de montants du projets au cours d'une année spécifique
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <PorjectsAmount />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Total de recettes du projets au cours d'une année spécifique
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <RecettesByYear />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Montants totaux des projets au cours d'une année spécifique par rapport à la nature
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <PorjectAmountByNature />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Montants totaux des recettes facturé et non facturé au cours d'une année spécifique 
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <RecettesFactureByYear />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Montants totaux des recettes facturé et non facturé au cours d'une année spécifique par rapport au mois
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <RecettesFactureByYearAndMonth />
        </DashboardLayout>
    )
}

export default RecettesStatistics;