import React, { useState, useEffect } from "react";
import { TextField, Box, Button, Snackbar, Alert } from "@mui/material";

const UpdatePoint = ({ point, handlePointUpdate }) => {
    const [reference, setReference] = useState("");
    const [nature, setNature] = useState("");
    const [name, setName] = useState("");
    const [X, setX] = useState(0);
    const [Y, setY] = useState(0);
    const [igt, setIgt] = useState('');
    const [note, setNote] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');


    useEffect(() => {
        if (point) {
            setReference(point?.reference);
            setNature(point?.nature);
            setName(point?.name);
            setX(point?.geometry?.coordinates?.[0]);
            setY(point?.geometry?.coordinates?.[1]);
            setIgt(point?.createdBy);
            setNote(point?.note);
        }
    }, [point]);

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const varia = {
        name: name,
        nature: nature,
        reference: reference,
        X,
        Y,
        igt,
        note: note,
        id: point?.id
    }
    return (
        <Box>
            <TextField
                label="Nom du point"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Nature du point"
                value={nature}
                onChange={(e) => setNature(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Référence"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="X"
                type='number'
                value={X}
                onChange={(e) => setX(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Y"
                type='number'
                value={Y}
                onChange={(e) => setY(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="IGT"
                value={igt}
                onChange={(e) => setIgt(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Observation"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <Button
                type="button"
                variant="contained"
                color="dark"
                sx={{
                    color: '#000 !important',
                    marginTop: '12px',
                    backgroundColor: '#ffc107 !important',
                }}
                // onClick={handlePointUpdate(varia)}
                onClick={() => handlePointUpdate(varia)}

            >
                Sauvegarder
            </Button>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default UpdatePoint;
