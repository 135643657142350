import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  TextField,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import StatisticsServices from '../../../services/statisticsServices';

const RecettesFactureByYear = () => {
  const theme = useTheme();
  const [selectedYear, setSelectedYear] = useState('');
  const [encaissementsAmount, setEncaissementsAmount] = useState(null);

  useEffect(() => {
    if (selectedYear === '') {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      setSelectedYear(String(currentYear));
    }
  }, []);

  const fetchEncaissements = async (year) => {
    try {
      const response = await StatisticsServices.encaissementsFactureByYear(year);
      setEncaissementsAmount(response.data || []);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  useEffect(() => {
    if (selectedYear) {
      fetchEncaissements(selectedYear);
    }
  }, [selectedYear]);


  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };


  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2017;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years.map((year) => (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    ));
  };

  console.log(encaissementsAmount);

  return (
    <>
      <Box
        mt="25px"
        p="0 30px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          select
          value={selectedYear}
          onChange={handleYearChange}
          label="Sélectionner l'année"
          fullWidth
        >
          <MenuItem value="">-- Select Year --</MenuItem>
          {generateYearOptions()}
        </TextField>
      </Box>
      <Box height="400px" m="-20px 0 0 0">
        {encaissementsAmount && encaissementsAmount.length > 0 ? (
          <ResponsivePie
            data={encaissementsAmount.map(item => ({ id: item.facture, label: item.facture, value: item.total }))}
            keys={['value']}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'category10' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            enableRadialLabels={false}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={theme.nivo}
          />
        ) : (
          <Typography variant="body2" mt={5} p={5}>Oops! Aucune donnée disponible</Typography>
        )}
      </Box>
    </>
  );
};

export default RecettesFactureByYear;
