import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllPieces, deletePieces, updatePieces } from '../../actions/PieceActions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Pagination,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  Alert,
  Snackbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';
import { encodeId } from '../../actions/Crypte';

const Pieces = ({ currentUser }) => {
  const dispatch = useDispatch();
  const pieces = useSelector((state) => state.piece.pieces);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [piece, setPiece] = useState('');

  useEffect(() => {
    dispatch(getAllPieces());
  }, [dispatch]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedNature, setSelectedNature] = useState(null);

  const handleOpenModal = (nature) => {
    setSelectedNature(nature);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedNature(null);
    setOpenModal(false);
  };

  const handleDelete = () => {
    if (selectedNature) {
      dispatch(deletePieces(selectedNature.id))
        .then(() => {
          setSnackbarMessage('Pièce supprimée avec succès');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        })
        .catch((error) => {
          setSnackbarMessage('Échec de la suppression de la pièce');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
      handleCloseModal();
      dispatch(getAllPieces());
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const openDialog = (item) => {
    setSelectedItem(item);
    setPiece(item?.piece);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedItem(null);
    setPiece('');
    setDialogOpen(false);
  };

  const handleChange = (e) => {
    setPiece(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const Data = {
      piece,
    };
    dispatch(updatePieces(selectedItem?.id, Data))
      .then((response) => {
        setSnackbarMessage('Pièce mise à jour avec succès');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setPiece('');
        dispatch(getAllPieces());
      })
      .catch((error) => {
        console.log(error);
        setSnackbarMessage('Échec de la mise à jour de la pièce');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les pièces")) ? (
        <>
          <Typography
            sx={{
              fontSize: '36px',
              textAlign: 'center',
              marginTop: '12px',
              fontWeight: 'bold',
              color: '#3e4396',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',

            }}
            variant="h2"
            gutterBottom
          >
            Liste des pièces
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pièce</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pieces.slice(startIndex, endIndex).map((nature) => (
                  <TableRow key={nature.id}>
                    <TableCell>{nature.piece}</TableCell>
                    <TableCell>
                      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les pièces")) && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => openDialog(nature)}
                          sx={{
                            color: '#fff !important'
                          }}
                          color="primary"
                        >
                          Modifier
                        </Button>
                      )}
                      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les pièces")) && (
                        <IconButton variant="contained" size="small" color='error' onClick={() => handleOpenModal(nature)}>
                          <DeleteIcon fontSize='medium'/>
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={Math.ceil(pieces.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="info"
            style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
          />

          {/* Delete Modal */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    Confirmation de la suppression
                  </Typography>
                  <Typography paragraph>
                    Êtes-vous sûr de vouloir supprimer cette pièce ?
                  </Typography>
                  <MDButton onClick={handleDelete} variant="contained" color="error" style={{ marginRight: '10px' }}>
                    Supprimer
                  </MDButton>
                  <MDButton onClick={handleCloseModal} variant="contained" color="white">
                    Annuler
                  </MDButton>
                </Paper>
              </Box>
            </Fade>
          </Modal>
          <Dialog
            open={dialogOpen}
            onClose={closeDialog}
            fullWidth
            maxWidth={'md'}
          >
            <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
              Modifier
            </DialogTitle>
            <DialogContent>
              {selectedItem ? (
                <>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} justifyContent="center" mt={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Pièce"
                          name="piece"
                          value={piece}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} >
                        <Button sx={{ color: '#fff !important' }} variant="contained" color="primary" type="submit">
                          Sauvegarder
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </>
              ) : (
                <Typography variant="h6" textAlign={'center'} gutterBottom>
                  Oops! quelque chose s'est mal passé
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Annuler</Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert variant='filled' onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

export default Pieces;
