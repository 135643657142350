import { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MDBox from "../../../components/MDBox";
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";
import './style.css';
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "./styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../../context";
import { Divider } from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TodayIcon from '@mui/icons-material/Today';
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import {
  useMediaQuery,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LocalAtm } from '@mui/icons-material';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NotificationsList from "./NotificationsList";

const DashboardNavbar = ({ absolute, light, isMini }) => {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { user: currentUser } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery('(max-width:600px)');
  const charges = useSelector((state) => state.charges.charges);
  const avances = useSelector((state) => state.encaissement.encaissements);
  const holidays = useSelector((state) => state.holiday.holidays);
  const requests = useSelector((state) => state.request.requests);
  const tasks = useSelector((state) => state.tache.taches) ?? [];
  const rejected = useSelector((state) => state.charges.rejected);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const anchorRef = useRef(null);

  const chargesRejcted = rejected?.rejectedCharges?.count;
  const holidaysRejcted = rejected?.rejectedHolidays?.count;
  const requestsRejcted = rejected?.rejectedRequests?.count;
  const rejectedAvances = rejected?.rejectedAvances?.length || 0;
  const totalRejected = chargesRejcted + holidaysRejcted + requestsRejcted + rejectedAvances;

  let count = 0;
  count = (charges.count || 0) + (avances.count || 0) + (holidays.holidayCount || 0) + (requests.count || 0);

  let countUser = 0;
  countUser = (tasks.taskCount || 0);
  let avacesCount = 0;
  let chargesCount = 0;
  let holidaysCount = 0;
  let requestsCount = 0;

  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider des encaissements")) {
    avacesCount = (avances.count || 0);
  } else {
    avacesCount = 0;
  }
  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les charges")) {
    chargesCount = (charges.count || 0);
  } else {
    chargesCount = 0;
  }
  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les congés")) {
    holidaysCount = (holidays.holidayCount || 0);
  } else {
    holidaysCount = 0;
  }
  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les avances de salaires")) {
    requestsCount = (requests.count || 0);
  } else {
    requestsCount = 0;
  }
  const notificationsCount = avacesCount + chargesCount + holidaysCount + requestsCount + countUser + totalRejected;

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);


  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseNotfif = () => setOpen(false);
  const handleOpenNotif = (event) => setOpen(event.currentTarget);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Envoyer des demandes de congés")) && (
        <NotificationItem component={Link} to={`/ressources-humaines/holidays/send/request`} icon={<EditCalendarIcon />} title="Envoyer une demandes de congés" />
      )}
      {(isMobile && (currentUser.roles.includes("ROLE_ADMIN")
        || currentUser.permissions.includes("Exporter PV de mission")
        || currentUser.permissions.includes("Exporter ordre de mission")
        || currentUser.permissions.includes("Générer et envoyer le rapport"))) &&
        <NotificationItem component={Link} to={`/bureau`} icon={<ScreenSearchDesktopIcon />} title="Bureau d'ordre" />
      }
      <NotificationItem component={Link} to={'/ressources-humaines/holidays/user'} icon={<TodayIcon />} title="Congés" />
      <NotificationItem component={Link} to={`/schedule`} icon={<CalendarMonthIcon />} title="Programme de la semaine" />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des projets")) && (
        <NotificationItem component={Link} to={`/projects/create`} icon={<CreateNewFolderIcon />} title="Créer Projet" />
      )}
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Envoyer des demandes d'avances sur salaires")) && (
        <NotificationItem component={Link} to={`/requests/send`} icon={<LocalAtm />} title="Demander avance sur salaire" />
      )}
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des clients")) && (
        <NotificationItem component={Link} to={`/clients/create`} icon={<PersonAddIcon />} title="Créer Client" />
      )}
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des charges")) && (
        <NotificationItem component={Link} to={`/charges/create`} icon={<RequestQuoteIcon />} title="Ajouter des Charges" />
      )}
      {currentUser && (currentUser.permissions.includes("Ajouter des charges")) && (
        <NotificationItem component={Link} to={`/charges/user/${currentUser?.nom}`} icon={<RequestQuoteIcon />} title="Liste de vos Charges" />
      )}
      <Divider />
      <NotificationItem component={Link} to={'/profile'} icon={<ManageAccountsIcon />} title="Profile" />
    </Menu>
  );

  const renderNotifications = () => (
    <Menu
      anchorEl={open}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(open)}
      onClose={handleCloseNotfif}
      sx={{ mt: 2 }}
    >
      <NotificationsList
        charges={charges}
        avances={avances}
        holidays={holidays}
        requests={requests}
        totalRejected={totalRejected}
        currentUser={currentUser}
        tasks={tasks}
      />
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {!isMobile &&
            <Breadcrumbs icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          }
        </MDBox>
        <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
          <MDBox pr={1}>
          </MDBox>
          <MDBox color={light ? "white" : "inherit"}>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              onClick={handleGoBack}
            >
              <ArrowBackIosIcon sx={iconsStyle}>
              </ArrowBackIosIcon>
            </IconButton>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              onClick={handleMiniSidenav}
            >
              <MenuOutlinedIcon sx={iconsStyle} fontSize="medium">
                {miniSidenav ? "menu_open" : "menu"}
              </MenuOutlinedIcon>
            </IconButton>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <AccountCircleIcon sx={iconsStyle} />
            </IconButton>
            {renderMenu()}
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              onClick={handleConfiguratorOpen}
            >
              <SettingsIcon sx={iconsStyle}>settings</SettingsIcon>
            </IconButton>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenNotif}
            >
              <NotificationsIcon sx={iconsStyle}>notifications</NotificationsIcon>
              <div className="counter">
                {notificationsCount || 0}
              </div>
            </IconButton>
            {renderNotifications()}

          </MDBox>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
