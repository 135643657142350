import NatureService from '../services/natureServices';
import {
  CREATE_NATURE_SUCCESS,
  CREATE_NATURE_FAILURE,
  UPDATE_NATURE_SUCCESS,
  UPDATE_NATURE_FAILURE,
  GET_NATURE_SUCCESS,
  GET_NATURE_FAILURE,
  DELETE_NATURE_SUCCESS,
  DELETE_NATURE_FAILURE,
  GET_ALL_NATURES_SUCCESS,
  GET_ALL_NATURES_FAILURE,
} from './types';

export const createNature = (natureData) => {
  return (dispatch) => {
    return NatureService.createNature(natureData)
      .then((response) => {
        dispatch({
          type: CREATE_NATURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_NATURE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllNatures = () => {
  return (dispatch) => {
    return NatureService.getAllNatures()
      .then((response) => {
        dispatch({
          type: GET_ALL_NATURES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_NATURES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateNature = (natureId, updatedNature) => {
  return (dispatch) => {
    return NatureService.updateNature(natureId, updatedNature)
      .then((response) => {
        dispatch({
          type: UPDATE_NATURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_NATURE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getNature = (natureId) => {
  return (dispatch) => {
    return NatureService.fetchNature(natureId)
      .then((response) => {
        dispatch({
          type: GET_NATURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_NATURE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteNature = (natureId) => {
  return (dispatch) => {
    return NatureService.deleteNature(natureId)
      .then((response) => {
        dispatch({
          type: DELETE_NATURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_NATURE_FAILURE,
          payload: error.message,
        });
      });
  };
};
