import PermissionService from '../services/permissionServices';
import {
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAILURE,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAILURE,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILURE,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAILURE,
} from './types';

export const createPermission = (permissionData) => {
  return (dispatch) => {
    return PermissionService.createPermission(permissionData)
      .then((response) => {
        dispatch({
          type: CREATE_PERMISSION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_PERMISSION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllPermissions = () => {
  return (dispatch) => {
    return PermissionService.getAllPermissions()
      .then((response) => {
        dispatch({
          type: GET_ALL_PERMISSIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_PERMISSIONS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updatePermission = (permissionId, updatedPermission) => {
  return (dispatch) => {
    return PermissionService.updatePermission(permissionId, updatedPermission)
      .then((response) => {
        dispatch({
          type: UPDATE_PERMISSION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PERMISSION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getPermission = (permissionId) => {
  return (dispatch) => {
    return PermissionService.fetchPermission(permissionId)
      .then((response) => {
        dispatch({
          type: GET_PERMISSION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PERMISSION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deletePermission = (permissionId) => {
  return (dispatch) => {
    return PermissionService.deletePermission(permissionId)
      .then((response) => {
        dispatch({
          type: DELETE_PERMISSION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PERMISSION_FAILURE,
          payload: error.message,
        });
      });
  };
};
