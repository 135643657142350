import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addQuantity, fetchAllProducts } from '../../actions/ProductsActions';
import {
    TextField,
    Button,
    MenuItem,
    Snackbar,
    Autocomplete,
    Alert,
} from '@mui/material';
import { getAllCharges } from '../../actions/ChargesActions';

const AddQuantity = ({ productId }) => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(0);
    const currentDate = new Date();
    const [withCharge, setWithCharge] = useState(false);
    const [chargeId, setChargeId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    let charges = useSelector((state) => state.charges.allCharges) || [];

    useEffect(() => {
        dispatch(getAllCharges());
    }, [dispatch]);

    const chargeOptions = charges?.map((charge) => ({
        value: charge.id,
        label: `designation : ${charge.designation} - mois: ${charge.mois} - Coût: ${charge.cout}`,
    }));

    const handleSubmit = (e) => {
        e.preventDefault();

        const Data = {
            quantity,
            date: currentDate,
            chargeId: chargeId?.value || null,
            productId,
        };

        dispatch(addQuantity(productId, Data))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Produit inséré avec succès !');
                setSnackbarOpen(true);
                dispatch(fetchAllProducts());
                setQuantity(0);
                setChargeId(null);
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la création. Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <form style={{ marginTop: '1rem' }}>
                <TextField
                    label="Quantité"
                    variant="outlined"
                    type='number'
                    fullWidth
                    margin="normal"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
                <TextField
                    select
                    label="Lié au charge"
                    name="withcharge"
                    fullWidth
                    margin="normal"
                    value={withCharge}
                    onChange={(e) => setWithCharge(e.target.value)}
                >
                    <MenuItem value={false} disabled>Select...</MenuItem>
                    <MenuItem value={true}>oui</MenuItem>
                </TextField>
                {withCharge && (
                    <>
                        <Autocomplete
                            options={chargeOptions}
                            value={chargeId}
                            onChange={(event, newValue) => setChargeId(newValue)}
                            getOptionLabel={(option) => option.label}
                            isSearchable
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Charge"
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                />
                            )}
                        />
                    </>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        color: '#fff !important'
                    }}
                    onClick={handleSubmit}
                >
                    Sauvegarder
                </Button>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};


export default AddQuantity;
