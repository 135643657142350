import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { getAllCommercials, deleteCommercial, updateCommercial, createAccount } from '../../actions/ContactActions';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import {
    Typography,
    TextField,
    Modal,
    Button,
    Box,
    Dialog,
    Container,
    DialogTitle,
    DialogActions,
    DialogContent,
    Snackbar,
    Alert
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';
import MDButton from '../../components/MDButton';
import { Link } from 'react-router-dom';

const Contacts = ({ currentUser }) => {
    const dispatch = useDispatch();
    const commercials = useSelector((state) => state.commercial.commercials);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [commercialToDelete, setCommercialToDelete] = useState(null);
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [updatedName, setUpdatedName] = useState('');
    const [updatedPhone, setUpdatedPhone] = useState('');
    const [updatedEmail, setUpdatedEmail] = useState('');
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getAllCommercials());
    }, [dispatch]);

    const handleUpdateClick = (contact) => {
        setSelectedContact(contact);
        setUpdatedName(contact.name);
        setUpdatedPhone(contact.phone);
        setUpdatedEmail(contact.email);
        setShowUpdateDialog(true);
    };

    const handleUpdate = () => {
        if (selectedContact && (updatedName !== '' || updatedPhone !== '' || updatedEmail !== '')) {
            dispatch(updateCommercial(selectedContact.id, {
                name: updatedName || selectedContact.name,
                phone: updatedPhone || selectedContact.phone,
                email: updatedEmail || selectedContact.email,
            }))
                .then(() => {
                    dispatch(getAllCommercials());
                })
                .catch((error) => {
                    console.error(error);
                });
            setShowUpdateDialog(false);
        }
    };
    const isMobile = window.innerWidth <= 768;

    const handleShowConfirmation = (commercialId) => {
        setCommercialToDelete(commercialId);
        setShowConfirmation(true);
    };

    const handleHideConfirmation = () => {
        setShowConfirmation(false);
        setCommercialToDelete(null);
    };

    const handleDelete = (commercialId) => {
        handleShowConfirmation(commercialId);
    };

    const handleConfirmDelete = (commercialId) => {
        if (commercialId) {
            dispatch(deleteCommercial(commercialId))
                .then(() => {
                    dispatch(getAllCommercials());
                });
            handleHideConfirmation();
        }
    };
    const columns = [
        { field: 'name', headerName: 'Nome', flex: 1 },
        ...(isMobile
            ? []
            : [
                {
                    field: 'id', headerName: 'ID',
                    ...(isMobile ? { width: 120 } : { flex: 1 }),
                },
                {
                    field: 'createdAt', headerName: 'Created At',
                    ...(isMobile ? { width: 120 } : { flex: 1 }),
                },
            ]),
        {
            field: 'actions',
            headerName: 'Actions',
            ...(isMobile ? { width: 205 } : { flex: 1 }),
            renderCell: (params) => (
                <Box>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")) && (
                        <>
                            <MDButton
                                color='info'
                                size='small'
                                className='me-2'
                                onClick={() => openCreate(params.row)}
                            >
                                Céer compte
                            </MDButton>
                        </>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les contacts")) && (
                        <EditIcon
                            fontSize='medium'
                            onClick={() => handleUpdateClick(params.row)}
                        />
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les contacts")) && (
                        <DeleteIcon
                            onClick={() => handleDelete(params.id)}
                            sx={{ cursor: 'pointer', marginLeft: '15px' }}
                            color='error'
                            fontSize='medium'
                        />
                    )}
                </Box>
            ),
        },
    ];

    const openCreate = (contact) => {
        setSelectedContact(contact);
        setAddDialogOpen(true);
    };

    const closeCreate = () => {
        setSelectedContact(null);
        setAddDialogOpen(false);
    };

    const handleSubmit = () => {

        const commercialData = {
            username,
            password,
        };
        if (!username || !password) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Erreur lors de la création du Compte. Veuillez réessayer.');
            setSnackbarOpen(true);
        } else {
            dispatch(createAccount(selectedContact?.id, commercialData))
                .then(() => {
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Compte créé avec succès !');
                    setSnackbarOpen(true);
                    setUsername('');
                    setPassword('');
                    closeCreate();
                    dispatch(getAllCommercials());
                })
                .catch((error) => {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('Erreur lors de la création du Compte. Veuillez réessayer.');
                    setSnackbarOpen(true);
                    console.error(error);
                });
        }
    };

    const handleDesactivate = () => {
        const commercialData = {
            username: null,
            password: null,
        };
        dispatch(updateCommercial(selectedContact?.id, commercialData))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Compte désactivé avec succès !');
                setSnackbarOpen(true);
                setUsername('');
                setPassword('');
                closeCreate();
                dispatch(getAllCommercials());
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur . Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les contacts")) ? (
                <Box>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',

                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Liste des contacts
                    </Typography>
                    <Box
                        m="40px 0 0 0"
                        height="90vh"
                    >
                        <DataGrid
                            rows={commercials}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
            <Modal
                open={showConfirmation}
                onClose={handleHideConfirmation}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" component="h2" gutterBottom>
                        Confirmation de suppression
                    </Typography>
                    <Typography sx={{ marginBottom: '1rem' }}>
                        Êtes-vous sûr de vouloir supprimer ce contact ?
                    </Typography>
                    <MDButton
                        onClick={() => {
                            handleHideConfirmation();
                            handleConfirmDelete(commercialToDelete);
                        }}
                        variant="contained"
                        color="error"
                        sx={{ marginRight: '1rem' }}
                    >
                        Supprimer
                    </MDButton>
                    <MDButton onClick={handleHideConfirmation} variant="contained">
                        Annuler
                    </MDButton>
                </Box>
            </Modal>
            <Modal
                open={showUpdateDialog}
                onClose={() => setShowUpdateDialog(false)}
                aria-labelledby="update-modal-title"
                aria-describedby="update-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" textAlign={'center'} mb={2} component="h2" gutterBottom>
                        Mise à jour du contact
                    </Typography>
                    <TextField
                        label="Nom"
                        fullWidth
                        value={updatedName}
                        onChange={(e) => setUpdatedName(e.target.value)}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <TextField
                        label="Téléphone"
                        fullWidth
                        value={updatedPhone}
                        onChange={(e) => setUpdatedPhone(e.target.value)}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <TextField
                        label="Email"
                        fullWidth
                        value={updatedEmail}
                        onChange={(e) => setUpdatedEmail(e.target.value)}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <MDButton
                        onClick={handleUpdate}
                        variant="contained"
                        color="info"
                        sx={{ marginRight: '1rem' }}
                    >
                        Mettre à jour
                    </MDButton>
                    <MDButton onClick={() => setShowUpdateDialog(false)} variant="contained">
                        Annuler
                    </MDButton>
                </Box>
            </Modal>
            <Dialog
                open={addDialogOpen}
                onClose={closeCreate}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Créer Compte
                </DialogTitle>
                <DialogContent>
                    {!selectedContact?.username && !selectedContact?.password ? (
                        <>
                            <TextField
                                label="Nom d'utilisateur"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Mot de passe"
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <Button
                                type="button"
                                variant="contained"
                                color="dark"
                                sx={{
                                    color: '#fff !important',
                                    marginTop: '12px',
                                    backgroundColor: '#0d6efd !important',
                                }}
                                onClick={handleSubmit}
                            >
                                Sauvegarder
                            </Button>
                        </>
                    ) : (
                        <Container>
                            <Typography variant="h6" textAlign={'center'} gutterBottom>
                                Ce contact a déjà un compte. Voulez-vous le désactiver ?
                            </Typography>
                            <Box sx={{
                                textAlign: 'center',
                                justifyContent: 'center'
                            }}>
                                <Button
                                    onClick={handleDesactivate}
                                    sx={{
                                        color: "#fff !important",
                                        backgroundColor: "green !important",
                                    }}>
                                    Oui
                                </Button>
                                <Button
                                    onClick={closeCreate}
                                    sx={{
                                        marginLeft: '0.6rem',
                                        color: "#fff !important",
                                        backgroundColor: "red !important",
                                    }}>
                                    Non
                                </Button>
                            </Box>
                        </Container>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCreate}>Annuler</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default Contacts;
