import VehiculeService from '../services/vehiculeServices';
import {
    CREATE_VEHICULE_SUCCESS,
    CREATE_VEHICULE_FAILURE,
    UPDATE_VEHICULE_SUCCESS,
    UPDATE_VEHICULE_FAILURE,
    GET_VEHICULE_SUCCESS,
    GET_VEHICULE_FAILURE,
    DELETE_VEHICULE_SUCCESS,
    DELETE_VEHICULE_FAILURE,
    GET_ALL_VEHICULES_SUCCESS,
    GET_ALL_VEHICULES_FAILURE,
    GET_USER_VEHICULES_SUCCESS,
GET_USER_VEHICULES_FAILURE,
} from './types';

export const CreateVehicule = (vehiculeData) => {
  return (dispatch) => {
    return VehiculeService.addVehicule(vehiculeData)
      .then((response) => {
        dispatch({
          type: CREATE_VEHICULE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_VEHICULE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllVehicules = () => {
  return (dispatch) => {
    return VehiculeService.getAllVehicules()
      .then((response) => {
        dispatch({
          type: GET_ALL_VEHICULES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_VEHICULES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateVehicule = (vehiculeId, updatedVehicule) => {
  return (dispatch) => {
    return VehiculeService.updateVehicule(vehiculeId, updatedVehicule)
      .then((response) => {
        dispatch({
          type: UPDATE_VEHICULE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_VEHICULE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getVehicule = (vehiculeId) => {
  return (dispatch) => {
    return VehiculeService.fetchVehicule(vehiculeId)
      .then((response) => {
        dispatch({
          type: GET_VEHICULE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_VEHICULE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteVehicule = (vehiculeId) => {
  return (dispatch) => {
    return VehiculeService.deleteVehicule(vehiculeId)
      .then((response) => {
        dispatch({
          type: DELETE_VEHICULE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_VEHICULE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllUserVehicules = (userId) => {
  return (dispatch) => {
    return VehiculeService.fetchUserVehicules(userId)
      .then((response) => {
        dispatch({
          type: GET_USER_VEHICULES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_VEHICULES_FAILURE,
          payload: error.message,
        });
      });
  };
};
