import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    TextField,
    MenuItem,
    Grid,
    Typography,
    useTheme,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import StatisticsServices from '../../../services/statisticsServices';
import { getAllUsers } from "../../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";

const UserTasksByYear = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [selectedYear, setSelectedYear] = useState(`${currentYear}`);
    const [data, setData] = useState(null);
    const users = useSelector((state) => state.users.users);
    const [selectedUser, setSelectedUser] = useState("1");

    useEffect(() => {
        if (selectedYear === '') {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            setSelectedYear(String(currentYear));
        }
    }, []);

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    const fetchData = async (year, userId) => {
        try {
            const response = await StatisticsServices.confirmedTasksCountByUserAndMonth(year, userId);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };

    useEffect(() => {
        if (selectedYear && selectedUser) {
            fetchData(selectedYear, selectedUser);
        }
    }, [selectedYear]);

    useEffect(() => {
        if (selectedYear && selectedUser) {
            fetchData(selectedYear, selectedUser);
        }
    }, [selectedUser]);


    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };


    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 2017;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            years.push(year);
        }

        return years.map((year) => (
            <MenuItem key={year} value={year}>
                {year}
            </MenuItem>
        ));
    };


    return (
        <>
            <Box
                mt="25px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <TextField
                            select
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Sélectionner l'année"
                            fullWidth
                        >
                            <MenuItem value="">-- Select Year --</MenuItem>
                            {generateYearOptions()}
                        </TextField>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            select
                            label="Personnel"
                            fullWidth
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                        >
                            {users?.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user.nom}&nbsp;{user.prenom}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Box>
            <Box height="500px" mb={5}>
                {data && data.length > 0 ? (
                    <ResponsiveBar
                        data={data}
                        keys={['taskCount']}
                        indexBy="mois"
                        margin={{ top: 50, right: 80, bottom: 120, left: 30 }}
                        padding={0.3}
                        colors={{ scheme: 'category10' }}
                        colorBy={(bar) => bar.data.mois}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -45,
                            truncateTickAt: -80,
                            labelSkipWidth: 120,
                            labelSkipHeight: 80,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        enableGridX={false}
                        enableGridY={true}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        theme={theme.nivo}
                    />
                ) : (
                    <Typography variant="body2" mt={5} p={5}>Oops! Aucune donnée disponible </Typography>
                )}
            </Box>
        </>
    );
};

export default UserTasksByYear;

