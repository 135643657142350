import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllDevis, deleteDevis, updateDevisStatut } from "../../actions/DevisActions";
import { createFacture } from '../../actions/FactureActions';
import FactureService from "../../services/factureServices";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    IconButton,
    Tooltip,
    Box,
} from "@mui/material";
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import { useMaterialUIController } from "../../context";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import AccessDenied from "../Errors/AccessDenied";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ValiderDevis from "./ValiderDevis";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DevisPreview from "./DevisPreview";
import { ToWords } from 'to-words';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const StyledDataGrid = styled(DataGrid)(() => ({
    '& .super-app-theme--oui': {
        backgroundColor: 'rgb(192, 216, 193)',
        '&:hover': {
            backgroundColor: 'rgb(150, 190, 152)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(150, 190, 152)',
            '&:hover': {
                backgroundColor: 'rgb(150, 190, 152)',
            },
        },
    },
    '& .super-app-theme--non': {
        backgroundColor: 'rgb(241, 192, 192)',
        '&:hover': {
            backgroundColor: 'rgb(233, 151, 151)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(233, 151, 151)',
            '&:hover': {
                backgroundColor: 'rgb(233, 151, 151)',
            },
        },
    },
    '& .super-app-theme--en': {
        backgroundColor: 'rgb(249, 210, 179)',
        '&:hover': {
            backgroundColor: 'rgb(246, 181, 128)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgb(246, 181, 128)',
            '&:hover': {
                backgroundColor: 'rgb(246, 181, 128)',
            },
        },
    },
}));

const Quotes = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const quotes = useSelector((state) => state.devis.devis);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedDevis, setSelectedDevis] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        dispatch(getAllDevis());
    }, [dispatch]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openDeleteModal = (quote) => {
        setSelectedDevis(quote);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedDevis(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedDevis) {
            dispatch(deleteDevis(selectedDevis.id))
                .then(() => {
                    dispatch(getAllDevis());
                });
            setSuccessMessage('devis supprimé avec succès!');
            setSnackbarOpen(true);
        }
        closeDeleteModal();
    };

    const handleReject = () => {
        if (selectedItem) {
            dispatch(updateDevisStatut(selectedItem.id, { valider: 'non' }));
            dispatch(getAllDevis());
            setIsRejectDialogOpen(false);
            setSuccessMessage('Devis rejeté avec succès!');
            setSnackbarOpen(true);
        }
    };

    const openDialog = (item) => {
        setSelectedItem(item);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
        dispatch(getAllDevis());
    };

    const openDetailsDialog = (item) => {
        setSelectedItem(item);
        setIsDetailsDialogOpen(true);
    };

    const closeDetailsDialog = () => {
        setSelectedItem(null);
        setIsDetailsDialogOpen(false);
    };

    const openRejectDialog = (item) => {
        setSelectedItem(item);
        setIsRejectDialogOpen(true);
    };

    const closeRejectDialog = () => {
        setSelectedItem(null);
        setIsRejectDialogOpen(false);
    };

    const handleCreateFacture = (quote) => {
        const factureData = {
            date: quote.date,
            objet: quote.objet,
            clientId: quote.clientId,
        };

        dispatch(createFacture(factureData))
            .then((createdFacture) => {
                FactureService.updateItemsByDevisId(quote.id, { factureId: createdFacture.id })
                    .then(() => {
                        dispatch(getAllDevis());
                        setSuccessMessage('Facture created successfully!');
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.log("Error updating items:", error);
                    });
            })
            .catch((error) => {
                console.log("Error creating Facture:", error);
            });
    };

    function numberToWordsFrench(number, U = null, D = "Cts") {
        if (number === null) return "Nombre non valide";
        const letter = {
            0: "zéro",
            1: "un",
            2: "deux",
            3: "trois",
            4: "quatre",
            5: "cinq",
            6: "six",
            7: "sept",
            8: "huit",
            9: "neuf",
            10: "dix",
            11: "onze",
            12: "douze",
            13: "treize",
            14: "quatorze",
            15: "quinze",
            16: "seize",
            17: "dix-sept",
            18: "dix-huit",
            19: "dix-neuf",
            20: "vingt",
            30: "trente",
            40: "quarante",
            50: "cinquante",
            60: "soixante",
            70: "soixante-dix",
            80: "quatre-vingt",
            90: "quatre-vingt-dix",
        };

        function getUnits(num) {
            if (num < 20) return letter[num];
            if (num < 100) {
                const unit = num % 10;
                return letter[Math.floor(num / 10) * 10] + (unit !== 0 ? "-" + letter[unit] : "");
            }
            return "";
        }

        function convertToWords(nb) {
            nb = parseFloat(nb.toString().replace(/ /gi, ""));
            if (isNaN(nb)) return "Nombre non valide";
            if (Math.ceil(nb) !== nb) {
                nb = nb.toString().split('.');
                return convertToWords(nb[0]) + " Dihrams et " + convertToWords(nb[1]) + (D ? " " + D : "");
            }

            const n = nb.toString().length;
            switch (n) {
                case 1:
                    return letter[nb];
                case 2:
                    if (nb > 19) {
                        const quotient = Math.floor(nb / 10);
                        const reste = nb % 10;
                        if (nb < 71 || (nb > 79 && nb < 91)) {
                            if (reste === 0) return letter[quotient * 10];
                            if (reste === 1) return letter[quotient * 10] + "-et-" + letter[reste];
                            return letter[quotient * 10] + "-" + letter[reste];
                        }
                        return letter[(quotient - 1) * 10] + "-" + letter[10 + reste];
                    }
                    return letter[nb];
                case 3:
                    const quotient = Math.floor(nb / 100);
                    const reste = nb % 100;
                    if (quotient === 1 && reste === 0) return "cent";
                    if (quotient === 1 && reste !== 0) return "cent" + " " + convertToWords(reste);
                    if (quotient > 1 && reste === 0) return letter[quotient] + " cents";
                    return letter[quotient] + " cent " + convertToWords(reste);
                case 4:
                case 5:
                case 6:
                    const quotientThousands = Math.floor(nb / 1000);
                    const resteThousands = nb - quotientThousands * 1000;
                    if (quotientThousands === 1 && resteThousands === 0) return "mille";
                    if (quotientThousands === 1 && resteThousands !== 0) return "mille" + " " + convertToWords(resteThousands);
                    if (quotientThousands > 1 && resteThousands === 0) return convertToWords(quotientThousands) + " mille";
                    return convertToWords(quotientThousands) + " mille " + convertToWords(resteThousands);
                default:
                    return "Nombre non géré";
            }
        }

        return convertToWords(number);
    }
    const toWords = new ToWords({ localeCode: 'fr-FR' });

    const handleExportToWord = async (devis) => {
        try {
            const response = await axios.get('/devis_template.docx', {
                responseType: 'arraybuffer',
            });

            const projectTemplate = response.data;
            const zip = new PizZip(projectTemplate);
            const doc = new Docxtemplater(zip);
            const [integerPart, decimalPart] = devis?.montant_ttc.split('.');
            let wordsIntegerPart = toWords.convert(integerPart);

            let wordsDecimalPart = '';
            if (decimalPart) {
                wordsDecimalPart = toWords.convert(decimalPart);
            }

            const data = {
                NumeroDevis: devis.numero || "-",
                objet: devis.objet || "-",
                creation: devis.date || "-",
                montantht: devis.montant_ht || "-",
                montantttc: (devis.montant_ht * 1.2).toFixed(2) || "-",
                montantttcwords: numberToWordsFrench(devis.montant_ht * 1.2, { lang: "fr" })
                    || "-",
                wordsIntegerPart: wordsIntegerPart || '00',
                wordsDecimalPart: wordsDecimalPart || '00',
                client: devis.client?.full_name || "-",
                adresse: devis.client?.adresse || "-",
                phone: devis.client?.telephone || "-",
                items: devis.items.map(item => ({
                    ...item,
                    ofPrice: (item.quantity * item.prix_unitaire).toFixed(2),
                })) || "-",
                tva: ((devis.montant_ht * 1.2) - (devis.items.length > 0
                    ? devis.items.reduce((total, item) => total + (item.quantity * item.prix_unitaire), 0)
                    : "-")).toFixed(2),
            };

            doc.setData(data);

            try {
                doc.render();
            } catch (error) {
                console.error('Error rendering the template:', error);
                return;
            }

            try {
                const blob = doc.getZip().generate({ type: 'blob' });
                saveAs(blob, `Devis_${devis.numero}.docx`);
            } catch (error) {
                console.error('Error generating the Word document:', error);
            }
        } catch (error) {
            console.error('Error fetching the template:', error);
        }
    };

    const columns = [
        {
            field: 'id', headerName: 'ID',
            flex: 1
        },
        {
            field: 'client?.full_name', headerName: 'Client',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.client?.full_name || '',
        },
        {
            field: 'date', headerName: 'Date',
            ...(isMobile ? { width: 120 } : { flex: 1 })
        },
        {
            field: 'objet', headerName: 'Objet',
            ...(isMobile ? { width: 120 } : { flex: 1 })
        },
        {
            field: 'montant_ht', headerName: 'Montant HT',
            ...(isMobile ? { width: 120 } : { flex: 1 })
        },
        {
            field: 'valider',
            headerName: 'Statut',
            flex: 1,
            valueGetter: (params) => params.row.valider || '-',
            renderCell: (params) => {
                let statusLabel, statusColor;

                switch (params.row.valider) {
                    case 'oui':
                        statusLabel = 'Confirmé';
                        statusColor = 'green';
                        break;
                    case 'non':
                        statusLabel = 'Rejeter';
                        statusColor = 'red';
                        break;
                    case 'en cours':
                        statusLabel = 'En cours';
                        statusColor = 'orange';
                        break;
                    default:
                        statusLabel = '-';
                        statusColor = 'black';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statusLabel}</span>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            ...(isMobile ? { width: 220 } : { flex: 4 }),
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Voir les détails" arrow>
                        <IconButton color="warning" onClick={() => openDetailsDialog(params.row)}>
                            <VisibilityIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les devis")) && (
                        <Tooltip title="Modifier" arrow>
                            <IconButton color="info" component={Link} to={`/finances/devis/update/${params.row.id}`} >
                                <BorderColorIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les devis")) && (
                        <Tooltip title="Supprimer" arrow>
                            <IconButton color="error" onClick={() => openDeleteModal(params.row)}>
                                <DeleteIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les devis")) && (
                        <>
                            {params.row.valider === 'en cours' && (
                                <Tooltip title="Valider" arrow>
                                    <IconButton color="success" onClick={() => openDialog(params.row)}>
                                        <DoneOutlineIcon fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les devis")) && (
                        <>
                            {params.row.valider === 'en cours' && (
                                <Tooltip title="Rejeter" arrow>
                                    <IconButton color="error" onClick={() => openRejectDialog(params.row)}>
                                        <HighlightOffIcon fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>

                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Exporter les devis")) && (
                        <Tooltip title="Télécharger" arrow>
                            <IconButton color="dark" onClick={() => handleExportToWord(params.row)}>
                                <DownloadForOfflineIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ),
        },
    ];

    const createButton = (
        <>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des devis")) &&
                <Link to={`/finances/devis/create`} className="text-decoration-none">
                    <MDButton
                        variant="contained"
                        color='info'
                        className='mt-1 ms-1'
                        backgroundColor={'transparent'}
                        size='small'
                    >
                        <ControlPointIcon sx={{ mr: "12px" }} />
                        Ajouter
                    </MDButton>
                </Link>
            }
        </>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les devis")) ? (
                <>
                    <>
                        <Typography variant="h2" textAlign={"center"} mb={5} gutterBottom>
                            Liste des devis
                        </Typography>
                        {quotes.length === 0 ? (
                            <>
                                <Typography variant="body1">Aucun devis disponible.</Typography>
                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des devis")) &&
                                    <Link to={`/finances/devis/create`} className="text-decoration-none float-end">
                                        <MDButton variant="contained" color="success">
                                            <ControlPointIcon sx={{ mr: "10px" }} />
                                            Ajouter
                                        </MDButton>
                                    </Link>
                                }
                            </>
                        ) : (
                            <>
                                <StyledDataGrid
                                    className={darkMode ? "text-light" : "text-dark"}
                                    rows={quotes}
                                    columns={columns}
                                    components={{
                                        Toolbar: (props) => (
                                            <div className='d-flex'>
                                                {createButton}
                                                <GridToolbar {...props} />
                                            </div>
                                        ),
                                    }}
                                    getRowClassName={(params) => `super-app-theme--${params.row.valider}`}
                                    loading={false}
                                    autoHeight
                                    pagination
                                    pageSize={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    rowCount={quotes.length}
                                    onPageSizeChange={handleChangeRowsPerPage}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                />

                            </>
                        )}
                    </>
                    <Dialog open={isRejectDialogOpen} onClose={closeRejectDialog}>
                        <DialogTitle>Confirmation de la suppression</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Êtes-vous sûr de vouloir rejeter ce devis ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={closeRejectDialog}>Annuler</MDButton>
                            <MDButton onClick={handleReject} color="error">
                                Rejeter
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                        <DialogTitle>confirmer la rejection</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Êtes-vous sûr de vouloir supprimer ce devis ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={closeDeleteModal}>Annuler</MDButton>
                            <MDButton onClick={handleDelete} color="error">
                                Supprimer
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={isDetailsDialogOpen}
                        onClose={closeDetailsDialog}
                        fullWidth
                        maxWidth='md'
                    >
                        <DialogTitle textAlign={'center'}>Détails de devis</DialogTitle>
                        <DialogContent>
                            <DevisPreview devis={selectedItem} />
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={closeDetailsDialog}>Annuler</MDButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={isDialogOpen}
                        onClose={closeDialog}
                        fullWidth
                        maxWidth="md"
                    >
                        <>
                            <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                                Valider devis
                            </DialogTitle>
                            <DialogContent>
                                <ValiderDevis devis={selectedItem} userId={currentUser.id} handleClose={closeDialog} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog} color="primary">
                                    Annuler
                                </Button>
                            </DialogActions>
                        </>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={isSnackbarOpen}
                        autoHideDuration={3000}
                        onClose={() => setSnackbarOpen(false)}
                        message={successMessage}
                    />
                </>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout>
    );
};

export default Quotes;

