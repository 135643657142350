import {
    CREATE_CLIENT_SUCCESS,
    UPDATE_CLIENT,
    RETRIEVE_CLIENT,
    DELETE_CLIENT,
    GET_CLIENT,
    SET_MESSAGE,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_FAILURE,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE,
    GET_FILES_SUCCESS,
    GET_FILES_FAILURE,
} from "./types";

import ClientServices from "../services/clientServices";

export const uploadClientFiles = (clientId, formData) => {
    return async (dispatch) => {
        try {
            const response = await ClientServices.uploadFilesToClient(clientId, formData);
            dispatch({
                type: UPLOAD_FILES_SUCCESS,
                payload: response.data,
            });
            return response;
        } catch (error) {
            dispatch({
                type: UPLOAD_FILES_FAILURE,
                payload: error.response ? error.response.data : 'An error occurred',
            });
            throw error;
        }
    };
};

export const fetchClientFiles = (clientId) => {
    return (dispatch) => {
        return ClientServices.getAllClientFiles(clientId)
            .then((response) => {
                dispatch({
                    type: GET_FILES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_FILES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchFileContent = (clientId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await ClientServices.getFileContent(clientId, fileName);
            return response;
        } catch (error) {
            console.error('Error fetching file content:', error);
            throw error;
        }
    };
};

export const downloadFile = (clientId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await ClientServices.downloadFile(clientId, fileName);

            if (response.data instanceof Blob) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            } else {
                console.error('Invalid file content in the response.');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
};

export const deleteFile = (clientId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await ClientServices.deleteFile(clientId, fileName);
            return response;
        } catch (error) {
            console.error('Error deleting file:', error);
            throw error;
        }
    };
};

export const createClient = (clientData) => async (dispatch) => {
    try {
        const res = await ClientServices.createClient(clientData);

        dispatch({
            type: CREATE_CLIENT_SUCCESS,
            payload: res.data
        });

        return Promise.resolve(res.data);
    } catch (err) {
        const message =
            err.response && err.response.data.message
                ? err.response.data.message
                : err.message || "Error creating client";

        dispatch({
            type: SET_MESSAGE,
            payload: message
        });

        return Promise.reject(err);
    }
};

export const updateClient = (id, data) => async (dispatch) => {
    try {
        const res = await ClientServices.updateClient(id, data);

        dispatch({
            type: UPDATE_CLIENT,
            payload: res.data
        });

        return Promise.resolve(res.data);
    } catch (err) {
        const message =
            err.response && err.response.data.message
                ? err.response.data.message
                : err.message || "Error updating client";

        dispatch({
            type: SET_MESSAGE,
            payload: message
        });

        return Promise.reject(err);
    }
};

export const deleteClient = (id) => async (dispatch) => {
    try {
        await ClientServices.deleteClient(id);

        dispatch({
            type: DELETE_CLIENT,
            payload: id
        });
    } catch (err) {
        const message =
            err.response && err.response.data.message
                ? err.response.data.message
                : err.message || "Error deleting client";

        dispatch({
            type: SET_MESSAGE,
            payload: message
        });
    }
};

export const getClient = (id) => async (dispatch) => {
    try {
        const res = await ClientServices.fetchClient(id);

        dispatch({
            type: GET_CLIENT,
            payload: res.data
        });
    } catch (err) {
        const message =
            err.response && err.response.data.message
                ? err.response.data.message
                : err.message || "Error retrieving client";

        dispatch({
            type: SET_MESSAGE,
            payload: message
        });
    }
};

export const ftechClient = (id) => {
    return (dispatch) => {
        return ClientServices.fetchClient(id)
            .then((response) => {
                dispatch({
                    type: GET_CLIENT_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_CLIENT_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getAllClients = () => async (dispatch) => {
    try {
        const res = await ClientServices.getAll();

        dispatch({
            type: RETRIEVE_CLIENT,
            payload: res.data
        });
    } catch (err) {
        const message =
            err.response && err.response.data.message
                ? err.response.data.message
                : err.message || "Error retrieving clients";

        dispatch({
            type: SET_MESSAGE,
            payload: message
        });
    }
};
