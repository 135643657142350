import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getPrimes, updatePrimes } from '../../actions/PrimesActions';
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';

const UpdatePrime = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  let navigate = useNavigate();
  const prime = useSelector((state) => state.primes.prime);

  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    montant: '',
    type: 'non imposable',
    mois: '',
  });

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    dispatch(getPrimes(id));
  }, [dispatch]);

  useEffect(() => {
    if (prime) {
      const mois = prime?.mois ? prime?.mois.slice(0, 7) : '';

      setFormData({
        name: prime?.name,
        montant: prime?.montant,
        type: prime?.type,
        mois: mois,
      });
    }
  }, [prime]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdatePrime = () => {
    dispatch(updatePrimes(id, formData))
      .then(() => {
        setSuccessMessage('Prime updated successfully');
        navigate("/primes");
      })
      .catch((error) => {
        setErrorMessage(`Error updating prime: ${error.message}`);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les primes")) ? (
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Update Prime
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Prime Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Montant"
                  type="number"
                  name="montant"
                  value={formData.montant}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    fullWidth
                    disabled
                  >
                    <MenuItem value="imposable">Imposable</MenuItem>
                    <MenuItem value="non imposable">Non Imposable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Mois"
                  type="month"
                  name='mois'
                  value={formData.mois || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={handleUpdatePrime}
                >
                  Update Prime
                </MDButton>
              </Grid>
            </Grid>
          </form>

          {successMessage && (
            <Snackbar open={true} autoHideDuration={6000}>
              <Alert severity="success" onClose={() => setSuccessMessage(null)}>
                {successMessage}
              </Alert>
            </Snackbar>
          )}

          {errorMessage && (
            <Snackbar open={true} autoHideDuration={6000}>
              <Alert severity="error" onClose={() => setErrorMessage(null)}>
                {errorMessage}
              </Alert>
            </Snackbar>
          )}
        </Container>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

export default UpdatePrime;
