import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientFiles, fetchFileContent, downloadFile, deleteFile } from '../../actions/ClientActions';
import {
    IconButton,
    Tooltip,
    Snackbar,
    Box,
    Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import { Close, Delete, ArrowCircleDown } from '@mui/icons-material';

const FilePreview = ({ fileContent, fileName, onClose, clientId, currentUser }) => {
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        if (parts.length > 1) {
            return parts[parts.length - 1].toLowerCase();
        }
        return '';
    };
    const fileExtension = getFileExtension(fileName);

    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isPdf = fileExtension === 'pdf';

    const handleDownload = () => {
        dispatch(downloadFile(clientId, fileName));
    };

    const handleDelete = () => {
        setSnackbarOpen(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteFile(clientId, fileName));
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box mt={3}>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Exporter les fichiers")) && (
                    <Tooltip title='Télécharger' arrow>
                        <IconButton className='btn-sm me-1 mt-2' size='small' color={'info'} onClick={handleDownload}>
                            <ArrowCircleDown fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                )}
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Supprimer les fichiers")) && (
                    <Tooltip title='Supprimer' arrow>
                        <IconButton className='btn-sm ms-1 mt-2' size='small' color={'error'} onClick={handleDelete}>
                            <Delete fontSize='medium' />
                        </IconButton>
                    </Tooltip>
                )}
            <Tooltip title='Fermer' arrow>
                <IconButton className='btn-sm ms-2 mt-2' size='small' color={'secondary'} onClick={onClose}>
                    <Close fontSize='medium' />
                </IconButton>
            </Tooltip>
            {isImage && (
                <>
                    <img
                        src={`data:image/${fileExtension};base64,${fileContent}`}
                        alt="Preview"
                        width={'95%'}
                    />
                </>
            )}
            {isPdf && (
                <>
                    <embed
                        src={`data:application/pdf;base64,${fileContent}`}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                    />
                </>
            )}

            {!isImage && (!isPdf) && (
                <>
                    <p>Aperçu non disponible pour ce type de fichier.</p>
                </>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="warning"
                >
                    Êtes-vous sûr de vouloir supprimer ce fichier ?
                    <MDButton color="inherit" size="small" onClick={handleConfirmDelete}>
                        Supprimer
                    </MDButton>
                </Alert>
            </Snackbar>
        </Box>
    );
};

const ClientFilesList = ({ clientId }) => {
    const dispatch = useDispatch();
    const projectFiles = useSelector((state) => state.files.files);
    const [selectedFile, setSelectedFile] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    useEffect(() => {
        dispatch(fetchClientFiles(clientId));
    }, [dispatch, clientId]);


    const handleViewFile = async (fileName) => {
        try {
            const response = await dispatch(fetchFileContent(clientId, fileName));
            setSelectedFile({
                fileName,
                fileContent: response.data,
            });
        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    };

    const handleCloseFilePreview = () => {
        setSelectedFile(null);
    };


    return (
        <div>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers")) && (
                <>
                    {projectFiles.length > 0 ? (
                        <List>
                            {projectFiles.map((file, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={file} />
                                    <MDButton
                                        variant="contained"
                                        className='btn-sm mt-2'
                                        size='small'
                                        color="primary"
                                        onClick={() => handleViewFile(file)}
                                    >
                                        Aperçu
                                    </MDButton>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <p>Aucun fichier trouvé.</p>
                    )}
                    {selectedFile && (
                        <FilePreview
                            fileContent={selectedFile.fileContent}
                            fileName={selectedFile.fileName}
                            clientId={clientId}
                            onClose={handleCloseFilePreview}
                            currentUser={currentUser}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ClientFilesList;
