import React from "react";
import {
    Typography,
    Grid,
    Paper
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';

const ChragesPreview = ({ charge }) => {

    const formatDateForMonthDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        return `${year}-${month}`;
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            <Grid3x3Icon fontSize="medium" sx={{ mr: 1 }} />
                            Id
                        </Typography>
                        <Typography>{charge?.id}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            <EditCalendarIcon fontSize="medium" sx={{ mr: 1 }} />
                            Date création
                        </Typography>
                        <Typography>{charge?.createdAt}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            <PersonIcon fontSize="medium" sx={{ mr: 1 }} />
                            Personnel
                        </Typography>
                        <Typography>{charge?.user?.nom || '_'}&nbsp;{charge?.user?.prenom || '_'}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            <AssignmentIcon fontSize="medium" sx={{ mr: 1 }} />
                            Designation
                        </Typography>
                        <Typography>{charge?.designation}</Typography>
                    </Paper>
                </Grid>

                <Grid item md={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            <CommentIcon fontSize="medium" sx={{ mr: 1 }} />
                            Observation
                        </Typography>
                        <Typography>{charge?.note || 'Aucune remarque !'}</Typography>
                    </Paper>
                </Grid>

                <Grid item md={6}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                            Cout
                        </Typography>
                        <Typography component="div" variant="h5" gutterBottom>
                            {charge?.cout}&nbsp;
                            <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item md={6}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            <CalendarMonthIcon fontSize="medium" sx={{ mr: 1 }} />
                            Mois
                        </Typography>
                        <Typography component="div" variant="h5" gutterBottom>
                            {formatDateForMonthDisplay(charge?.mois)}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default ChragesPreview;