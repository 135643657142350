import React, { useEffect, useState } from 'react';
import ProjetService from '../../services/projetServices';
import { Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, TablePagination } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const Top10ClientsByAmount = () => {
    const [top10Clients, setTop10Clients] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        fetchTop10ClientsByAmount();
    }, []);

    const fetchTop10ClientsByAmount = async () => {
        try {
            const response = await ProjetService.getTop10ClientsByAmount();
            setTop10Clients(response.data);
        } catch (error) {
            console.error('Error fetching top 10 clients by amount:', error);
        }
    };

    const chartData = top10Clients.map((client) => ({
        clientId: client.id,
        fullName: client.full_name,
        totalPrix: parseFloat(client.totalPrix),
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIdx = page * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    const paginatedData = top10Clients.slice(startIdx, endIdx);
    const chartPaginatedData = paginatedData.map((client) => ({
        clientId: client.id,
        fullName: client.full_name,
        totalPrix: parseFloat(client.totalPrix),
    }));

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <Typography variant="h3" textAlign={'center'} gutterBottom>
                    Top 10 Clients by Amount
                </Typography>
                {top10Clients.length === 0 ? (
                    <Typography variant="body1">No data available.</Typography>
                ) : (
                    <>
                        <Box alignItems={'center'} style={{ height: 300 }}>
                            <ResponsiveBar
                                data={chartPaginatedData}
                                keys={['totalPrix']}
                                indexBy="clientId"
                                margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                                padding={0.3}
                                colors={{ scheme: 'category10' }}
                                axisBottom={{
                                    tickRotation: -45,
                                }}
                                axisLeft={{
                                    tickValues: 5,
                                    tickPadding: 5,
                                    tickSize: 5,
                                    tickRotation: 0,
                                    legend: 'Total Price',
                                }}
                                enableGridX
                                enableGridY
                                labelTextColor="inherit:darker(1.2)"
                                tooltip={({ id, value, color }) => (
                                    <strong style={{ color }}>
                                        {id}: {value}
                                    </strong>
                                )}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Total Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.map((client) => (
                                        <TableRow key={client.id}>
                                            <TableCell>{client.full_name}</TableCell>
                                            <TableCell>{client.totalPrix}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={top10Clients.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </DashboardLayout>
        </>
    );
};

export default Top10ClientsByAmount;
