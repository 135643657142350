import StagiaireService from '../services/stagiaireServices';
import {
    CREATE_STAGIAIRE_SUCCESS,
    CREATE_STAGIAIRE_FAILURE,
    UPDATE_STAGIAIRE_SUCCESS,
    UPDATE_STAGIAIRE_FAILURE,
    GET_STAGIAIRE_SUCCESS,
    GET_STAGIAIRE_FAILURE,
    DELETE_STAGIAIRE_SUCCESS,
    DELETE_STAGIAIRE_FAILURE,
    GET_ALL_STAGIAIRES_SUCCESS,
    GET_ALL_STAGIAIRES_FAILURE,
} from './types';

export const createStagiaire = (stagiaireData) => {
  return (dispatch) => {
    return StagiaireService.createStagiaire(stagiaireData)
      .then((response) => {
        dispatch({
          type: CREATE_STAGIAIRE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_STAGIAIRE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllStagiaires = () => {
  return (dispatch) => {
    return StagiaireService.getAllStagiaires()
      .then((response) => {
        dispatch({
          type: GET_ALL_STAGIAIRES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_STAGIAIRES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateStagiaire = (stagiaireId, updatedStagiaire) => {
  return (dispatch) => {
    return StagiaireService.updateStagiaire(stagiaireId, updatedStagiaire)
      .then((response) => {
        dispatch({
          type: UPDATE_STAGIAIRE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_STAGIAIRE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getStagiaire = (stagiaireId) => {
  return (dispatch) => {
    return StagiaireService.fetchStagiaire(stagiaireId)
      .then((response) => {
        dispatch({
          type: GET_STAGIAIRE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_STAGIAIRE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteStagiaire = (stagiaireId) => {
  return (dispatch) => {
    return StagiaireService.deleteStagiaire(stagiaireId)
      .then((response) => {
        dispatch({
          type: DELETE_STAGIAIRE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_STAGIAIRE_FAILURE,
          payload: error.message,
        });
      });
  };
};
