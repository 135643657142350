import  API_URL from './apiUrl';

class TachesService {
  createTache(tacheData) {
    return API_URL.post("/tache/create", tacheData);
  }

  createNewTache(tacheData) {
    return API_URL.post("/tache/create/new/tache", tacheData);
  }

  getAllTache() {
    return API_URL.get("/tache");
  }

  updateTache(tacheId, updatedTache) {
    return API_URL.put(`/tache/taches/update/${tacheId}`, updatedTache);
  }

  fetchTache(tacheId) {
    return API_URL.get(`/tache/${tacheId}`);
  }

  fetchAllTaches() {
    return API_URL.get(`/tache/taches`);
  }

  deleteTache(tacheId) {
    return API_URL.delete(`/tache/delete/${tacheId}`);
  }

  fetchPendingTache(userId){
    return API_URL.get(`/tache/user/${userId}`);
  }

  fetchUserTaches(userId){
    return API_URL.get(`/tache/user/findAll/${userId}`);
  }

  confirmTache(tacheId){
    return API_URL.put(`/tache/taches/confirm/${tacheId}`);
  }

  fetchProjectTasks(projectId){
    return API_URL.get(`/tache/taches/project/${projectId}`);
  }

  ressignTache(tacheData) {
    return API_URL.post("/tache/taches/reassign/tache", tacheData);
  }
}

export default new TachesService();
