import API_URL from './apiUrl';

class PolylineService {

  addPolylineToProject(projectId, data) {
    return API_URL.post(`/survey/projects/${projectId}/polyline`, data
      ,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  getPolylinesByProjectId(projectId) {
    return API_URL.get(`/survey/projects/polyline/${projectId}`);
  }

  getPolylinesByZone(zone) {
    return API_URL.get(`/survey/projects/polyline/zone/${zone}`);
  }

  getPolylines() {
    return API_URL.get(`/survey/projects/polylines`);
  }

}

export default new PolylineService();