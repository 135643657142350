import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { login } from '../../actions/UserActions';
import logoImg from '../../assets/images/logo.png';
import logoGest from '../../assets/images/gest.png';
import {
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import './style.css';
import MDBox from "../../components/MDBox";
import bgImage from '../../assets/images/geo2.png';
import { FacebookOutlined, YouTube, Language } from '@mui/icons-material';
import { contactLogin } from "../../actions/ContactActions";

const Login = () => {
  let navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const form = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { message } = useSelector(state => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    dispatch(contactLogin({ username, password }))
      .then(() => {
        enqueueSnackbar('Connexion réussie!', { variant: 'success' });
        navigate("/");
      })
      .catch(() => {
        const errorMessage = message ? message : 'Login failed. Please check your credentials.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setLoading(false);
      });

  };

  if (currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div id="test" >
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              bgImage &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: '.7',
          }}
        />
        <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-3 mx-auto">
          <div class="card card0 border-0">
            <div class="row d-flex">
              <div class="col-lg-6 ">
                <div class="card1 pb-5">
                  <div class="row">
                    <img src={logoImg} class="logo" />
                  </div>
                  <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
                    <img src={logoGest} class="image" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5">
                  <div class="row mb-4 px-3">
                    <h6 class="mb-1 mt-2">Suivez-nous</h6>
                    <div class="facebook">
                      <FacebookOutlined fontSize="medium" />
                    </div>
                    <div class="website">
                      <Language fontSize="medium" />
                    </div>
                    <div class="youtube">
                      <YouTube fontSize="medium" />
                    </div>
                  </div>
                  <div class="row px-3 mb-4">
                    <div class="line"></div>

                  </div>
                  <div class="row px-3">
                    <label class="mb-1"><h6 class="mb-0 text-sm">Nom d'utilisateur</h6></label>
                    <input class="mb-4" type="text" name="email"
                      placeholder="Entrer votre nom d'utilisateur"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div class="row px-3">
                    <label class="mb-1"><h6 class="mb-0 text-sm">Mot de passe</h6></label>
                    <input type="password" name="password" placeholder="Mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div class="row mb-3 mt-4 px-3">
                    <button type="submit" class="btn btn-blue text-center" onClick={handleLogin}>Login</button>
                  </div>
                  {loading && <CircularProgress size={20} />}
                </div>
              </div>
            </div>
            <div class="bg-blue py-4">
              <div class=" px-3">
                <div className="float-start">
                  <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; {currentYear}. SysCamp All rights reserved.</small>
                </div>
                <div className="float-end">
                  <a href="" className="me-2"><small>About-Us</small></a>
                  <a href="" className="ms-2 me-1"><small>Guid</small></a>
                  <a href="" className="ms-2 me-1"><small>Support</small></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
