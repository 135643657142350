import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts, deleteProduct } from '../../actions/ProductsActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Tooltip,
} from "@mui/material";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { Delete, BorderColor, Add, Remove, Visibility } from '@mui/icons-material';
import AddProduct from './AddProduct';
import UpdateQuantity from './UpdateQuantity';
import AddQuantity from './AddQuantity';
import MinusQuantity from './MinusQuantity';
import ProductStock from './ProductStock';

const ProductsList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [minusDialogOpen, setMinusDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllProducts());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredProducts = products.filter((product) => {
            const name = product.name || '';
            return (
                name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
            .sort((a, b) => {
                if (a.createdAt === '0000-00-00' && b.createdAt !== '0000-00-00') {
                    return 1;
                } else if (b.createdAt === '0000-00-00' && a.createdAt !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }
            });

        setFilteredProducts(filteredProducts);
    }, [products, searchTerm]);

    const columns = [
        {
            field: 'name', headerName: 'Nom',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.name || '',
        },
        {
            field: 'quantity', headerName: 'Quantite',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'lastAdd', headerName: 'Ajoutée à',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'lastMin', headerName: 'Dernière modification',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'status', headerName: 'Statut',
            renderCell: (params) => {
                const { quantity } = params.row;
                let statut;
                let statusColor;

                if (quantity > 0) {
                    statut = 'En stock';
                    statusColor = 'green';
                } else {
                    statut = 'En rupture de stock';
                    statusColor = 'red';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statut}</span>
                );
            },
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },

        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <Tooltip title='Supprimer'>
                        <IconButton
                            className='me-1 ms-1'
                            color='error'
                            onClick={() => openDielog(params.row)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Historique des stocks'>
                        <IconButton
                            color='info'
                            onClick={() => openUpdateDielog(params.row)}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Ajouter Quantité'>
                        <IconButton
                            color='info'
                            onClick={() => openAddDialog(params.row)}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Réduire la quantité'>
                        <IconButton
                            color='info'
                            onClick={() => openMinusDialog(params.row)}
                        >
                            <Remove />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteProduct(selectedItem?.id))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Produit supprimé avec succès.');
                setSnackbarOpen(true);
                dispatch(fetchAllProducts());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur de suppression ! Veuillez réessayer.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openAddDialog = (item) => {
        setSelectedItem(item);
        setAddDialogOpen(true);
    }

    const closeAddDialog = () => {
        setSelectedItem(null);
        setAddDialogOpen(false);
    }

    const openMinusDialog = (item) => {
        setSelectedItem(item);
        setMinusDialogOpen(true);
    }

    const closeMinusDialog = () => {
        setSelectedItem(null);
        setMinusDialogOpen(false);
    }

    const openUploadDielog = () => {
        // setSelectedItem(item);
        setUploadDialogOpen(true);
    }

    const closeUploadDilaog = () => {
        setSelectedItem(null);
        setUploadDialogOpen(false);
    }

    const openUpdateDielog = (item) => {
        setSelectedItem(item);
        setUpdateDialogOpen(true);
    }

    const closeUpdateDilaog = () => {
        setSelectedItem(null);
        setUpdateDialogOpen(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const AddButton = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="6rem"
            bottom="6rem"
            zIndex={99}
            color="dark"
            sx={{
                cursor: "pointer",
                background: "#0288d1",
                color: "#fff !important"
            }}
            onClick={() => openUploadDielog()}
        >
            <Add fontSize="small" color="inherit" />
        </Box>
    );
    return (
        <>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || (currentUser.permissions.includes("Gérer le stock"))) ? (
                <>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher un produit"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                        >
                            <DataGrid
                                rows={filteredProducts}
                                columns={columns}
                                pagination
                                components={{ Toolbar: GridToolbar }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    {AddButton}
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer ce produit?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={uploadDialogOpen}
                        onClose={closeUploadDilaog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Ajouter des produits
                        </DialogTitle>
                        <DialogContent>
                            <AddProduct />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeUploadDilaog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={addDialogOpen}
                        onClose={closeAddDialog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Ajouter Quantité
                        </DialogTitle>
                        <DialogContent>
                            {selectedItem &&
                                <AddQuantity productId={selectedItem?.id} />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeAddDialog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={minusDialogOpen}
                        onClose={closeMinusDialog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Réduire la quantité
                        </DialogTitle>
                        <DialogContent>
                            {selectedItem &&
                                <MinusQuantity productId={selectedItem?.id} />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeMinusDialog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={updateDialogOpen}
                        onClose={closeUpdateDilaog}
                        fullWidth
                        maxWidth="lg"
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Modifier
                        </DialogTitle>
                        <DialogContent>
                            <ProductStock productId={selectedItem?.id} currentUser={currentUser} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeUpdateDilaog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant='filled' onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default ProductsList;