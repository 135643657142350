import API_URL from './apiUrl';

class TauxService {
    createTaux(tauxData) {
        return API_URL.post( "/taux/create", tauxData);
    }

    getAllTaux() {
        return API_URL.get("/taux");
    }

    updateTaux(tauxId, updatedTaux) {
        return API_URL.put(`/taux/update/${tauxId}`, updatedTaux);
    }

    fetchTaux(tauxId) {
        return API_URL.get(`/taux/${tauxId}`);
    }
    
    deleteTaux(tauxId) {
        return API_URL.delete(`/taux/delete/${tauxId}`);
    }
}

export default new TauxService();