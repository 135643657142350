import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUserSalaires, deleteSalaire, createSalaire } from '../../actions/SalaireActions';
import {
    Container,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Snackbar,
    Alert,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import MDButton from '../../components/MDButton';
import SalairePreview from './SalairePreview';
import UpdateUserSalaire from './UpdateSalaire';
import { Delete, BorderColor } from '@mui/icons-material';

const UserSalairesList = ({ darkMode, userId, user, currentUser }) => {
    const dispatch = useDispatch();
    const salaires = useSelector((state) => state.salaires.salaires);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [selectedPrimeId, setSelectedPrimeId] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [pageSize, setPageSize] = useState(25);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedItem, setSelectedItem] = useState(null);
    const [showGarderButton, setShowGarderButton] = useState(true);

    useEffect(() => {
        dispatch(fetchAllUserSalaires(userId));
    }, [dispatch]);

    const formatDateForMonthDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        return `${year}-${month}`;
    };

    useEffect(() => {
        const currentMonth = new Date().toISOString().slice(0, 7);
        const salaireExists = salaires.some((salaire) => formatDateForMonthDisplay(salaire.mois) === currentMonth);

        setShowGarderButton(!salaireExists);
    }, [salaires]);

    const filteredSalaires = selectedMonth
        ? salaires.filter((salaire) => salaire.mois.startsWith(selectedMonth))
        : salaires;

    const columns = [
        // { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'mois', headerName: 'Mois', flex: 1 },
        { field: 'salaire_base', headerName: 'Salaire de base', flex: 2 },
        { field: 'salaire_net', headerName: 'Net', flex: 1 },
        { field: 'salaire_brut', headerName: 'Brut', flex: 1 },
        { field: 'salaire_brutimpo', headerName: 'Brut imposable', flex: 1 },
        { field: 'primes', headerName: 'Primes', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 3,
            renderCell: (params) => (
                <>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les salaires")) && (
                        <Tooltip title='Modifier le salaire' arrow>
                            <IconButton
                                className='me-1'
                                color="info"
                                onClick={() => openUpdateDialog(params.row.id)}
                            >
                                <BorderColor />
                            </IconButton>
                        </Tooltip>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les salaires")) && (
                        <Tooltip title='Supprimer le salaire' arrow>
                            <IconButton
                                className='me-1'
                                color="error"
                                onClick={() => handleOpenDeleteModal(params.row.id)}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des salaires")) && (
                        <>
                            {showGarderButton && (
                                <Tooltip title='Garder Le même salaire pour le mois current' arrow>
                                    <MDButton
                                        className='ms-1'
                                        color="success"
                                        size='small'
                                        onClick={() => openDialog(selectedItem)}
                                    >
                                        Garder
                                    </MDButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                </>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    const handleOpenDeleteModal = (primeId) => {
        setSelectedPrimeId(primeId);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedPrimeId(null);
        setDeleteModalOpen(false);
    };

    const openDialog = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setDialogOpen(false);
    };

    const openUpdateDialog = (item) => {
        setSelectedItem(item);
        setUpdateDialogOpen(true);
    };

    const closeUpdateDialog = () => {
        setSelectedItem(null);
        setUpdateDialogOpen(false);
    };
    const handleDelete = () => {
        dispatch(deleteSalaire(selectedPrimeId))
            .then(() => {
                dispatch(fetchAllUserSalaires(userId));
            })
            .catch((error) => {
                console.error('Error delete Request:', error);
            });
        closeDeleteModal();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const Data = {
            mois: new Date(),
            salaire_base: selectedItem?.salaire_base || 0,
            primes: selectedItem?.salaire_base || 0,
            salaire_brut: selectedItem?.salaire_brut || 0,
            salaire_net: selectedItem?.salaire_net || 0,
            salaire_brutimpo: selectedItem?.salaire_brutimpo || 0,
            nb_heures25: selectedItem?.nb_heures25 || 0,
            nb_heures50: selectedItem?.nb_heures50 || 0,
            nb_heures100: selectedItem?.nb_heures100 || 0,
            userId: userId,
        }
        dispatch(createSalaire(Data))
            .then(() => {
                setSnackbarMessage('Salaire a été gardé avec succès.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                closeDialog();
            })
            .catch((error) => {
                setSnackbarMessage(error.response.data.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('Error creating avance:', error);
            });
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    return (
        <Container>
            <div className='mt-4 mb-3' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <TextField
                    label="Filtrer les salaires par mois"
                    type="month"
                    fullWidth
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <DataGrid
                rows={filteredSalaires}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                autoHeight
                pageSize={pageSize}
                className={darkMode ? "text-light" : "text-dark"}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            />

            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle className="text-dark">Confirmation de la suppression</DialogTitle>
                <DialogContent>
                    <DialogContentText className="text-dark">
                        Etes-vous sûr de vouloir supprimer ce Salaire?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={closeDeleteModal} color="light">
                        Annuler
                    </MDButton>
                    <MDButton onClick={handleDelete} color="error">
                        Supprimer
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth='md'
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        color: '#fff !important',
                        backgroundColor: '#2196f3 !important'
                    }}
                >
                    Confirmation de Salaire
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h5' mt={2} mb={2}>
                        Êtes-vous sûr de vouloir conserver ce salaire pour ce mois-ci?
                    </Typography>
                    <SalairePreview selectedItem={selectedItem} user={user} />
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={closeDialog} color="light">
                        Annuler
                    </MDButton>
                    <MDButton onClick={handleSubmit} color="info">
                        Garder
                    </MDButton>
                </DialogActions>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{ mt: 2 }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth='md'
                open={isUpdateDialogOpen}
                onClose={closeUpdateDialog}
            >
                <DialogTitle className="text-dark">Confirmation de la suppression</DialogTitle>
                <DialogContent>
                    <UpdateUserSalaire user={user} salaireId={selectedItem} />
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={closeUpdateDialog} color="light">
                        Annuler
                    </MDButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default UserSalairesList;
