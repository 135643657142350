import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon as LeafletPolygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';
import L from 'leaflet';
import PolygonServices from '../../services/polygonServices';

const ProjectPolygons = () => {
    const mapRef = useRef(null);
    const [convertedCoordinates, setConvertedCoordinates] = useState([]);
    const [initialCenter, setInitialCenter] = useState([30.39341198110206, -9.565342191279383]);
    const [initialZoom, setInitialZoom] = useState(10);
    const [polygons, setPolygons] = useState([]);

    useEffect(() => {
        fetchPolygons();
    }, []);

    const fetchPolygons = async () => {
        try {
            const response = await PolygonServices.getPolygons();
            setPolygons(response.data.polygons);
        } catch (error) {
            console.error('Error fetching polygons:', error);
        }
    };

    useEffect(() => {
        if (polygons?.length === 0) return;

        const zone = polygons[0]?.zone;
        let sourceCRS;

        switch (zone) {
            case '26191':
                sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
                break;
            case '26192':
                sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
                break;
            case '26194':
                sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
                break;
            case '26195':
                sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
                break;
            default:
                sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }

        const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

        proj4.defs('EPSG:3857', targetCRS);
        proj4.defs(`EPSG:${zone}`, sourceCRS);

        const converted = polygons[0]?.geometry?.coordinates[0].map(coord => {
            const [x, y] = proj4(sourceCRS, targetCRS, coord);
            return [y, x];
        });

        setConvertedCoordinates(converted);
        setInitialCenter(converted[0]);
        setInitialZoom(18);
    }, [polygons]);

    useEffect(() => {
        console.log('initialCenter', initialCenter);
        console.log('setInitialZoomout', initialZoom);
    }, [initialCenter, initialZoom]);

    return (
        <>
            {convertedCoordinates?.length > 0 && (
                <MapContainer
                    center={initialCenter}
                    zoom={initialZoom}
                    style={{ height: '80vh', width: '100%' }}
                    whenCreated={(mapInstance) => {
                        mapRef.current = mapInstance;
                    }}
                >
                    <TileLayer
                        url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                        maxZoom={21}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                    />
                    <LeafletPolygon positions={convertedCoordinates} />
                </MapContainer>
            )}
        </>
    );
};

export default ProjectPolygons;
// const ProjectPolygons = ({ polygons }) => {
//     const mapRef = useRef(null);
//     const [convertedCoordinates, setConvertedCoordinates] = useState([]);
//     const zone = polygons[0].zone;
//     console.log('polygons',polygons);
//     console.log(zone);
//     const originalCoordinates = [
//         [99755.5, 384314.2],
//         [99760.5, 384301.7],
//         [99772.5, 384306.7],
//         [99766.2, 384322.4],
//         [99764, 384324.1],
//     ];

//     console.log('originalCoordinates', originalCoordinates);
//     useEffect(() => {
//         const sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
//         const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

//         proj4.defs('EPSG:26192', sourceCRS);
//         proj4.defs('EPSG:3857', targetCRS);

//         const converted = originalCoordinates.map(coord => {
//             const [x, y] = proj4(sourceCRS, targetCRS, coord);
//             return [y, x];
//         });

//         setConvertedCoordinates(converted);
//     }, []);

//     console.log('convertedCoordinates', convertedCoordinates);

//     return (
//         <>
//             <MapContainer
//                 center={[30.39341198110206, -9.565342191279383]}
//                 zoom={10}
//                 style={{ height: '80vh', width: '100%' }}
//                 whenCreated={(mapInstance) => {
//                     mapRef.current = mapInstance;
//                 }}
//             >
//                 <TileLayer
//                     url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
//                     maxZoom={21}
//                     subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
//                     attribution='<a href="https://www.google.com/maps">Google Maps</a>'
//                 />
//                 <LeafletPolygon positions={convertedCoordinates} />
//             </MapContainer>
//         </>
//     );
// };


// import React, { useState, useEffect, useRef } from 'react';
// import { MapContainer, TileLayer, Polygon as LeafletPolygon } from 'react-leaflet';
// import MuiAlert from '@mui/material/Alert';
// import PolygonServices from '../../services/polygonServices';
// import proj4 from 'proj4';
// import 'proj4leaflet';
// import L from 'leaflet';

// const ProjectPolygons = ({ polygons }) => {
//     const [error, setError] = useState(null);
//     const mapRef = useRef(null);

//     useEffect(() => {
//         if (polygons.length > 0 && mapRef.current && polygons[0].geometry && polygons[0].geometry.coordinates) {
//             mapRef.current.fitBounds(polygons[0].geometry.coordinates[0]);
//         }
//     }, [polygons]);

//     console.log(polygons);
//     const getCRS = () => {
//         if (!polygons || polygons.length === 0 || !polygons[0].zone) {
//             return new L.Proj.CRS('EPSG:26192', '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32', {
//                 transformation: new L.Transformation(1, 0, -1, 0),
//             });
//         }

//         const zone = polygons[0].zone;
//         let proj4def;

//         switch (zone) {
//             case '26191':
//                 proj4def = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
//                 break;
//             case '26192':
//                 proj4def = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
//                 break;
//             case '26194':
//                 proj4def = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
//                 break;
//             case '26195':
//                 proj4def = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
//                 break;
//             default:
//                 proj4def = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
//         }

//         proj4.defs(`EPSG:${zone}`, proj4def);

//         const customCRS = new L.Proj.CRS(`EPSG:${zone}`, proj4def, {
//             transformation: new L.Transformation(1, 0, -1, 0),
//         });

//         return customCRS;
//     };
//     const projdef = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';


//     return (
//         <>
//             <MapContainer
//                 center={[0, 0]}
//                 zoom={10}
//                 crs={L.Proj.CRS('EPSG:26192', projdef)}
//                 style={{ height: '80vh', width: '100%' }}
//                 whenCreated={(mapInstance) => {
//                     mapRef.current = mapInstance;
//                 }}
//             >
//                 <TileLayer
//                     url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
//                     maxZoom={21}
//                     subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
//                     attribution='<a href="https://www.google.com/maps">Google Maps</a>'
//                 />
//                 {polygons.map((polygon) => (
//                     <LeafletPolygon key={polygon.id} positions={polygon.geometry.coordinates[0]} />
//                 ))}
//             </MapContainer>
//             {error && <MuiAlert severity="error">{error}</MuiAlert>}
//         </>
//     );
// };

// export default ProjectPolygons;