import {
    CREATE_STAGIAIRE_SUCCESS,
    CREATE_STAGIAIRE_FAILURE,
    UPDATE_STAGIAIRE_SUCCESS,
    UPDATE_STAGIAIRE_FAILURE,
    GET_STAGIAIRE_SUCCESS,
    GET_STAGIAIRE_FAILURE,
    DELETE_STAGIAIRE_SUCCESS,
    DELETE_STAGIAIRE_FAILURE,
    GET_ALL_STAGIAIRES_SUCCESS,
    GET_ALL_STAGIAIRES_FAILURE,
  } from '../actions/types';
const initialState = {
  stagiaire: null,
  stagiaires: [],
  error: null,
};

const stagiaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STAGIAIRE_SUCCESS:
      return {
        ...state,
        stagiaire: action.payload,
        error: null,
      };
    case CREATE_STAGIAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_STAGIAIRE_SUCCESS:
      return {
        ...state,
        stagiaire: action.payload,
        error: null,
      };
    case UPDATE_STAGIAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_STAGIAIRE_SUCCESS:
      return {
        ...state,
        stagiaire: action.payload,
        error: null,
      };
    case GET_STAGIAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_STAGIAIRE_SUCCESS:
      return {
        ...state,
        stagiaire: null,
        error: null,
      };
    case DELETE_STAGIAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_STAGIAIRES_SUCCESS:
      return {
        ...state,
        stagiaires: action.payload,
        error: null,
      };
    case GET_ALL_STAGIAIRES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default stagiaireReducer;