import {
    CREATE_SALAIRE_SUCCESS,
    CREATE_SALAIRE_FAILURE,
    UPDATE_SALAIRE_SUCCESS,
    UPDATE_SALAIRE_FAILURE,
    GET_SALAIRE_SUCCESS,
    GET_SALAIRE_FAILURE,
    DELETE_SALAIRE_SUCCESS,
    DELETE_SALAIRE_FAILURE,
    GET_ALL_SALAIRES_SUCCESS,
    GET_ALL_SALAIRES_FAILURE,
  } from '../actions/types';
const initialState = {
  salaire: null,
  salaires: [],
  error: null,
};

const salairesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SALAIRE_SUCCESS:
      return {
        ...state,
        salaire: action.payload,
        error: null,
      };
    case CREATE_SALAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_SALAIRE_SUCCESS:
      return {
        ...state,
        salaire: action.payload,
        error: null,
      };
    case UPDATE_SALAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SALAIRE_SUCCESS:
      return {
        ...state,
        salaire: action.payload,
        error: null,
      };
    case GET_SALAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_SALAIRE_SUCCESS:
      return {
        ...state,
        salaire: null,
        error: null,
      };
    case DELETE_SALAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_SALAIRES_SUCCESS:
      return {
        ...state,
        salaires: action.payload,
        error: null,
      };
    case GET_ALL_SALAIRES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default salairesReducer;