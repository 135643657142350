import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSalaire } from "../../actions/SalaireActions";
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Select,
    Box,
    Snackbar,
    FormControl,
    InputLabel,
} from "@mui/material";
import { Alert } from "@mui/material";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';

const AddSalaire = ({ currentUser, userId }) => {
    const dispatch = useDispatch();
    const [mois, setMois] = useState("");
    const [salaireNet, setSalaireNet] = useState("");
    const [note, setNote] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const Data = {
            mois,
            salaire_net: salaireNet,
            note,
            userId: userId,
        };

        try {
            const response = await dispatch(createSalaire(Data));
            setSnackbarMessage("Salaire est créé avec succès.");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setMois("");
            setNote("");
            setSalaireNet("");
        } catch (error) {
            setSnackbarMessage(error.response.data.message);
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            console.error("Error creating Request:", error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Box p={3}>
                <form onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            label="Mois"
                            type="month"
                            value={mois}
                            onChange={(e) => setMois(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                        />
                        <TextField
                            label="Salaire NET"
                            type="number"
                            value={salaireNet}
                            onChange={(e) => setSalaireNet(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Note"
                            multiline
                            rows={5}
                            variant="outlined"
                            margin="normal"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                mt: 3,
                                color: '#fff !important'
                            }}
                        >
                            Ajouter
                        </Button>
                    </div>

                </form>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{ mt: 2 }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
};

export default AddSalaire;