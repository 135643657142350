import  API_URL  from './apiUrl';

class AvanceService {
    addAvance(avanceData) {
        return API_URL.post("/encaissements/create/", avanceData);
    }

    getAllAvances() {
        return API_URL.get("/encaissements/anavces");
    }

    fetchPendingAvances() {
        return API_URL.get(`/encaissements/anavces/pending/requests`);
    }

    updateAvance(avanceId, updatedAvance) {
        return API_URL.put(`/encaissements/update/${avanceId}`, updatedAvance);
    }

    fetchAvance(avanceId) {
        return API_URL.get(`/encaissements/${avanceId}`);
    }

    deleteAvance(avanceId) {
        return API_URL.delete(`/encaissements/delete/${avanceId}`);
    }

    fetchProjectAdvnaces(projectId) {
        return API_URL.get(`/encaissements/project/${projectId}`);
    }

    uploadFilesToAvance = (projectId, formData) => {
        return API_URL.post(`/encaissements/project/${projectId}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      getAllAvanceFiles = (projectId) => {
        return API_URL.get(`/encaissements/project/${projectId}/files`);
      }
    
      getFileContent = (projectId, fileName) => {
        return API_URL.get(`/encaissements/project/${projectId}/files/${fileName}`);
      }
    
      downloadFile = (projectId, fileName) => {
        return API_URL.get(`/encaissements/project/${projectId}/download/${fileName}`, {
          responseType: 'blob',
        });
      };
    
      deleteFile = (projectId, fileName) => {
        return API_URL.delete(`/encaissements/project/${projectId}/delete/${fileName}`);
      }
}

export default new AvanceService();