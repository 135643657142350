import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import StatisticsServices from '../../../services/statisticsServices';

const TotaleClotureByContact = () => {
    const theme = useTheme();
    const [selectedYear, setSelectedYear] = useState('');
    const [amountData, setAmountData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');

    useEffect(() => {
        if (selectedMonth === '' && selectedYear === '') {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            const currentYear = currentDate.getFullYear();
            setSelectedMonth(String(currentMonth));
            setSelectedYear(String(currentYear));
        }
    }, []);

    const fetchCounts = async (month, year) => {
        try {
            const response = await StatisticsServices.projectsAmountByContactAndPayment(month, year);
            setAmountData(response.data || []);
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };

    useEffect(() => {
        if (selectedMonth && selectedYear) {
            fetchCounts(selectedMonth, selectedYear);
        }
    }, [selectedMonth, selectedYear]);

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const generateMonthOptions = () => {
        const months = [
            { value: '1', label: 'January' },
            { value: '2', label: 'February' },
            { value: '3', label: 'March' },
            { value: '4', label: 'April' },
            { value: '5', label: 'May' },
            { value: '6', label: 'June' },
            { value: '7', label: 'July' },
            { value: '8', label: 'August' },
            { value: '9', label: 'September' },
            { value: '10', label: 'October' },
            { value: '11', label: 'November' },
            { value: '12', label: 'December' },
        ];

        return months.map((month) => (
            <MenuItem key={month.value} value={month.value}>
                {month.label}
            </MenuItem>
        ));
    };


    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 2017;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            years.push(year);
        }

        return years.map((year) => (
            <MenuItem key={year} value={year}>
                {year}
            </MenuItem>
        ));
    };

    return (
        <>
            <Box
                mt="25px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <TextField
                            select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            label="Sélectionner un mois"
                            fullWidth
                        >
                            <MenuItem value="">-- Select Month --</MenuItem>
                            {generateMonthOptions()}
                        </TextField>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            select
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Sélectionner l'année"
                            fullWidth
                        >
                            <MenuItem value="">-- Select Year --</MenuItem>
                            {generateYearOptions()}
                        </TextField>
                    </Grid>
                </Grid>
            </Box>
            <Box height="500px" m="-20px 0 0 0">
                {amountData && amountData.length > 0 ? (
                    <ResponsiveBar
                        data={amountData}
                        keys={['totalPaye', 'totalNonPaye']}
                        indexBy="name"
                        margin={{ top: 50, right: 80, bottom: 120, left: 30 }}
                        padding={0.3}
                        colors={{ scheme: 'category10' }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -45,
                            truncateTickAt: -80,
                            labelSkipWidth: 120,
                            labelSkipHeight: 80,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        enableGridX={false}
                        enableGridY={true}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        groupMode='grouped'
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        theme={theme.nivo}
                    />
                ) : (
                    <Typography variant="body2" mt={5} p={5}>
                        Oops! Aucune donnée disponible
                    </Typography>
                )}
            </Box>
        </>
    );
};

export default TotaleClotureByContact;
