import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, updateUserPermissions } from '../../actions/UserActions';
import { getAllPermissions } from '../../actions/PermissionActions';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';
import MDButton from '../../components/MDButton';
import {
    Switch,
    Typography,
    Snackbar,
    Alert,
    Grid,
    Paper,
    TextField,
    FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const PersonnelPermissions = ({ currentUser }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user);
    const permissions = useSelector((state) => state.permission.permissions);
    const [searchQuery, setSearchQuery] = useState('');

    const [permissionIds, setpermissionIds] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getUser(id));
        dispatch(getAllPermissions());
    }, [dispatch, id]);

    useEffect(() => {
        if (user?.permissions) {
            setpermissionIds(user?.permissions.map((permission) => permission.id));
        }
    }, [user?.permissions]);

    const categorizePermissions = () => {
        const categorizedPermissions = {};

        permissions.forEach((permission) => {
            const title = getTitleFromPermission(permission.name);
            if (!categorizedPermissions[title]) {
                categorizedPermissions[title] = [];
            }
            categorizedPermissions[title].push(permission);
        });

        return categorizedPermissions;
    };


    const getTitleFromPermission = (permissionName) => {
        if (permissionName.includes('projets')) {
            return 'projets';
        } else if (permissionName.includes('fichiers')) {
            return 'fichiers';
        } else if (permissionName.includes('matériels')) {
            return 'matériels';
        } else if (permissionName.includes('factures')) {
            return 'factures';
        } else if (permissionName.includes('véhicules')) {
            return 'véhicules';
        } else if (permissionName.includes('congés')) {
            return 'congés';
        } else if (permissionName.includes('encaissements')) {
            return 'encaissements';
        } else if (permissionName.includes('devis')) {
            return 'devis';
        } else if (permissionName.includes('primes')) {
            return 'primes';
        } else if (permissionName.includes('salaires')) {
            return 'salaires';
        } else if (permissionName.includes('contacts')) {
            return 'contacts';
        } else if (permissionName.includes('charges')) {
            return 'charges';
        } else if (permissionName.includes('tâches')) {
            return 'tâches';
        } else if (permissionName.includes('clients')) {
            return 'clients';
        } else if (permissionName.includes('stagiaires')) {
            return 'stagiaires';
        } else if (permissionName.includes('personnel')) {
            return 'personnel';
        } else if (permissionName.includes('natures')) {
            return 'natures';
        } else if (permissionName.includes('pièces')) {
            return 'pièces';
        } else if (permissionName.includes('opérations bancaires')) {
            return 'opérations bancaires';
        } else if (permissionName.includes('points')) {
            return 'points';
        }
        return 'Autre';
    };

    const handlePermissionToggle = (permissionId) => {
        setpermissionIds((prevSelected) => {
            if (prevSelected.includes(permissionId)) {
                return prevSelected.filter((id) => id !== permissionId);
            } else {
                return [...prevSelected, permissionId];
            }
        });
    };

    const handleSavePermissions = () => {
        dispatch(updateUserPermissions(id, permissionIds))
            .then(() => {
                setSnackbarMessage('Permissions ont été modifiées avec succès');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarMessage('Error updating permissions: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const filteredPermissions = () => {
        const filteredPermissions = permissions.filter((permission) => {
            const title = getTitleFromPermission(permission.name);
            return title.toLowerCase().includes(searchQuery.toLowerCase());
        });
        return filteredPermissions;
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && currentUser.roles.includes('ROLE_ADMIN') ? (
                <StyledGrid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            textAlign={'center'}
                            className='mb-'
                            mb={'20px'}
                            variant='h4'
                            sx={{
                                fontSize: '36px',
                                fontWeight: 'bold',
                                color: '#3e4396',
                                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            Configurer les permissions d'accès pour: {user?.nom}&nbsp;{user?.prenom}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Rechercher des autorisations"
                            variant="standard"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Grid>
                    {Object.entries(categorizePermissions()).map(([title, permissions]) => (
                        title.toLowerCase().includes(searchQuery.toLowerCase()) && (
                            <Grid item xs={12} key={title.toUpperCase()}>
                                <Paper elevation={3} sx={{ padding: 5 }}>
                                    <Typography variant='h5'>{title.toUpperCase()}</Typography>
                                    {permissions.map((permission) => (
                                        <FormControlLabel
                                            key={permission.id}
                                            control={
                                                <Switch
                                                    checked={permissionIds.includes(permission.id)}
                                                    onChange={() => handlePermissionToggle(permission.id)}
                                                    color='primary'
                                                />
                                            }
                                            label={permission.name}
                                        />
                                    ))}
                                </Paper>
                            </Grid>
                        )
                    ))}
                    <Grid item xs={12}>
                        <MDButton
                            variant='contained'
                            color='info'
                            onClick={handleSavePermissions}
                        >
                            Enregistrer les autorisations
                        </MDButton>
                    </Grid>
                </StyledGrid>
            ) : (
                <AccessDenied />
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    variant='filled'
                    sx={{
                        color: '#fff'
                    }}
                    severity={snackbarSeverity}
                    onClose={handleSnackbarClose}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default PersonnelPermissions;


