import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCotisations, deleteCotisation } from '../../actions/CotisationActions';
import {
    Container,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import AccessDenied from "../Errors/AccessDenied";
import { CSVLink } from 'react-csv';
const CotisationsList = ({ currentUser, darkMode }) => {
    const dispatch = useDispatch();
    const cotisations = useSelector((state) => state.cotisation.cotisations);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedPrimeId, setSelectedPrimeId] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [pageSize, setPageSize] = useState(25);

    useEffect(() => {
        dispatch(getAllCotisations());
    }, [dispatch]);

    const filteredCotisations = selectedMonth
        ? cotisations.filter((cotisation) => cotisation.mois.startsWith(selectedMonth))
        : cotisations;

    const exportSalariesCSV = {
        filename: `salaries_${selectedMonth || 'all'}.csv`,
        data: filteredCotisations?.map((cotisation) => ({
            // ID: cotisation.id,
            // Mois: cotisation.mois,
            'Employé': `${cotisation.user?.prenom || ''} ${cotisation.user?.nom || ''}`,
            'Salaire Base': cotisation.salaire_base,
            'Salaire Net': cotisation.salaire_net,
            'Salaire Brut': cotisation.salaire_brut,
            // Primes: cotisation.primes,
            // 'Employé': cotisation.user?.nom || '',
        })),
    };
    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'mois', headerName: 'Mois', flex: 1 },
        { field: 'salaire_base', headerName: 'Salaire base', flex: 2 },
        { field: 'salaire_net', headerName: 'Salaire net', flex: 1 },
        { field: 'salaire_brut', headerName: 'Salaire brut', flex: 1 },
        { field: 'salaire_brutimpo', headerName: 'Salaire brut imposable', flex: 1 },
        { field: 'primes', headerName: 'Primes', flex: 1 },
        {
            field: 'user?.nom', headerName: 'Employé', flex: 1,
            valueGetter: (params) => params.row.user?.nom || '',
        },

        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => (
                <>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les salaires")) && (
                        <MDButton
                            variant="outlined"
                            className='btn-sm me-1'
                            color="error"
                            size='small'
                            onClick={() => handleOpenDeleteModal(params.row.id)}
                        >
                            Delete
                        </MDButton>
                    )}
                </>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    const handleOpenDeleteModal = (primeId) => {
        setSelectedPrimeId(primeId);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedPrimeId(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        dispatch(deleteCotisation(selectedPrimeId));
        closeDeleteModal();
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les salaires")) ? (
                <Container>
                    <Typography variant="h2" textAlign={'center'} gutterBottom>
                        Liste des Salaires
                    </Typography>
                    <div className='mt-1 mb-3' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <TextField
                        label="Mois"
                        type="month"
                        fullWidth
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <CSVLink {...exportSalariesCSV}>
                        <MDButton variant="contained" size="small" color="info">
                            Exporter Salaries
                        </MDButton>
                    </CSVLink>
                    </div>
                    <DataGrid
                        rows={cotisations}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        autoHeight
                        pageSize={pageSize}
                        className={darkMode ? "text-light" : "text-dark"}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        disableSelectionOnClick
                    />

                    <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                        <DialogTitle className="text-dark">Confirmation de la suppression</DialogTitle>
                        <DialogContent>
                            <DialogContentText className="text-dark">
                                Etes-vous sûr de vouloir supprimer ce Salaire ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={closeDeleteModal} color="light">
                                Annuler
                            </MDButton>
                            <MDButton onClick={handleDelete} color="error">
                                Supprimer
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </DashboardLayout>
    );
};

export default CotisationsList;
