import React from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import TotaleClotureByContact from "./TotaleClotureByContact";
import TotaleClotureByPayement from "./TotaleClotureByPayement";
import TptaleClotureByYear from "./TotaleClotureByYear";
import {
    Typography,
    Grid,
    Paper,
    Divider,
} from '@mui/material';
import ProjectsByContact from "../performance/ProjectsByContact";
import CaisseStatistics from "./Caisse";

const RecouvrementStatistics = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h2" mb={2} textAlign={"center"}>
                RECOUVERMENT
            </Typography>
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Montants totaux du projets au cours d'une mois et année spécifique par rapport à les contacts
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <TotaleClotureByContact />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Montants totaux du projets au cours d'une mois et année spécifique (payé et non payé)
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <TotaleClotureByPayement />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Montants totaux du projets au cours d'une année spécifique par rapport au mois
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <TptaleClotureByYear />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Montants totaux du projets au cours d'une année et contact spécifique par rapport au mois
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <ProjectsByContact />
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                calculer la caisse enregistreuse
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <CaisseStatistics />
        </DashboardLayout>
    )
}

export default RecouvrementStatistics;