import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createMaterial } from "../../actions/MaterialsActions";
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Snackbar,
    Alert,
    Autocomplete,
    Box,
} from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import { getAllUsers } from "../../actions/UserActions";

const CreateMaterial = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [dateAcquisition, setDateAcquisition] = useState("");
    const [prixAcquisition, setPrixAcquisition] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [userId, setUserId] = useState("");
    const users = useSelector((state) => state.users.users);

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const materialData = {
            type,
            name,
            date_acquisition: dateAcquisition,
            prix_acquisition: prixAcquisition,
            userId,
        };

        try {
            await dispatch(createMaterial(materialData));
            setSnackbarSeverity("success");
            setSnackbarMessage("Matériel ajouté avec succès!");
        } catch (error) {
            setSnackbarSeverity("error");
            setSnackbarMessage("Erreur lors de l'ajout du matériel.");
        }

        setSnackbarOpen(true);
        setType("");
        setDateAcquisition("");
        setPrixAcquisition("");
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };
    const usersOptions = users.map((user) => ({
        value: user.id,
        label: user.nom,
    }));
    const defaultUserOption = userId ? usersOptions.find((option) => option.value === userId) : null;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des matériels")) ? (
                <Container maxWidth="sm">
                    <Box p={3}>
                        <Typography variant="h2" align="center" gutterBottom>
                            Ajouter du matériel
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                select
                                label='Type de matériel'
                                name="type"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                required
                                fullWidth
                                variant="standard"
                            >
                                <MenuItem value="" disabled>
                                    Sélectionnez le type
                                </MenuItem>
                                <MenuItem value="materiel-topographique">Matériel topographique</MenuItem>
                                <MenuItem value="materiel-informatique">Matériel informatique</MenuItem>
                                <MenuItem value="autre">Autre</MenuItem>
                            </TextField>
                            <TextField
                                label="Nom"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                variant="standard"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                type="date"
                                label="date d'Acquisition"
                                value={dateAcquisition}
                                onChange={(e) => setDateAcquisition(e.target.value)}
                                variant="standard"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Prix Acquisition"
                                type="number"
                                value={prixAcquisition}
                                onChange={(e) => setPrixAcquisition(e.target.value)}
                                fullWidth
                                margin="normal"
                                variant="standard"
                            />
                            <Autocomplete
                                options={usersOptions}
                                getOptionLabel={(option) => option.label}
                                value={defaultUserOption}
                                onChange={(event, newValue) => setUserId(newValue ? newValue.value : '')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Personnel"
                                        placeholder="Filter users by name..."
                                        variant="standard"
                                    />
                                )}
                                sx={{ mb: 2 }}
                            />
                            <MDButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ mt: 3 }}
                            >
                                Ajouter
                            </MDButton>
                        </form>
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                        >
                            <Alert
                                onClose={handleSnackbarClose}
                                severity={snackbarSeverity}
                                sx={{ width: "100%" }}
                            >
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout>
    );
};

export default CreateMaterial;
