import React, { useEffect, useState } from 'react';
import ChargeService from '../../services/chargesServices';
import { Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const TotalChargesForUsers = () => {
    const [totalChargesForUsers, setTotalChargesForUsers] = useState([]);

    useEffect(() => {
        fetchTotalChargesForUsers();
    }, []);

    const fetchTotalChargesForUsers = async () => {
        try {
            const response = await ChargeService.getTotalChargesForUsers();
            setTotalChargesForUsers(response.data);
        } catch (error) {
            console.error('Error fetching total charges for users:', error);
        }
    };

    const chartData = totalChargesForUsers.map((user) => ({
        userId: user.id,
        name: `${user.nom} ${user.prenom}`,
        totalCharges: user.charges.reduce((acc, charge) => acc + parseFloat(charge.totalCharges), 0),
    }));

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <Typography variant="h3" textAlign={'center'} gutterBottom>
                    Total Charges for Users
                </Typography>
                {totalChargesForUsers.length === 0 ? (
                    <Typography variant="body1">No data available.</Typography>
                ) : (
                    <>
                    <Box alignItems={'center'} style={{ height: 300 }}>
                        <ResponsiveBar
                            data={chartData}
                            keys={['totalCharges']}
                            indexBy="name"
                            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                            padding={0.3}
                            colors={{ scheme: 'category10' }}
                            axisBottom={{
                                tickRotation: -45,
                            }}
                            axisLeft={{
                                tickValues: 5,
                                tickPadding: 5,
                                tickSize: 5,
                                tickRotation: 0,
                                legend: 'Total Charges',
                            }}
                            enableGridX
                            enableGridY
                            labelTextColor="inherit:darker(1.2)"
                            tooltip={({ id, value, color }) => (
                                <strong style={{ color }}>
                                    {id}: {value}
                                </strong>
                            )}
                        />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Total Charges</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {totalChargesForUsers.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.id}</TableCell>
                                            <TableCell>{`${user.nom} ${user.prenom}`}</TableCell>
                                            <TableCell>{user.charges.reduce((acc, charge) => acc + parseFloat(charge.totalCharges), 0)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </DashboardLayout>
        </>
    );
};

export default TotalChargesForUsers;
