import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';
import { EditControl } from 'react-leaflet-draw';
import PointServices from '../../services/pointServices';
import { Icon } from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FeatureGroup } from 'react-leaflet';
import { Dialog, DialogTitle, DialogActions, Alert, Snackbar, TextField, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';

const markerIcon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

const PointsMap = ({ zone }) => {
    const [points, setPoints] = useState([]);
    const [convertedCoordinates, setConvertedCoordinates] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedPointId, setSelectedPointId] = useState(null);

    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [reference, setRefence] = useState("");
    const [nature, setNature] = useState("");
    const [name, setName] = useState("");
    const [from, setFrom] = useState(false);
    const [X, setX] = useState(0);
    const [Y, setY] = useState(0);
    const { user: currentUser } = useSelector((state) => state.auth);
    let fullName = `${currentUser?.nom} ${currentUser?.prenom}`;

    useEffect(() => {
        const fetchPoints = async () => {
            try {
                const response = await PointServices.fetchPointsByZone(zone);
                setPoints(response.data.points);
            } catch (error) {
                console.error('Error fetching points:', error);
            }
        };

        fetchPoints();
    }, [zone]);

    useEffect(() => {
        if (points.length === 0) return;
        let sourceCRS;

        switch (zone) {
            case '26191':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
                break;
            case '26192':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
                break;
            case '26194':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
                break;
            case '26195':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
                break;
            default:
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
        const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

        proj4.defs('EPSG:3857', targetCRS);
        proj4.defs(`EPSG:${zone}`, sourceCRS);

        const converted = points.map((point) => {
            const [x, y] = proj4(sourceCRS, targetCRS, point.geometry.coordinates);
            return [y, x];
        });

        setConvertedCoordinates(converted);
    }, [points, zone]);

    const handleDrawCreate = (e) => {
        const { layerType, layer } = e;

        if (layerType === 'marker') {
            const { lat, lng } = layer.getLatLng();
            const [X, Y] = proj4('EPSG:3857', `EPSG:${zone}`, [lng, lat]);

            setX(X);
            setY(Y);
        }
    };

    const handlePolygonSubmit = () => {
        if (from && reference && name && nature) {
            PointServices.addPoint({
                name: name,
                nature: nature,
                zone: zone,
                X,
                Y,
                createdBy: fullName,
                reference: reference,
            })
                .then((response) => {
                    console.log('Point added successfully:', response.data);
                    handleSnackbarOpen('Point added successfully', 'success');
                })
                .catch((error) => {
                    console.error('Error adding point:', error);
                    handleSnackbarOpen('Error adding point', 'error');
                });

            setRefence('');
            setName('');
            setNature('');
            setFrom(false);
        } else {
            handleSnackbarOpen('Please fill in all the form fields', 'error');
        }
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const handleDeleteDialogOpen = (pointId) => {
        setSelectedPointId(pointId);
        setDeleteDialogOpen(true);
    };
    const handleDeleteDialogClose = () => {
        setSelectedPointId(null);
        setDeleteDialogOpen(false);
    };
    const handleDeletePoint = () => {
        if (selectedPointId) {
            PointServices.deletePoint(selectedPointId)
                .then((response) => {
                    console.log('Point deleted successfully:', response.data);
                    handleSnackbarOpen(`Point deleted successfully: ${response.data.message}`, 'success');
                    handleDeleteDialogClose();
                })
                .catch((error) => {
                    console.error('Error deleting point:', error);
                    handleSnackbarOpen('Error deleting point', 'error');
                });
        }
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {convertedCoordinates.length > 0 && (
                <>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            color: '#fff !important',
                            marginBottom: '12px',
                        }}
                        onClick={() => setFrom(true)}
                    >
                        Ajouter Un point
                    </Button>
                    {from && <>
                        <TextField
                            label="Référence"
                            value={reference}
                            onChange={(e) => setRefence(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Nom du point"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Nature du point"
                            value={nature}
                            onChange={(e) => setNature(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </>}
                    <MapContainer
                        center={[30.39341198110206, -9.565342191279383]}
                        zoom={10}
                        style={{ height: '80vh', width: '100%' }}
                    >
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                            maxZoom={21}
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                        />
                        {from &&
                            <FeatureGroup>
                                <EditControl
                                    position="topright"
                                    draw={{
                                        marker: {
                                            icon: markerIcon,
                                        },
                                        polyline: false,
                                        rectangle: false,
                                        circle: false,
                                        circlemarker: false,
                                        polygon: false,
                                    }}
                                    onCreated={handleDrawCreate}
                                />
                            </FeatureGroup>
                        }
                        <MarkerClusterGroup>
                            {convertedCoordinates.map((coord, index) => (
                                <Marker key={index} position={coord} icon={markerIcon}>
                                    <Popup>
                                        <div>Id: {points[index].id}</div>
                                        <div>Name: {points[index].name}</div>
                                        <div>Nature: {points[index].nature}</div>
                                        <div>Reference: {points[index].reference}</div>
                                        <div>X: {points[index]?.geometry?.coordinates[1]}</div>
                                        <div>Y: {points[index]?.geometry?.coordinates[0]}</div>
                                        <MDButton
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDeleteDialogOpen(points[index].id)}
                                        >
                                            Delete
                                        </MDButton>
                                    </Popup>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>
                    </MapContainer>
                    {from && <>
                        <Button
                            type="button"
                            variant="contained"
                            color="dark"
                            sx={{
                                color: '#000 !important',
                                marginTop: '12px',
                                backgroundColor: '#ffc107 !important',
                            }}
                            onClick={handlePolygonSubmit}
                        >
                            Sauvegarder
                        </Button>
                    </>
                    }
                </>
            )}
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                    <Button onClick={handleDeletePoint} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default PointsMap;

