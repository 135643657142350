import {
    CREATE_AVANCE_SUCCESS,
    CREATE_AVANCE_FAILURE,
    UPDATE_AVANCE_SUCCESS,
    UPDATE_AVANCE_FAILURE,
    GET_AVANCE_SUCCESS,
    GET_AVANCE_FAILURE,
    DELETE_AVANCE_SUCCESS,
    DELETE_AVANCE_FAILURE,
    GET_ALL_AVANCES_SUCCESS,
    GET_ALL_AVANCES_FAILURE,
    GET_PROJECT_AVANCES_SUCCESS,
    GET_PROJECT_AVANCES_FAILURE,
    GET_ALL_PENDING_AVANCES_SUCCESS,
    GET_ALL_PENDING_AVANCES_FAILURE,
} from '../actions/types';

const initialState = {
    encaissement: null,
    encaissements: [],
    allEncaissements: [],
    error: null,
};

const encaissementReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_AVANCE_SUCCESS:
            return {
                ...state,
                encaissement: action.payload,
                error: null,
            };
        case CREATE_AVANCE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_AVANCE_SUCCESS:
            return {
                ...state,
                encaissement: action.payload,
                error: null,
            };
        case UPDATE_AVANCE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_AVANCE_SUCCESS:
            return {
                ...state,
                encaissement: action.payload,
                error: null,
            };
        case GET_AVANCE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_AVANCE_SUCCESS:
            return {
                ...state,
                encaissement: null,
                error: null,
            };
        case DELETE_AVANCE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_AVANCES_SUCCESS:
            return {
                ...state,
                allEncaissements: action.payload,
                error: null,
            };
        case GET_ALL_AVANCES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PROJECT_AVANCES_SUCCESS:
            return {
                ...state,
                encaissements: action.payload,
                error: null,
            };
        case GET_PROJECT_AVANCES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_PENDING_AVANCES_SUCCESS:
            return {
                ...state,
                encaissements: action.payload,
                error: null,
            };
        case GET_ALL_PENDING_AVANCES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default encaissementReducer;