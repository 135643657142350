import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { getAllOperations, deleteOperation } from "../../actions/OperationsActions";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    DialogTitle,
    useMediaQuery,
    Snackbar,
    Box,
    Alert,
} from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { encodeId } from "../../actions/Crypte";
import VisibilityIcon from '@mui/icons-material/Visibility';
import OperationPreview from "./OperationPreview";
import StatisticsServices from "../../services/statisticsServices";

const OperationsList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const operations = useSelector((state) => state.operation.operations);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        dispatch(getAllOperations());
    }, [dispatch]);

    const openDeleteModal = (item) => {
        setSelectedMaterial(item);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedMaterial(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedMaterial) {
            dispatch(deleteOperation(selectedMaterial.id))
                .then(() => {
                    dispatch(getAllOperations());
                    setSnackbarMessage('Opération supprimée avec succès');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    setSnackbarMessage("Échec de la suppression de l'opération");
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                });
        }
        closeDeleteModal();
    };

    const openDialog = (item) => {
        setSelectedItem(item);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'type', headerName: "Type d'opération", flex: 1 },
        { field: 'date', headerName: "Date d'opération", flex: 1 },
        { field: 'prix', headerName: "Prix d'opération", flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 200,
            renderCell: (params) => (
                <div>
                    <>
                        <IconButton
                            color="success"
                            onClick={() => openDialog(params.row)}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les opérations bancaires")) && (
                        <IconButton
                            component={Link}
                            color="info"
                            to={`/operations-bancaire/update/${encodeId(params.row.id)}`}
                        >
                            <BorderColorIcon />
                        </IconButton>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les opérations bancaires")) && (
                        <IconButton
                            onClick={() => openDeleteModal(params.row)}
                            style={{ marginLeft: "8px" }}
                            color="error"
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            ),
        },
    ];

    const CustomButton = () => {
        const handleExportArchive = async (folder) => {
            try {
                const response = await StatisticsServices.downloadArchive(folder);

                const blob = new Blob([response.data], { type: 'application/zip' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'archive.zip';

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            } catch (error) {
                console.error('Error exporting archive:', error);
            }
        };

        return (
            <div className="MuiDataGrid-toolbarContainer css-1j9kmqg-MuiDataGrid-toolbarContainer" style={{ display: 'flex', }}>
                <IconButton
                    color="info"
                    component={Link}
                    to={`/operations-bancaire/create`}
                    sx={{ fontSize: '0.75rem !important' }}
                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-knwngq-MuiButtonBase-root-MuiButton-root">
                    <ControlPointIcon sx={{ mr: '7px' }} />
                    AJOUTER
                </IconButton>
                <IconButton
                    color="info"
                    sx={{ fontSize: '0.75rem !important', marginLeft: '0.5rem' }}
                    onClick={() => handleExportArchive('operations-bancaires')}
                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-knwngq-MuiButtonBase-root-MuiButton-root">
                    <GetAppIcon sx={{ mr: '7px' }} />
                    Archive
                </IconButton>
            </div>
        );
    };

    const CustomToolbar = (props) => {

        return (
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
            }}>
                <GridToolbar {...props} />
                <CustomButton />
            </div>
        );
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les opérations bancaires")) ? (
                <Container maxWidth="md">
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                        mb={5}
                    >
                        Liste des opérations
                    </Typography>
                    {operations.length === 0 ? (
                        <>
                            <Typography variant="body1">Oops ! Aucune opération disponible.</Typography>
                            <Link to={`/operations-bancaire/create`} className="text-decoration-none float-end">
                                <MDButton variant="contained" color="success" >
                                    <ControlPointIcon sx={{ mr: "10px" }} />
                                    Créer
                                </MDButton>
                            </Link>
                        </>
                    ) : (
                        <Box>
                            <Box
                                m="0 0 0 0"
                                height="75vh"
                            >
                                <DataGrid
                                    rows={operations}
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </Box>
                        </Box>
                    )}
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle>Confirmation de la suppression</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir supprimer cette opération ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal}>Annuler</Button>
                    <Button onClick={handleDelete} sx={{ color: "red !important" }} >
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                    Détails de l'opération
                </DialogTitle>
                <DialogContent>
                    <OperationPreview selectedItem={selectedItem} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert variant="filled" onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default OperationsList;
