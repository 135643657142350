import React, { useState, useEffect } from "react";
import { Grid, Alert, Snackbar, Container, Typography, AlertTitle, TextField, Autocomplete, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { CreateVehicule } from "../../actions/VehiculeActions";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../actions/UserActions";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { useMaterialUIController } from "../../context";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import AccessDenied from "../Errors/AccessDenied";

const AddVehicule = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const [type, setType] = useState("");
    const [date, setDate] = useState("");
    const [matricule, setMatricule] = useState("");
    const [userId, setUserId] = useState("");
    const [prix, setPrix] = useState(0);
    const [kilometrage, setKilometrage] = useState(0);
    const [model, setModel] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const users = useSelector((state) => state.users.users);

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const requestData = {
            type,
            model,
            prix_acquisition: prix,
            date_acquisition: date,
            kilometrage,
            matricule,
            userId,
        };

        dispatch(CreateVehicule(requestData));

        setSubmitted(true);
        openSnackbar('Vehicule created successfully', 'success');
        setType("");
        setPrix(0);
        setModel("");
        setKilometrage(0);
        setDate("");
        setUserId("");
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity || 'success');
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const usersOptions = users.map((user) => ({
        value: user.id,
        label: user.nom,
    }));
    const defaultUserOption = userId ? usersOptions.find((option) => option.value === userId) : null;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des véhicules")) ? (
                <Container maxWidth="sm" style={{ marginTop: "20px" }}>
                    <Typography variant="h2" textAlign={'center'}>Créer un nouveau véhicule</Typography>
                    {submitted ? (
                        <Box mt={2}>
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                Le véhicule a été créé avec succès —
                                <strong>
                                    <Link className="text-decoration-none ms-1" to={'/moyens-materiels/vehicules'}>
                                        vérifiez-le !
                                    </Link>
                                </strong>
                            </Alert>
                        </Box>
                    ) : (
                        <Box mt={2}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Type"
                                            fullWidth
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Model"
                                            fullWidth
                                            value={model}
                                            onChange={(e) => setModel(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Matricule"
                                            fullWidth
                                            value={matricule}
                                            onChange={(e) => setMatricule(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            label="Prix Acquisition"
                                            fullWidth
                                            value={prix}
                                            onChange={(e) => setPrix(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="date"
                                            label="Date Acquisition"
                                            fullWidth
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            label="Kilométrage"
                                            fullWidth
                                            value={kilometrage}
                                            onChange={(e) => setKilometrage(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            options={usersOptions}
                                            getOptionLabel={(option) => option.label}
                                            value={defaultUserOption}
                                            onChange={(event, newValue) => setUserId(newValue ? newValue.value : '')}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Personnel"
                                                    required
                                                    placeholder="Filter users by name..."
                                                    variant="standard"
                                                />
                                            )}
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Button type="submit" sx={{color : '#fff !important'}} variant="contained" color="primary" fullWidth>
                                    Submit
                                </Button>
                            </form>
                        </Box>
                    )}
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </DashboardLayout>
    );
};

export default AddVehicule;
