import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { updateTaux, getAllTaux } from '../../actions/TauxActions'; // Import your update action
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { Edit as EditIcon } from '@mui/icons-material';
import MDButton from '../../components/MDButton';

const TauxList = () => {
    const dispatch = useDispatch();
    const [selectedTaux, setSelectedTaux] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [updatedTauxValue, setUpdatedTauxValue] = useState('');
    const tauxList = useSelector((state) => state.taux.taux);

    useEffect(() => {
        dispatch(getAllTaux());
    }, [dispatch]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'taux', headerName: 'Taux', width: 120 },
        { field: 'createdAt', headerName: 'Created At', width: 200 },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <Box>
                    <MDButton
                        size='small'
                        variant='outlined'
                        color='info'
                        className="btn btn-sm"
                        onClick={() => handleUpdateClick(params.row)}
                    >
                        <EditIcon />
                    </MDButton>
                </Box>
            ),
        },
    ];

    const handleUpdateClick = (item) => {
        setSelectedTaux(item);
        setOpenDialog(true);
        setUpdatedTauxValue(item.taux.toString());
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setUpdatedTauxValue('');
        setSelectedTaux(null);
    };

    const handleUpdate = async () => {
        if (selectedTaux && updatedTauxValue !== '') {
            await dispatch(updateTaux(selectedTaux.id, { name: selectedTaux.name, taux: parseFloat(updatedTauxValue) }));
            handleDialogClose();
            dispatch(getAllTaux());
        }
    };

    const handleSelectionChange = (params) => {
        if (params.selectionModel?.length > 0) {
            const selectedRowIndex = params.selectionModel[0];
            setSelectedTaux(tauxList[selectedRowIndex]);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={tauxList}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    // checkboxSelection
                    components={{ Toolbar: GridToolbar }}
                    onSelectionModelChange={handleSelectionChange}
                />
                <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    fullWidth
                    maxWidth={'sm'}
                >
                    <DialogTitle textAlign={'center'}>Mettre à jour le taux</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Taux Name"
                            type="text"
                            fullWidth
                            value={selectedTaux ? selectedTaux.name : ''}
                            disabled
                            sx={{
                                marginTop: '12px'
                            }}
                        />
                        <TextField
                            label="New Taux Value"
                            type="number"
                            sx={{
                                marginTop: '12px'
                            }}
                            fullWidth
                            value={updatedTauxValue}
                            onChange={(e) => setUpdatedTauxValue(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Annuler
                        </Button>
                        <Button 
                        onClick={handleUpdate} 
                        sx={{
                            backgroundColor: '#2196f3',
                            color:'#fff !important',
                            "&:hover": {
                                border: '1px solid',
                                borderColor: '#2196f3 !important',
                                color: '#2196f3 !important',
                              },
                        }}
                        color="primary"
                        >
                            modifier
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </DashboardLayout>
    );
};

export default TauxList;
