import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  TextField,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import StatisticsServices from '../../../services/statisticsServices';

const RecettesFactureByYearAndMonth = () => {
  const theme = useTheme();
  const [selectedYear, setSelectedYear] = useState('');
  const [encaissementsAmount, setEncaissementsAmount] = useState(null);

  useEffect(() => {
    if (selectedYear === '') {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      setSelectedYear(String(currentYear));
    }
  }, []);

  const fetchEncaissements = async (year) => {
    try {
      const response = await StatisticsServices.encaissementsAmountFactureByYear(year);
      setEncaissementsAmount(response.data || []);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  useEffect(() => {
    if (selectedYear) {
      fetchEncaissements(selectedYear);
    }
  }, [selectedYear]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2017;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years.map((year) => (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    ));
  };

  console.log(encaissementsAmount);

  return (
    <>
      <Box
        mt="25px"
        p="0 30px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          select
          value={selectedYear}
          onChange={handleYearChange}
          label="Sélectionner l'année"
          fullWidth
        >
          <MenuItem value="">-- Select Year --</MenuItem>
          {generateYearOptions()}
        </TextField>
      </Box>
      <Box height="400px" m="-20px 0 0 0">
        {encaissementsAmount && encaissementsAmount.length > 0 ? (
          <ResponsiveBar
            data={encaissementsAmount}
            keys={['totalNonFacture', 'totalFacture']}
            indexBy="mois"
            margin={{ top: 50, right: 80, bottom: 120, left: 30 }}
            padding={0.3}
            colors={{ scheme: 'category10' }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              truncateTickAt: -80,
              labelSkipWidth: 120,
              labelSkipHeight: 80,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            enableGridX={false}
            enableGridY={true}
            labelSkipWidth={12}
            labelSkipHeight={12}
            groupMode='grouped'
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            theme={theme.nivo}
          />
        ) : (
          <Typography variant="body2" mt={5} p={5}>
            Oops! Aucune donnée disponible
          </Typography>
        )}
      </Box>
    </>
  );
};

export default RecettesFactureByYearAndMonth;
