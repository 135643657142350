import SalaireService from '../services/salaireServices';
import {
  CREATE_SALAIRE_SUCCESS,
  CREATE_SALAIRE_FAILURE,
  UPDATE_SALAIRE_SUCCESS,
  UPDATE_SALAIRE_FAILURE,
  GET_SALAIRE_SUCCESS,
  GET_SALAIRE_FAILURE,
  DELETE_SALAIRE_SUCCESS,
  DELETE_SALAIRE_FAILURE,
  GET_ALL_SALAIRES_SUCCESS,
  GET_ALL_SALAIRES_FAILURE,
} from './types';

export const createSalaire = (salaireData) => {
  return (dispatch) => {
    return SalaireService.createSalaire(salaireData)
      .then((response) => {
        dispatch({
          type: CREATE_SALAIRE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_SALAIRE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getAllSalaires = () => {
  return (dispatch) => {
    return SalaireService.getAllSalaires()
      .then((response) => {
        dispatch({
          type: GET_ALL_SALAIRES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_SALAIRES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchAllUserSalaires = (userId) => {
  return (dispatch) => {
    return SalaireService.fetchUserSalaires(userId)
      .then((response) => {
        dispatch({
          type: GET_ALL_SALAIRES_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_SALAIRES_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};


export const updateSalaire = (salaireId, updatedSalaire) => {
  return (dispatch) => {
    return SalaireService.updateSalaire(salaireId, updatedSalaire)
      .then((response) => {
        dispatch({
          type: UPDATE_SALAIRE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SALAIRE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getSalaire = (salaireId) => {
  return (dispatch) => {
    return SalaireService.fetchSalaire(salaireId)
      .then((response) => {
        dispatch({
          type: GET_SALAIRE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SALAIRE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteSalaire = (salaireId) => {
  return (dispatch) => {
    return SalaireService.deleteSalaire(salaireId)
      .then((response) => {
        dispatch({
          type: DELETE_SALAIRE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SALAIRE_FAILURE,
          payload: error.message,
        });
      });
  };
};
