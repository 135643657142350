import React, { useEffect, useState } from 'react';
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import TasksConfirmedByUsers from "./TasksConfirmedByUsers";
import UserTasksByYear from "./UserTasksByYear";
import TodayIcon from '@mui/icons-material/Today';
import {
    Typography,
    Grid,
    Paper,
    Divider,
} from '@mui/material';
import StatisticsServices from '../../../services/statisticsServices';
import EventIcon from '@mui/icons-material/Event';
const PerformanceStatistics = () => {
    const [tasksCount, setTasksCount] = useState(null);

    useEffect(() => {
        completedTasksLastCurrentMonth();
    }, []);

    const completedTasksLastCurrentMonth = async () => {
        try {
            const response = await StatisticsServices.completedTasksCountMonth();
            setTasksCount(response.data);
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h2" mb={2} textAlign={"center"}>
                Rendement
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="info"
                            sx={{
                                color: 'rgb(31, 119, 180) !important',
                            }}
                            gutterBottom>
                            <EventIcon fontSize="medium" sx={{ mr: 1 }} />
                            nombre des tâches exécutées dans le moi en cours :
                        </Typography>
                        <Typography> {tasksCount?.tasksCurrentMonth || 0}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" color="info"
                            sx={{
                                color: 'rgb(31, 119, 180) !important',
                            }}
                            gutterBottom>
                            <TodayIcon fontSize="medium" sx={{ mr: 1 }} />
                            nombre des tâches exécutées dans le moi précédent :
                        </Typography>
                        <Typography>{tasksCount?.tasksPreviousMonth || 0}</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Typography variant="h6" mt={2} mb={2} textAlign={"center"}>
                Nombre des tâches confirmer par l'utilisateurs
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <TasksConfirmedByUsers />
            <Typography variant="h6" mt={4} mb={2} textAlign={"center"}>
                Nombre de tâches confirmées par l'utilisateur dans l'année à préciser
            </Typography>
            <Divider sx={{
                margin: 'auto',
                width: '50%',
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'rgb(31, 119, 180) !important',
                height: '5px',
            }} />
            <UserTasksByYear />
        </DashboardLayout>
    )
}

export default PerformanceStatistics;