import React, { useEffect, useState } from 'react';
import ProjectService from '../../services/projetServices';
import {
    Typography,
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import {
    ResponsiveBar,
} from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const ProjectsAmountByMonth = () => {
    const [projectsAmountByMonth, setProjectsAmountByMonth] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        fetchProjectsAmountByMonth();
    }, []);

    const fetchProjectsAmountByMonth = async () => {
        try {
            const response = await ProjectService.getProjectsAmountByMonth();
            setProjectsAmountByMonth(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setPage(0);
    };

    const filteredProjects = projectsAmountByMonth.filter((item) =>
        item.month?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginationCount = Math.ceil(filteredProjects.length / rowsPerPage);
    const paginatedProjects = filteredProjects.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const chartData = paginatedProjects.map((item) => ({
        month: item.month,
        totalAmount: item.totalAmount,
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h3" textAlign={'center'} gutterBottom>
                Total Projects Amount By Month
            </Typography>
            <div style={{ height: 300 }}>
            <ResponsiveBar
                data={chartData}
                indexBy="month"
                keys={['totalAmount']}
                margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                axisLeft={{
                    tickValues: 5,
                    tickPadding: 5,
                    tickSize: 5,
                    tickRotation: 0,
                    legend: 'Total Amount',
                }}
                axisBottom={{
                    tickRotation: -45,
                }}
                enableGridX
                enableGridY
                labelTextColor="inherit:darker(1.2)"
                tooltip={({ id, value, color }) => (
                    <strong style={{ color }}>
                        {id}: {value}
                    </strong>
                )}
            />
            </div>
            <Box mb={2}>
                <TextField
                    fullWidth
                    variant="filled"
                    label="Search by month"
                    value={searchTerm}
                    onChange={handleSearch}
                    sx={{ gridColumn: 'span 2' }}
                    size="small"
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ display: 'table-header-group' }}>
                        <TableRow>
                            <TableCell>Month</TableCell>
                            <TableCell>Total Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedProjects.map((item) => (
                            <TableRow key={item.month}>
                                <TableCell>{item.month}</TableCell>
                                <TableCell>{item.totalAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={filteredProjects.length}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </DashboardLayout>
    );
};

export default ProjectsAmountByMonth;
