import  API_URL  from './apiUrl';

class HolidaysServices {
  sendHolidaysRequest(holidayRequest) {
    return API_URL.post("/holidays/send-holiday-request/", holidayRequest);
  }

  fetchPendingHoliays() {
    return API_URL.get(`/holidays/pending/request`);
  }

  fetchAllHoliays() {
    return API_URL.get(`/holidays/`);
  }

  fetchHoliday(holidayId) {
    return API_URL.get(`/holidays/holiday/${holidayId}`);
  }

  fetchUserHolidays(userId) {
    return API_URL.get(`/holidays/user/${userId}`);
  }

  updateHoliday(holidayId, updatedHoliday) {
    return API_URL.put(`/holidays/holiday/update/${holidayId}`, updatedHoliday);
  }

  deleteHoliday(holidayId) {
    return API_URL.delete(`/holidays/delete/${holidayId}`);
  }

}

export default new HolidaysServices();