import {
    CREATE_COMMERCIAL_SUCCESS,
    CREATE_COMMERCIAL_FAILURE,
    UPDATE_COMMERCIAL_SUCCESS,
    UPDATE_COMMERCIAL_FAILURE,
    GET_COMMERCIAL_SUCCESS,
    GET_COMMERCIAL_FAILURE,
    DELETE_COMMERCIAL_SUCCESS,
    DELETE_COMMERCIAL_FAILURE,
    GET_ALL_COMMERCIALS_SUCCESS,
    GET_ALL_COMMERCIALS_FAILURE,
  } from '../actions/types';
const initialState = {
  commercial: null,
  commercials: [],
  error: null,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMMERCIAL_SUCCESS:
      return {
        ...state,
        commercial: action.payload,
        error: null,
      };
    case CREATE_COMMERCIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_COMMERCIAL_SUCCESS:
      return {
        ...state,
        commercial: action.payload,
        error: null,
      };
    case UPDATE_COMMERCIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_COMMERCIAL_SUCCESS:
      return {
        ...state,
        commercial: action.payload,
        error: null,
      };
    case GET_COMMERCIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_COMMERCIAL_SUCCESS:
      return {
        ...state,
        commercial: null,
        error: null,
      };
    case DELETE_COMMERCIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_COMMERCIALS_SUCCESS:
      return {
        ...state,
        commercials: action.payload,
        error: null,
      };
    case GET_ALL_COMMERCIALS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default contactReducer;
