import API_URL from "./apiUrl";

class DevisService {
  createDevis(devisData) {
    return API_URL.post("/devis/create", devisData);
  }

  getAllDevis() {
    return API_URL.get("/devis");
  }

  updateDevis(devisId, updatedDevis) {
    return API_URL.put(`/devis/update/${devisId}`, updatedDevis);
  }

  updateDevisStatut(devisId, updatedDevis) {
    return API_URL.put(`/devis/update/statut/${devisId}`, updatedDevis);
  }

  fetchDevis(devisId) {
    return API_URL.get(`/devis/${devisId}`);
  }
  deleteDevis(devisId) {
    return API_URL.delete(`/devis/delete/${devisId}`);
  }
}

export default new DevisService();
