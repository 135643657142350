import API_URL from './apiUrl';

class ProjectService {
    createProject(projectData) {
        return API_URL.post("/projet/create", projectData);
    }

    createBigProject(projectData) {
        return API_URL.post("/projet/create/grandProjet", projectData);
    }

    fetchProject(projectId) {
        return API_URL.get(`/projet/${projectId}`);
    }

    updateProject(projectId, updatedProject) {
        return API_URL.put(`/projet/update/${projectId}`, updatedProject);
    }

    updateOneProject(projectId, updatedProject) {
        return API_URL.put(`/projet/update/projet/${projectId}`, updatedProject);
    }

    getAllProjects() {
        return API_URL.get("/projet");
    }

    deleteProject(projectId) {
        return API_URL.delete(`/projet/delete/${projectId}`);
    }

    countProjectsByStatus() {
        return API_URL.get(`/projet/count/projects`);
    }

    getProjectsByStatus(status) {
        return API_URL.get(`/projet/projects/statut/${status}`);
    }

    getProjectsByClientId(clientId) {
        return API_URL.get(`/projet/client/${clientId}`);
    }

    getProjectsByContactId(contactId) {
        return API_URL.get(`/projet/contact/${contactId}`);
    }

    countProjectsOfMonth() {
        return API_URL.get('/projet/projets/count-last-month');
    }

    countProjectsByMonth(month, year) {
        return API_URL.get(`/projet/projets/projectCountByMonth?month=${month}&year=${year}`);
    }

    getTotalProjectsAmountByPaiement() {
        return API_URL.get('/projet/projets/total/projectsAmountByPaiement');
    }

    getTotalProjectsAmountByFacture() {
        return API_URL.get('/projet/projets/total/projectsAmountByFacture');
    }

    getTotalProjectsAmountByPaiementAndMonth() {
        return API_URL.get('/projet/projets/total/projectsAmountByPaiementAndMonth');
    }

    getTotalProjectsAmountByFactureAndMonth() {
        return API_URL.get('/projet/projets/total/projectsAmountByFactureAndMonth');
    }

    getProjectsAmountByMonth() {
        return API_URL.get('/projet/projets/total/projectsAmountByMonth');
    }

    getRevenueByContact() {
        return API_URL.get('/projet/projets/total/projectsAmountByCommercial');
    }

    getTop10ClientsByAmount() {
        return API_URL.get('/projet/projets/total/Top10ClientsByAmount');
    }

    getCurrentMonthAmount() {
        return API_URL.get('/projet/projets/total/currentMonth');
    }

    projectUploadFiles(projectId, formData) {
        return API_URL.post(`/projet/projects/${projectId}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getAllProjectFiles(projectId) {
        return API_URL.get(`/projet/projects/${projectId}/files`);
    }

    getFileContent = (projectId, fileName) => {
        return API_URL.get(`/projet/projects/${projectId}/files/${fileName}`);
    }

    downloadFile = (projectId, fileName) => {
        return API_URL.get(`/projet/projects/${projectId}/download/${fileName}`, {
            responseType: 'blob',
        });
    };

    deleteFile = (projectId, fileName) => {
        return API_URL.delete(`/projet/projects/${projectId}/delete/${fileName}`);
    }
    
}

export default new ProjectService();


// import API_URL from './apiUrl';

// class ProjectService {
//     createProject(projectData) {
//         return API_URL.post(API_URL + "/projet/create", projectData);
//     }

//     fetchProject(projectId) {
//         return API_URL.get(`${API_URL}/projet/${projectId}`);
//     }

//     updateProject(projectId, updatedProject) {
//         return API_URL.put(`${API_URL}/projet/update/${projectId}`, updatedProject);
//     }

//     getAllProjects() {
//         return API_URL.get(API_URL + "/projet");
//     }

//     deleteProject(projectId) {
//         return API_URL.delete(`${API_URL}/projet/delete/${projectId}`);
//     }

//     countProjectsByStatus() {
//         return API_URL.get(`${API_URL}/projet/count/projects`);
//     }

//     getProjectsByStatus(status) {
//         return API_URL.get(`${API_URL}/projet/projects/statut/${status}`);
//     }

//     getProjectsByClientId(clientId) {
//         return API_URL.get(`${API_URL}/projet/client/${clientId}`);
//     }

//     countProjectsOfMonth() {
//         return API_URL.get(API_URL + '/projet/projets/count-last-month');
//     }

//     countProjectsByMonth(month, year) {
//         return API_URL.get(`${API_URL}/projet/projets/projectCountByMonth?month=${month}&year=${year}`);
//     }

//     getTotalProjectsAmountByPaiement() {
//         return API_URL.get(API_URL + '/projet/projets/total/projectsAmountByPaiement');
//     }

//     getTotalProjectsAmountByFacture() {
//         return API_URL.get(API_URL + '/projet/projets/total/projectsAmountByFacture');
//     }

//     getTotalProjectsAmountByPaiementAndMonth() {
//         return API_URL.get(API_URL + '/projet/projets/total/projectsAmountByPaiementAndMonth');
//     }

//     getTotalProjectsAmountByFactureAndMonth() {
//         return API_URL.get(API_URL + '/projet/projets/total/projectsAmountByFactureAndMonth');
//     }

//     getProjectsAmountByMonth() {
//         return API_URL.get(API_URL + '/projet/projets/total/projectsAmountByMonth');
//     }

//     projectUploadFiles(projectId, formData) {
//         return API_URL.post(`${API_URL}/projet/projects/${projectId}/upload`, formData);
//     }

//     getAllProjectFiles(projectId) {
//         return API_URL.get(`${API_URL}/projet/projects/${projectId}/files`);
//     }

//     downloadFileOfProject(projectId, fileName) {
//         return API_URL.post(`${API_URL}/projet/projects/${projectId}/files/download`, fileName);
//     }
// }

// export default new ProjectService();