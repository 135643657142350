import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCharge } from "../../actions/ChargesActions";
import ChargeService from "../../services/chargesServices";
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Autocomplete,
    Box,
    Snackbar,
    FormControl,
    Grid,
    Paper,
    Card,
} from "@mui/material";
import { Alert } from "@mui/material";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import { getAllVehicules } from "../../actions/VehiculeActions";
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import { getAllUsers } from "../../actions/UserActions";
import FileUpload from "./UploadFiles";
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';
import { Link } from "react-router-dom";
import { fetchAllProjects } from '../../actions/ProjetActions';
const AddCharge = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [mois, setMois] = useState("");
    const [note, setNote] = useState("");
    const [designation, setDesignation] = useState("");
    const [cout, setCout] = useState("");
    const [data, setData] = useState(null);
    const [paiement, setPaiement] = useState("");
    const [projetId, setProjetId] = useState(null);
    const [fromProjet, setFormProjet] = useState(false);
    const [showFrom, setShowForm] = useState(false);
    const users = useSelector((state) => state.users.users);
    const [enumValues, setEnumValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [selectedVehicle, setSelectedVehicle] = useState("");
    const vehicules = useSelector((state) => state.vehicule.vehicules);
    const projects = useSelector((state) => state.project.projects);
    const [selectedUser, setSelectedUser] = useState(null);
    let status = '';

    useEffect(() => {
        ChargeService.fetchEnumValues()
            .then((response) => {
                console.log("ENUM values received from the server:", response.data);
                setEnumValues(response.data);
                setLoading(false);
            })
            .catch((error) => console.error("Error fetching ENUM values:", error));
    }, []);

    useEffect(() => {
        dispatch(getAllVehicules());
        dispatch(getAllUsers());
        dispatch(fetchAllProjects());
    }, [dispatch]);

    if (currentUser && currentUser.roles.includes("ROLE_ADMIN")) {
        status = 'oui';
    } else {
        status = 'en cours';
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const chargeData = {
            mois,
            designation,
            cout,
            note,
            paiement,
            status: status,
            userId: selectedUser?.id || null,
            vehiculeId: selectedVehicle.id || null,
            projetId: projetId || null,
        };

        try {
            const response = await dispatch(createCharge(chargeData));
            setData(response.data);
            setSnackbarMessage("Charge créés avec succès.");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);

            setMois("");
            setNote("");
            setPaiement("");
            setDesignation("");
            setCout("");
            setSelectedVehicle("");
        } catch (error) {
            setSnackbarMessage("Erreur lors de la création des charges.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            console.error("Erreur lors de la création des charges:", error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    const formatDate = (dateString) => {
        const options = { month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const projectOptions = projects.map((projet) => ({
        value: projet.id,
        label: projet.numero,
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") 
                || currentUser.permissions.includes("Gérer les charges")) ? (
                <Container maxWidth="sm">
                    {data ? (
                        <Container>
                            <Typography variant="h2" align="center" gutterBottom>
                                Création de charges
                            </Typography>

                            <div className="text-center">
                                <Typography className="alert alert-success" variant="body1">
                                    Votre Charge a été créé avec succès!
                                </Typography>

                                <Button
                                    variant="contained"
                                    component={Link}
                                    to={'/charges'}
                                    sx={{
                                        backgroundColor: "#4caf50",
                                        color: "#fff",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        margin: "16px",
                                    }}
                                >
                                    Consulter les charges
                                </Button>

                                <Link to={'/'}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#4caf50",
                                            color: "#fff",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            margin: "16px",
                                        }}
                                    >
                                        Rentrer à l'accueil
                                    </Button>
                                </Link>

                                <Link to={'/charges/ajouter'}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#4caf50",
                                            color: "#fff",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            margin: "16px",
                                        }}
                                    >
                                        Ajouter un autre
                                    </Button>
                                </Link>
                            </div>

                            <Typography variant="h4" align="center" gutterBottom>
                                Ajoute une justification pour votre charge :
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Card sx={{ padding: 2 }}>
                                        <Typography variant="h6" color="primary" gutterBottom>
                                            <PersonIcon fontSize="medium" sx={{ mr: 1 }} />
                                            Personnel
                                        </Typography>
                                        <Typography>{currentUser?.nom}&nbsp;{currentUser?.prenom}</Typography>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ padding: 2 }}>
                                        <Typography variant="h6" color="primary" gutterBottom>
                                            <AssignmentIcon fontSize="medium" sx={{ mr: 1 }} />
                                            Designation
                                        </Typography>
                                        <Typography>{data?.designation}</Typography>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12}>
                                    <Paper sx={{ padding: 2 }}>
                                        <Typography variant="h6" color="primary" gutterBottom>
                                            <CommentIcon fontSize="medium" sx={{ mr: 1 }} />
                                            Observation
                                        </Typography>
                                        <Typography>{data?.note || 'Aucune remarque !'}</Typography>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                                        <Typography variant="h6" color="primary" gutterBottom>
                                            <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                                            Cout
                                        </Typography>
                                        <Typography component="div" variant="h5" gutterBottom>
                                            {data?.cout}&nbsp;
                                            <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                                MAD
                                            </Typography>
                                        </Typography>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                                        <Typography variant="h6" color="primary" gutterBottom>
                                            <CalendarMonthIcon fontSize="medium" sx={{ mr: 1 }} />
                                            Mois
                                        </Typography>
                                        <Typography component="div" variant="h5" gutterBottom>
                                            {formatDate(data?.mois)}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>

                            <Box mt={5}>
                                <FileUpload chargeId={data?.id} />
                            </Box>
                        </Container>
                    ) : (
                        <>
                            <Box p={3}>
                                <Typography variant="h2" align="center" gutterBottom>
                                    Création de charges
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    {loading ? (
                                        <div>Loading...</div>
                                    ) : (
                                        <div>
                                            <TextField
                                                select
                                                label="Designation"
                                                value={designation}
                                                onChange={(e) => setDesignation(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                required
                                            >
                                                {enumValues.map((value) => (
                                                    <MenuItem
                                                        sx={{
                                                            padding: '12px !important',
                                                        }}
                                                        key={value} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            {(designation === 'Maintenance véhicule' || designation === 'Assurance véhicule' || designation === 'Vignette véhicule' || designation === 'Carburant') && (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        select
                                                        label="Vehicle"
                                                        margin="normal"
                                                        value={selectedVehicle}
                                                        onChange={(e) => setSelectedVehicle(e.target.value)}
                                                    >
                                                        {vehicules?.map((vehicle) => (
                                                            <MenuItem key={vehicle.id} value={vehicle}>
                                                                {vehicle.type}&nbsp;{vehicle.model}&nbsp;{vehicle.matricule}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            )}

                                            <TextField
                                                label="Mois"
                                                type="month"
                                                value={mois}
                                                onChange={(e) => setMois(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                required
                                            />

                                            <TextField
                                                label="Cout"
                                                type="number"
                                                value={cout}
                                                onChange={(e) => setCout(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                required
                                            />
                                            <TextField
                                                fullWidth
                                                label="Note"
                                                multiline
                                                rows={5}
                                                variant="outlined"
                                                margin="normal"
                                                value={note}
                                                onChange={(e) => setNote(e.target.value)}
                                            />
                                            {(currentUser && (currentUser.roles.includes("ROLE_ADMIN"))) &&
                                                <>
                                                    <TextField
                                                        select
                                                        label='Mode de paiement'
                                                        name="type"
                                                        margin="normal"
                                                        sx={{marginBottom:'1.5rem'}}
                                                        value={paiement}
                                                        onChange={(e) => setPaiement(e.target.value)}
                                                        fullWidth
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Sélectionnez le mode
                                                        </MenuItem>
                                                        <MenuItem value="Espèces">Espèces</MenuItem>
                                                        <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                                                        <MenuItem value="Chèques">Chèques</MenuItem>
                                                    </TextField>
                                                </>
                                            }
                                            <TextField
                                                value={showFrom}
                                                onChange={(e) => setShowForm(e.target.value)}
                                                fullWidth
                                                label='Lié au personnel'
                                                sx={{ marginBottom: 3 }}
                                                variant="outlined"
                                                select
                                            >
                                                <MenuItem value={false} disabled>Select Option...</MenuItem>
                                                <MenuItem value={false}>Non</MenuItem>
                                                <MenuItem value={true}>Oui</MenuItem>
                                            </TextField>
                                            {showFrom && (
                                                <>
                                                    <Autocomplete
                                                        options={users}
                                                        sx={{
                                                            marginBottom: '1rem',
                                                        }}
                                                        getOptionLabel={(user) => `${user?.nom} ${user?.prenom}`}
                                                        onChange={(event, newValue) => setSelectedUser(newValue)}
                                                        renderInput={(params) => <TextField {...params} label="Personnel" />}
                                                    />

                                                    {/* <Autocomplete
                                                        options={users}
                                                        sx={{
                                                            marginBottom: '1rem',
                                                        }}
                                                        getOptionLabel={(user) => user?.nom}
                                                        onChange={(event, newValue) => setSelectedUser(newValue)}
                                                        renderInput={(params) => <TextField {...params} label="Personnel" />}
                                                    /> */}
                                                </>
                                            )}
                                            <TextField
                                                value={fromProjet}
                                                onChange={(e) => setFormProjet(e.target.value)}
                                                fullWidth
                                                label='Lié au projet'
                                                sx={{ marginBottom: 3 }}
                                                variant="outlined"
                                                select
                                            >
                                                <MenuItem value={false} disabled>Select Option...</MenuItem>
                                                <MenuItem value={false}>Non</MenuItem>
                                                <MenuItem value={true}>Oui</MenuItem>
                                            </TextField>
                                            {fromProjet && (
                                                <>
                                                    <Autocomplete
                                                        options={projectOptions}
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, newValue) => setProjetId(newValue.value)}
                                                        renderInput={(params) => <TextField {...params} label="Projet " />}
                                                    />
                                                </>
                                            )}
                                            <MDButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                sx={{ mt: 3 }}
                                            >
                                                Create Charge
                                            </MDButton>
                                        </div>
                                    )}
                                </form>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    open={openSnackbar}
                                    autoHideDuration={6000}
                                    onClose={handleSnackbarClose}
                                >
                                    <Alert
                                        onClose={handleSnackbarClose}
                                        severity={snackbarSeverity}
                                        sx={{ mt: 2 }}
                                    >
                                        {snackbarMessage}
                                    </Alert>
                                </Snackbar>
                            </Box>
                        </>
                    )}
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout>
    );
};

export default AddCharge;
