import React, { useEffect, useState } from 'react';
import ProjectService from '../../services/projetServices';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const ProjectsAmountByFacture = () => {
    const [projectsAmountByFacture, setProjectsAmountByFacture] = useState([]);

    useEffect(() => {
        fetchProjectsAmountByFacture();
    }, []);

    const fetchProjectsAmountByFacture = async () => {
        try {
            const response = await ProjectService.getTotalProjectsAmountByFacture();
            setProjectsAmountByFacture(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const chartData = projectsAmountByFacture.map((item) => ({
        id: item.facture || 'null',
        value: parseFloat(item.totalAmount),
    }));

    const colors = ['#8884d8', '#82ca9d', '#ffc658'];

    return (

        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h3" textAlign={'center'} gutterBottom>
                Total Projects Amount By Facture
            </Typography>
            <div style={{ height: 400 }}>
                <ResponsivePie
                    data={chartData}
                    colors={colors}
                    startAngle={-90}
                    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                    innerRadius={0.6}
                    padAngle={1}
                    cornerRadius={3}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    enableRadialLabels={false}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkColor={{ from: 'color' }}
                    radialLabelsLinkStrokeWidth={2}
                    enableSlicesLabels={false}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ display: 'table-header-group' }}>
                        <TableRow>
                            <TableCell>Facture</TableCell>
                            <TableCell>Total Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectsAmountByFacture.map((item) => (
                            <TableRow key={item.facture}>
                                <TableCell>{item.facture}</TableCell>
                                <TableCell>{item.totalAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DashboardLayout>
    );
};

export default ProjectsAmountByFacture;
