import React from 'react';
import { Typography, Container, Box, Button } from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="sm">
                <Box textAlign="center" mt={10}>
                    <Typography variant="h1" color="error" gutterBottom>
                        404
                    </Typography>
                    <Typography variant="h4" color="error" gutterBottom>
                        Page non trouvée
                    </Typography>
                    <Typography variant="body1">
                        Désolé, nous n'avons pas pu trouver cette page.
                    </Typography>
                    <Button
                        component={Link}
                        to={'/'}
                        size='large'
                        sx={{
                            marginTop: '2rem'
                        }}
                    >
                        <KeyboardReturnIcon /> &nbsp;Retour à la page d'accueil
                    </Button>
                </Box>
            </Container>
        </DashboardLayout>
    );
};

export default NotFound;