import  API_URL  from './apiUrl';

class ConstantService {
  createConstant(constantData) {
    return API_URL.post("/constant/create/", constantData);
  }

  getAllConstants() {
    return API_URL.get("/constant");
  }

  fetchUserConstant(userId) {
    return API_URL.get(`/constant/user/${userId}`);
  }

  updateConstant(constantId, updatedConstant) {
    return API_URL.put(`/constant/update/${constantId}`, updatedConstant);
  }

  fetchConstant(constantId) {
    return API_URL.get(`/constant/${constantId}`);
  }
  
  deleteConstant(constantId) {
    return API_URL.delete(`/constant/delete/${constantId}`);
  }
}

export default new ConstantService();
