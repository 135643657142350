import CotisationService from '../services/cotisationServices';
import {
    CREATE_COTISATION_SUCCESS,
    CREATE_COTISATION_FAILURE,
    UPDATE_COTISATION_SUCCESS,
    UPDATE_COTISATION_FAILURE,
    GET_COTISATION_SUCCESS,
    GET_COTISATION_FAILURE,
    DELETE_COTISATION_SUCCESS,
    DELETE_COTISATION_FAILURE,
    GET_ALL_COTISATIONS_SUCCESS,
    GET_ALL_COTISATIONS_FAILURE,
} from './types';

export const createCotisation = (cotisationData) => {
  return (dispatch) => {
    return CotisationService.createCotisation(cotisationData)
      .then((response) => {
        dispatch({
          type: CREATE_COTISATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_COTISATION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllCotisations = () => {
  return (dispatch) => {
    return CotisationService.getAllCotisations()
      .then((response) => {
        dispatch({
          type: GET_ALL_COTISATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_COTISATIONS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchAllUserSalaires = (userId) => {
  return (dispatch) => {
    return CotisationService.fetchUserSalaires(userId)
      .then((response) => {
        dispatch({
          type: GET_ALL_COTISATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_COTISATIONS_FAILURE,
          payload: error.message,
        });
      });
  };
};


export const updateCotisation = (cotisationId, updatedCotisation) => {
  return (dispatch) => {
    return CotisationService.updateCotisation(cotisationId, updatedCotisation)
      .then((response) => {
        dispatch({
          type: UPDATE_COTISATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_COTISATION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getCotisation = (cotisationId) => {
  return (dispatch) => {
    return CotisationService.fetchCotisation(cotisationId)
      .then((response) => {
        dispatch({
          type: GET_COTISATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_COTISATION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteCotisation = (cotisationId) => {
  return (dispatch) => {
    return CotisationService.deleteCotisation(cotisationId)
      .then((response) => {
        dispatch({
          type: DELETE_COTISATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_COTISATION_FAILURE,
          payload: error.message,
        });
      });
  };
};
