import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAvance } from '../../actions/EncaissementActions';
import { fetchRejcted } from '../../actions/ChargesActions';
import {
    Container,
    TextField,
    MenuItem,
    Button,
    Snackbar,
    Alert
} from '@mui/material';

const UpdateAvance = ({ currentUser, avance }) => {
    const dispatch = useDispatch();

    const [updatedAvance, setUpdatedAvance] = useState({
        prix: '',
        type: '',
        date: '',
        status: '',
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        if (avance) {
            setUpdatedAvance({
                prix: avance.prix,
                type: avance.type || '',
                date: avance.date || '',
                status: 'en cours' || '',
            });
        }
    }, [avance]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedAvance({
            ...updatedAvance,
            [name]: value,
        });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateAvance(avance?.id, updatedAvance))
            .then(() => {
                setSnackbarMessage('Avance a été mis à jour avec succès.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                dispatch(fetchRejcted(currentUser?.id));
            })
            .catch((error) => {
                setSnackbarMessage(error.response.data.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('Error updating avance:', error);
            });
    };

    return (
        <>
            <Container maxWidth="lg">
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Prix"
                        name="prix"
                        type="text"
                        value={updatedAvance.prix}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        margin="normal"
                    />
                    <TextField
                        select
                        label='Mode de paiement'
                        name="type"
                        value={updatedAvance.type}
                        onChange={handleChange}
                        required
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Sélectionnez le mode
                        </MenuItem>
                        <MenuItem value="Espèces">Espèces</MenuItem>
                        <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                        <MenuItem value="Chèques">Chèques</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        label="Date"
                        name="date"
                        type="date"
                        value={updatedAvance.date}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            color: '#fff !important',
                            marginTop: '12px',
                        }}
                    >
                        Modifier & Renvoyer
                    </Button>
                </form>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert variant='filled' severity={snackbarSeverity} onClose={handleCloseSnackbar}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
};

export default UpdateAvance;
