import PciecesService from '../services/pieceServices';
import {
  CREATE_PIECES_SUCCESS,
  CREATE_PIECES_FAILURE,
  UPDATE_PIECES_SUCCESS,
  UPDATE_PIECES_FAILURE,
  GET_PIECES_SUCCESS,
  GET_PIECES_FAILURE,
  DELETE_PIECES_SUCCESS,
  DELETE_PIECES_FAILURE,
  GET_ALL_PIECESS_SUCCESS,
  GET_ALL_PIECESS_FAILURE,
} from './types';

export const createPieces = (piecesData) => {
  return (dispatch) => {
    return PciecesService.createPieces(piecesData)
      .then((response) => {
        dispatch({
          type: CREATE_PIECES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_PIECES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllPieces = () => {
  return (dispatch) => {
    return PciecesService.getAllPieces()
      .then((response) => {
        dispatch({
          type: GET_ALL_PIECESS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_PIECESS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updatePieces = (piecesId, updatedPieces) => {
  return (dispatch) => {
    return PciecesService.updatePieces(piecesId, updatedPieces)
      .then((response) => {
        dispatch({
          type: UPDATE_PIECES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PIECES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getPieces = (piecesId) => {
  return (dispatch) => {
    return PciecesService.fetchPieces(piecesId)
      .then((response) => {
        dispatch({
          type: GET_PIECES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PIECES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deletePieces = (piecesId) => {
  return (dispatch) => {
    return PciecesService.deletePieces(piecesId)
      .then((response) => {
        dispatch({
          type: DELETE_PIECES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PIECES_FAILURE,
          payload: error.message,
        });
      });
  };
};

