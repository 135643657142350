import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCharge, updateCharge } from "../../actions/ChargesActions";
import { useParams } from "react-router-dom";
import ChargeService from "../../services/chargesServices";
import { Container, Autocomplete, Typography, TextField, Select, Box, MenuItem, Snackbar, Alert } from '@mui/material';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import { fetchAllProjects } from '../../actions/ProjetActions';
import { getAllUsers } from "../../actions/UserActions";

const formatDateForMonthPicker = (dateString) => {
    if (!dateString) return "";
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
};

const EditCharge = ({ currentUser }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const charge = useSelector((state) => state.charges.charge);
    const [cout, setCout] = useState("");
    const [mois, setMois] = useState("");
    const [note, setNote] = useState("");
    const [paiement, setPaiement] = useState("");
    const [designation, setDesignation] = useState("");
    const [enumValues, setEnumValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [projetId, setProjetId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const projects = useSelector((state) => state.project.projects);
    const users = useSelector((state) => state.users.users);

    useEffect(() => {
        dispatch(getCharge(id));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(fetchAllProjects());
    }, [dispatch]);

    useEffect(() => {
        if (charge) {
            setCout(charge.cout);
            setMois(formatDateForMonthPicker(charge.mois));
            setNote(charge?.note);
            setPaiement(charge?.paiement);
            setDesignation(charge.designation);
            setProjetId(charge?.projetId);
            setSelectedUser(charge?.userId);
        }
    }, [charge]);

    useEffect(() => {
        ChargeService.fetchEnumValues()
            .then((response) => {
                setEnumValues(response.data);
                setLoading(false);
            })
            .catch((error) => console.error('Error fetching ENUM values:', error));
    }, []);

    const handleUpdate = async () => {
        const updatedCharge = {
            cout,
            mois,
            note,
            paiement,
            designation,
            projetId: projetId,
            userId: selectedUser,
        };
        try {
            await dispatch(updateCharge(charge.id, updatedCharge));
            setSnackbarMessage("Charge mise à jour avec succès.");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage("Erreur lors de la mise à jour des charges.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.error("Error updating charge:", error);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const projectOptions = projects.map((projet) => ({
        value: projet.id,
        label: projet.numero,
    }));

    const userOptions = users.map((user) => ({
        value: user.id,
        label: user.nom,
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des charges") || currentUser.permissions.includes("Modifier les charges")) ? (
                <Container maxWidth="sm">
                    <Box p={3}>
                        <Typography variant="h2" align="center" gutterBottom>
                            Modifier la Charge
                        </Typography>
                        {charge ? (
                            <form>
                                <TextField
                                    label="Cout"
                                    type="text"
                                    value={cout}
                                    onChange={(e) => setCout(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Mois"
                                    type="month"
                                    value={mois}
                                    onChange={(e) => setMois(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Select
                                    select
                                    label="Designation"
                                    value={designation}
                                    onChange={(e) => setDesignation(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                >
                                    {enumValues.map((value) => (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    fullWidth
                                    label="note"
                                    multiline
                                    rows={5}
                                    variant="outlined"
                                    margin="normal"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                                <TextField
                                    select
                                    label='Mode de paiement'
                                    name="type"
                                    value={paiement}
                                    onChange={(e) => setPaiement(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value="" disabled>
                                        Sélectionnez le mode
                                    </MenuItem>
                                    <MenuItem value="Espèces">Espèces</MenuItem>
                                    <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                                    <MenuItem value="Chèques">Chèques</MenuItem>
                                </TextField>
                                <Autocomplete
                                    options={userOptions}
                                    getOptionLabel={(option) => option.label}
                                    sx={{ marginBottom: '1rem' }}
                                    value={userOptions.find((option) => option.value === selectedUser) || null}
                                    onChange={(event, newValue) => setSelectedUser(newValue.value)}
                                    renderInput={(params) => <TextField {...params} label="Personnel " />}
                                />
                                <Autocomplete
                                    options={projectOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={projectOptions.find((option) => option.value === projetId) || null}
                                    onChange={(event, newValue) => setProjetId(newValue.value)}
                                    renderInput={(params) => <TextField {...params} label="Projet " />}
                                />
                                <MDButton
                                    variant="contained"
                                    color="info"
                                    onClick={handleUpdate}
                                    // fullWidth
                                    sx={{ mt: 3 }}
                                >
                                    Modifier
                                </MDButton>
                            </form>
                        ) : (
                            <Typography variant="body1" align="center">
                                Loading charge details...
                            </Typography>
                        )}
                    </Box>
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default EditCharge;
