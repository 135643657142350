import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPendingHolidays, updateHoliday } from '../../actions/HolidaysActions';
import {
    Box,
    Typography,
    Snackbar,
    Alert,
    Card,
    Select,
    FormControl,
    MenuItem,
    TextField,
    Dialog,
    InputLabel,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { useMaterialUIController } from "../../context";
import MDButton from '../../components/MDButton';
import AccessDenied from "../Errors/AccessDenied";

const PendingHolidaysList = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const holidays = useSelector((state) => state.holiday.holidays) ?? [];
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalValiderOpen, setModalValiderOpen] = useState(false);
    const [isModalRejectOpen, setModalRejectOpen] = useState(false);
    const [isPaid, setIsPaid] = useState('');
    const [cause, setCause] = useState('');

    useEffect(() => {
        dispatch(getPendingHolidays())
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching pending tasks:', error);
                setLoading(false);
            });
    }, [dispatch]);

    const openValideModal = (item) => {
        setSelectedItem(item);
        setModalValiderOpen(true);
    };

    const closeValideModal = () => {
        setSelectedItem(null);
        setModalValiderOpen(false);
    };

    const openRejectModel = (item) => {
        setSelectedItem(item);
        setModalRejectOpen(true);
    };

    const closeRejectModal = () => {
        setSelectedItem(null);
        setModalRejectOpen(false);
    };

    const handleValider = () => {
        if (selectedItem) {
            dispatch(updateHoliday(selectedItem.id, { status: 'confirmed', isPaid: isPaid || '' }))
                .then(() => {
                    dispatch(getPendingHolidays());
                })
                .catch((error) => {
                    console.error('Error Confiramtion indemnite:', error);
                });
            setSelectedItem(null);
        }
        closeValideModal();
    };

    const handleReject = () => {
        if (selectedItem) {
            dispatch(updateHoliday(selectedItem.id, { status: 'rejected', cause: cause || '' }))
                .then(() => {
                    dispatch(getPendingHolidays());
                })
                .catch((error) => {
                    console.error('Error rjecting indemnite:', error);
                });
            setSelectedItem(null);
        }
        closeRejectModal();
    };

    const columns = [
        {
            field: 'user?.nom', headerName: 'Personnel',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.user?.nom || '',
        },
        {
            field: 'requestDays', headerName: 'Nombre de jours',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'requestDate', headerName: 'Date de la demande',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'startDate', headerName: 'Date de début',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'endDate', headerName: 'Date de fin',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'confirmTask',
            headerName: 'Confirmation',
            ...(isMobile ? { width: 120 } : { flex: 2 }),
            renderCell: (params) => {

                return (
                    <>
                        <MDButton
                            className='me-1'
                            variant="contained"
                            color="success"
                            onClick={() => openValideModal(params.row)}
                        >
                            Confirmer
                        </MDButton>
                        <MDButton
                            className='ms-1'
                            variant="contained"
                            color="error"
                            onClick={() => openRejectModel(params.row)}
                        >
                            Rejeter
                        </MDButton>
                    </>
                );
            },
            cellClassName: 'actions-cell',
        },
    ];


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les congés")) ? (
                <Box>
                    <Typography mb={4} mt={2} textAlign={'center'} variant="h2" gutterBottom>
                        Demandes de congés en attente
                    </Typography>
                    <DataGrid
                        className={darkMode ? "text-light" : "text-dark"}
                        rows={holidays?.holidays ?? []}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        loading={loading}
                        autoHeight
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <Dialog open={isModalValiderOpen} onClose={closeValideModal} fullWidth maxWidth='sm'>
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "green",
                        color: '#fff'
                    }}
                >
                    Confirmer Congés
                </DialogTitle>
                <DialogContent>
                    <DialogContentText mt={2} style={{ color: darkMode ? "#fff" : "#000" }}>
                        <div className="mb-2">
                            Êtes-vous sûr de vouloir confirmer ce congé?
                        </div>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="type">Payé</InputLabel>
                            <Select
                                label="Payé"
                                labelId="type-label"
                                id="Payé"
                                name="Payé"
                                value={isPaid}
                                fullWidth
                                onChange={(e) => setIsPaid(e.target.value)}
                            >
                                <MenuItem value="oui" >
                                    Oui
                                </MenuItem>
                                <MenuItem value="non" >
                                    Non
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeValideModal}>
                        Annuler
                    </MDButton>
                    <MDButton color='success' className="btn btn-sm" onClick={handleValider}>
                        Valider
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog open={isModalRejectOpen} onClose={closeRejectModal} fullWidth maxWidth='sm'>
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "red",
                        color: '#fff'
                    }}
                >
                    Rejeter Congés
                </DialogTitle>
                <DialogContent>
                    <DialogContentText mt={2} style={{ color: darkMode ? "#fff" : "#000" }}>
                        <div className="mb-2">
                            Etes-vous sûr de vouloir refuser ce congé?
                        </div>
                        <TextField
                            type="text"
                            name="cause"
                            value={cause}
                            onChange={(e) => setCause(e.target.value)}
                            required
                            fullWidth
                            label="Cause du Rejet"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeRejectModal}>
                        Annuler
                    </MDButton>
                    <MDButton color='error' className="btn btn-sm" onClick={handleReject}>
                        Rejeter
                    </MDButton>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default PendingHolidaysList;
