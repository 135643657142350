import  API_URL  from './apiUrl';

class CotisationService {
  createCotisation(cotisationData) {
    return API_URL.post("/cotisations/create/", cotisationData);
  }

  getAllCotisations() {
    return API_URL.get("/cotisations");
  }

  updateCotisation(cotisationId, updatedCotisation) {
    return API_URL.put(`/cotisations/update/${cotisationId}`, updatedCotisation);
  }

  fetchCotisation(cotisationId) {
    return API_URL.get(`/cotisations/${cotisationId}`);
  }

  
  fetchUserSalaires(userId) {
    return API_URL.get(`/cotisations/salaires/user/${userId}`);
  }
  
  deleteCotisation(cotisationId) {
    return API_URL.delete(`/cotisations/delete/${cotisationId}`);
  }
}

export default new CotisationService();
