import  API_URL  from './apiUrl';

class StagiaireService {
  createStagiaire(stagiaireData) {
    return API_URL.post("/stagiaires/create/", stagiaireData);
  }

  getAllStagiaires() {
    return API_URL.get("/stagiaires");
  }

  updateStagiaire(stagiaireId, updatedStagiaire) {
    return API_URL.put(`/stagiaires/update/${stagiaireId}`, updatedStagiaire);
  }

  fetchStagiaire(stagiaireId) {
    return API_URL.get(`/stagiaires/${stagiaireId}`);
  }
  
  deleteStagiaire(stagiaireId) {
    return API_URL.delete(`/stagiaires/delete/${stagiaireId}`);
  }
}

export default new StagiaireService();
