import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFacture, getFacture } from '../../actions/FactureActions';
import { useParams } from 'react-router-dom';
import { Snackbar, Alert } from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import {
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import MDButton from "../../components/MDButton";
import { decodeId } from '../../actions/Crypte';
import Spinner from '../Spinner/Index';
const UpdateFacture = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const factureId = decodeId(id);
  const facture = useSelector((state) => state.facture.facture);

  const [date, setDate] = useState('');
  const [objet, setObjet] = useState('');
  const [statut, setStatut] = useState('');
  const [reference, setReference] = useState('');
  const [datePaiement, setDatePaiement] = useState('');
  const [modePaiement, setModePaiement] = useState('');
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState('');
  const [itemUnite, setItemUnite] = useState('');
  const [itemPriceUnit, setItemPriceUnit] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    dispatch(getFacture(factureId));
  }, [dispatch, factureId]);

  useEffect(() => {
    if (facture) {
      setDate(facture.date);
      setDatePaiement(facture.date_paiement);
      setObjet(facture.objet);
      setReference(facture.reference);
      setStatut(facture.statut);
      setModePaiement(facture.modePaiement);
      setItems([...facture?.items]);
    }
  }, [facture]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedFacture = {
      date,
      objet,
      reference,
      date_paiement: datePaiement,
      mode_paiement: modePaiement,
      statut: statut || 'en_instance',
      items,
    };

    dispatch(updateFacture(facture.id, updatedFacture))
      .then(() => {
        setSnackbarMessage('Facture mise à jour avec succès!');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleItemChange = (index, fieldName, fieldValue) => {
    const updatedItems = [...items];
    updatedItems[index][fieldName] = fieldValue;

    if (fieldName === 'quantity' || fieldName === 'prix_unitaire') {
      const item = updatedItems[index];
      item.price = parseFloat(item.prix_unitaire) * parseInt(item.quantity);
    }

    setItems(updatedItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    if (!itemName || !itemUnite || !itemPriceUnit || !itemQuantity) return;

    const newItem = {
      name: itemName,
      unite: itemUnite,
      prix_unitaire: parseFloat(itemPriceUnit),
      price: parseFloat(itemPriceUnit) * parseInt(itemQuantity),
      quantity: parseInt(itemQuantity),
    };

    setItems((prevItems) => [...prevItems, newItem]);

    setItemName('');
    setItemUnite('');
    setItemPriceUnit('');
    setItemQuantity('');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const calculateTotalPrice = () => {
    let total = 0;
    items.forEach((item) => {
      total += item.prix_unitaire * item.quantity;
    });
    return total;
  };

  const calculTotalePrix = (item) => {
    return item.prix_unitaire * item.quantity;
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les factures")) ? (
        <div>
          {facture ? (
            <>
              <Typography variant="h2" align="center" mb={3}>
                Mettre à jour la facture
              </Typography>
              <Typography variant="body1" align="center" mb={1}>
                {facture.client?.full_name}
              </Typography>
              <Typography variant="h4" align="center" mb={3}>
                {facture.numero}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      type="date"
                      fullWidth
                      label='Date'
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Objet'
                      fullWidth
                      type="text"
                      value={objet}
                      onChange={(e) => setObjet(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Date Paiement'
                      type="date"
                      fullWidth
                      value={datePaiement}
                      onChange={(e) => setDatePaiement(e.target.value)}
                      disabled={statut === 'en_instance'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Statut:</InputLabel>
                      <Select
                        value={statut}
                        onChange={(e) => setStatut(e.target.value)}
                      >
                        <MenuItem value="en_instance">En Instance</MenuItem>
                        <MenuItem value="paye">Payé</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      label='Mode Paiement'
                      value={modePaiement}
                      onChange={(e) => setModePaiement(e.target.value)}
                      disabled={statut === 'en_instance'}
                    >
                      <MenuItem value="" disabled>Select ...</MenuItem>
                      <MenuItem value="Espèces">Espèces</MenuItem>
                      <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                      <MenuItem value="Chèques">Chèques</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Référenece'
                      fullWidth
                      type="text"
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" mb={2}>Désignations :</Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Designation</TableCell>
                            <TableCell>Unite</TableCell>
                            <TableCell>Prix Unitaire</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {items.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <TextField
                                  type="text"
                                  name="name"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleItemChange(index, 'name', e.target.value)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  type="text"
                                  name="unite"
                                  value={item.unite}
                                  onChange={(e) =>
                                    handleItemChange(index, 'unite', e.target.value)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  type="number"
                                  value={item.prix_unitaire}
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      'prix_unitaire',
                                      parseFloat(e.target.value)
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  type="number"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      'quantity',
                                      parseFloat(e.target.value)
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {calculTotalePrix(item)}
                              </TableCell>
                              <TableCell>
                                <MDButton
                                  type="button"
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleRemoveItem(index)}
                                >
                                  Retirer
                                </MDButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className='float-end bg-white p-3 mb-2 mt-1'>
                      <Typography variant="body1">
                        Totale HT: {calculateTotalPrice()}
                      </Typography>
                      <Typography variant="body1">
                        Totale TTC: {calculateTotalPrice() * 1.2}
                      </Typography>
                    </div>
                    <Grid container mt={3} mb={2} spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          fullWidth
                          value={itemName}
                          onChange={(e) => setItemName(e.target.value)}
                          placeholder="Designation"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          fullWidth
                          value={itemUnite}
                          onChange={(e) => setItemUnite(e.target.value)}
                          placeholder="Unite"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="number"
                          fullWidth
                          value={itemPriceUnit}
                          onChange={(e) => setItemPriceUnit(e.target.value)}
                          placeholder="Prix Unitaire"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="number"
                          fullWidth
                          value={itemQuantity}
                          onChange={(e) => setItemQuantity(e.target.value)}
                          placeholder="Quantity"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <MDButton
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={handleAddItem}
                        >
                          Ajouter un item
                        </MDButton>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
                <MDButton type="submit" variant="contained" color="info" mt={3}>
                  mettre à jour la facture
                </MDButton>
              </form>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity="success"
                  sx={{ width: '100%' }}
                  variant='filled'
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </>
          ) : (
            <>
              <Spinner />
            </>
          )}
        </div>
      ) : (
        <div className="container">
          <AccessDenied />
        </div>
      )}
    </DashboardLayout>
  );
};

export default UpdateFacture;

