import PolygonService from '../services/polygonServices';
import {
    CREATE_PROJECT_POLYGON_SUCCESS,
    CREATE_PROJECT_POLYGON_FAILURE,
} from './types';

export const createProjectPolygon = (projectId, data) => {
  return (dispatch) => {
    return PolygonService.addPolygonToProject(projectId, data)
      .then((response) => {
        dispatch({
          type: CREATE_PROJECT_POLYGON_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_PROJECT_POLYGON_FAILURE,
          payload: error.message,
        });
        throw error; 
      });
  };
};



