import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Alert, Button, Typography, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PointServices from '../../services/pointServices';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DuplicatedPointsMap from './DuplicatedMap';

const DuplicatePoints = ({ currentUser }) => {
    const [duplicates, setDuplicates] = useState([]);
    const [selectedPoints, setSelectedPoints] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [duplicatedDialogOpen, setDuplicatedDialogOpen] = useState(false);

    useEffect(() => {
        fetchDuplicates();
    }, []);

    const fetchDuplicates = async () => {
        try {
            const response = await PointServices.fetchDuplicatedPoints();
            setDuplicates(response.data);
        } catch (error) {
            console.error('Error fetching duplicates:', error);
            handleSnackbarOpen('Failed to fetch duplicates', 'error');
        }
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDelete = async () => {
        try {
            await PointServices.deletePoints(selectedPoints);
            handleSnackbarOpen('Points deleted successfully', 'success');
            fetchDuplicates();
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error('Error deleting points:', error);
            handleSnackbarOpen('Failed to delete points', 'error');
        }
    };

    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        { field: 'name', headerName: 'Nom', flex: 1 },
        { field: 'reference', headerName: 'Référence', flex: 1 },
        { field: 'nature', headerName: 'Nature', flex: 1 },
        { field: 'createdBy', headerName: 'Créé par', flex: 1 },
        { field: 'coordinates', headerName: 'Coordonnées', width: 250, valueGetter: (params) => params.row.geometry.coordinates.join(', ') },
    ];

    console.log(selectedPoints);

    const rows = duplicates.flatMap((duplicate) =>
        duplicate.points.map((point) => ({
            id: point.id,
            name: point.name,
            reference: point.reference,
            nature: point.nature,
            createdBy: point.createdBy,
            geometry: point.geometry,
        }))
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box>
                <Typography textAlign={'center'} variant='h3' mt={2}>Points dupliqués</Typography>
                {rows.length > 0 ? (
                    <div style={{ height: 600, width: '100%' }}>
                        <Box justifyContent={'flex-end'} alignItems={'end'} textAlign={'end'} mb={1} mt={2}>
                            <Button variant="contained" color='error'
                                sx={{
                                    color: '#fff !important',
                                    background: 'orange !important',
                                    marginRight: '0.5rem'
                                }}
                                onClick={() => setDuplicatedDialogOpen(true)}>
                                Duplicated Points
                            </Button>
                            <Button variant="contained" color='error' sx={{ color: '#fff !important', background: 'red !important' }}
                                onClick={() => setDeleteDialogOpen(true)} disabled={selectedPoints.length === 0}>
                                Delete Selected Points
                            </Button>
                        </Box>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => setSelectedPoints(newSelection)}
                        />
                    </div>
                ) : (
                    <p>No duplicate points found</p>
                )}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                >
                    <DialogTitle
                        textAlign={'center'}
                        sx={{
                            backgroundColor: "red",
                            color: '#fff'
                        }}
                    >
                        Supprimer les points
                    </DialogTitle>
                    <DialogContent>
                        <Typography mt={2} variant="h6">
                            Êtes-vous sûr de vouloir supprimer ces points ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)}>Annuler</Button>
                        <Button
                            sx={{
                                color: 'white !important',
                                backgroundColor: 'red !important',
                            }}
                            onClick={handleDelete} color="error">
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={duplicatedDialogOpen}
                    onClose={() => setDuplicatedDialogOpen(false)}
                    fullWidth
                    maxWidth={'100%'}
                >
                    <DialogTitle
                        textAlign={'center'}
                        sx={{
                            backgroundColor: "orange",
                            color: '#fff'
                        }}
                    >
                        Points dupliqués
                    </DialogTitle>
                    <DialogContent>
                        <DuplicatedPointsMap points={rows} currentUser={currentUser} fetchPoints={fetchDuplicates} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDuplicatedDialogOpen(false)}>Annuler</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </DashboardLayout>
    );
};

export default DuplicatePoints;
