import React, { useEffect, useState } from 'react';
import ChargeService from '../../services/chargesServices';
import { Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const TotalChargesByYear = () => {
    const [totalChargesByYear, setTotalChargesByYear] = useState([]);

    useEffect(() => {
        fetchTotalChargesByYear();
    }, []);

    const fetchTotalChargesByYear = async () => {
        try {
            const response = await ChargeService.getTotalChargesByYear();
            setTotalChargesByYear(response.data);
        } catch (error) {
            console.error('Error fetching total charges by year:', error);
        }
    };

    const chartData = totalChargesByYear.map((yearData) => ({
        year: yearData.year,
        chargeCount: yearData.chargeCount,
        totalCharges: parseFloat(yearData.totalCharges),
    }));

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <Typography variant="h3" textAlign={'center'} gutterBottom>
                    Total Charges by Year
                </Typography>
                {totalChargesByYear.length === 0 ? (
                    <Typography variant="body1">No data available.</Typography>
                ) : (
                    <>
                        <Box alignItems={'center'} style={{ height: 300 }}>
                            <ResponsiveBar
                                data={chartData}
                                keys={['totalCharges']}
                                indexBy="year"
                                margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                                padding={0.3}
                                colors={{ scheme: 'category10' }}
                                axisBottom={{
                                    tickRotation: -45,
                                }}
                                axisLeft={{
                                    tickValues: 5,
                                    tickPadding: 5,
                                    tickSize: 5,
                                    tickRotation: 0,
                                    legend: 'Total Charges',
                                }}
                                enableGridX
                                enableGridY
                                labelTextColor="inherit:darker(1.2)"
                                tooltip={({ id, value, color }) => (
                                    <strong style={{ color }}>
                                        {id}: {value}
                                    </strong>
                                )}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Year</TableCell>
                                        <TableCell>Charge Count</TableCell>
                                        <TableCell>Total Charges</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {totalChargesByYear.map((yearData) => (
                                        <TableRow key={yearData.year}>
                                            <TableCell>{yearData.year}</TableCell>
                                            <TableCell>{yearData.chargeCount}</TableCell>
                                            <TableCell>{yearData.totalCharges}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </DashboardLayout>
        </>
    );
};

export default TotalChargesByYear;
