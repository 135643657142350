import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';
import PointServices from '../../services/pointServices';
import { Icon } from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Dialog, DialogTitle, DialogActions, Alert, Snackbar, TextField, Button, DialogContent, MenuItem } from '@mui/material';
import MDButton from '../../components/MDButton';
import {
    Grid,
} from "@mui/material";
import UpdatePoint from './UpdatePoint';
import L from 'leaflet';

const markerIcon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

const DuplicatedPointsMap = ({ points, currentUser, fetchPoints }) => {
    const [convertedCoordinates, setConvertedCoordinates] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [selectedPointId, setSelectedPointId] = useState(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedProjection, setSelectedProjection] = useState('26192');
    const [inputX, setInputX] = useState('');
    const [inputY, setInputY] = useState('');
    const [popupPosition, setPopupPosition] = useState(null);
    const [popupContent, setPopupContent] = useState('');

    const mapRef = useRef(null);
    const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

    useEffect(() => {
        if (points?.length === 0) return;
        const sourceCRS = getSourceCRS(selectedProjection);

        proj4.defs('EPSG:3857', targetCRS);
        proj4.defs(`EPSG:${selectedProjection}`, sourceCRS);

        const converted = points?.map((point) => {
            const [x, y] = proj4(sourceCRS, targetCRS, point.geometry.coordinates);
            return [y, x];
        });

        setConvertedCoordinates(converted);
    }, [points, selectedProjection]);

    const getSourceCRS = (zone) => {
        switch (zone) {
            case '26191':
                return '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
            case '26192':
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
            case '26194':
                return '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.999616304 +x_0=1200000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
            case '26195':
                return '+proj=lcc +lat_1=22.5 +lat_0=22.5 +lon_0=-5.4 +k_0=0.999616437 +x_0=1500000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
            default:
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
    };

    const Map = () => {
        const map = useMap();
        mapRef.current = map;

        return null;
    };

    const handlePointUpdate = (varia) => {
        const updatedPoint = {
            name: varia.name,
            nature: varia.nature,
            reference: varia.reference,
            X: varia.X,
            Y: varia.Y,
            createdBy: varia.igt,
            note: varia.note
        };

        PointServices.updatePoint(varia?.id, updatedPoint)
            .then((response) => {
                console.log('Point updated successfully:', response?.data.message);
                handleSnackbarOpen(response?.data.message, 'success');
                fetchPoints();
            })
            .catch((error) => {
                console.error('Error updating point:', error);
                handleSnackbarOpen(error?.response?.data.message, 'error')

            });
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleDeleteDialogOpen = (pointId) => {
        setSelectedPointId(pointId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setSelectedPointId(null);
        setDeleteDialogOpen(false);
    };

    const handleUpdateDialogOpen = (point) => {
        setSelectedPointId(point);
        setUpdateDialogOpen(true);
    };

    const handleUpdateDialogClose = () => {
        setSelectedPointId(null);
        setUpdateDialogOpen(false);
    };

    const handleDeletePoint = () => {
        if (selectedPointId) {
            PointServices.deletePoint(selectedPointId)
                .then((response) => {
                    console.log('Point deleted successfully:', response.data);
                    handleSnackbarOpen(`${response.data.message}`, 'success');
                    handleDeleteDialogClose();
                    fetchPoints();
                })
                .catch((error) => {
                    console.error('Error deleting point:', error);
                    handleSnackbarOpen('Error deleting point', 'error');
                });
        }
    };

    const handleProjectionChange = (event) => {
        setSelectedProjection(event.target.value);
    };

    const goToCoordinates = (x, y) => {
        const sourceCRS = getSourceCRS(selectedProjection);
        const [lng, lat] = proj4(sourceCRS, targetCRS, [x, y]);

        if (mapRef.current) {
            mapRef.current.flyTo([lat, lng], 16); // 16 is an example zoom level
            setPopupPosition([lat, lng]);
            setPopupContent(`X: ${x}, Y: ${y}`);
        }
    };

    const handleGoToCoordinates = () => {
        if (inputX && inputY) {
            goToCoordinates(parseFloat(inputX), parseFloat(inputY));
        } else {
            handleSnackbarOpen('Please enter valid coordinates', 'error');
        }
    };

    return (
        <>
            <Grid container spacing={2} mb={2} mt={2}>
                <Grid item xs={12} sm={12} >
                    <TextField
                        select
                        label='Sélectionner la projection des points'
                        id="projection-select"
                        name="selectedProjection"
                        value={selectedProjection}
                        fullWidth
                        onChange={handleProjectionChange}
                    >
                        <MenuItem value="26191">Zone 1: Merchich / zone Nord</MenuItem>
                        <MenuItem value="26192">Zone 2: Merchich / zone Sud </MenuItem>
                        <MenuItem value="26194">Zone 3: Merchich / sahara Nord</MenuItem>
                        <MenuItem value="26195">Zone 4: Merchich / sahara Nord</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        label="X Coordinate"
                        value={inputX}
                        onChange={(e) => setInputX(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        label="Y Coordinate"
                        value={inputY}
                        onChange={(e) => setInputY(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <MDButton
                        variant="contained"
                        color="primary"
                        size='small'
                        className='mt-2'
                        onClick={handleGoToCoordinates}
                    >
                        Go to Coordinates
                    </MDButton>
                </Grid>
            </Grid>
            <MapContainer
                center={[30.39341198110206, -9.565342191279383]}
                zoom={10}
                style={{ height: '80vh', width: '100%' }}
            >
                <Map />
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Google Maps">
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                            maxZoom={21}
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="OpenStreetMap">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            maxZoom={21}
                            attribution='<a href="https://www.openstreetmap.org">OpenStreetMap</a>'
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.Overlay checked name="Rattachements">
                        <MarkerClusterGroup>
                            {convertedCoordinates.map((coord, index) => (
                                <Marker key={index} position={coord} icon={markerIcon}>
                                    <Popup>
                                        <div>Id: {points[index]?.id}</div>
                                        <div>Name: {points[index]?.name}</div>
                                        <div>Nature: {points[index]?.nature}</div>
                                        <div>Reference: {points[index]?.reference}</div>
                                        <div>X: {points[index]?.geometry?.coordinates[0]}</div>
                                        <div>Y: {points[index]?.geometry?.coordinates[1]}</div>
                                        {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les points de rattachement")) && (
                                            <MDButton
                                                variant="contained"
                                                color="error"
                                                size='small'
                                                className='mt-2'
                                                onClick={() => handleDeleteDialogOpen(points[index].id)}
                                            >
                                                Supprimer
                                            </MDButton>
                                        )}
                                        {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les points de rattachement")) && (
                                            <MDButton
                                                variant="contained"
                                                color="info"
                                                size='small'
                                                className='mt-2 ms-2'
                                                onClick={() => handleUpdateDialogOpen(points[index])}
                                            >
                                                Modifier
                                            </MDButton>
                                        )}
                                    </Popup>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>
                    </LayersControl.Overlay>
                </LayersControl>
                {popupPosition && (
                    <Marker position={popupPosition} icon={markerIcon}>
                        <Popup>
                            <div>{popupContent}</div>
                        </Popup>
                    </Marker>
                )}
            </MapContainer>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Confirmation de la suppression</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Annuler</Button>
                    <Button onClick={handleDeletePoint} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={updateDialogOpen}
                onClose={handleUpdateDialogClose}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle className='text-center' bgcolor='#ffc107' style={{ fontSize: '29px' }}>
                    Modifier point
                </DialogTitle>
                <DialogContent>
                    <UpdatePoint point={selectedPointId} handlePointUpdate={handlePointUpdate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateDialogClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default DuplicatedPointsMap;
