import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPassword } from '../../actions/UserActions';
import {
    Container,
    Typography,
    TextField,
    Grid,
    Snackbar,
    Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';
const UpdatePassword = ({ userId }) => {
    const dispatch = useDispatch();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { success, error } = useSelector((state) => state);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordSuccess, setPasswordSuccess] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        const data = {
            currentPassword,
            newPassword,
        };

        dispatch(updateUserPassword(userId, data))
            .then(() => {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setPasswordError(null);
                setPasswordSuccess('Password updated successfully');
                openSnackbar('Password updated successfully', 'success');
            })
            .catch(() => {
                setPasswordSuccess(null);
                setPasswordError('Failed to update password');
                openSnackbar('Failed to update password', 'error');
            });
    };

    const openSnackbar = (message, severity) => {
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="div" align="center" mt={3}>
                Modifier le mot de passe
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Current Password"
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Grid>
                    {passwordError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {passwordError}
                        </Alert>
                    )}
                    {passwordSuccess && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            {passwordSuccess}
                        </Alert>
                    )}
                </Grid>
                <MDButton
                    type="submit"
                    variant="contained"
                    color="info"
                    //   fullWidth
                    className='mt-4'
                >
                    Mettre à jour
                </MDButton>

            </form>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert onClose={closeSnackbar} severity="success">
                    Password updated successfully
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UpdatePassword;
