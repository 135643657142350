import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { getAllMaterials, deleteMaterial } from "../../actions/MaterialsActions";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
} from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";

const MaterialList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const materials = useSelector((state) => state.material.materials);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getAllMaterials());
    }, [dispatch]);

    const openDeleteModal = (material) => {
        setSelectedMaterial(material);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedMaterial(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedMaterial) {
            dispatch(deleteMaterial(selectedMaterial.id))
                .then(() => {
                    setSnackbarMessage('Material Deleted successfully');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    setSnackbarMessage('Failed to delete Material');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                });
        }
        closeDeleteModal();
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'date_acquisition', headerName: 'Date Acquisition', flex: 1 },
        { field: 'prix_acquisition', headerName: 'Prix Acquisition', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 200,
            renderCell: (params) => (
                <div>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les matériels")) && (
                        <Link to={`/materials/update/${params.row.id}`} className="text-decoration-none">
                            <MDButton variant="contained" color="primary" size="small">
                                Edit
                            </MDButton>
                        </Link>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les matériels")) && (
                        <MDButton
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => openDeleteModal(params.row)}
                            style={{ marginLeft: "8px" }}
                        >
                            Delete
                        </MDButton>
                    )}
                </div>
            ),
        },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les matériels")) ? (
                <Container maxWidth="md">
                    <Typography variant="h2" textAlign={"center"} mb={5} gutterBottom>
                        Material List
                    </Typography>
                    <Link to={`/materials/create`} className="text-decoration-none float-end">
                        <MDButton variant="contained" color="success" >
                            <ControlPointIcon sx={{ mr: "10px" }} />
                            Create
                        </MDButton>
                    </Link>
                    {materials.length === 0 ? (
                        <Typography variant="body1">No materials available.</Typography>
                    ) : (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={materials}
                                columns={columns}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </div>
                    )}
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this material?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default MaterialList;
