import API_URL from './apiUrl';
import { otherApi } from './apiOther';

class PointService {
    addPointsFromCSV(data) {
        return API_URL.post(`/survey/points/add/csv`, data
            ,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    fetchPointsByZone(zone) {
        return API_URL.get(`/survey/points/${zone}`);
    }

    fetchDuplicatedPoints() {
        return API_URL.get(`/survey/points/detect/duplicates`);
    }

    deletePoints = async (ids) => {
        return API_URL.post('/survey/delete/points', { ids });
    }

    fetchPointsByZone1(zone) {
        return otherApi.get(`/survey/points/${zone}`);
    }

    updatePoint(pointId, updatedPoint) {
        return API_URL.put(`/survey/points/update/${pointId}`, updatedPoint);
    }

    addPoint(data) {
        return API_URL.post(`/survey/points/add`, data);
    }

    deletePoint(pointId) {
        return API_URL.delete(`/survey/points/delete/${pointId}`);
    }

}

export default new PointService();