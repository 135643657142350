import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPrimes, deletePrimes } from '../../actions/PrimesActions';
import {
    Box,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Button,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import { Link } from 'react-router-dom';
import AccessDenied from "../Errors/AccessDenied";

const PrimesList = ({ currentUser, darkMode }) => {
    const dispatch = useDispatch();
    const primes = useSelector((state) => state.primes.primes);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedPrimeId, setSelectedPrimeId] = useState(null);

    const [pageSize, setPageSize] = useState(25);

    useEffect(() => {
        dispatch(getAllPrimes());
    }, [dispatch]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'montant', headerName: 'Montant', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        {
            field: 'user?.nom', headerName: 'User', flex: 1,
            valueGetter: (params) => params.row.user?.nom || '',
        },
        { field: 'mois', headerName: 'Mois', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => (
                <>
                    <MDButton
                        variant="outlined"
                        className='btn-sm me-1'
                        color="error"
                        size='small'
                        onClick={() => handleOpenDeleteModal(params.row.id)}
                    >
                        Delete
                    </MDButton>
                    <MDButton
                        component={Link}
                        to={`/primes/update/${params.row.id}`}
                        variant="outlined"
                        size='small'
                        className='btn-sm ms-1'
                        color="success"
                    >
                        Edit
                    </MDButton>
                </>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    const handleOpenDeleteModal = (primeId) => {
        setSelectedPrimeId(primeId);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedPrimeId(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        dispatch(deletePrimes(selectedPrimeId));
        closeDeleteModal();
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Voir la liste des Primes Affecter")) ? (
                <Container>
                    <Typography variant="h4" gutterBottom>
                        List of Primes
                    </Typography>
                    {/* <div style={{ height: 500, width: '100%' }}> */}
                    <DataGrid
                        rows={primes}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        autoHeight
                        pageSize={pageSize}
                        className={darkMode ? "text-light" : "text-dark"}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        disableSelectionOnClick
                    />
                    {/* </div> */}

                    <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                        <DialogTitle className="text-dark">Confirm Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText className="text-dark">
                                Are you sure you want to delete this prime?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={closeDeleteModal} color="light">
                                Cancel
                            </MDButton>
                            <MDButton onClick={handleDelete} color="error">
                                Delete
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout>
    );
};

export default PrimesList;
