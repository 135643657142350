import React, { useState } from 'react';
import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';

const TextToCSVConverter = () => {
    const [csvData, setCsvData] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const fileContent = e.target.result;
            const lines = fileContent.split('\n').filter(line => line.trim() !== '');
            const csvLines = lines.map(line => {
                const parts = line.trim().split(/\s+/);
                const name = parts[0];
                const x = parts[1];
                const y = parts[2];
                const reference = parts.slice(3).join(' ');
                return `${name};${x};${y};${reference}`;
            });

            setCsvData(csvLines.join('\n'));
            handleSnackbarOpen('File parsed successfully', 'success');
        };

        reader.onerror = () => {
            handleSnackbarOpen('Error reading file', 'error');
        };

        if (file) {
            reader.readAsText(file);
        }
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDownload = () => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'converted_points.csv';
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box>
                <Typography textAlign={'center'} variant='h3'>Text to CSV Converter</Typography>
                <input
                    type="file"
                    accept=".txt"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="file-input"
                />
                <label htmlFor="file-input">
                    <MDButton variant="contained" color="primary" component="span">
                        Upload Text File
                    </MDButton>
                </label>
                {csvData && (
                    <Box mt={2}>
                        <MDButton variant="contained" color="secondary" onClick={handleDownload}>Download CSV</MDButton>
                    </Box>
                )}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </DashboardLayout>
    );
};

export default TextToCSVConverter;
