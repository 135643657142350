import {
    CREATE_VEHICULE_SUCCESS,
    CREATE_VEHICULE_FAILURE,
    UPDATE_VEHICULE_SUCCESS,
    UPDATE_VEHICULE_FAILURE,
    GET_VEHICULE_SUCCESS,
    GET_VEHICULE_FAILURE,
    DELETE_VEHICULE_SUCCESS,
    DELETE_VEHICULE_FAILURE,
    GET_ALL_VEHICULES_SUCCESS,
    GET_ALL_VEHICULES_FAILURE,
    GET_USER_VEHICULES_SUCCESS,
    GET_USER_VEHICULES_FAILURE,
} from '../actions/types';

const initialState = {
    vehicule: null,
    vehicules: [],
    error: null,
};

const vehiculeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_VEHICULE_SUCCESS:
            return {
                ...state,
                vehicule: action.payload,
                error: null,
            };
        case CREATE_VEHICULE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_VEHICULE_SUCCESS:
            return {
                ...state,
                vehicule: action.payload,
                error: null,
            };
        case UPDATE_VEHICULE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_VEHICULE_SUCCESS:
            return {
                ...state,
                vehicule: action.payload,
                error: null,
            };
        case GET_VEHICULE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_VEHICULE_SUCCESS:
            return {
                ...state,
                vehicule: null,
                error: null,
            };
        case DELETE_VEHICULE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_VEHICULES_SUCCESS:
            return {
                ...state,
                vehicules: action.payload,
                error: null,
            };
        case GET_ALL_VEHICULES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_USER_VEHICULES_SUCCESS:
            return {
                ...state,
                vehicules: action.payload,
                error: null,
            };
        case GET_USER_VEHICULES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default vehiculeReducer;