import API_URL from './apiUrl';

class RoleService {
  createRole(roleData) {
    return API_URL.post("/auth/create/", roleData);
  }

  getAllRoles() {
    return API_URL.get("/auth/roles");
  }

  updateRole(roleId, updatedRole) {
    return API_URL.put(`/auth/update/${roleId}`, updatedRole);
  }

  fetchRole(roleId) {
    return API_URL.get(`/auth/${roleId}`);
  }
  deleteRole(roleId) {
    return API_URL.delete(`/auth/delete/${roleId}`);
  }
}

export default new RoleService();

// import API_URL from './apiUrl';

// class RoleService {
//   createRole(roleData) {
//     return API_URL.post(API_URL +"/auth/create/", roleData);
//   }

//   getAllRoles() {
//     return API_URL.get(API_URL+"/auth/roles");
//   }

//   updateRole(roleId, updatedRole) {
//     return API_URL.put(`${API_URL}/auth/update/${roleId}`, updatedRole);
//   }

//   fetchRole(roleId) {
//     return API_URL.get(`${API_URL}/auth/${roleId}`);
//   }
//   deleteRole(roleId) {
//     return API_URL.delete(`${API_URL}/auth/delete/${roleId}`);
//   }
// }

// export default new RoleService();
