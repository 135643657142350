import React, { useEffect, useState } from 'react';
import ProjectService from '../../services/projetServices';
import { Table, TableBody, TableCell, TablePagination, TableContainer, TableHead, TableRow, Paper, Typography, TextField } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ResponsiveBar } from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const AmountByFactureAndMonth = () => {
    const [projectsAmountByFactureAndMonth, setProjectsAmountByFactureAndMonth] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
  
    useEffect(() => {
      fetchProjectsAmountByFactureAndMonth();
    }, []);
  
    const fetchProjectsAmountByFactureAndMonth = async () => {
      try {
        const response = await ProjectService.getTotalProjectsAmountByFactureAndMonth();
        setProjectsAmountByFactureAndMonth(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleSort = (column) => {
      if (sortColumn === column) {
        setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortColumn(column);
        setSortDirection('asc');
      }
    };
  
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
      setPage(0);
    };
  
    const filteredProjects = projectsAmountByFactureAndMonth.filter((item) =>
      item.month?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const paginationCount = Math.ceil(filteredProjects.length / rowsPerPage);
    const paginatedProjects = filteredProjects.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  
    const sortedProjects = React.useMemo(() => {
      if (!sortColumn) return paginatedProjects;
  
      return paginatedProjects.slice().sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
  
        // Handle null values (consider them as empty strings)
        const aValueStr = aValue ? aValue : '';
        const bValueStr = bValue ? bValue : '';
  
        if (sortDirection === 'asc') {
          return aValueStr.localeCompare(bValueStr);
        } else {
          return bValueStr.localeCompare(aValueStr);
        }
      });
    }, [sortColumn, sortDirection, paginatedProjects]);
  
    const renderSortIndicator = (column) => {
      if (sortColumn === column) {
        return sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
      }
      return null;
    };
  
    const yAxisTickFormatter = (tickValue) => {
      if (tickValue >= 1000) {
        return `${tickValue / 1000}k`;
      }
      return tickValue;
    };
  
    const chartData = [];
    sortedProjects.forEach((item) => {
      const monthData = chartData.find((data) => data.month === item.month);
      if (monthData) {
        monthData[item.facture] = item.totalAmount;
      } else {
        chartData.push({
          month: item.month,
          [item.facture]: item.totalAmount,
        });
      }
    });
  
    const groupedChartData = [];
    chartData.forEach((data) => {
      let sumOui = 0;
      let sumNon = 0;
      let sumNull = 0;
  
      if (data.hasOwnProperty('oui')) sumOui += data.oui;
      if (data.hasOwnProperty('non')) sumNon += data.non;
      if (data.hasOwnProperty('null')) sumNull += data.null;
  
      groupedChartData.push({
        month: data.month,
        oui: sumOui,
        non: sumNon,
        null: sumNull,
      });
    });
  
    // Generate random colors for the bars
    const colors = {
      oui: getRandomColor(),
      non: getRandomColor(),
      null: getRandomColor(),
    };
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Typography variant="h3" textAlign={'center'} gutterBottom>
          Total Projects Amount By Facture and Month
        </Typography>
        <div style={{ height: 300 }}>
          <ResponsiveBar
            data={groupedChartData}
            keys={['oui', 'non', 'null']}
            indexBy="month"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            layout="vertical"
            colors={{ scheme: 'nivo' }}
            enableGridX
            enableGridY
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Month',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Total Amount',
              legendPosition: 'middle',
              legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            animate
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
        <TextField
          fullWidth
          variant="filled"
          size='small'
          label="Search by month"
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: 10 }}
          sx={{ gridColumn: "span 2" }}
        />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ display: 'table-header-group' }}>
                        <TableRow>
                            <TableCell onClick={() => handleSort('facture')}>
                                Facture {renderSortIndicator('facture')}
                            </TableCell>
                            <TableCell onClick={() => handleSort('month')}>
                                Month {renderSortIndicator('month')}
                            </TableCell>
                            <TableCell onClick={() => handleSort('totalAmount')}>
                                Total Amount {renderSortIndicator('totalAmount')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedProjects.map((item) => (
                            <TableRow key={item.facture + item.month}>
                                <TableCell>{item.facture}</TableCell>
                                <TableCell>{item.month}</TableCell>
                                <TableCell>{item.totalAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Pagination */}
            <TablePagination
                component="div"
                count={filteredProjects.length}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </DashboardLayout>
    );
};

export default AmountByFactureAndMonth;

