import {
    CREATE_TAUX_SUCCESS,
    CREATE_TAUX_FAILURE,
    UPDATE_TAUX_SUCCESS,
    UPDATE_TAUX_FAILURE,
    GET_TAUX_SUCCESS,
    GET_TAUX_FAILURE,
    DELETE_TAUX_SUCCESS,
    DELETE_TAUX_FAILURE,
    GET_ALL_TAUXS_SUCCESS,
    GET_ALL_TAUXS_FAILURE,
  } from '../actions/types';
const initialState = {
  taux: null,
  taux: [],
  error: null,
};

const tauxReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TAUX_SUCCESS:
      return {
        ...state,
        taux: action.payload,
        error: null,
      };
    case CREATE_TAUX_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_TAUX_SUCCESS:
      return {
        ...state,
        taux: action.payload,
        error: null,
      };
    case UPDATE_TAUX_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TAUX_SUCCESS:
      return {
        ...state,
        taux: action.payload,
        error: null,
      };
    case GET_TAUX_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_TAUX_SUCCESS:
      return {
        ...state,
        taux: null,
        error: null,
      };
    case DELETE_TAUX_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_TAUXS_SUCCESS:
      return {
        ...state,
        taux: action.payload,
        error: null,
      };
    case GET_ALL_TAUXS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default tauxReducer;