import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    Snackbar,
    Alert,
    TableRow,
    Modal,
    Typography,
    Select,
    MenuItem,
    TextField,
    Box,
    Tooltip,
    IconButton,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import { getProjectTasks, ressignTask, deleteTache } from '../../actions/TacheActions';
import { Link } from 'react-router-dom';
import { Delete, BorderColor } from '@mui/icons-material';

const ProjectTasks = ({ projectId, darkMode }) => {
    const dispatch = useDispatch();
    const taskData = useSelector((state) => state.tache.taches) || { tasks: [] };
    const users = useSelector((state) => state.users.users) || [];
    const { tasks } = taskData;

    const taskNames = ['preparation', 'terrain', 'bureau', 'controle', 'livraison'];
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tacheToDelete, setTacheToDelete] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [reassignData, setReassignData] = useState({
        taskId: null,
        userId: '',
        deadline: '',
    });

    const openReassignModal = (task) => {
        setSelectedTask(task);
        setReassignData({
            taskId: task?.id,
            userId: '',
            deadline: '',
        });
        setModalOpen(true);
    };

    const openDialog = (tache) => {
        setSelectedItem(tache);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };
    const closeReassignModal = () => {
        setModalOpen(false);
    };

    const handleReassignTask = () => {
        dispatch(ressignTask(reassignData)).then(() => {
            dispatch(getProjectTasks(projectId));
            closeReassignModal();
        });
    };

    const handleDeleteClick = (tacheId) => {
        setTacheToDelete(tacheId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteTache(tacheToDelete.id))
            .then(() => {
                dispatch(getProjectTasks(projectId));
                setSuccessMessage('Task deleted successfully.');
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.error('Error deleting task:', error);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    useEffect(() => {
        dispatch(getProjectTasks(projectId));
    }, [dispatch, projectId]);


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const getStatusColor = (isRejected, isConfirmed, isPending) => {
        if (isConfirmed) {
            return '#4CAF50';
        } else if (isPending) {
            return '#FFA500';
        } else if (isRejected) {
            return 'red';
        } else {
            return '';
        }
    };

    return (
        <>
            <TableContainer className={darkMode ? "text-light" : "text-dark"} sx={{ padding: '10px', marginTop: '10px', }} component={Paper}>
                <Table>
                    <TableHead style={{ display: 'table-header-group' }}>
                        <TableRow>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Tâches</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Etat</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Responsable</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Date d'échéance</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Date d'exécution</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {taskNames.map((status) => {
                            const filteredTasks = tasks?.filter((task) => task.name === status);

                            const isRejected = filteredTasks?.some(task => task.status === 'rejected');
                            const isConfirmed = filteredTasks?.some(task => task.status === 'confirmed');
                            const isPending = filteredTasks?.some(task => task.status === 'pending');

                            return (
                                <TableRow key={status} style={{ backgroundColor: getStatusColor(isRejected, isConfirmed, isPending) }}>
                                    <TableCell className='fw-bold' color={darkMode ? "text-light" : "text-dark"}>{status.toLocaleUpperCase()}</TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {isPending
                                            ? 'En cours de traitement'
                                            : isRejected
                                                ? 'Refaite'
                                                : isConfirmed
                                                    ? 'Validée'
                                                    : 'Non affectée'
                                        }
                                    </TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {filteredTasks?.length > 0 ? (
                                            filteredTasks?.map((task, index) => (
                                                <div key={index}>{`${task.user?.nom} ${task.user?.prenom}`}</div>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {filteredTasks?.length > 0 ? (
                                            filteredTasks?.map((task, index) => (
                                                <div key={index}>{task?.deadline}</div>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {filteredTasks?.length > 0 ? (
                                            filteredTasks?.map((task, index) => (
                                                <div key={index}>{task?.completedAt}</div>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ProjectTasks;

