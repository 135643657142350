import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createNature } from '../../actions/NatureActions';
import {
    Container,
    Typography,
    TextField,
    Button,
    Alert,
    Grid,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';

const CreateNature = ({ currentUser, createNature, success }) => {
    
    const [name, setName] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const natureData = {
            name,
        };
        createNature(natureData);
        setName('');
        setShowAlert(true);
    };

    const handleChange = (e) => {
        setName(e.target.value);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            { currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.permissions.includes('Ajouter des natures')) ? (
                <Container maxWidth="md" sx={{ mt: 4 }}>
                    <Typography variant="h2" align="center" gutterBottom>
                        Ajouter un nature
                    </Typography>
                    {showAlert && (
                        <Alert severity="success" sx={{ mb: 3 }}>
                            La nature créée avec succès !
                        </Alert>
                    )}
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} mt={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nature"
                                    name="name"
                                    value={name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    sx={{
                                        color: '#fff !important',
                                        // fontSize: 14,
                                        // fontWeight: 'bold',
                                        // py: 2,
                                        // px: 4,
                                    }}
                                >
                                    Sauvegarder
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </DashboardLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user,
        success: state.nature.success,
    };
};

export default connect(mapStateToProps, { createNature })(CreateNature);
