import API_URL from './apiUrl';

class FactureService {
  createFacture(factureData) {
    return API_URL.post("/facture/create", factureData);
  }

  getAllFactures() {
    return API_URL.get("/facture");
  }

  updateFacture(factureId, updatedFacture) {
    return API_URL.put(`/facture/update/${factureId}`, updatedFacture);
  }

  fetchFacture(factureId) {
    return API_URL.get(`/facture/${factureId}`);
  }

  fetchClientFactures(clientId) {
    return API_URL.get(`/facture/client/${clientId}/factures`);
  }

  deleteFacture(factureId) {
    return API_URL.delete(`/facture/delete/${factureId}`);
  }

  getMonthlyRevenue() {
    return API_URL.get("/facture/factures/revenue/monthly");
  }

  updateItemsByDevisId(devisId, factureId) {
    return API_URL.put(`/facture/items/factured/${devisId}`, factureId);
  }

  uploadFilesToFacture = (factureId, formData) => {
    return API_URL.post(`/facture/factures/${factureId}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getAllFactureFiles = (factureId) => {
    return API_URL.get(`/facture/factures/${factureId}/files`);
  }

  getFileContent = (factureId, fileName) => {
    return API_URL.get(`/facture/factures/${factureId}/files/${fileName}`);
  }

  downloadFile = (factureId, fileName) => {
    return API_URL.get(`/facture/factures/${factureId}/download/${fileName}`, {
      responseType: 'blob',
    });
  };

  deleteFile = (factureId, fileName) => {
    return API_URL.delete(`/facture/factures/${factureId}/delete/${fileName}`);
  }

}

export default new FactureService();
