import { useState, useEffect, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MDBox from "../../../components/MDBox";
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";
import './style.css';
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
} from "./styles";
import {
    useMaterialUIController,
    setTransparentNavbar,
} from "../../../context";
import { Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import {
    useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch } from "react-redux";
import { contactLogout } from "../../../actions/ContactActions";

const PageNavbar = ({ absolute, light, isMini }) => {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch1 = useDispatch();

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }
        window.addEventListener("scroll", handleTransparentNavbar);
        handleTransparentNavbar();
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const logOut = useCallback(() => {
        dispatch1(contactLogout());
    }, [dispatch]);


    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >

            {/* <Divider /> */}
            <NotificationItem component={Button}  onClick={() => logOut()} icon={<LogoutIcon />} title="Logout" />
        </Menu>
    );

    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    return (
        <>
            <AppBar
                position={absolute ? "absolute" : navbarType}
                color="inherit"
                sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
            >
                <Toolbar sx={(theme) => navbarContainer(theme)}>
                    <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                        {!isMobile &&
                            <Breadcrumbs icon="home"
                                title={route[route.length - 1]}
                                route={route}
                                light={light}
                            />
                        }
                    </MDBox>
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <MDBox pr={1}>
                            {/* <MDInput label="Cherche ici" /> */}
                        </MDBox>
                        <MDBox color={light ? "white" : "inherit"}>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleGoBack}
                            >
                                <ArrowBackIosIcon sx={iconsStyle}>
                                </ArrowBackIosIcon>
                            </IconButton>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <AccountCircleIcon sx={iconsStyle} />
                            </IconButton>
                            {renderMenu()}

                        </MDBox>
                    </MDBox>
                    {/* )} */}
                </Toolbar>
            </AppBar>
        </>
    );
}

PageNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

PageNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default PageNavbar;
