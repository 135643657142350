import React from 'react';
import { Typography, Container, Box, Button } from '@mui/material';
import PageLayout from '../../examples/LayoutContainers/PageLayout';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Link } from 'react-router-dom';
import PageNavbar from '../../examples/Navbars/DashboardNavbar/PageNavbar';

const PageNotFound = () => {
    return (
        <PageLayout>
            <PageNavbar />
            <Container maxWidth="sm">
                <Box textAlign="center" mt={10}>
                    <Typography variant="h1" color="error" gutterBottom>
                        404
                    </Typography>
                    <Typography variant="h4" color="error" gutterBottom>
                        Page non trouvée
                    </Typography>
                    <Typography variant="body1">
                        Désolé, nous n'avons pas pu trouver cette page.
                    </Typography>
                    <Button
                        component={Link}
                        to={'/'}
                        size='large'
                        sx={{
                            marginTop: '2rem'
                        }}
                    >
                        <KeyboardReturnIcon /> &nbsp;Retour à la page d'accueil
                    </Button>
                </Box>
            </Container>
        </PageLayout>
    );
};

export default PageNotFound;