import React, { useState } from 'react';
import { Button, Alert, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Box, Typography } from '@mui/material';
import PointService from '../../services/pointServices';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import { Link } from 'react-router-dom';
const PointImport = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedProjection, setSelectedProjection] = useState('26191');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleProjectionChange = (event) => {
        setSelectedProjection(event.target.value);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleImport = async () => {
        if (!selectedFile) {
            setSnackbarMessage('Please select a CSV file.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('csvFile', selectedFile);
        formData.append('selectedProjection', selectedProjection);

        try {
            const response = await PointService.addPointsFromCSV(formData);
            console.log(response);
            setSnackbarMessage(response.data.message);
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Error importing points. Please check your CSV file.');
            console.log(error);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box sx={{ p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Import Points from CSV
                </Typography>
                <Box justifyContent={'flex-end'} alignItems={'end'} textAlign={'end'} mb={1} mt={2}>
                    <MDButton variant="contained" color='info'
                        component={Link}
                        to={'/convert/txttocsv'}
                    >
                        Convert TXT to CSV
                    </MDButton>
                </Box>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="projection-label">Select Projection Zone</InputLabel>
                    <Select
                        labelId="projection-label"
                        id="projection-select"
                        value={selectedProjection}
                        onChange={handleProjectionChange}
                        label="Select Projection Zone"
                    >
                        <MenuItem value="26191">Zone 1</MenuItem>
                        <MenuItem value="26192">Zone 2</MenuItem>
                        <MenuItem value="26194">Zone 3</MenuItem>
                        <MenuItem value="26195">Zone 4</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField
                        type="file"
                        id="file-input"
                        label="Select CSV File"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleFileChange}
                    />
                </FormControl>
                <Box mt={2}>
                    <MDButton variant="contained" color="primary" onClick={handleImport}>
                        Import Points
                    </MDButton>
                </Box>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert severity={snackbarSeverity} onClose={handleSnackbarClose} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </DashboardLayout>
    );
};

export default PointImport;
