import axios from 'axios';

export const otherApi = axios.create({
    // baseURL: "http://localhost:8000/api",
    baseURL: "https://backend.metic.ma:8000/api",
    // baseURL: "https://data.saftuing.com:8001/api",
    // baseURL: "https://backend.sm2e.ma:8015/api",
    // baseURL: "https://test.geomaping.net/api",
    // baseURL: "https://survback.syscamp.ma:8011/api",
    
    headers: {
        "Content-type": "application/json",
        // "X-App-Type": "Web",
    },
});