import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStagiaire } from '../../actions/StagiaireActions';
import { TextField, Grid, Container, Typography, Snackbar, Alert } from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';

const AddStagiaire = ({currentUser}) => {
    const dispatch = useDispatch();
    const [stagiaireData, setStagiaireData] = useState({
        cin: '',
        full_name: '',
        email: '',
        telephone: '',
        adresse: '',
        date_debut: '',
        note: '',
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStagiaireData({
            ...stagiaireData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createStagiaire(stagiaireData))
            .then(() => {
                setSnackbarMessage('Stagiaire ajouté avec succès.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setStagiaireData({
                    cin: '',
                    full_name: '',
                    email: '',
                    telephone: '',
                    adresse: '',
                    date_debut: '',
                    note: '',
                });
            })
            .catch((error) => {
                setSnackbarMessage('Erreur lors de l\'ajout du stagiaire. Veuillez réessayer.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('Erreur lors de l\'ajout du stagiaire:', error);
            });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || (currentUser.permissions.includes("Ajouter les stagiaires"))) ? (
                <Container maxWidth="md">
                    <Typography variant="h2" textAlign={'center'} gutterBottom>
                        Ajouter un stagiaire
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="CIN"
                                    name="cin"
                                    type="text"
                                    value={stagiaireData.cin}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nom complet"
                                    name="full_name"
                                    type="text"
                                    value={stagiaireData.full_name}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={stagiaireData.email}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Telephone"
                                    name="telephone"
                                    type="text"
                                    value={stagiaireData.telephone}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Adresse"
                                    name="adresse"
                                    type="text"
                                    value={stagiaireData.adresse}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="date de début"
                                    name="date_debut"
                                    type="date"
                                    value={stagiaireData.date_debut}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Note (Optionel)"
                                    name="note"
                                    type="text"
                                    value={stagiaireData.note}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MDButton type="submit" variant="contained" color="info">
                                    Ajouter
                                </MDButton>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity={snackbarSeverity} onClose={handleCloseSnackbar}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </DashboardLayout>
    );
};

export default AddStagiaire;
