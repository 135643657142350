import API_URL from './apiUrl';

class VehiculeService {
    addVehicule(vehiculeData) {
        return API_URL.post("/vehicule/create", vehiculeData);
    }
    getAllVehicules() {
        return API_URL.get("/vehicule/vehicules");
    }

    updateVehicule(vehiculeId, updatedVehicule) {
        return API_URL.put(`/vehicule/update/${vehiculeId}`, updatedVehicule);
    }

    fetchVehicule(vehiculeId) {
        return API_URL.get(`/vehicule//${vehiculeId}`);
    }

    fetchUserVehicules(userId) {
        return API_URL.get(`/vehicule/vehicules/user/${userId}`);
    }

    deleteVehicule(vehiculeId) {
        return API_URL.delete(`/vehicule/delete/${vehiculeId}`);
    }
}

export default new VehiculeService();