import API_URL from './apiUrl';

class ProductService {
  createProduct(productData) {
    return API_URL.post("/products/create/", productData);
  }

  addQuantity(productId, productData) {
    return API_URL.post(`/products/${productId}/add-quantity`, productData);
  }

  minusQuantity(productId, productData) {
    return API_URL.post(`/products/${productId}/minus-quantity`, productData);
  }

  getAllProductStock(productId) {
    return API_URL.get(`/products/stocks/${productId}`);
  }

  getAllProducts() {
    return API_URL.get("/products");
  }

  updateProduct(productId, updatedProduct) {
    return API_URL.put(`/products/update/${productId}`, updatedProduct);
  }

  fetchProduct(productId) {
    return API_URL.get(`/products/${productId}`);
  }

  deleteProduct(productId) {
    return API_URL.delete(`/products/delete/${productId}`);
  }
}

export default new ProductService();
