import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewTache } from '../../actions/TacheActions';
import { getAllUsers } from '../../actions/UserActions';
import {
    Box,
    Button,
    TextField,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Snackbar,
    SnackbarContent,
    IconButton,
} from '@mui/material';
// import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { Close as CloseIcon } from '@mui/icons-material';
import MDButton from "../../components/MDButton";
import UserPendingTaches from "../personnel/PendingTaches";

const CreateTache = ({ projectId }) => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users) || [];
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tacheData, setTacheData] = useState({
        name: '',
        deadline: '',
        note: '',
        projectId: projectId,
        userId: '',
    });
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTacheData({
            ...tacheData,
            [name]: value,
        });
    };

    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = () => {
        setErrorSnackbarOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(createNewTache(tacheData))
            .then(() => {
                setSnackbarType('success');
                setSnackbarMessage('Task created successfully');
                setSuccessSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarType('error');
                setSnackbarMessage(`Error creating task: ${error.message}`);
                setErrorSnackbarOpen(true);
            });
    };


    return (
        <>
            {/* <DashboardNavbar /> */}
            {/* <Typography variant="h4" gutterBottom>
                Create a New Task
            </Typography> */}
            <form className='mt-3' onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label='Employé'
                            name="userId"
                            value={tacheData.userId}
                            onChange={handleInputChange}
                            required
                            fullWidth
                        >
                            <MenuItem value="" disabled>
                                Sélectionnez un utilisateur
                            </MenuItem>
                            {Array.isArray(users) && users.length > 0 && users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user?.nom}&nbsp;{user?.prenom}
                                    &nbsp;, tâches actuelles:&nbsp;{user?.taches?.length}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {tacheData?.userId &&
                        <Grid item xs={12}>
                            <MDButton
                                variant="contained"
                                color="success"
                                onClick={() => setIsDialogOpen(true)}
                                fullWidth
                            >
                                tâches actuelles
                            </MDButton>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            label="Nom de la tâche"
                            name="name"
                            value={tacheData.name}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Date d'échéance"
                            type="date"
                            name="deadline"
                            value={tacheData.deadline}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Note"
                            name="note"
                            multiline
                            rows={4}
                            value={tacheData.note}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <MDButton type="submit" fullWidth variant="contained" color="info">
                        Créer
                    </MDButton>
                </Box>
            </form>
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                fullWidth
                maxWidth="100%"
            >
                <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                    Liste des tâches actuelles
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <UserPendingTaches userId={tacheData?.userId} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={successSnackbarOpen || errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={
                    snackbarType === 'success'
                        ? handleSuccessSnackbarClose
                        : handleErrorSnackbarClose
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    style={{
                        backgroundColor:
                            snackbarType === 'success' ? 'green' : 'red', // Customize colors
                    }}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={
                                snackbarType === 'success'
                                    ? handleSuccessSnackbarClose
                                    : handleErrorSnackbarClose
                            }
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        </>
    );
};

export default CreateTache;
