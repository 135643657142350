import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    DialogTitle,
    Snackbar,
    Grid,
    Card,
    CardContent,
    Box,
    Stack,
    Alert,
} from "@mui/material";
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { encodeId } from "../../actions/Crypte";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIcon from '@mui/icons-material/Assignment';

const TachePreview = ({ selectedItem }) => {
    console.log(selectedItem?.projet?.nature);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" color="info" gutterBottom>
                                Projet:
                            </Typography>
                            <Typography>{selectedItem?.projet?.numero}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" color="info" gutterBottom>
                                Référence fonciere:
                            </Typography>
                            <Typography>{selectedItem?.projet?.reference_fonciere}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" color="info" gutterBottom>
                                Nature:
                            </Typography>
                            {selectedItem?.projet?.nature?.map((natures) => (
                                <Typography key={natures.id}>{natures?.name || '-'}</Typography>
                            ))}

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" color="info" gutterBottom>
                                Remarque:
                            </Typography>
                            <Typography>{selectedItem?.note || '-'}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <AssignmentIcon fontSize="large" sx={{ mr: 1 }} color="info" />
                                <Typography variant="h6" color="info" gutterBottom>
                                    Tâche
                                </Typography>
                            </Stack>
                            <Typography component="div" variant="h5" gutterBottom>
                                {selectedItem?.name}&nbsp;
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card variant="outlined">
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <CalendarMonthIcon fontSize="large" sx={{ mr: 1 }} color="info" />
                                <Typography variant="h6" color="info" gutterBottom>
                                    Deadline
                                </Typography>
                            </Stack>
                            <Typography component="div" variant="h5" gutterBottom>
                                {selectedItem?.deadline}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default TachePreview;