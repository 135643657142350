import {
    CREATE_OPERATION_SUCCESS,
    CREATE_OPERATION_FAILURE,
    UPDATE_OPERATION_SUCCESS,
    UPDATE_OPERATION_FAILURE,
    GET_OPERATION_SUCCESS,
    GET_OPERATION_FAILURE,
    DELETE_OPERATION_SUCCESS,
    DELETE_OPERATION_FAILURE,
    GET_ALL_OPERATIONS_SUCCESS,
    GET_ALL_OPERATIONS_FAILURE,
  } from '../actions/types';
const initialState = {
  operation: null,
  operations: [],
  error: null,
};

const operationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OPERATION_SUCCESS:
      return {
        ...state,
        operation: action.payload,
        error: null,
      };
    case CREATE_OPERATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_OPERATION_SUCCESS:
      return {
        ...state,
        operation: action.payload,
        error: null,
      };
    case UPDATE_OPERATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_OPERATION_SUCCESS:
      return {
        ...state,
        operation: action.payload,
        error: null,
      };
    case GET_OPERATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_OPERATION_SUCCESS:
      return {
        ...state,
        operation: null,
        error: null,
      };
    case DELETE_OPERATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: action.payload,
        error: null,
      };
    case GET_ALL_OPERATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default operationReducer;
