import React, { useEffect, useState } from 'react';
import UserService from '../../services/userSrvices';
import { Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, TablePagination } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const UserPerformanceIndex = () => {
    const [userPerformance, setUserPerformance] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        fetchUserPerformanceIndex();
    }, []);

    const fetchUserPerformanceIndex = async () => {
        try {
            const response = await UserService.getUserPerformanceIndex();
            setUserPerformance(response.data);
        } catch (error) {
            console.error('Error fetching user performance index:', error);
        }
    };

    const chartData = userPerformance.map((user) => ({
        userId: user.id,
        fullName: `${user.nom} ${user.prenom}`,
        confirmedTaskCount: user.confirmedTaskCount,
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIdx = page * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    const paginatedData = userPerformance.slice(startIdx, endIdx);
    const chartPaginatedData = paginatedData.map((user) => ({
        userId: user.id,
        fullName: `${user.nom} ${user.prenom}`,
        confirmedTaskCount: user.confirmedTaskCount,
    }));

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <Typography variant="h3" textAlign={'center'} gutterBottom>
                    User Performance Index
                </Typography>
                {userPerformance.length === 0 ? (
                    <Typography variant="body1">No data available.</Typography>
                ) : (
                    <>
                        <Box alignItems={'center'} style={{ height: 300 }}>
                            <ResponsiveBar
                                data={chartPaginatedData}
                                keys={['confirmedTaskCount']}
                                indexBy="userId"
                                margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
                                padding={0.3}
                                colors={{ scheme: 'category10' }}
                                axisBottom={{
                                    tickRotation: -45,
                                }}
                                axisLeft={{
                                    tickValues: 5,
                                    tickPadding: 5,
                                    tickSize: 5,
                                    tickRotation: 0,
                                    legend: 'Confirmed Task Count',
                                }}
                                enableGridX
                                enableGridY
                                labelTextColor="inherit:darker(1.2)"
                                tooltip={({ id, value, color }) => (
                                    <strong style={{ color }}>
                                        {id}: {value}
                                    </strong>
                                )}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell>Confirmed Task Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{`${user.nom} ${user.prenom}`}</TableCell>
                                            <TableCell>{user.confirmedTaskCount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={userPerformance.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </DashboardLayout>
        </>
    );
};

export default UserPerformanceIndex;
