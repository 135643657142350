export default {
    imposable: {
        names: [
            "La prime d’assiduité",
            "La prime de production",
            "La frais voyage congé payé ",
            "La prime de technicité ",
            "La prime de bilan",
            "La prime de vacances ",
            "La prime de rendement",
            "La prime d’ajustement salaire",
            "La prime d’aide au logement ",
            "La prime de productivité",
            "La gratification fin d’année",
            "La prime de transport non justif",
            "La prime d’informatique",
            "L’intéressement au chiffre d’affaires",
            "La prime de panier non justifié",
            "La prime de standard ",
            "La participation aux frais scolaires",
            "L’indemnité de préavis",
            "L’indemnité de résidence",
            "l’indemnité de responsabilité",
            "L’indemnité de fonction",
            "L’indemnité de vie chère",
            "Le rappel sur salaires",
            "Le rappel sur gratifications",
            "L’alloc. Assistance famille",
            "L’indemnité d’encadrement",
            "La prime hiérarchique",
            "La prime de recherche",
            "L’indemnité pour risques prof",
            "La dotation produits consommés",
            "L’indemnité de direction",
            "La participation aux bénéfices",
            "L’indemnité de frais de bureau",
            "L’indemnité de poste",
            "L’indemnité de présidence",
            "L’indemnité de rapatriement",
            "La prime d’inventaire",
            "La prime de naissance",
            "La prime d’intérimaire",
            "Les étrennes",
            "La prime de qualification",
            "La prime de voyages particuliers",
            "Les jetons de présence",
            "L’allocation familiale complémentaire",
            "L’indemnité versée pour accident de travail",
            "La prime de froid",
            "La prime de fond dans les mines",
            "La prime de travail dans l’eau, neige ou boue",
            "La prime de sous-sol",
            "La prime d’altitude (hauteurs)",
            "La prime de travail d’outils pneumatiques",
            "La prime climatique",
            "La prime de profondeurs souterraines",
            "Indemnité pour travaux dangereux, insalubres",
            "L’indemnité de congé payé",
            "La prime d’assurance (voiture personnelle)",
            "Indemnité de séparation ou de dépaysement",
            "L’indemnité de zone",
            "l’indemnité de séjour ou défraiement",
            "L’indemnité de statut",
            "Les avantages en nature",
            "L’indemnité de chauffage ou de chaleur",
            "Indemnité de roulage",
        ],
    },
};
