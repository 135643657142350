import {
    CREATE_DEVIS_SUCCESS,
    CREATE_DEVIS_FAILURE,
    UPDATE_DEVIS_SUCCESS,
    UPDATE_DEVIS_FAILURE,
    GET_DEVIS_SUCCESS,
    GET_DEVIS_FAILURE,
    DELETE_DEVIS_SUCCESS,
    DELETE_DEVIS_FAILURE,
    GET_ALL_DEVIS_SUCCESS,
    GET_ALL_DEVIS_FAILURE,
  } from '../actions/types';
const initialState = {
  devi: null,
  devis: [],
  error: null,
};

const devisReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DEVIS_SUCCESS:
      return {
        ...state,
        devi: action.payload,
        error: null,
      };
    case CREATE_DEVIS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_DEVIS_SUCCESS:
      return {
        ...state,
        devi: action.payload,
        error: null,
      };
    case UPDATE_DEVIS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_DEVIS_SUCCESS:
      return {
        ...state,
        devi: action.payload,
        error: null,
      };
    case GET_DEVIS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_DEVIS_SUCCESS:
      return {
        ...state,
        devi: null,
        error: null,
      };
    case DELETE_DEVIS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_DEVIS_SUCCESS:
      return {
        ...state,
        devis: action.payload,
        error: null,
      };
    case GET_ALL_DEVIS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default devisReducer;
