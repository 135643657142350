import API_URL from './apiUrl';

class OperationService {
    createOperation(operationData) {
        return API_URL.post("/operations/create", operationData);
    }

    getAllOperations() {
        return API_URL.get("/operations");
    }

    updateOperation(operationId, updatedOperation) {
        return API_URL.put(`/operations/update/${operationId}`, updatedOperation);
    }

    fetchOperation(operationId) {
        return API_URL.get(`/operations/${operationId}`);
    }

    deleteOperation(operationId) {
        return API_URL.delete(`/operations/delete/${operationId}`);
    }

    uploadFilesToOperation = (operationId, formData) => {
        return API_URL.post(`/operations/operation/${operationId}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      getAllOperationFiles = (operationId) => {
        return API_URL.get(`/operations/operation/${operationId}/files`);
      }
    
      getFileContent = (operationId, fileName) => {
        return API_URL.get(`/operations/operation/${operationId}/files/${fileName}`);
      }
    
      downloadFile = (operationId, fileName) => {
        return API_URL.get(`/operations/operation/${operationId}/download/${fileName}`, {
          responseType: 'blob',
        });
      };
    
      deleteFile = (operationId, fileName) => {
        return API_URL.delete(`/operations/operation/${operationId}/delete/${fileName}`);
      }
}

export default new OperationService();
