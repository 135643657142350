import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    Snackbar,
    Alert,
    TableRow,
    Modal,
    Typography,
    Select,
    MenuItem,
    TextField,
    Box,
    Tooltip,
    IconButton,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import { getProjectTasks, ressignTask, deleteTache } from '../../actions/TacheActions';
import { getAllUsers } from '../../actions/UserActions';
import UpdateTache from './UpdateTask';
import { Link } from 'react-router-dom';
import { Delete, BorderColor } from '@mui/icons-material';

const ProjectTasksTable = ({ projectId, darkMode, currentUser }) => {
    const dispatch = useDispatch();
    const taskData = useSelector((state) => state.tache.taches) || { tasks: [] };
    const users = useSelector((state) => state.users.users) || [];
    const { tasks } = taskData;

    const taskNames = ['preparation', 'terrain', 'bureau', 'controle', 'livraison'];
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tacheToDelete, setTacheToDelete] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [reassignData, setReassignData] = useState({
        taskId: null,
        userId: '',
        deadline: '',
    });

    useEffect(() => {
        dispatch(getProjectTasks(projectId));
    }, [dispatch, projectId]);

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);
    
    const openReassignModal = (task) => {
        setSelectedTask(task);
        setReassignData({
            taskId: task?.id,
            userId: '',
            deadline: '',
        });
        setModalOpen(true);
    };

    const openDialog = (tache) => {
        setSelectedItem(tache);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };
    const closeReassignModal = () => {
        setModalOpen(false);
    };

    const handleReassignTask = () => {
        dispatch(ressignTask(reassignData)).then(() => {
            dispatch(getProjectTasks(projectId));
            closeReassignModal();
        });
    };

    const handleDeleteClick = (tacheId) => {
        setTacheToDelete(tacheId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteTache(tacheToDelete.id))
            .then(() => {
                dispatch(getProjectTasks(projectId));
                setSuccessMessage('Task deleted successfully.');
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.error('Error deleting task:', error);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };



    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const getStatusColor = (isRejected, isConfirmed, isPending) => {
        if (isConfirmed) {
            return '#4CAF50';
        } else if (isPending) {
            return '#FFA500';
        } else if (isRejected) {
            return 'red';
        } else {
            return '';
        }
    };

    return (
        <>
            <TableContainer className={darkMode ? "text-light" : "text-dark"} sx={{ padding: '10px', marginTop: '10px', }} component={Paper}>
                <Table>
                    <TableHead style={{ display: 'table-header-group' }}>
                        <TableRow>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Tâches</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Etat</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Responsable</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Date d'échéance</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Date d'exécution</TableCell>
                            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Affécter des projets aux employés")) && (
                                <TableCell className={darkMode ? "text-light" : "text-dark"}>Action</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {taskNames.map((status) => {
                            const filteredTasks = tasks?.filter((task) => task.name === status);

                            const isRejected = filteredTasks?.some(task => task.status === 'rejected');
                            const isConfirmed = filteredTasks?.some(task => task.status === 'confirmed');
                            const isPending = filteredTasks?.some(task => task.status === 'pending');

                            return (
                                <TableRow key={status} style={{ backgroundColor: getStatusColor(isRejected, isConfirmed, isPending) }}>
                                    <TableCell className='fw-bold' color={darkMode ? "text-light" : "text-dark"}>{status.toLocaleUpperCase()}</TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {isPending
                                            ? 'En cours de traitement'
                                            : isRejected
                                                ? 'Refaite'
                                                : isConfirmed
                                                    ? 'Validée'
                                                    : 'Non affectée'
                                        }
                                    </TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {filteredTasks?.length > 0 ? (
                                            filteredTasks?.map((task, index) => (
                                                <div key={index}>{`${task.user?.nom} ${task.user?.prenom}`}</div>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {filteredTasks?.length > 0 ? (
                                            filteredTasks?.map((task, index) => (
                                                <div key={index}>{task?.deadline}</div>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                        {filteredTasks?.length > 0 ? (
                                            filteredTasks?.map((task, index) => (
                                                <div key={index}>{task?.completedAt}</div>
                                            ))
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Affecter les tâches")) && (
                                        <TableCell>
                                            {filteredTasks?.length > 0 ? (
                                                <>

                                                    {filteredTasks?.map((task, index) => (
                                                        <div key={index} className='d-flex mt-1'>
                                                            <MDButton
                                                                size='small'
                                                                className='me-2'
                                                                onClick={() => openReassignModal(filteredTasks[0])}
                                                            >
                                                                Réaffecter
                                                            </MDButton>
                                                            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les tâches")) && (
                                                                <>
                                                                    <Tooltip title='Modifier' arrow>
                                                                        <IconButton
                                                                            variant="contained"
                                                                            className="me-1"
                                                                            size='small'
                                                                            onClick={() => openDialog(task)}
                                                                            color="info"
                                                                        >
                                                                            <BorderColor fontSize='medium' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les tâches")) && (
                                                                <Tooltip title='Supprimer' arrow>
                                                                    <IconButton
                                                                        variant="contained"
                                                                        size='small'
                                                                        className="ms-1"
                                                                        color="error"
                                                                        onClick={() => handleDeleteClick(task)}
                                                                    >
                                                                        <Delete fontSize='medium' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    ))}

                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les tâches")) && (
                    <Button className='float-end' component={Link} to={'/projects/history/tasks'}>
                        voir l'historique des tâches
                    </Button>
                )}
            </TableContainer>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle 
                sx={{
                    textAlign: 'center',
                    background: 'red !important',
                    color: '#fff',
                    marginBottom: '1rem'
                }}
                >Supprimer la tâche</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir supprimer cette tâche ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Annuler</Button>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                        onClick={handleDeleteConfirm} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <Modal
                open={isModalOpen}
                onClose={closeReassignModal}
                aria-labelledby="reassign-modal-title"
                aria-describedby="reassign-modal-description"
            >
                <Paper>
                    <Box sx={{ ...modalStyle }}>
                        <Typography variant="h6">Réaffecter la tâche : {selectedTask?.name}</Typography>
                        <Select
                            name="userId"
                            value={reassignData.userId}
                            sx={{ mt: '13px', mb: '13px' }}
                            fullWidth
                            onChange={(e) => setReassignData({ ...reassignData, userId: e.target.value })}
                        >
                            <MenuItem value="" disabled>
                                Sélectionnez un utilisateur
                            </MenuItem>
                            {users?.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user?.nom}&nbsp;{user?.prenom}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            type="date"
                            name="deadline"
                            sx={{ mb: '13px' }}
                            value={reassignData.deadline}
                            fullWidth
                            onChange={(e) => setReassignData({ ...reassignData, deadline: e.target.value })}
                        />
                        <MDButton
                            onClick={handleReassignTask}
                            variant="contained"
                            color="info"
                            fullWidth
                        >
                            Réaffecter la tâche
                        </MDButton>
                    </Box>
                </Paper>
            </Modal>

            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Modifier la tâche
                </DialogTitle>
                <DialogContent>
                    <UpdateTache tacheId={selectedItem?.id} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default ProjectTasksTable;

