import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFacture } from '../../actions/FactureActions';
import { getAllClients } from '../../actions/ClientActions';
import { Snackbar, Alert, TextField, Button, Grid, Typography, FormControl, InputLabel, MenuItem, Select as MuiSelect, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';
import { getProjectsByClientId } from '../../actions/ProjetActions';

const CreateFacture = ({ currentUser }) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState('');
  const [objet, setObjet] = useState('');
  const [reference, setReference] = useState('');
  const [statut, setStatut] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState('');
  const [itemUnite, setItemUnite] = useState('');
  const [itemPriceUnit, setItemPriceUnit] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedOption, setSelectedOption] = useState("none");
  const [showForm, setShowForm] = useState(false);
  const clients = useSelector((state) => state.clients);
  const projects = useSelector((state) => state.project.projectsClient);
  const clientProjects = projects?.projects || [];
  const [projetId, setProjetId] = useState(null);

  useEffect(() => {
    dispatch(getAllClients());
  }, [dispatch]);

  useEffect(() => {
    if (selectedClient && selectedClient.value) {
      dispatch(getProjectsByClientId(selectedClient.value));
    }
  }, [dispatch, selectedClient]);


  const handleAddItem = () => {
    if (!itemName || !itemQuantity || !itemUnite || !itemPriceUnit) return;

    const newItem = {
      name: itemName,
      unite: itemUnite,
      prix_unitaire: parseFloat(itemPriceUnit),
      price: parseFloat(itemPriceUnit) * parseFloat(itemQuantity),
      quantity: parseFloat(itemQuantity),
    };

    setItems((prevItems) => [...prevItems, newItem]);
    setItemName('');
    setItemUnite('');
    setItemPriceUnit('');
    setItemQuantity('');
  };

  const clientOptions = clients.map((client) => ({
    value: client.id,
    label: client.full_name,
  }));

  const handleRemoveItem = (index) => {
    setItems((prevItems) => prevItems.filter((item, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const factureData = {
      date,
      objet,
      reference,
      statut: statut || 'en_instance',
      clientId: selectedClient.value,
      projetId: projetId || null,
      items,
    };

    dispatch(createFacture(factureData))
      .then(() => {
        openSnackbar('Facture créée avec succès', 'success');
        setDate('');
        setObjet('');
        setStatut('');
        setSelectedClient(null);
        setItems([]);
        setItemName('');
        setItemUnite('');
        setItemPriceUnit('');
        setItemQuantity('');
      })
      .catch((error) => {
        openSnackbar('Échec de la création de la facture', 'error');
        console.log('Error creating facture:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'itemName':
        setItemName(value);
        break;
      case 'itemPriceUnit':
        setItemPriceUnit(value);
        break;
      case 'itemUnite':
        setItemUnite(value);
        break;
      case 'itemQuantity':
        setItemQuantity(value);
        break;
      default:
        break;
    }
  };


  // const calculateTotalPrice = () => {
  //   let total = 0;
  //   items.forEach((item) => {
  //     total += item.prix_unitaire * item.quantity;
  //   });
  //   return total;
  // };

  const openSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity || 'success');
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    const showForm = selectedOption !== "none";
    setSelectedOption(selectedOption);
    setShowForm(showForm);
  };

  const calculateTotalPrice = () => {
    let total = 0;
    items.forEach((item) => {
      total += item.prix_unitaire * item.quantity;
    });

    return total.toFixed(2);
  };

  const calculateTotalTTC = () => {
    const totalHT = calculateTotalPrice();
    const totalTTC = totalHT * 1.2;

    return totalTTC.toFixed(2);
  };

  const projectOptions = clientProjects.map((projet) => ({
    value: projet.id,
    label: projet.numero,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.permissions.includes('Ajouter des factures')) ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '36px',
                textAlign: 'center',
                marginTop: '12px',
                fontWeight: 'bold',
                color: '#3e4396',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
              }}
              variant="h4"
              align="center"
            >Créer une facture</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label="Date"
                type="date"
                name="date"
                variant="standard"
                margin="normal"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              {/* <InputLabel>Statut</InputLabel> */}
              <TextField
                select
                value={statut}
                onChange={(e) => setStatut(e.target.value)}
                name="statut"
                label="statut"
                margin="normal"
                variant="standard"
              >
                <MenuItem value="en_instance">
                  <em>Select statut...</em>
                </MenuItem>
                <MenuItem value="en_instance">En Instance</MenuItem>
                <MenuItem value="paye">Payé</MenuItem>
              </TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Objet"
                type="text"
                name="objet"
                margin="normal"
                variant="standard"
                value={objet}
                onChange={(e) => setObjet(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                options={clientOptions}
                value={selectedClient}
                onChange={(event, newValue) => setSelectedClient(newValue)}
                getOptionLabel={(option) => option.label}
                isSearchable
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    fullWidth
                    variant="standard"
                    margin="normal"
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              {/* <TextField
                select
                value={selectedOption}
                onChange={handleOptionChange}
                name="Form"
                label="Lié au projet"
                margin="normal"
                sx={{ marginTop: '12px' }}
                variant="standard"
              >
                <MenuItem disabled>
                  <em>Sélectionner ...</em>
                </MenuItem>
                <MenuItem value="none">Non</MenuItem>
                <MenuItem value="oui">Oui</MenuItem>
              </TextField>
              {showForm && ( */}
              <Autocomplete
                options={projectOptions}
                margin="normal"
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => setProjetId(newValue.value)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Projet "
                    variant="standard"
                  />}
              />
              {/* )} */}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Référence"
                type="text"
                name="reference"
                margin="normal"
                variant="standard"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                margin: '12px',
              }}
              variant="h5"
            >
              Ajouter des désignations
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ display: 'table-header-group' }}>
                  <TableRow>
                    <TableCell>N°</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Unite</TableCell>
                    <TableCell>Prix Unitaire</TableCell>
                    <TableCell>Quantité</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(({ name, unite, prix_unitaire, quantity, price }, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{unite}</TableCell>
                      <TableCell>{prix_unitaire}</TableCell>
                      <TableCell>{quantity}</TableCell>
                      <TableCell>{prix_unitaire * quantity}</TableCell>
                      <TableCell>
                        <MDButton
                          variant="contained"
                          color="error"
                          onClick={() => handleRemoveItem(index)}
                        >
                          Remove
                        </MDButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box sx={{ margin: '1rem' }}>
                <Typography variant="h6" align="right">Total HT: {calculateTotalPrice()}</Typography>
                <Typography variant="h6" align="right">Total TTC: {calculateTotalTTC()}</Typography>
              </Box>
            </TableContainer>
            <Grid container spacing={2} marginTop="8px" alignItems="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  type="text"
                  name="itemName"
                  value={itemName}
                  onChange={handleChange}
                  label="Désignation"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="text"
                  name="itemUnite"
                  value={itemUnite}
                  onChange={handleChange}
                  label="Unité"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  name="itemQuantity"
                  value={itemQuantity}
                  onChange={handleChange}
                  label="Quantité"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  name="itemPriceUnit"
                  value={itemPriceUnit}
                  onChange={handleChange}
                  label="Prix Unitaire"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDButton
                  variant="contained"
                  color="success"
                  onClick={handleAddItem}
                  fullWidth
                >
                  Ajouter
                </MDButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}>
            <MDButton
              variant="contained"
              color="info"
              onClick={handleSubmit}
              fullWidth
            >
              créer la facture
            </MDButton>
          </Grid>
        </Grid>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert variant='filled' onClose={closeSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
};

export default CreateFacture;
