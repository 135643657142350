import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ForumIcon from "@mui/icons-material/Forum";
import { Container, Grid, Card, CardContent, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import MDButton from '../../components/MDButton';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Notifications = ({ currentUser, darkMode }) => {
  const charges = useSelector((state) => state.charges.charges);
  const avances = useSelector((state) => state.encaissement.encaissements);
  const holidays = useSelector((state) => state.holiday.holidays);
  const requests = useSelector((state) => state.request.requests);
  const tasks = useSelector((state) => state.tache.taches) ?? [];
  const rejected = useSelector((state) => state.charges.rejected);
  const chargesRejcted = rejected?.rejectedCharges?.count;
  const holidaysRejcted = rejected?.rejectedHolidays?.count;
  const requestsRejcted = rejected?.rejectedRequests?.count;
  const rejectedAvances = rejected?.rejectedAvances?.length;
  const totalRejected = chargesRejcted + holidaysRejcted + requestsRejcted + rejectedAvances;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="md" >
        <>
          {(charges?.count || 0) + (avances?.count || 0) + (holidays?.holidayCount || 0) + (requests?.count || 0) + (tasks?.taskCount || 0) + (totalRejected || 0) ? (
            <Grid container spacing={2}>
              {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les charges")) &&
                <>
                  {charges?.count > 0 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined" style={{ color: darkMode ? "#fff" : "#000" }}>
                          <CardContent>
                            <RequestQuoteIcon fontSize="large" color="primary" />
                            <Typography className="mt-3" variant="h5">
                              Nouvelles Charges
                            </Typography>
                            <Typography className="mt-2" variant="body1" gutterBottom>
                              Vous avez de nouveaux Charges en attente de validation, <br />
                              <strong> {charges?.count}&nbsp;Charges </strong>
                            </Typography>
                            <Link
                              to={'/charges/pendinglist'}
                              className="text-decoration-none"
                            >
                              <MDButton className="mt-2" variant="contained" color="primary" fullWidth>
                                Voir plus
                              </MDButton>
                            </Link>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                </>
              }
              {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider des encaissements")) &&
                <>
                  {avances?.count > 0 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined" style={{ color: darkMode ? "#fff" : "#000" }}>
                          <CardContent>
                            <PriceCheckIcon fontSize="large" color="secondary" />
                            <Typography className="mt-3" variant="h5">Nouveaux Encaissements</Typography>
                            <Typography className="mt-2" variant="body1" gutterBottom>
                              Vous avez Nouveaux Encaissements, <br />
                              <strong> {avances?.count}&nbsp;Encaissements </strong>
                            </Typography>
                            <Link
                              to={'/finances/projects/avances/pendinglist'}
                              className="text-decoration-none"
                            >
                              <MDButton className="mt-2" variant="contained" color="secondary" fullWidth>
                                Voir plus
                              </MDButton>
                            </Link>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                </>
              }
              {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les congés")) &&
                <>
                  {holidays?.holidayCount > 0 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined" style={{ color: darkMode ? "#fff" : "#000" }}>
                          <CardContent>
                            <CalendarTodayIcon fontSize="large" color="success" />
                            <Typography className="mt-3" variant="h5">Demandes de congés</Typography>
                            <Typography className="mt-2" variant="body1" gutterBottom>
                              Vous avez des demandes de congés,<br />
                              <strong> {holidays.holidayCount} demandes </strong>
                            </Typography>
                            <MDButton component={Link} to={'/ressources-humaines/holidays/pending/requests'} className="mt-2" variant="contained" color="success" fullWidth>
                              Voir plus
                            </MDButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                </>
              }
              {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les avances de salaires")) &&
                <>
                  {requests?.count > 0 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined" style={{ color: darkMode ? "#fff" : "#000" }}>
                          <CardContent>
                            <ForumIcon fontSize="large" color="warning" />
                            <Typography className="mt-3" variant="h5">
                              Demandes des avances sur salaire
                            </Typography>
                            <Typography className="mt-2" variant="body1" gutterBottom>
                              Vous avez des nouvelles demandes d'avances sur salaire,<br />
                              <strong> {requests?.count} demandes </strong>
                            </Typography>
                            <Link
                              to={"/requests/pendingList"}
                              className="text-decoration-none"
                            >
                              <MDButton className="mt-2" variant="contained" color="warning" fullWidth>
                                Voir plus
                              </MDButton>
                            </Link>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                </>
              }
              {tasks?.taskCount > 0 && (
                <>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ color: darkMode ? "#fff" : "#000" }}>
                      <CardContent>
                        <AssignmentIcon fontSize="large" color="info" />
                        <Typography className="mt-3" variant="h5">Nouvelles Tâches</Typography>
                        <Typography className="mt-2" variant="body1" gutterBottom>
                          Vous avez des Nouvelles tâches<br />
                          <strong> {tasks?.taskCount} tâches </strong>
                        </Typography>
                        <MDButton component={Link} to={'/projects/taches'} className="mt-2" variant="contained" color="info" fullWidth>
                          Voir plus
                        </MDButton>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
              {totalRejected > 0 && (
                <>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ color: darkMode ? "#fff" : "#000" }}>
                      <CardContent>
                        <HighlightOffIcon fontSize="large" color="error" />
                        <Typography className="mt-3" variant="h5">Nouveaux rejets</Typography>
                        <Typography className="mt-2" variant="body1" gutterBottom>
                          Vous avez des Nouveaux rejets<br />
                          <strong> {totalRejected} rejets </strong>
                        </Typography>
                         <MDButton component={Link} to={'/rejected/list'} className="mt-2" variant="contained" color="error" fullWidth>
                          Voir plus
                        </MDButton> 
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <>
              <Grid p={2} container spacing={2}>
                <Typography ml={2} mt={2} variant="body1" gutterBottom>
                  Aucune notification disponible :)!
                </Typography>
              </Grid>
            </>
          )}
        </>
      </Container>
    </DashboardLayout>
  );
};

export default Notifications;


