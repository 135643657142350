import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { getAllFactures, deleteFacture } from '../../actions/FactureActions';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  DialogTitle,
  Container,
  Tooltip,
  IconButton
} from '@mui/material';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useMaterialUIController } from "../../context";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AccessDenied from '../Errors/AccessDenied';
import FacturePreview from './FacturePreview';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { encodeId } from '../../actions/Crypte';
import { ToWords } from 'to-words';
import StatisticsServices from "../../services/statisticsServices";
import GetAppIcon from '@mui/icons-material/GetApp';

const FactureList = ({ currentUser }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(25);
  const factures = useSelector((state) => state.facture.factures);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFactures, setFilteredFactures] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const filteredFactures = factures?.filter((facture) => {
      const numero = facture.numero || '';
      const client = facture?.client?.full_name;
      return (
        numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date)); 

    setFilteredFactures(filteredFactures);
  }, [factures, searchTerm]);

  useEffect(() => {
    dispatch(getAllFactures());
  }, [dispatch]);

  const openDeleteModal = (facture) => {
    setSelectedFacture(facture);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedFacture(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedFacture) {
      await dispatch(deleteFacture(selectedFacture.id));
      dispatch(getAllFactures());

      setSelectedFacture(null);
    }
    closeDeleteModal();
  };

  const openDialog = (project) => {
    setSelectedItem(project);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedItem(null);
    setIsDialogOpen(false);
  };

  function numberToWordsFrench(number, U = null, D = "Cts") {
    if (number === null) return "Nombre non valide";
    const letter = {
      0: "zéro",
      1: "un",
      2: "deux",
      3: "trois",
      4: "quatre",
      5: "cinq",
      6: "six",
      7: "sept",
      8: "huit",
      9: "neuf",
      10: "dix",
      11: "onze",
      12: "douze",
      13: "treize",
      14: "quatorze",
      15: "quinze",
      16: "seize",
      17: "dix-sept",
      18: "dix-huit",
      19: "dix-neuf",
      20: "vingt",
      30: "trente",
      40: "quarante",
      50: "cinquante",
      60: "soixante",
      70: "soixante-dix",
      80: "quatre-vingt",
      90: "quatre-vingt-dix",
    };

    function getUnits(num) {
      if (num < 20) return letter[num];
      if (num < 100) {
        const unit = num % 10;
        return letter[Math.floor(num / 10) * 10] + (unit !== 0 ? "-" + letter[unit] : "");
      }
      return "";
    }

    function convertToWords(nb) {
      nb = parseFloat(nb.toString().replace(/ /gi, ""));
      if (isNaN(nb)) return "Nombre non valide";
      if (Math.ceil(nb) !== nb) {
        nb = nb.toString().split('.');
        return convertToWords(nb[0]) + " Dihrams et " + convertToWords(nb[1]) + (D ? " " + D : "");
      }

      const n = nb.toString().length;
      switch (n) {
        case 1:
          return letter[nb];
        case 2:
          if (nb > 19) {
            const quotient = Math.floor(nb / 10);
            const reste = nb % 10;
            if (nb < 71 || (nb > 79 && nb < 91)) {
              if (reste === 0) return letter[quotient * 10];
              if (reste === 1) return letter[quotient * 10] + "-et-" + letter[reste];
              return letter[quotient * 10] + "-" + letter[reste];
            }
            return letter[(quotient - 1) * 10] + "-" + letter[10 + reste];
          }
          return letter[nb];
        case 3:
          const quotient = Math.floor(nb / 100);
          const reste = nb % 100;
          if (quotient === 1 && reste === 0) return "cent";
          if (quotient === 1 && reste !== 0) return "cent" + " " + convertToWords(reste);
          if (quotient > 1 && reste === 0) return letter[quotient] + " cents";
          return letter[quotient] + " cent " + convertToWords(reste);
        case 4:
        case 5:
        case 6:
          const quotientThousands = Math.floor(nb / 1000);
          const resteThousands = nb - quotientThousands * 1000;
          if (quotientThousands === 1 && resteThousands === 0) return "mille";
          if (quotientThousands === 1 && resteThousands !== 0) return "mille" + " " + convertToWords(resteThousands);
          if (quotientThousands > 1 && resteThousands === 0) return convertToWords(quotientThousands) + " mille";
          return convertToWords(quotientThousands) + " mille " + convertToWords(resteThousands);
        default:
          return "Nombre non géré";
      }
    }

    return convertToWords(number);
  }

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  const toWords = new ToWords({ localeCode: 'fr-FR' });

  const handleExportToWord = async (facture) => {
    try {
      const response = await axios.get('/facture_template.docx', {
        responseType: 'arraybuffer',
      });
      const [integerPart, decimalPart] = facture?.montant_ttc.split('.');
      let wordsIntegerPart = toWords.convert(integerPart);

      let wordsDecimalPart = '';
      if (decimalPart) {
        wordsDecimalPart = toWords.convert(decimalPart);
      }

      const projectTemplate = response.data;
      const zip = new PizZip(projectTemplate);
      const doc = new Docxtemplater(zip);

      const data = {
        factureNumber: facture.numero,
        reference: facture.reference || "-",
        objet: facture.objet || "-",
        creation: facture.date ? formatDate(facture.date) : "-",
        montantttc: facture.montant_ttc || "-",
        // montantttcwords: numberToWordsFrench(facture.montant_ttc, { lang: "fr" })
        //   || "-",
        wordsIntegerPart: wordsIntegerPart || '00',
        wordsDecimalPart: wordsDecimalPart || '00',
        client: facture.client?.full_name || "-",
        ice: facture.client?.ice || "-",
        adresse: facture.client?.adresse || "-",
        items: facture.items.map((item, index) => ({
          ...item,
          index: index + 1,
          ofPrice: (item.quantity * item.prix_unitaire).toFixed(2),
        })) || "-",
        totalePrice: facture.items.length > 0
          ? facture.items.reduce((total, item) => total + item.quantity * item.prix_unitaire, 0).toFixed(2)
          : 0,
        tva: (0.2 * (facture.items.length > 0
          ? facture.items.reduce((total, item) => total + (item.quantity * item.prix_unitaire).toFixed(2), 0)
          : 0)).toFixed(2),
      };

      doc.setData(data);

      try {
        doc.render();
      } catch (error) {
        console.error('Error rendering the template:', error);
        return;
      }

      try {
        const blob = doc.getZip().generate({ type: 'blob' });
        saveAs(blob, `facture_${facture.numero}.docx`);
      } catch (error) {
        console.error('Error generating the Word document:', error);
      }
    } catch (error) {
      console.error('Error fetching the template:', error);
    }
  };

  const columns = [
    {
      field: 'numero', headerName: 'Numero',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },
    {
      field: 'date', headerName: 'Date',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },

    {
      field: 'objet', headerName: 'Objet',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },
    {
      field: 'client?.full_name', headerName: 'Client',
      ...(isMobile ? { width: 120 } : { flex: 1 }),
      valueGetter: (params) => params.row.client?.full_name || '',
    },
    {
      field: 'statut',
      headerName: 'Statut',
      ...(isMobile ? { width: 120 } : { flex: 1 }),
      valueGetter: (params) => params.row.statut || '-',
      renderCell: (params) => {
        let statusLabel, statusColor;

        switch (params.row.statut) {
          case 'paye':
            statusLabel = 'Payé';
            statusColor = 'green';
            break;
          case 'en_instance':
            statusLabel = 'En instance';
            statusColor = 'orange';
            break;
          default:
            statusLabel = '-';
            statusColor = 'black';
        }

        return (
          <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statusLabel}</span>
        );
      },
    },
    {
      field: 'montant_ttc', headerName: 'Montant TTC',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <Box>
          <Tooltip title="Détails" arrow>
            <IconButton
              className='me-1 ms-1'
              color='warning'
              onClick={() => openDialog(params.row)}>
              <OpenInFullIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les factures")) && (
            <Tooltip title="Modifier" arrow>
              <IconButton component={Link} color='info' to={`/finances/factures/update/${encodeId(params.row.id)}`} >
                <BorderColorIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les factures")) && (
            <Tooltip title="Supprimer" arrow>
              <IconButton
                className='me-2'
                variant="contained"
                color="error"
                size="small"
                onClick={() => openDeleteModal(params.row)}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Exporter les factures")) && (
            <Tooltip title="Télécharger" arrow>
              <IconButton
                color='dark'
                onClick={() => handleExportToWord(params.row)}
              >
                <DownloadForOfflineIcon fontSize='medium' />
              </IconButton>
            </Tooltip>

          )}
        </Box>
      ),
      flex: 2,
      cellClassName: 'actions-cell',
    },
  ];

  const CustomButton = () => {
    const handleExportArchive = async (folder) => {
      try {
        const response = await StatisticsServices.downloadArchive(folder);

        const blob = new Blob([response.data], { type: 'application/zip' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'archive.zip';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } catch (error) {
        console.error('Error exporting archive:', error);
      }
    };

    return (
      <div className="MuiDataGrid-toolbarContainer css-1j9kmqg-MuiDataGrid-toolbarContainer" style={{ display: 'flex', }}>
        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les factures")) &&
          <IconButton
            color="info"
            component={Link}
            to={`/finances/factures/create`}
            sx={{ fontSize: '0.75rem !important', fontWeight: 'bold' }}
            className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-knwngq-MuiButtonBase-root-MuiButton-root">
            <ControlPointIcon sx={{ mr: '7px' }} />
            AJOUTER
          </IconButton>
        }
        {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
          || currentUser.permissions.includes("Exporter l'archive")) &&
          <IconButton
            color="info"
            sx={{ fontSize: '0.75rem !important', marginLeft: '0.5rem', fontWeight: 'bold' }}
            onClick={() => handleExportArchive('factures')}
            className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-knwngq-MuiButtonBase-root-MuiButton-root">
            <GetAppIcon sx={{ mr: '7px' }} />
            ARCHIVE
          </IconButton>
        }
      </div>
    );
  };

  const CustomToolbar = (props) => {

    return (
      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
      }}>
        <GridToolbar {...props} />
        <CustomButton />
      </div>
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les factures")) ? (
        <>
          <>
            <Box>
              <Typography
                sx={{
                  fontSize: '36px',
                  textAlign: 'center',
                  marginTop: '12px',
                  fontWeight: 'bold',
                  color: '#3e4396',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',

                }}
                variant="h2"
                gutterBottom
              >
                Liste des Factures
              </Typography>
            </Box>
            {factures.length === 0 ? (
              <>
                <Typography variant="body1">Aucune facture disponible.</Typography>
                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les factures")) &&
                  <MDButton component={Link} to={`/finances/factures/create`} variant="contained" color="success">
                    <ControlPointIcon sx={{ mr: "10px" }} />
                    Créer
                  </MDButton>
                }
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Rechercher une facture par client ou par numéro"
                  onChange={handleSearch}
                  value={searchTerm}
                  name="searchTerm"
                  sx={{
                    gridColumn: "span 2",
                    marginBottom: '1rem',
                    backgroundColor: '#dcdcdc',
                    "& .MuiInputLabel-root": { paddingLeft: '1rem', paddingRight: '1rem' },
                  }}
                />
                <Box
                  m="0 0 0 0"
                  height="90vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <DataGrid
                    rows={filteredFactures}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    style={{ color: darkMode ? "#fff" : "#000" }}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                </Box>
              </>
            )}
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
              <DialogTitle>Confirmation de la suppression</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Êtes-vous sûr de vouloir supprimer cette facture ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteModal}>Annuler</Button>
                <MDButton onClick={handleDelete} color="error">
                  Supprimer
                </MDButton>
              </DialogActions>
            </Dialog>
          </>
        </>
      ) : (
        <div className="container">
          <AccessDenied />
        </div>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth
        maxWidth="lg"
      >
        {selectedItem && (
          <>
            <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
              Détails de la facture
            </DialogTitle>
            <DialogContent>
              <FacturePreview facture={selectedItem} />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Fermer
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

    </DashboardLayout>
  );
};

export default FactureList;


