import {
  CREATE_PRIME_SUCCESS,
  CREATE_PRIME_FAILURE,
  UPDATE_PRIME_SUCCESS,
  UPDATE_PRIME_FAILURE,
  GET_PRIME_SUCCESS,
  GET_PRIME_FAILURE,
  DELETE_PRIME_SUCCESS,
  DELETE_PRIME_FAILURE,
  GET_ALL_PRIMES_SUCCESS,
  GET_ALL_PRIMES_FAILURE,
  GET_ALL_USER_PRIMES_SUCCESS,
  GET_ALL_USER_PRIMES_FAILURE,
  UPDATE_USER_PRIME_SUCCESS,
  UPDATE_USER_PRIME_FAILURE,
} from '../actions/types';
const initialState = {
  prime: null,
  primes: [],
  userPrimes: [],
  error: null,
};

const primesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PRIME_SUCCESS:
      return {
        ...state,
        prime: action.payload,
        error: null,
      };
    case CREATE_PRIME_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_PRIME_SUCCESS:
      return {
        ...state,
        prime: action.payload,
        error: null,
      };
    case UPDATE_PRIME_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_USER_PRIME_SUCCESS:
      return {
        ...state,
        prime: action.payload,
        error: null,
      };
    case UPDATE_USER_PRIME_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PRIME_SUCCESS:
      return {
        ...state,
        prime: action.payload,
        error: null,
      };
    case GET_PRIME_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_PRIME_SUCCESS:
      return {
        ...state,
        prime: null,
        error: null,
      };
    case DELETE_PRIME_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_PRIMES_SUCCESS:
      return {
        ...state,
        primes: action.payload,
        error: null,
      };
    case GET_ALL_PRIMES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_USER_PRIMES_SUCCESS:
      return {
        ...state,
        userPrimes: action.payload,
        error: null,
      };
    case GET_ALL_USER_PRIMES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default primesReducer;