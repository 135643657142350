import API_URL from './apiUrl';

class StatisticsService {
    projectsCreatedInLastAndCurrentMonth() {
        return API_URL.get("/statistics/count/projects/currentAndlastMont");
    }

    totalAmountClotureNonPaiement() {
        return API_URL.get("/statistics/count/projects/totalAmountClotureNonPaiement");
    }

    countProjectsByMonthAndNature(month, year) {
        return API_URL.get(`/statistics/count/projects/projectCountByMonthNature?month=${month}&year=${year}`);
    }

    projectsAmountByContactAndPayment(month, year) {
        return API_URL.get(`/statistics/count/projects/byYear/${year}/month/${month}/totalAmountByContact`);
    }
    
    projectsClotureAmountByPayment(month, year) {
        return API_URL.get(`/statistics/count/projects/byYear/${year}/month/${month}/amountByPayment`);
    }

    countProjectsByYearAndPayement(year) {
        return API_URL.get(`/statistics/count/projects/byYear/${year}/totalAmountClotureNonPaiement`);
    }

    countProjectsByYear(year) {
        return API_URL.get(`/statistics/count/projects/byYear/${year}`);
    }

    countProjectsAmount(year) {
        return API_URL.get(`/statistics/count/projects/totaleAmount/${year}`);
    }

    countProjectsByYearAndNature(year) {
        return API_URL.get(`/statistics/count/projects/byYearAndNature/${year}`);
    }

    countProjectsByMonthAndPaiement(month, year) {
        return API_URL.get(`/statistics/count/projects/byMonthAndPaiement?month=${month}&year=${year}`);
    }

    countProjectsAmountByNature(year) {
        return API_URL.get(`/statistics/count/projects/totaleAmountByNature/${year}`);
    }

    projectsCountByContactAndMonth(year, contactId) {
        return API_URL.get(`/statistics/count/projects/byYear/${year}/contact/${contactId}`);
    }

    // charges

    chargesAmountInLastAndCurrentMonth() {
        return API_URL.get("/statistics/count/charges/currentAndlastMont");
    }

    chargesAmountByYear(year) {
        return API_URL.get(`/statistics/count/charges/byYear/${year}`);
    }

    chargesAmountByDesingation(month, year) {
        return API_URL.get(`/statistics/count/charges/byYear/${year}/andMonth/${month}`);
    }

    chargesAmountByYearAndDesingation(year) {
        return API_URL.get(`/statistics/count/charges/byYearAndDesingation/${year}`);
    }

    chargesAmountByYearAndVehicule(year, vehiculeId) {
        return API_URL.get(`/statistics/count/charges/byYear/${year}/andVehicule/${vehiculeId}`);
    }

    chargesAmountByYearAndUser(year, userId) {
        return API_URL.get(`/statistics/count/charges/byYear/${year}/andUser/${userId}`);
    }

    // encaissements

    encaissementsAmountCurrentAndPrevMonth() {
        return API_URL.get("/statistics/count/encaissements/currentAndlastMont");
    }

    encaissementsAmountByYear(year) {
        return API_URL.get(`/statistics/count/encaissements/byYear/${year}`);
    }

    encaissementsFactureByYear(year) {
        return API_URL.get(`/statistics/count/encaissements/totalFactureByYear/${year}`);
    }

    encaissementsAmountFactureByYear(year) {
        return API_URL.get(`/statistics/count/encaissements/totalFactureByYearAndMonth/${year}`);
    }

    // tasks

    completedTasksCountMonth() {
        return API_URL.get("/statistics/count/tasks/completedTasksCountMonth");
    }

    confirmedTasksCountByUser(month, year) {
        return API_URL.get(`/statistics/count/tasks/byYear/${year}/andMonth/${month}`);
    }

    confirmedTasksCountByUserAndMonth(year, userId) {
        return API_URL.get(`/statistics/count/tasks/byYear/${year}/user/${userId}`);
    }

    // folders

    downloadArchive(folder) {
        return API_URL.get(`/statistics/dowloand/folder/${folder}`, { responseType: 'blob' }
        );
    }

    // -------- -------- //

    totalChargesByStatus(startDate, endDate) {
        return API_URL.get(`/statistics/caisse/charges?startDate=${startDate}&endDate=${endDate}`);
    }

    totalEncaissementByType(startDate, endDate) {
        return API_URL.get(`/statistics/caisse/encaissements?startDate=${startDate}&endDate=${endDate}`);
    }

    totalOperationsByType(startDate, endDate) {
        return API_URL.get(`/statistics/caisse/operations?startDate=${startDate}&endDate=${endDate}`);
    }

    totalRequests(startDate, endDate) {
        return API_URL.get(`/statistics/caisse/requests?startDate=${startDate}&endDate=${endDate}`);
    }


}

export default new StatisticsService();
