import React, { useEffect, useState } from "react";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import axios from 'axios';

const MailView = () => {
    const [webmailContent, setWebmailContent] = useState('');

    useEffect(() => {
        axios.get('http://localhost:8000/webmail')
            .then(response => {
                setWebmailContent(response.data);
            })
            .catch(error => {
                console.error('Error fetching webmail content:', error);
            });
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <iframe
                title="Webmail"
                width="100%"
                height="800"
                srcDoc={webmailContent}
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </DashboardLayout>
    );
};

export default MailView;
