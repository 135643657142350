import { useState } from "react";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import CoverLayout from "./CoverLayout";

// Images
import curved9 from "../../assets/images/geo2.png";

function UserSignIn() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDBox mb={1} ml={0.5}>
            <MDTypography component="label" variant="caption" fontWeight="bold">
              Email
            </MDTypography>
          </MDBox>
          <MDInput type="email" placeholder="Email" />
        </MDBox>
        <MDBox mb={2}>
          <MDBox mb={1} ml={0.5}>
            <MDTypography component="label" variant="caption" fontWeight="bold">
              Password
            </MDTypography>
          </MDBox>
          <MDInput type="password" placeholder="Password" />
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth>
            sign in
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </CoverLayout>
  );
}

export default UserSignIn;

