import {
    CREATE_PIECES_SUCCESS,
    CREATE_PIECES_FAILURE,
    UPDATE_PIECES_SUCCESS,
    UPDATE_PIECES_FAILURE,
    GET_PIECES_SUCCESS,
    GET_PIECES_FAILURE,
    DELETE_PIECES_SUCCESS,
    DELETE_PIECES_FAILURE,
    GET_ALL_PIECESS_SUCCESS,
    GET_ALL_PIECESS_FAILURE,
  } from '../actions/types';
const initialState = {
  piece: null,
  pieces: [],
  error: null,
};

const piecesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PIECES_SUCCESS:
      return {
        ...state,
        piece: action.payload,
        error: null,
      };
    case CREATE_PIECES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_PIECES_SUCCESS:
      return {
        ...state,
        piece: action.payload,
        error: null,
      };
    case UPDATE_PIECES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PIECES_SUCCESS:
      return {
        ...state,
        piece: action.payload,
        error: null,
      };
    case GET_PIECES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_PIECES_SUCCESS:
      return {
        ...state,
        piece: null,
        error: null,
      };
    case DELETE_PIECES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_PIECESS_SUCCESS:
      return {
        ...state,
        pieces: action.payload,
        error: null,
      };
    case GET_ALL_PIECESS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default piecesReducer;