import ContactService from '../services/contactServices';
import {
  CREATE_COMMERCIAL_SUCCESS,
  CREATE_COMMERCIAL_FAILURE,
  UPDATE_COMMERCIAL_SUCCESS,
  UPDATE_COMMERCIAL_FAILURE,
  GET_COMMERCIAL_SUCCESS,
  GET_COMMERCIAL_FAILURE,
  DELETE_COMMERCIAL_SUCCESS,
  DELETE_COMMERCIAL_FAILURE,
  GET_ALL_COMMERCIALS_SUCCESS,
  GET_ALL_COMMERCIALS_FAILURE,
  CREATE_CONTACT_ACCOUNT_SUCCESS,
  CREATE_CONTACT_ACCOUNT_FAILURE,
  CONTACT_LOGIN_SUCCESS,
  CONTACT_LOGIN_FAILURE,
  SET_MESSAGE,
  CONTACT_LOGOUT,
} from './types';

export const createCommercial = (commercialData) => {
  return (dispatch) => {
    return ContactService.createCommercial(commercialData)
      .then((response) => {
        dispatch({
          type: CREATE_COMMERCIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_COMMERCIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllCommercials = () => {
  return (dispatch) => {
    return ContactService.getAllCommercials()
      .then((response) => {
        dispatch({
          type: GET_ALL_COMMERCIALS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_COMMERCIALS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateCommercial = (commercialId, updatedCommercial) => {
  return (dispatch) => {
    return ContactService.updateCommercial(commercialId, updatedCommercial)
      .then((response) => {
        dispatch({
          type: UPDATE_COMMERCIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_COMMERCIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getCommercial = (commercialId) => {
  return (dispatch) => {
    return ContactService.fetchCommercial(commercialId)
      .then((response) => {
        dispatch({
          type: GET_COMMERCIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_COMMERCIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteCommercial = (commercialId) => {
  return (dispatch) => {
    return ContactService.deleteCommercial(commercialId)
      .then((response) => {
        dispatch({
          type: DELETE_COMMERCIAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_COMMERCIAL_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const createAccount = (contactId, contactData) => {
  return (dispatch) => {
    return ContactService.createAccount(contactId, contactData)
      .then((response) => {
        dispatch({
          type: CREATE_CONTACT_ACCOUNT_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_CONTACT_ACCOUNT_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const contactLogin = (username, password) => (dispatch) => {
  return ContactService.contactLogin(username, password).then(
    (data) => {
      const token = data.accessToken;
      const timestamp = new Date().getTime();
      localStorage.setItem("token", token);
      localStorage.setItem("tokenTimestamp", timestamp);
      dispatch({
        type: CONTACT_LOGIN_SUCCESS,
        payload: { contact: data },
      });
      // console.log(data);
      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CONTACT_LOGIN_FAILURE,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      throw error;
    }
  );
};

export const contactLogout = () => (dispatch) => {
  ContactService.logout();
  dispatch({
    type: CONTACT_LOGOUT,
  });
};