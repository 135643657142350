import HolidaysServices from '../services/holidayServices';

import {
    GET_ALL_HOLIDAYS_SUCCESS,
    GET_ALL_HOLIDAYS_FAILURE,
    DELETE_HOLIDAY_SUCCESS,
    DELETE_HOLIDAY_FAILURE,
    UPDATE_HOLIDAY_SUCCESS,
    UPDATE_HOLIDAY_FAILURE,
    GET_ALL_PENDING_HOLIDAYS_SUCCESS,
    GET_ALL_PENDING_HOLIDAYS_FAILURE,
    SEND_HOLIDAY_REQUEST_SUCCESS,
    SEND_HOLIDAY_REQUEST_FAILURE,
    FETCH_HOLIDAY_SUCCESS,
    FETCH_HOLIDAY_FAILURE,
    GET_ALL_USERHOLIDAYS_SUCCESS,
    GET_ALL_USERHOLIDAYS_FAILURE,
} from './types';

export const sendHolidayRequest = (holidayRequest) => {
    return (dispatch) => {
        return HolidaysServices.sendHolidaysRequest(holidayRequest)
            .then((response) => {
                dispatch({
                    type: SEND_HOLIDAY_REQUEST_SUCCESS,
                    payload: response.data,
                });
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: SEND_HOLIDAY_REQUEST_FAILURE,
                    payload: error.message,
                });
                return Promise.reject(error);
            });
    };
};

export const getPendingHolidays = () => {
    return (dispatch) => {
        return HolidaysServices.fetchPendingHoliays()
            .then((response) => {
                dispatch({
                    type: GET_ALL_PENDING_HOLIDAYS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_PENDING_HOLIDAYS_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getAllHolidays = () => {
    return (dispatch) => {
        return HolidaysServices.fetchAllHoliays()
            .then((response) => {
                dispatch({
                    type: GET_ALL_HOLIDAYS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_HOLIDAYS_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const updateHoliday = (holidayId, updatedHoliday) => {
    return (dispatch) => {
        return HolidaysServices.updateHoliday(holidayId, updatedHoliday)
            .then((response) => {
                dispatch({
                    type: UPDATE_HOLIDAY_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_HOLIDAY_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const deleteHoliday = (holidayId) => {
    return (dispatch) => {
        return HolidaysServices.deleteHoliday(holidayId)
            .then((response) => {
                dispatch({
                    type: DELETE_HOLIDAY_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_HOLIDAY_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getHoliday = (holidayId) => {
    return (dispatch) => {
        return HolidaysServices.fetchHoliday(holidayId)
            .then((response) => {
                dispatch({
                    type: FETCH_HOLIDAY_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_HOLIDAY_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getUserHolidays = (userId) => {
    return (dispatch) => {
        return HolidaysServices.fetchUserHolidays(userId)
            .then((response) => {
                dispatch({
                    type: GET_ALL_USERHOLIDAYS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_USERHOLIDAYS_FAILURE,
                    payload: error.message,
                });
            });
    };
};
