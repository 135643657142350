import React, { useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import PolylinesMap from '../geometry/PolylinesMap';
import {
  Typography,
  Card,
  Grid,
  MenuItem,
  Box,
  TextField,
} from "@mui/material";
import MDButton from '../../components/MDButton';
const MapOfProjects = () => {
  const [selectedProjection, setSelectedProjection] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const handleProjectionChange = (event) => {
    setSelectedProjection(event.target.value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Grid item xs={12} lg={8} mb={2}>
        <Card>
          <Box p={2} lineHeight={0}>
            <Typography variant="h5">Sélectionnez une zone</Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <TextField
                  select
                  label='Select Projection'
                  id="projection-select"
                  name="selectedProjection"
                  value={selectedProjection}
                  fullWidth
                  onChange={handleProjectionChange}
                >
                  <MenuItem value="26191">Zone 1: Merchich / zone Nord</MenuItem>
                  <MenuItem value="26192">Zone 2: Merchich / zone Sud </MenuItem>
                  <MenuItem value="26194">Zone 3: Merchich / sahara Nord</MenuItem>
                  <MenuItem value="26195">Zone 4: Merchich / sahara Nord</MenuItem>
                </TextField>
              </Grid>
              {selectedProjection && <>
                <Grid item xs={12} sm={6} >
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setIsOpen(true)}
                  >
                    Afficher
                  </MDButton>
                </Grid>
              </>}
            </Grid>
          </Box>
        </Card>
      </Grid>
      {isOpen && <> */}
        {/* <PolylinesMap zone={selectedProjection} /> */}
        <PolylinesMap />
      {/* </>} */}
    </DashboardLayout>
  );
};

export default MapOfProjects;
