import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteFacture } from '../../actions/FactureActions';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  IconButton,
  Tooltip,
} from '@mui/material';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useMaterialUIController } from "../../context";
import MDButton from '../../components/MDButton';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import FacturePreview from './FacturePreview';
import { encodeId } from '../../actions/Crypte';
import { ToWords } from 'to-words';
import { Delete, ArrowCircleDown } from '@mui/icons-material';

const ClientFactures = ({ factures, isMobile }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(25);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDeleteModal = (facture) => {
    setSelectedFacture(facture);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedFacture(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    if (selectedFacture) {
      dispatch(deleteFacture(selectedFacture.id));
      setSelectedFacture(null);
    }
    closeDeleteModal();
  };

  const openDialog = (project) => {
    setSelectedItem(project);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedItem(null);
    setIsDialogOpen(false);
  };


  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };
  const toWords = new ToWords({ localeCode: 'fr-FR' });

  const handleExportToWord = async (facture) => {
    try {
      const response = await axios.get('/facture_template.docx', {
        responseType: 'arraybuffer',
      });

      const projectTemplate = response.data;
      const zip = new PizZip(projectTemplate);
      const doc = new Docxtemplater(zip);
      const [integerPart, decimalPart] = facture?.montant_ttc.split('.');
      let wordsIntegerPart = toWords.convert(integerPart);

      let wordsDecimalPart = '';
      if (decimalPart) {
        wordsDecimalPart = toWords.convert(decimalPart);
      }
      const data = {
        factureNumber: facture.numero,
        reference: facture.reference || "-",
        objet: facture.objet || "-",
        creation: facture.date ? formatDate(facture.date) : "-",
        montantttc: facture.montant_ttc || "-",
        // montantttcwords: numberToWordsFrench(facture.montant_ttc, { lang: "fr" })
        //   || "-",
        wordsIntegerPart: wordsIntegerPart || '00',
        wordsDecimalPart: wordsDecimalPart || '00',
        client: facture.client?.full_name || "-",
        ice: facture.client?.ice || "-",
        adresse: facture.client?.adresse || "-",
        items: facture.items.map((item, index) => ({
          ...item,
          index: index + 1,
          ofPrice: item.quantity * item.prix_unitaire,
        })) || "-",
        totalePrice: facture.items.length > 0
          ? facture.items.reduce((total, item) => total + item.quantity * item.prix_unitaire, 0)
          : "-",
        tva: 0.2 * (facture.items.length > 0
          ? facture.items.reduce((total, item) => total + item.quantity * item.prix_unitaire, 0)
          : "-"),
      };

      doc.setData(data);

      try {
        doc.render();
      } catch (error) {
        console.error('Error rendering the template:', error);
        return;
      }

      try {
        const blob = doc.getZip().generate({ type: 'blob' });
        saveAs(blob, `facture_${facture.numero}.docx`);
      } catch (error) {
        console.error('Error generating the Word document:', error);
      }
    } catch (error) {
      console.error('Error fetching the template:', error);
    }
  };

  const columns = [
    {
      field: 'date', headerName: 'Date',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },
    {
      field: 'objet', headerName: 'Objet',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },
    {
      field: 'montant_ttc', headerName: 'Montant TTC',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },
    {
      field: 'statut', headerName: 'Statut',
      ...(isMobile ? { width: 120 } : { flex: 1 })
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <Box>
          <Tooltip title='Détails de la facture' arrow>
            <IconButton
              className='me-1 ms-1'
              size='small'
              color='warning'
              onClick={() => openDialog(params.row)}>
              <OpenInFullIcon />
            </IconButton>
          </Tooltip>
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les factures")) && (
            <Tooltip title='Modifier' arrow>
              <IconButton component={Link} color='info' to={`/finances/factures/update/${encodeId(params.row.id)}`} >
                <BorderColorIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les factures")) && (
            <Tooltip title='Supprimer' arrow>
              <IconButton
                className='me-2'
                variant="contained"
                color="error"
                size="small"
                onClick={() => openDeleteModal(params.row)}
              >
                <Delete fontSize='medium' />
              </IconButton>
            </Tooltip>
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Exporter les factures")) && (
            <Tooltip title='Télécharger' arrow>
              <IconButton
                className='ms-1'
                variant="contained"
                size="small"
                color="info"
                onClick={() => handleExportToWord(params.row)}
              >
                <ArrowCircleDown fontSize='medium' />
              </IconButton>
            </Tooltip>

          )}
        </Box>
      ),
      ...(isMobile ? { width: 180 } : { flex: 2 }),
      cellClassName: 'actions-cell',
    },
  ];


  return (
    <>
      <>
        <>
          {factures.length === 0 ? (
            <>
              <Typography variant="body1">Aucune facture disponible.</Typography>
            </>
          ) : (
            <Box
              m="0 0 0 0"
              height="70vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
              }}
            >
              <DataGrid
                rows={factures}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                style={{ color: darkMode ? "#fff" : "#000" }}
                components={{ Toolbar: GridToolbar }}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          )}
          <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
            <DialogTitle>Confirmation de la suppression</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Êtes-vous sûr de vouloir supprimer cette facture ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteModal}>Annuler</Button>
              <MDButton onClick={handleDelete} color="error">
                Supprimer
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      </>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth
        maxWidth="md"
      >
        {selectedItem && (
          <>
            <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
              Détails de la facture
            </DialogTitle>
            <DialogContent>
              <FacturePreview facture={selectedItem} />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Fermer
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ClientFactures;


