import React, { useState } from "react";
import { TextField, Box, Button } from "@mui/material";

const AddPoint = ({ handlePointSubmit }) => {
    const [reference, setReference] = useState("");
    const [nature, setNature] = useState("");
    const [name, setName] = useState("");
    const [X, setX] = useState(0);
    const [Y, setY] = useState(0);
    const [igt, setIgt] = useState('');
    const [note, setNote] = useState('');

    const varia = {
        name,
        nature,
        reference,
        X,
        Y,
        igt,
        note,
    }
    return (
        <Box>
            <TextField
                label="Nom du point"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Nature du point"
                value={nature}
                onChange={(e) => setNature(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Référence"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="X"
                type='number'
                value={X}
                onChange={(e) => setX(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Y"
                type='number'
                value={Y}
                onChange={(e) => setY(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="IGT"
                value={igt}
                onChange={(e) => setIgt(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Observation"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <Button
                type="button"
                variant="contained"
                color="dark"
                sx={{
                    color: '#000 !important',
                    marginTop: '12px',
                    backgroundColor: '#ffc107 !important',
                }}
                onClick={() => handlePointSubmit(varia)}

            >
                Sauvegarder
            </Button>
        </Box>
    );
}

export default AddPoint;
