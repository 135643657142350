import ChargeService from '../services/chargesServices';
import {
  CREATE_CHARGE_SUCCESS,
  CREATE_CHARGE_FAILURE,
  UPDATE_CHARGE_SUCCESS,
  UPDATE_CHARGE_FAILURE,
  GET_CHARGE_SUCCESS,
  GET_CHARGE_FAILURE,
  DELETE_CHARGE_SUCCESS,
  DELETE_CHARGE_FAILURE,
  GET_ALL_CHARGES_SUCCESS,
  GET_ALL_CHARGES_FAILURE,
  GET_PENDING_CHARGES_SUCCESS,
  GET_PENDING_CHARGES_FAILURE,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
  GET_FILES_SUCCESS,
  GET_FILES_FAILURE,
  GET_REJECTED_SUCCESS,
  GET_REJECTED_FAILURE,
  GET_USER_CHARGES_SUCCESS,
  GET_USER_CHARGES_FAILURE,
} from './types';

export const uploadChargeFiles = (chargeId, formData) => {
  return async (dispatch) => {
    try {
      const response = await ChargeService.uploadFilesToCharge(chargeId, formData);
      dispatch({
        type: UPLOAD_FILES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: UPLOAD_FILES_FAILURE,
        payload: error.response ? error.response.data : 'An error occurred',
      });
      throw error;
    }
  };
};

export const fetchChargeFiles = (chargeId) => {
  return (dispatch) => {
    return ChargeService.getAllChargeFiles(chargeId)
      .then((response) => {
        dispatch({
          type: GET_FILES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FILES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchFileContent = (chargeId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await ChargeService.getFileContent(chargeId, fileName);
      return response;
    } catch (error) {
      console.error('Error fetching file content:', error);
      throw error;
    }
  };
};

export const downloadFile = (chargeId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await ChargeService.downloadFile(chargeId, fileName);

      if (response.data instanceof Blob) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } else {
        console.error('Invalid file content in the response.');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
};

export const deleteFile = (chargeId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await ChargeService.deleteFile(chargeId, fileName);
      return response;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  };
};

export const createCharge = (devisData) => {
  return (dispatch) => {
    return ChargeService.createCharge(devisData)
      .then((response) => {
        dispatch({
          type: CREATE_CHARGE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_CHARGE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getAllCharges = () => {
  return (dispatch) => {
    return ChargeService.getAllCharges()
      .then((response) => {
        dispatch({
          type: GET_ALL_CHARGES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_CHARGES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllUserCharges = (userId) => {
  return (dispatch) => {
    return ChargeService.fetchChargesByUserId(userId)
      .then((response) => {
        dispatch({
          type: GET_USER_CHARGES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USER_CHARGES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchAllPendingCharges = () => {
  return (dispatch) => {
    return ChargeService.getAllPendingCharges()
      .then((response) => {
        dispatch({
          type: GET_PENDING_CHARGES_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_PENDING_CHARGES_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchRejcted = (userId) => {
  return (dispatch) => {
    return ChargeService.getAllRejected(userId)
      .then((response) => {
        dispatch({
          type: GET_REJECTED_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_REJECTED_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const updateCharge = (chargeId, updatedCharge) => {
  return (dispatch) => {
    return ChargeService.updateCharge(chargeId, updatedCharge)
      .then((response) => {
        dispatch({
          type: UPDATE_CHARGE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CHARGE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getCharge = (chargeId) => {
  return (dispatch) => {
    return ChargeService.fetchCharge(chargeId)
      .then((response) => {
        dispatch({
          type: GET_CHARGE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CHARGE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteCharge = (chargeId) => {
  return (dispatch) => {
    return ChargeService.deleteCharge(chargeId)
      .then((response) => {
        dispatch({
          type: DELETE_CHARGE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CHARGE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  }
};