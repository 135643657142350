import React, { useEffect, useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { ResponsiveBar } from '@nivo/bar';
import { fetchProjectCount, countProjectsLastMonth, NumberOfProjectsLastMonth } from "../../actions/ProjetActions";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useDispatch, useSelector } from 'react-redux';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MDTypography from "../../components/MDTypography";
import { ResponsivePie } from '@nivo/pie';
import { Card, Divider } from "@mui/material";
import { ResponsiveLine } from '@nivo/line';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DrawIcon from '@mui/icons-material/Draw';
import MDButton from "../../components/MDButton";
import { useMediaQuery } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from "react-router-dom";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import Spinner from "../../pages/Spinner/Index";

const Dashboard = ({ projectCount, loading, error, fetchProjectCount, count }) => {
  const dispatch = useDispatch();
  const counted = useSelector((state) => state.project.count);
  const { user: currentUser } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    dispatch(countProjectsLastMonth());
  }, [dispatch]);

  useEffect(() => {
    fetchProjectCount();
  }, [fetchProjectCount]);


  const statusInfo = {
    preparation: {
      color: "primary",
      icon: <HourglassTopIcon />,
    },
    terrain: {
      color: "warning",
      icon: <EngineeringIcon />,
    },
    bureau: {
      color: "success",
      icon: <DrawIcon />,
    },
    controle: {
      color: "dark",
      icon: <PublishedWithChangesIcon />,
    },
  };

  console.log(counted);

  const data = [
    {
      id: 'Projects Count',
      data: [
        { x: 'Semaine 1', y: counted[0]?.count || 0 },
        { x: 'Semaine 2', y: counted[1]?.count || 0 },
        { x: 'Semaine 3', y: counted[2]?.count || 0 },
        { x: 'Semaine 4', y: counted[3]?.count || 0 },
      ],
    },
  ];

  const Barchart = Array.isArray(projectCount) ? projectCount.filter((project) => project?.statut !== 'cloture').map((project) => ({
    statut: project?.statut,
    count: project?.count,
  })) : [];

  const pieChartDataT = Array.isArray(projectCount) ? projectCount.filter((project) => project?.statut === 'preparation' || project?.statut === 'terrain').map((project) => ({
    id: project?.statut,
    label: project?.statut,
    value: project?.count,
  })) : [];

  const pieChartDataB = Array.isArray(projectCount) ? projectCount.filter((project) => project?.statut === 'terrain' || project?.statut === 'bureau').map((project) => ({
    id: project?.statut,
    label: project?.statut,
    value: project?.count,
  })) : [];

  const pieChartDataC = Array.isArray(projectCount) ? projectCount.filter((project) => project?.statut === 'controle' || project?.statut === 'livraison').map((project) => ({
    id: project?.statut,
    label: project?.statut,
    value: project?.count,
  })) : [];


  const chartData = {
    height: 480,
    type: 'bar',
    options: {
      chart: {
        id: 'bar-chart',
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%'
        }
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: `'Roboto', sans-serif`,
        position: 'bottom',
        offsetX: 20,
        labels: {
          useSeriesColors: false
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8
        }
      },
      fill: {
        type: 'solid'
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: true
      }
    },
    series: [
      {
        name: 'Count',
        data: Barchart.map((data) => data.count),
      },
    ],
    // options: {
    //   chart: {
    //     type: 'bar',
    //     toolbar: {
    //       show: false,
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //     },
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   xaxis: {
    //     categories: Barchart.map((data) => data.statut),
    //     labels: {
    //       rotate: -45,
    //     },
    //   },
    //   yaxis: {
    //     tickAmount: 5,
    //   },
    //   colors: ['#1f77b4'],
    //   grid: {
    //     show: true,
    //     borderColor: '#f1f1f1',
    //   },
    // },
    // series: [
    //   {
    //     name: 'Count',
    //     data: Barchart.map((data) => data.count),
    //   },
    // ],
  };


  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {!isMobile && (
          <>
            <MDBox mt={3.5} >
              {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des projets")) && (
                <MDButton color='info'
                  component={Link}
                  to={`/projects/create`}
                  className='mt-2 mb-4 me-1 ms-1 float-end'
                >
                  <AddCircleIcon />&nbsp;
                  ajouter projet
                </MDButton>
              )}
              {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des clients")) && (
                <MDButton
                  color='info'
                  component={Link}
                  to={`/clients/create`}
                  className='mt-2 mb-4 me-1 ms-1 float-end'
                >
                  <PersonAddAlt1Icon />&nbsp;
                  Ajouter client
                </MDButton>
              )}
              {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Exporter PV de mission")
                || currentUser.permissions.includes("Exporter ordre de mission")
                || currentUser.permissions.includes("Générer et envoyer le rapport")) && (
                  <MDButton color='info'
                    component={Link}
                    to={`/bureau`}
                    className='mt-2 mb-4 me-1 ms-1 float-end'
                  >
                    <ScreenSearchDesktopIcon />&nbsp;
                    BUREAU d'ordre
                  </MDButton>
                )}
            </MDBox>
          </>
        )}
        <MDBox py={3}>
          {
            loading ? (
              <div>
                <Spinner />
              </div>
            ) :
              error ? (
                <p>Error: {error}</p>
              ) :
                (
                  <Grid container spacing={3}>
                    {Array.isArray(projectCount) && projectCount.length > 0 ? (
                      projectCount.map((project, index) => (
                        project.statut !== "livraison" && project.statut !== "cloture" && (
                          <React.Fragment key={index}>
                            <Grid item xs={12} md={6} lg={3}>
                              <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                  color={statusInfo[project.statut].color}
                                  icon={statusInfo[project.statut].icon}
                                  title={project.statut}
                                  count={project.count}
                                  percentage={{
                                    color: "success",
                                    amount: `+${project.count}%`,
                                    label: "than last week",
                                  }}
                                />
                              </MDBox>
                            </Grid>
                          </React.Fragment>
                        )
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <p>Aucun Projet Disponible.</p>
                      </Grid>
                    )}
                  </Grid>
                )}
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <Card sx={{ height: "100%" }}>
                    <MDBox padding="1rem">
                      {useMemo(
                        () => (
                          <MDBox
                            variant="gradient"
                            bgColor={"info"}
                            borderRadius="lg"
                            coloredShadow={"info"}
                            py={2}
                            pr={0.5}
                            mt={-5}
                            height="19rem"
                          >
                            <div style={{ maxWidth: '100%', minWidth: '22rem', height: 280 }}>
                              <ResponsiveLine
                                data={data}
                                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                curve="linear"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                  orient: 'bottom',
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: 'Semaines',
                                  legendOffset: 36,
                                  legendPosition: 'middle',
                                }}
                                axisLeft={{
                                  orient: 'left',
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: 'Project Count',
                                  legendOffset: -40,
                                  legendPosition: 'middle',
                                }}
                                colors={{ scheme: 'nivo' }}
                                pointSize={10}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                useMesh={true}
                              />
                            </div>
                          </MDBox>
                        ),
                      )}
                      <MDBox pt={3} pb={1} px={1}>
                        <MDTypography variant="h6" textTransform="capitalize">
                          Projets
                        </MDTypography>
                        <MDTypography component="div" variant="button" color="text" fontWeight="light">
                          Projets créés dans 30 derniers jours
                        </MDTypography>
                        <Divider />
                        <MDBox display="flex" alignItems="center">
                          <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="light">
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <Card sx={{ height: "100%" }}>
                    <MDBox padding="1rem">
                      {useMemo(
                        () => (
                          <MDBox
                            variant="gradient"
                            bgColor={"success"}
                            borderRadius="lg"
                            coloredShadow={"success"}
                            py={2}
                            pr={0.5}
                            mt={-5}
                            height="19rem"
                          >
                            <div style={{ width: '100%', height: 280 }}>
                              <ResponsiveBar
                                data={Barchart}
                                date={new Date()}
                                keys={['count']}
                                indexBy="statut"
                                margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
                                axisBottom={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: -45,
                                }}
                                colors={{ scheme: 'pastel2' }}
                                axisLeft={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                }}
                                enableLabel={false}
                                enableGridX={false}
                                enableGridY
                              />
                            </div>
                          </MDBox>
                        ),
                      )}
                      <MDBox pt={3} pb={1} px={1}>
                        <MDTypography variant="h6" textTransform="capitalize">
                          Projets
                        </MDTypography>
                        <MDTypography component="div" variant="button" color="text" fontWeight="light">
                          Projets comptés par statut
                        </MDTypography>
                        <Divider />
                        <MDBox display="flex" alignItems="center">
                          <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>

                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="light">

                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <Card sx={{ height: "100%" }}>
                    <MDBox padding="1rem">
                      {useMemo(
                        () => (
                          <MDBox
                            variant="gradient"
                            bgColor={"primary"}
                            borderRadius="lg"
                            coloredShadow={"primary"}
                            py={2}
                            pr={0.5}
                            mt={-5}
                            height="19rem"
                          >
                            <div style={{ width: '100%', height: 280 }}>
                              {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Création des projets")) && (
                                <ResponsivePie
                                  data={pieChartDataC}
                                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                  innerRadius={0.5}
                                  padAngle={0.7}
                                  cornerRadius={3}
                                  colors={{ scheme: 'pastel1' }}
                                  borderWidth={1}
                                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                  radialLabelsSkipAngle={10}
                                  radialLabelsTextXOffset={6}
                                  radialLabelsTextColor="#333333"
                                  radialLabelsLinkOffset={0}
                                  radialLabelsLinkDiagonalLength={16}
                                  radialLabelsLinkHorizontalLength={24}
                                  radialLabelsLinkStrokeWidth={1}
                                  radialLabelsLinkColor={{ from: 'color' }}
                                  slicesLabelsSkipAngle={10}
                                  slicesLabelsTextColor="#333333"
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={15}
                                  legends={[
                                    {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      justify: false,
                                      translateX: 0,
                                      translateY: 56,
                                      itemsSpacing: 0,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: '#999',
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 1,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                        {
                                          on: 'hover',
                                          style: {
                                            itemTextColor: '#000',
                                          },
                                        },
                                      ],
                                    },
                                  ]}
                                />
                              )}
                              {currentUser && (currentUser.roles.includes("ROLE_AGENT-BUREAU")) && (
                                <ResponsivePie
                                  data={pieChartDataB}
                                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                  innerRadius={0.5}
                                  padAngle={0.7}
                                  cornerRadius={3}
                                  colors={{ scheme: 'pastel1' }}
                                  borderWidth={1}
                                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                  radialLabelsSkipAngle={10}
                                  radialLabelsTextXOffset={6}
                                  radialLabelsTextColor="#333333"
                                  radialLabelsLinkOffset={0}
                                  radialLabelsLinkDiagonalLength={16}
                                  radialLabelsLinkHorizontalLength={24}
                                  radialLabelsLinkStrokeWidth={1}
                                  radialLabelsLinkColor={{ from: 'color' }}
                                  slicesLabelsSkipAngle={10}
                                  slicesLabelsTextColor="#333333"
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={15}
                                  legends={[
                                    {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      justify: false,
                                      translateX: 0,
                                      translateY: 56,
                                      itemsSpacing: 0,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: '#999',
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 1,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                        {
                                          on: 'hover',
                                          style: {
                                            itemTextColor: '#000',
                                          },
                                        },
                                      ],
                                    },
                                  ]}
                                />
                              )}
                              {currentUser && (currentUser.roles.includes("ROLE_AGENT-TERRAIN")) && (
                                <ResponsivePie
                                  data={pieChartDataT}
                                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                  innerRadius={0.5}
                                  padAngle={0.7}
                                  cornerRadius={3}
                                  colors={{ scheme: 'pastel1' }}
                                  borderWidth={1}
                                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                  radialLabelsSkipAngle={10}
                                  radialLabelsTextXOffset={6}
                                  radialLabelsTextColor="#333333"
                                  radialLabelsLinkOffset={0}
                                  radialLabelsLinkDiagonalLength={16}
                                  radialLabelsLinkHorizontalLength={24}
                                  radialLabelsLinkStrokeWidth={1}
                                  radialLabelsLinkColor={{ from: 'color' }}
                                  slicesLabelsSkipAngle={10}
                                  slicesLabelsTextColor="#333333"
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={15}
                                  legends={[
                                    {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      justify: false,
                                      translateX: 0,
                                      translateY: 56,
                                      itemsSpacing: 0,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: '#999',
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 1,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                        {
                                          on: 'hover',
                                          style: {
                                            itemTextColor: '#000',
                                          },
                                        },
                                      ],
                                    },
                                  ]}
                                />
                              )}
                            </div>
                          </MDBox>
                        ),
                      )}
                      <MDBox pt={3} pb={1} px={1}>
                        <MDTypography variant="h6" textTransform="capitalize">
                          Projets
                        </MDTypography>
                        <MDTypography component="div" variant="button" color="text" fontWeight="light">
                          Projets liés aux postes d'utilisateurs
                        </MDTypography>
                        <Divider />
                        <MDBox display="flex" alignItems="center">
                          <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="light">
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
              {/* <Grid item sm={6} xs={12} md={6} lg={12}>
                {chartData ? <Chart {...chartData} /> : null}
              </Grid> */}
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox py={3}>
          
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    count: state.project.count || 0,
    projectCount: state.project?.projectCount || [],
    loading: state.project.loading,
    error: state.project.error,
  };
};

const mapDispatchToProps = {
  fetchProjectCount,
  NumberOfProjectsLastMonth,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
