import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserRequests, deleteRequest } from "../../actions/RequestActions";
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { useMaterialUIController } from "../../context";
import MDButton from "../../components/MDButton";
import { Delete, BorderColor } from "@mui/icons-material";

const UserRequests = ({ currentUser, userId, salaire }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const requests = useSelector((state) => state.request.requests);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageSize, setPageSize] = useState(25);


    useEffect(() => {
        dispatch(getUserRequests(userId));
    }, [dispatch, userId]);

    const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedItem(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedItem) {
            dispatch(deleteRequest(selectedItem.id))
                .then(() => {
                    dispatch(getUserRequests(currentUser.id));
                })
                .catch((error) => {
                    console.error('Error delete Request:', error);
                });
            setSelectedItem(null);
        }
        closeDeleteModal();
    };

    const formatDateForMonthDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const monthIndex = dateObj.getMonth();
        const frenchMonths = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
        const month = frenchMonths[monthIndex];
        return `${month} ${year}`;
    };


    const formatDateDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        return `${day}-${month}-${year}`;
    };

    const columns = [
        { field: 'prix', headerName: 'Cout', flex: 1 },
        // { field: 'mois', headerName: 'Mois', flex: 1 },
        // { field: 'createdAt', headerName: 'Date', flex: 1 },
        {
            field: 'mois', headerName: 'Mois',
            flex: 1,
            valueFormatter: (params) => formatDateForMonthDisplay(params.value)
        },
        {
            field: 'createdAt', headerName: 'Date',
            flex: 1,
            valueFormatter: (params) => formatDateDisplay(params.value)
        },
        { field: 'note', headerName: 'Note', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <div style={{ overflow: 'visible !important' }}>
                    <>
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || (currentUser.permissions.includes("Supprimer les avances sur salaires"))) && (
                            <Tooltip title='Supprimer' arrow>
                                <IconButton
                                    size='small'
                                    color='error'
                                    className="btn btn-sm"
                                    onClick={() => openDeleteModal(params.row)}
                                    style={{ marginLeft: "8px" }}
                                >
                                    <Delete fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || (currentUser.permissions.includes("Modifier les avances sur salaires"))) && (
                            <Tooltip title='Modifier' arrow>
                                <IconButton
                                    to={`/requests/update/${params.row.id}`}
                                    component={Link}
                                    size='small'
                                    color='success'
                                    className="btn btn-sm"
                                    style={{ marginLeft: "8px" }}
                                >
                                    <BorderColor fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                </div>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    return (
        <>
            <>
                {currentUser && (currentUser.roles.includes("ROLE_ADMIN")) && (
                    <>
                        <Typography variant="body1" textAlign={"center"} mb={1} gutterBottom>
                            total des avances actuelles: {requests?.total}&nbsp;
                            <Typography component="span" variant="body2" color="success"
                                sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                        <Typography variant="body1" textAlign={"center"} mb={1} gutterBottom>
                            Salaire de mois actuelles: {salaire}&nbsp;
                            <Typography component="span" variant="body2" color="success"
                                sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                        <Typography variant="body1" textAlign={"center"} mb={5} gutterBottom>
                            Rest: {(salaire - requests?.total) || 0}&nbsp;
                            <Typography component="span" variant="body2" color="success"
                                sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                MAD
                            </Typography>
                        </Typography>
                    </>
                )}
                {requests?.length === 0 ? (
                    <>
                        <Typography variant="body1">No data available.</Typography>
                    </>
                ) : (
                    <Box
                        m="0 0 0 0"
                        height="90vh"
                    >
                        <DataGrid
                            rows={requests?.requests || []}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            className={`${darkMode ? "text-light" : "text-dark"}`}
                            components={{ Toolbar: GridToolbar }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                )}
            </>
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle className={darkMode ? "text-light" : "text-dark"}>Confirmation de la suppression</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: darkMode ? "#fff" : "#000" }}>
                        Êtes-vous sûr de vouloir supprimer cet avance ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeDeleteModal}>
                        Annuler
                    </MDButton>
                    <MDButton color='error' className="btn btn-sm" onClick={handleDelete}>
                        Supprimer
                    </MDButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserRequests;
