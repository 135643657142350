import React, { useState } from 'react';
import { TextField, Button, Typography, Snackbar } from '@mui/material';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   form: {
//     maxWidth: 400,
//     margin: 'auto',
//     marginTop: theme.spacing(4),
//   },
//   submitButton: {
//     marginTop: theme.spacing(2),
//   },
// }));

const SupportForm = () => {
//   const classes = useStyles();

  const [formData, setFormData] = useState({
    subject: '',
    message: '',
    email: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSnackbarOpen(true);

    setFormData({
      subject: '',
      message: '',
      email: '',
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <form  onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Contact Support
      </Typography>
      <TextField
        fullWidth
        label="Subject"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        required
        margin="normal"
      />
      <TextField
        fullWidth
        label="Your Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
        margin="normal"
      />
      <TextField
        fullWidth
        label="Message"
        name="message"
        multiline
        rows={4}
        value={formData.message}
        onChange={handleChange}
        required
        margin="normal"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        // className={classes.submitButton}
      >
        Send Email
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Email sent successfully!"
      />
    </form>
  );
};

export default SupportForm;
