import * as types from '../actions/types';

const contact = JSON.parse(localStorage.getItem("contact"));

const initialState = contact
    ? { isLoggedIn: true, contact }
    : { isLoggedIn: false, contact: null };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.CONTACT_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                contact: payload.contact,
            };
        case types.CONTACT_LOGIN_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                contact: null,
            };
        case types.CONTACT_LOGOUT:
            localStorage.removeItem("token");
            localStorage.removeItem("tokenTimestamp");
            return {
                ...state,
                isLoggedIn: false,
                contact: null,
            };
        default:
            return state;
    }
}