import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, fetchUserFiles, fetchFileContent, downloadFile, deleteFile } from '../../actions/UserActions';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent,
} from '@mui/material';
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccessDenied from '../Errors/AccessDenied';
import MDButton from '../../components/MDButton';
import FileUploadToUser from './UploadFiles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import axios from 'axios';
import { saveAs } from 'file-saver';
const FilePreview = ({ fileContent, fileName, onClose, userId }) => {
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const getFileExtension = (fileName) => {
    const parts = fileName.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    }
    return '';
  };
  const fileExtension = getFileExtension(fileName);

  const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
  const isPdf = fileExtension === 'pdf';

  const handleDownload = () => {
    dispatch(downloadFile(userId, fileName));
  };

  const handleDelete = () => {
    setSnackbarOpen(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteFile(userId, fileName))
      .then(() => {
        dispatch(fetchUserFiles(userId));
      });
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="sm">
        <DialogTitle>File Preview</DialogTitle>
        <DialogContent>
          {isImage && (
            <>
              <img
                src={`data:image/${fileExtension};base64,${fileContent}`}
                alt="Preview"
              />
              <MDButton className='btn-sm me-1 mt-2' size='small' color={'info'} onClick={handleDownload}>
                Download
              </MDButton>
              <MDButton className='btn-sm ms-1 mt-2' size='small' color={'error'} onClick={handleDelete}>
                Delete
              </MDButton>
            </>
          )}
          {isPdf && (
            <>
              <embed
                src={`data:application/pdf;base64,${fileContent}`}
                type="application/pdf"
                width="100%"
                height="500px"
              />
              <MDButton className='btn-sm me-1 mt-2' size='small' color={'info'} onClick={handleDownload}>
                Download
              </MDButton>
              <MDButton className='btn-sm ms-1 mt-2' size='small' color={'error'} onClick={handleDelete}>
                Delete
              </MDButton>
            </>
          )}

          {!isImage && (!isPdf) && (
            <>
              <p>Preview not available for this file type.</p>
              <MDButton className='btn-sm me-1 mt-2' size='small' color={'info'} onClick={handleDownload}>
                Download
              </MDButton>
              <MDButton className='btn-sm ms-1 mt-2' size='small' color={'error'} onClick={handleDelete}>
                Delete
              </MDButton>
            </>
          )}
          <MDButton className='btn-sm ms-2 mt-2' size='small' color={'secondary'} onClick={onClose}>
            Close
          </MDButton>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="warning"
        >
          Are you sure you want to delete this file?
          <MDButton color="inherit" size="small" onClick={handleConfirmDelete}>
            Confirm
          </MDButton>
        </Alert>
      </Snackbar>
    </>
  );
};

const PersonnelInfo = ({ currentUser, darkMode }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const files = useSelector((state) => state.users.files);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleViewFile = async (fileName) => {
    try {
      const response = await dispatch(fetchFileContent(id, fileName));
      setSelectedFile({
        fileName, 
        fileContent: response.data,
      });
    } catch (error) {
      console.error('Error fetching file content:', error);
    }
  };

  const handleCloseFilePreview = () => {
    setSelectedFile(null);
  };
  
  useEffect(() => {
    dispatch(getUser(id));
    dispatch(fetchUserFiles(id));
  }, [dispatch, id]);

  const getTitleFromPermission = (permissionName) => {
    if (permissionName.includes('projets')) {
      return 'projets';
    } else if (permissionName.includes('fichiers')) {
      return 'fichiers';
    } else if (permissionName.includes('matériels')) {
      return 'matériels';
    } else if (permissionName.includes('factures')) {
      return 'factures';
    } else if (permissionName.includes('véhicules')) {
      return 'véhicules';
    } else if (permissionName.includes('congés')) {
      return 'congés';
    } else if (permissionName.includes('encaissements')) {
      return 'encaissements';
    } else if (permissionName.includes('devis')) {
      return 'devis';
    } else if (permissionName.includes('primes')) {
      return 'primes';
    } else if (permissionName.includes('salaires')) {
      return 'salaires';
    } else if (permissionName.includes('contacts')) {
      return 'contacts';
    } else if (permissionName.includes('charges')) {
      return 'charges';
    }
    else if (permissionName.includes('clients')) {
      return 'clients';
    }
    return 'Other';
  };

  const permissionsByTitle = {};
  user?.permissions?.forEach((permission) => {
    const title = getTitleFromPermission(permission.name);
    if (!permissionsByTitle[title]) {
      permissionsByTitle[title] = [];
    }
    permissionsByTitle[title].push(permission);
  });

  const handleExportToWord = async (user) => {
    try {
      const response = await axios.get('/attestation_travail.docx', {
        responseType: 'arraybuffer',
      });

      const projectTemplate = response.data;
      const zip = new PizZip(projectTemplate);
      const doc = new Docxtemplater(zip);
      let day = new Date();

      const data = {
        nom: user.nom,
        prenom: user.prenom || "-",
        cin: user.cin || "-",
        recrutment: user.date_recrutement || "-",
        export: day.toLocaleDateString() || "-",
      };

      doc.setData(data);

      try {
        doc.render();
      } catch (error) {
        console.error('Error rendering the template:', error);
        return;
      }

      try {
        const blob = doc.getZip().generate({ type: 'blob' });
        saveAs(blob, `user_${user?.nom}.docx`);
      } catch (error) {
        console.error('Error generating the Word document:', error);
      }
    } catch (error) {
      console.error('Error fetching the template:', error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && ((currentUser.roles.includes("ROLE_ADMIN")) || (currentUser.permissions.includes("Voir les informations sur les employés"))) ? (
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Personnel: {user?.nom} {user?.prenom}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Card elevation={3} style={{ color: darkMode ? "#fff" : "#000" }}>
                <CardContent>
                  <Typography variant="body1">Username: {user?.username}</Typography>
                  <Typography variant="body1">CIN: {user?.cin}</Typography>
                  <Typography variant="body1">Nom: {user?.nom}</Typography>
                  <Typography variant="body1">Prenom: {user?.prenom}</Typography>
                  <Typography variant="body1">Email: {user?.email}</Typography>
                  <Typography variant="body1">Telephone: {user?.telephone}</Typography>
                  <Typography variant="body1">Adresse: {user?.adresse}</Typography>
                  <Typography variant="body1">Salaire: {user?.salaire}</Typography>
                  <Typography variant="body1">Contrat: {user?.contart}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card elevation={3} style={{ color: darkMode ? "#fff" : "#000" }}>
                <CardContent>
                  <div>
                    {files.length > 0 ? (
                      <List>
                        {files.map((file, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={file} />
                            <MDButton
                              variant="contained"
                              className='btn-sm mt-2'
                              size='small'
                              color="primary"
                              onClick={() => handleViewFile(file)}
                            >
                              Voir
                            </MDButton>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <p>Aucun fichier trouvé.</p>
                    )}
                    {selectedFile && (
                      <FilePreview
                        fileContent={selectedFile.fileContent}
                        fileName={selectedFile.fileName}
                        userId={id}
                        onClose={handleCloseFilePreview}
                      />
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card elevation={3} style={{ color: darkMode ? "#fff" : "#000" }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Poste
                  </Typography>
                  <List>
                    {user?.roles &&
                      user.roles.map((role, index) => (
                        <div key={index}>
                          <ListItem>
                            <ListItemText primary={role.name} />
                          </ListItem>
                          {index < user.roles.length - 1 && <Divider />}
                        </div>
                      ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} style={{ color: darkMode ? "#fff" : "#000" }} >
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    Files
                  </Typography>
                  <FileUploadToUser userId={user?.id} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Card elevation={3} style={{ marginTop: '20px', color: darkMode ? "#fff" : "#000" }}>
                <CardContent>
                  <Typography variant="h5" mb={2} gutterBottom>
                    Permissions
                  </Typography>
                  {Object.entries(permissionsByTitle).map(([title, permissions], index) => (
                    <div key={index}>
                      <Typography variant="h6" gutterBottom>
                        {title}
                      </Typography>
                      <Grid container spacing={2}>
                        {permissions.map((permission, innerIndex) => (
                          <Grid item xs={12} sm={6} md={4} key={innerIndex}>
                            {permission.name}
                            {innerIndex < permissions.length - 1 && <Divider sx={{ margin: '0px !important' }} />}
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ))}
                  <MDButton
                    variant="contained"
                    className="me-1 float-end"
                    component={Link}
                    to={`/ressources-humaines/personnel/user/update/permessions/${user?.id}`}
                    color="info"
                  >
                    <BorderColorIcon />
                  </MDButton>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid elevation={3} mt={'20px'} style={{ marginLeft: "20px" }}>
            <MDButton
              variant="contained"
              className="me-1"
              component={Link}
              to={`/primes/create`}
              color="primary"
            >
              Donner des Primes
            </MDButton>
            <MDButton
              variant="contained"
              className="me-1"
              component={Link}
              to={`/cotisation/create`}
              color="primary"
            >
              Calculer Salaire
            </MDButton>
            <MDButton
              variant="contained"
              className="me-1"
              color="primary"
              onClick={() => handleExportToWord(user)}
            >
              Exporter
            </MDButton>
          </Grid>
        </Container>
      ) : (
        <AccessDenied />
      )}
    </DashboardLayout>
  );
};

export default PersonnelInfo;
