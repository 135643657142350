import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllUserPrimes, updateUserPrimes, getAllPrimes } from '../../actions/PrimesActions';
import {
  Container,
  Grid,
  Button,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';

const UpdateUserPrimes = ({currentUser}) => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const primes = useSelector((state) => state.primes.primes);
  const userPrimes = useSelector((state) => state.primes.userPrimes);

  const [selectedPrimes, setSelectedPrimes] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    dispatch(getAllPrimes());
    dispatch(getAllUserPrimes(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    // Initialize selectedPrimes with the user's existing primes
    setSelectedPrimes(userPrimes.map((prime) => prime.id));
  }, [userPrimes]);

  const handleCheckboxChange = (primeId) => {
    const updatedSelection = [...selectedPrimes];
    if (updatedSelection.includes(primeId)) {
      updatedSelection.splice(updatedSelection.indexOf(primeId), 1);
    } else {
      updatedSelection.push(primeId);
    }
    setSelectedPrimes(updatedSelection);
  };

  const handleUpdatePrimes = () => {
    dispatch(updateUserPrimes(userId, { primeIds: selectedPrimes }))
      .then(() => {
        setSuccessMessage('User primes updated successfully.');
      })
      .catch((error) => {
        setErrorMessage(`Error updating user primes: ${error}`);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les primes")) ? (
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Update User Primes
          </Typography>

          <Grid container spacing={2}>
            {primes.map((prime) => (
              <Grid item xs={4} key={prime.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedPrimes.includes(prime.id)}
                      onChange={() => handleCheckboxChange(prime.id)}
                    />
                  }
                  label={prime.name}
                />
              </Grid>
            ))}
          </Grid>

          <MDButton className='mt-4' variant="contained" color="info" onClick={handleUpdatePrimes}>
            Update Primes
          </MDButton>

          <Snackbar
            open={successMessage !== null}
            autoHideDuration={6000}
            onClose={() => setSuccessMessage(null)}
          >
            <Alert onClose={() => setSuccessMessage(null)} severity="success">
              {successMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={errorMessage !== null}
            autoHideDuration={6000}
            onClose={() => setErrorMessage(null)}
          >
            <Alert onClose={() => setErrorMessage(null)} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

export default UpdateUserPrimes;
