import PrimeService from '../services/primeServices';
import {
  CREATE_PRIME_SUCCESS,
  CREATE_PRIME_FAILURE,
  UPDATE_PRIME_SUCCESS,
  UPDATE_PRIME_FAILURE,
  GET_PRIME_SUCCESS,
  GET_PRIME_FAILURE,
  DELETE_PRIME_SUCCESS,
  DELETE_PRIME_FAILURE,
  GET_ALL_PRIMES_SUCCESS,
  GET_ALL_PRIMES_FAILURE,
  GET_ALL_USER_PRIMES_SUCCESS,
  GET_ALL_USER_PRIMES_FAILURE,
  UPDATE_USER_PRIME_SUCCESS,
  UPDATE_USER_PRIME_FAILURE,
} from './types';

export const createPrimes = (primeData) => {
  return (dispatch) => {
    return PrimeService.createPrimes(primeData)
      .then((response) => {
        dispatch({
          type: CREATE_PRIME_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_PRIME_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const addPrimesToUsers = (Data) => {
  return (dispatch) => {
    return PrimeService.AddPrimesToUser(Data)
      .then((response) => {
        dispatch({
          type: CREATE_PRIME_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_PRIME_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllPrimes = () => {
  return (dispatch) => {
    return PrimeService.getAllPrimes()
      .then((response) => {
        dispatch({
          type: GET_ALL_PRIMES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_PRIMES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updatePrimes = (primeId, updatedPrimes) => {
  return (dispatch) => {
    return PrimeService.updatePrimes(primeId, updatedPrimes)
      .then((response) => {
        dispatch({
          type: UPDATE_PRIME_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PRIME_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getPrimes = (primeId) => {
  return (dispatch) => {
    return PrimeService.fetchPrimes(primeId)
      .then((response) => {
        dispatch({
          type: GET_PRIME_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PRIME_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deletePrimes = (primeId) => {
  return (dispatch) => {
    return PrimeService.deletePrimes(primeId)
      .then((response) => {
        dispatch({
          type: DELETE_PRIME_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PRIME_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllUserPrimes = (userId) => {
  return (dispatch) => {
    return PrimeService.fetchUserPrimes(userId)
      .then((response) => {
        dispatch({
          type: GET_ALL_USER_PRIMES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_USER_PRIMES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateUserPrimes = (userId, updatedPrimes) => {
  return (dispatch) => {
    return PrimeService.updateUsersPrimes(userId, updatedPrimes)
      .then((response) => {
        dispatch({
          type: UPDATE_USER_PRIME_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_USER_PRIME_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllUserPrimesByMonth = (userId, mois) => {
  return (dispatch) => {
    return PrimeService.fetchUserPrimesByMonth(userId, mois)
      .then((response) => {
        dispatch({
          type: GET_ALL_USER_PRIMES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_USER_PRIMES_FAILURE,
          payload: error.message,
        });
      });
  };
};