import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePrimes } from '../../actions/PrimesActions';
import {
    Container,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';
import { getUser } from "../../actions/UserActions";

const UserPrimesList = ({ primes, darkMode, currentUser, userId }) => {
    const dispatch = useDispatch();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedPrimeId, setSelectedPrimeId] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [pageSize, setPageSize] = useState(25);

    const filteredSalaires = selectedMonth
        ? primes.filter((prime) => prime.mois.startsWith(selectedMonth))
        : primes;

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Prime', flex: 2 },
        { field: 'montant', headerName: 'Montant', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'mois', headerName: 'Mois', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => (
                <>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les primes")) && (
                        <MDButton
                            // variant="outlined"
                            className='btn-sm me-1'
                            color="error"
                            size='small'
                            onClick={() => handleOpenDeleteModal(params.row.id)}
                        >
                            Supprimer
                        </MDButton>
                    )}
                </>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    const handleOpenDeleteModal = (primeId) => {
        setSelectedPrimeId(primeId);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedPrimeId(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        dispatch(deletePrimes(selectedPrimeId))
            .then(() => {
                dispatch(getUser(userId));
                closeDeleteModal();
            })
            .catch((error) => {
                console.error('Error delete Request:', error);
            });
    };

    return (
        <>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les primes")) ? (
                <>
                    <div className='mt-3 mb-3' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextField
                            label="Filtrer par mois"
                            type="month"
                            fullWidth
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <DataGrid
                        rows={filteredSalaires}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        autoHeight
                        pageSize={pageSize}
                        className={darkMode ? "text-light" : "text-dark"}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        disableSelectionOnClick
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />

                    <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                        <DialogTitle className="text-dark">Confirmation de la suppression</DialogTitle>
                        <DialogContent>
                            <DialogContentText className="text-dark">
                                Etes-vous sûr de vouloir supprimer ce Prime?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={closeDeleteModal} color="light">
                                Annuler
                            </MDButton>
                            <MDButton onClick={handleDelete} color="error">
                                Supprimer
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default UserPrimesList;
